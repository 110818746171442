import React from "react";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { useKeycloak } from "@react-keycloak/web";
import { Container } from "reactstrap";

import {
    setIsShopUser,
    setNumberClick,
    setOneShopSwitch,  
    store,
} from "@components/VerticalLayout/store";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

import "./Login.css";

export function LoginWithSSO() {
    const fetchWithToken = useFetchWithKcToken();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState<boolean>(false);

    const { isShopUser } = useSnapshot(store);

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string) => {
            return await fetchWithToken(
                `${process.env.REACT_APP_ACCESS_API_URL}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    return response;
                })
                .catch((_) => {
                    return "";
                });
        },
        // eslint-disable-next-line
        []
    );

    async function handleValidSubmit(values: any) {
        setLoading(true);
        const urlVerifyUserAccount = `${process.env.REACT_APP_ACCESS_API_URL}/customerAccount`;


let data = { email: values.email };
        await fetchWithToken(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/json",            },
            body: JSON.stringify(data),

            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (response.error) {
                    setLoading(false);
                } else {
                    if (response.data?.email !== "" && !response.error) {
                        if (response.data?.etataccount === "Activer") {
                            const urlApi = `${process.env.REACT_APP_ACCESS_API_URL}/getAutorisationProfileEntity?uuidEntity=${response.data?.uuidprofile}`;
                            await fetchWithToken(urlApi, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    localStorage.setItem(
                                        "authorizationSetting",
                                        JSON.stringify(result)
                                    );
                                });

                            localStorage.setItem(
                                "uuidUser",
                                response.data?.uuiduser
                            );
                            localStorage.setItem(
                                "Uid_Entitie_User",
                                response.data?.uidentitie
                            );

                            localStorage.setItem(
                                "jwt",
                                keycloak.token as string
                            );
                            localStorage.setItem(
                                "firstName",
                                response.data?.firstname
                            );
                            localStorage.setItem(
                                "lastName",
                                response.data?.name
                            );
                            localStorage.setItem("email", response.data.email);
                            localStorage.setItem(
                                "secondaryColor",
                                response.data.secondarycolor
                            );
                            localStorage.setItem(
                                "mainColor",
                                response.data.maincolor
                            );

                            setLoading(false);
                            navigate("/compte");
                            const url = `${process.env.REACT_APP_API_V2_URL}/userInfo?userId=${response.data?.uidentitie}`;

                            // await fetchWithToken(url, {
                            //     headers: {
                            //         "Content-Type": "application/json",
                            //     },
                            // })
                            //     .then((response) => response.json())
                            //     .then(async (result) => {
                            //         let pays: any = cryptDecryptDataUser(
                            //             result.data?.country
                            //         );

                            //         if (
                            //             result.data?.type === "boutique" ||
                            //             result.data?.type === "Boutique"
                            //         ) {
                            //             await cryptDecryptDataUser(
                            //                 result.data?.commercialname
                            //             ).then((name: string) => {
                            //                 localStorage.setItem(
                            //                     "selectedAccount",
                            //                     name
                            //                 );
                            //                 setOneShopSwitch(
                            //                     result.data?.uid_franchise,
                            //                     result.data?.uid,
                            //                     pays,
                            //                     name
                            //                 );
                            //             });

                            //             setNumberClick(0);
                            //             setIsShopUser(true);
                            //             navigate("/setting/customer-account");
                            //         } else {
                                        
                            //             setIsShopUser(false);
                            //         }
                            //     })
                            //     .catch((_) => {
                            //         setIsShopUser(false);
                            //     });
                        } else {
                            return swal({
                                icon: "error",
                                content: (
                                    <p>
                                        {
                                            t(
                                                `This account is ${response.data?.etataccount}`
                                            )!
                                        }
                                    </p>
                                ),
                                buttons: false,
                                timer: 4000,
                            });
                        }
                    } else {
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
                setLoading(false);
            })
            .catch((_) => {
                setLoading(false);
                return false;
            });
    }

    // React.useEffect(() => {
    //     console.log(keycloak.tokenParsed)
    //     const uidEntity = localStorage.getItem("Uid_Entitie_User");

    //     if (
    //         uidEntity !== null 
    //         // uidEntity === keycloak.tokenParsed?.uuid &&
    //         // isShopUser === false
    //     ) {
    //         navigate("/compte");
    //     }
    // });

    React.useEffect(() => {
        handleValidSubmit({
            // keycloak redire
            email: keycloak.authenticated && keycloak.tokenParsed?.email,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container fluid={true} className="p-0 m-0 overflow-hidden">
            <NProgressWrapper isLoading={loading} />
        </Container>
    );
}
