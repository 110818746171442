import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import ErrorToast from "@components/Common/ErrorTost";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    setLimit?: Function;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    setLimit = () => 1,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const [error, setError] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");


    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    async function archiveFidelity() {
        toast.dismiss();
        let isFidelityCentralization: boolean = false;
        let isNotFidelityCentralization: boolean = false;

        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            arrayOfRows.forEach(async (el: any) => {
                if (el[t("State")] === true) {
                    isFidelityCentralization = true;
                } else {
                    isNotFidelityCentralization = true;
                    let dataFidelity = {
                        userId: userID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        isShop: false,
                        data: {
                            [el.Id]: {
                                designation: el[t("Display name")],
                                type: el[t("Loyalty")],
                                number_fidelity_points:
                                    el.number_fidelity_points,
                                money_correspondence: el.moneyCorrespondence,
                                devise: el.Devise,
                                pays: el.Pays,
                                authentication_mode: el.authentication_mode,
                                sortedVisibility: el.sortedVisibility,
                                Login: el.Login,
                                password: el[t("Password")],
                                note: el[t("Remark")],
                                isActive: false,
                                isArchived: true,
                                shopId: el.shopId,
                                dashboardLink: el["Dashboard Link"],
                                user: el.user,
                                apiKey: el["API key"],
                                auresKonnectGroup: el[t("Group")],
                                isCentralizationActive: el[t("State")],
                                isVisibiliteActive: el[t("isVisibiliteActive")],
                                fidelityChoiceIndex: el.fidelityChoiceIndex,
                            },
                        },
                    };

                    const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataFidelity),
                    };
                    try {
                        mutate(
                            apiUrlAdd,
                            await fetchWithToken(apiUrlAdd, requestOptions)
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        setError(true);
                                    }

                                    setDataIsUpdated!(true);
                                })
                        );
                    } catch (e) {
                        toast.error(`${t("There's an error")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
            });
            if (error === false && isNotFidelityCentralization) {
                toast.success(
                    `${t(
                        "The fidelity settings have been successfully archived"
                    )}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setDataIsUpdated!(true);
            }
            if (isFidelityCentralization) {
                ErrorToast(
                    t("Attention! you cannot archive a centralized loyalty")
                );

                setDataIsUpdated!(true);
            }
            setLimit(2);
        } else {
            let dataFidelity = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        designation: e.selectedRow[t("Display name")],
                        type: e.selectedRow[t("Loyalty")],
                        number_fidelity_points:
                            e.selectedRow.number_fidelity_points,
                        money_correspondence: e.selectedRow.moneyCorrespondence,
                        devise: e.selectedRow.Devise,
                        pays: e.selectedRow.Pays,
                        authentication_mode: e.selectedRow.authentication_mode,
                        sortedVisibility: e.selectedRow.sortedVisibility,
                        Login: e.selectedRow.Login,
                        password: e.selectedRow[t("Password")],
                        note: e.selectedRow[t("Remark")],
                        isActive: false,
                        isArchived: true,
                        shopId: e.selectedRow.shopId,
                        dashboardLink: e.selectedRow["Dashboard Link"],
                        user: e.selectedRow.user,
                        apiKey: e.selectedRow["API key"],
                        auresKonnectGroup: e.selectedRow[t("Group")],
                        isCentralizationActive: e.selectedRow[t("State")],
                        isVisibiliteActive:
                            e.selectedRow[t("isVisibiliteActive")],

                        fidelityChoiceIndex: e.selectedRow.fidelityChoiceIndex,
                    },
                },
            };
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataFidelity),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The fidelity settings have been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, this fidelity setting(s) can be used by other modules.\n Do you want to confirm the archiving of the selected fidelity setting(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveFidelity();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
