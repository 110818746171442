import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as InformationStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsLoading,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { setItemsDisable } from "@store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationAssociatedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setData: Function;
    setLimit: Function;
};

export function ConfirmationAssociatedMessage({
    isModalOpened,
    setIsModalOpened,
    setData,
    setLimit,
}: ConfirmationAssociatedMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const {
        localShop,
        isAssociateModalOpened,
        isAssociateModalClosed,
        numberActiveMultiShop,
        selectedRows,
        ids,
        rowsShop,
        activeModeInformation,
        message,
        localShopWithInformation,
    } = useSnapshot(InformationStore);
    const [error] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function getUnion(array1: any, array2: any) {
        let data: any = [];
        let max: any = array2.length > array1.length ? array2 : array1;
        let min: any = array2.length < array1.length ? array2 : array1;
        if (array1.length === array2.length) {
            min = array1;
            max = array2;
        }
        min.forEach((el: any) => {
            if (max.indexOf(el) !== -1) {
                data.push(el);
            }
        });
        return data;
    }
    function canAssociate(shopId: string, MI: any) {
        let can: boolean = false;
        let shopInform: any = localShopWithInformation.find(
            (x: any) => x.shopId === shopId
        );

        let unionMOS: any = getUnion(
            shopInform.modeOfSale,
            MI[t("Sales method")].map((x: any) => {
                return x.value;
            })
        );
        let unionSOS: any = getUnion(shopInform.supportOfSale, [
            MI[t("Sales support")]?.value,
        ]);

        unionMOS = MI[t("Sales method")].filter((x: any) => {
            return unionMOS.indexOf(x.value) !== -1;
        });
        if (unionMOS.length > 0 && unionSOS.length > 0) {
            can = true;
        }
        return {
            can: can,
            unionMOS: unionMOS,
            unionSOS: [MI[t("Sales support")]],
        };
    }
    function checkIfAssociated(shopId: string) {
        let associated: any[] = [];

        rowsShop.forEach((elt: any) => {
            if (elt.shopId === shopId && elt[t("Shop")] !== undefined) {
                associated = elt.subRows;
            }
        });
        return associated;
    }

    async function AssociateModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();

        let localData: any = [];

        let blockedAssignArray: boolean[] = [];
        let isReassignment: boolean = false;
        for (let element of localShop) {
            let objectData: any = {};
            let associated = checkIfAssociated(element);
            if (selectedRows.length !== 0) {
                // eslint-disable-next-line no-loop-func
                selectedRows.forEach(async (el: any) => {
                    if (el.Id !== undefined) {
                        let canAssign: any = canAssociate(element, el);
                        let indexOfAssociated = associated.findIndex(
                            (elt: any) => elt.Id === el.Id
                        );

                        let indexOfActive = activeModeInformation.findIndex(
                            (element: any) => element.Id === el.Id
                        );
                        if (
                            indexOfAssociated === -1 ||
                            (indexOfAssociated !== -1 &&
                                activeModeInformation[indexOfActive].state !==
                                    associated[indexOfAssociated].state)
                        ) {
                            if (canAssign.can === true) {
                                Object.assign(objectData, {
                                    [el.Id]: {
                                        designation:
                                            el[t("Mode of information")],
                                        idMI: el?.idMI,
                                        supportOfSale: canAssign.unionSOS[0],
                                        salesSupportData: [
                                            canAssign.unionSOS[0],
                                        ],
                                        displayName: el[t("Display name")],
                                        modeOfSale: canAssign.unionMOS,
                                        tag: el.tag,
                                        isArchived: el.isArchived,
                                        shopId: element,
                                        state: activeModeInformation.find(
                                            (element: any) =>
                                                element.Id === el.Id
                                        ).state,
                                        isReassignment:
                                            associated.findIndex(
                                                (elt: any) => elt.Id === el.Id
                                            ) !== -1,
                                        advancedDisplayName:
                                            el.advancedDisplayName,
                                    },
                                });
                                blockedAssignArray.push(false);
                            } else {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                blockedAssignArray.push(true);
                            }
                        }
                    }
                });

                let dataModeOfInformation = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [element],
                    franchiseId: franchiseID,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                // eslint-disable-next-line no-loop-func
                Object.values(objectData).forEach((el: any) => {
                    if (el.isReassignment === true) {
                        isReassignment = true;
                    }
                });
                localData.push(dataModeOfInformation);
            }
        }
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetchWithToken(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }

            if (error === false && blockedAssignArray.includes(false)) {
                const getItemsDisableUrl: Response = await fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(
                    `${
                        isReassignment
                            ? t("Information mode reassignment was successful")
                            : t("Information mode assignment was successful")!
                    }`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setData(true);
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length -
                        ids
                );
                setIsLoading(false);
            }
            if (blockedAssignArray.includes(true)) {
                setLimit(2);
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                setIsLoading(false);
            }
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        AssociateModeOfInformation();
                        setIsModalOpened(!isModalOpened);
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
