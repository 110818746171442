import { CardWrapperSpecific } from "./CardWrapperSpecific";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { setItemActivation } from "../store";

export default function Activation({ selectedRows }: any) {
    const { t } = useTranslation();
    const [items, setItems] = React.useState<any>([
        {
            name: t("C&C website"),
            active:
                Object.values(selectedRows).length > 0
                    ? selectedRows[0]["site web"] === 1
                        ? true
                        : false
                    : false,
            image: "./images/applicationc&c.png",
        },
        {
            name: t("C&C app"),
            active:
                Object.values(selectedRows).length > 0
                    ? selectedRows[0].application
                    : false,
            image: "./images/sitec&c.png",
        },
    ]);
    useEffect(() => {
        setItemActivation(items);
    }, [items]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-3"
            >
                {t("Activation")}
            </StyledH2>
            <div
                className="d-flex flex-row justify-content-center align-items-center"
                style={{ gap: "100px", marginTop: "150px" }}
            >
                {items.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={items}
                        index={index}
                        setItems={setItems}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}
