import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { uuid } from "uuidv4";

import store, {
    setIsLoading,
    setKey,
    setActions,
    setIsActionsClicked,
    setIsEdited,
} from "../store";
import { store as ModeOfSale } from "../../../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function ActionColumnModeSale({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    isModeOfSaleArchived,
    setIsModeOfSaleArchived,
    setIsAddModeOfSaleClicked,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberArchived,
        numberActive,
        shopsModeSaleData,
        archivedModeOfSaleFranchise,
        isEdited,
        isActionsClicked,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID, shopID } = useSnapshot(
        ModeOfSale
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [listSaleMode, setListSaleMode] = React.useState<any>([]);
    const shopId = oneShop ? shopID : 0;
    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = associateModeOfSale();
        dataArchive = archiveModeSale();
    } else {
        dataArchive = archiveModeOfSaleFranchise();
    }

    function archiveModeSale() {
        let idsAssociated: any = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function archiveModeOfSaleFranchise() {
        let idsAssociated: any = [];
        if (
            archivedModeOfSaleFranchise.othersData
                .archivedModeOfSaleFranchise !== undefined
        ) {
            archivedModeOfSaleFranchise.othersData.archivedModeOfSaleFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function associateModeOfSale() {
        let idsAssociated: any[] = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isModeOfSaleExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });

        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        }
        !isExist && setIsArchiveModalOpened(true);
    }

    async function activeModeOfSale() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let dataModeOfSale = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [el.Id]: {
                        designation: el["Mode of sale"],
                        tag: el.tag,
                        display_name: el.display_name,
                        support_vente: el.support_vente,
                        isArchived: false,
                        state: el.shopId === "0" ? true : el.state,
                        isFavorite: el.isFavorite,
                        shopId: el.shopId,
                        advancedDisplayName: el.advancedDisplayName,
                    },
                },
            };

            localData.push(dataModeOfSale);
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
            setIsLoading(false);
        }

        if (error === false) {
            toast.success(
                `${t("Sales method has been successfully activated")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setIsLoading(false);
            setKey(uuid());
        }
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element.support_vente.forEach((sale: any) => {
                                if (sale === elt[t("Sale support")]) {
                                    isSaleSupportArchived = true;
                                }
                            });
                        });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isSaleSupportArchived;
    }
    const dataFetch = React.useCallback(async () => {
        const data = await (
            await fetchWithToken(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
            )
        ).json();

        let listSaleMode: any[] = [];
        if (data.data !== undefined) {
            data.data.forEach((element: any) => {
                if (element["Mode de vente"] !== undefined)
                    element["Mode de vente"].forEach((elt: any) => {
                        listSaleMode.push(`${elt.value} ${element.shopId}`);
                    });
            });
        }
        setListSaleMode(listSaleMode);
        // eslint-disable-next-line
    }, [franchiseID, shopId, userID]);
    
    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);
    
    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_FRmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddModeOfSaleClicked(false);
                                        setIsModeOfSaleArchived(false);
                                    });
                                } else {
                                    setIsModeOfSaleArchived(false);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistActivated">
                                {t("Sales method activated(s)")}({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);

                                        setIsModeOfSaleArchived(true);
                                        setIsAddModeOfSaleClicked(false);
                                    });
                                } else {
                                    setIsModeOfSaleArchived(true);
                                    setIsAddModeOfSaleClicked(false);
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {t("Sales method archived(s)")}({numberArchived}
                                )
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz mdv_btn_FRhistoric",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);

                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        }
                                        setIsAddModeOfSaleClicked(false);
                                    });
                                } else {
                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                isLoading ||
                                                selectedRows.length === 0 ||
                                                (isModeOfSaleArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={async () => {
                                        setIsLoading(true);
                                        let isArchived = await isSaleSupportArchived();

                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else if (
                                            isArchived === true &&
                                            isModeOfSaleArchived
                                        ) {
                                            ErrorToast(
                                                `${t(
                                                    "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                )!}`
                                            );
                                            setIsLoading(false);
                                        } else {
                                            setIsLoading(false);
                                            isModeOfSaleArchived
                                                ? selectedRows.some(
                                                      (element: any) =>
                                                          dataArchive.includes(
                                                              element.Id
                                                          )
                                                  ) && !oneShop
                                                    ? setIsModalOpened(
                                                          !isModalOpened
                                                      )
                                                    : activeModeOfSale()
                                                : isModeOfSaleExist();
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRarchived cmn_btn_FRunarchive">
                                        {isModeOfSaleArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                    listSaleMode={listSaleMode}
                />
            ) : null}
        </React.Fragment>
    );
}
