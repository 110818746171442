import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";

import { store as posEditor } from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated: Function;
    data: any;
    setChoicePosEditor: Function;
    PosData?: any;
    setIsEdited: Function;
};

export function ConfirmationAssociationModal({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    data,
    setChoicePosEditor,
    PosData,
    setIsEdited,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { isArchived, posEditorData, posEditorFranchise } = useSnapshot(
        posEditor
    );
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    async function AssociatePosEditor() {
        toast.dismiss();
        let objectDataArchive: any =
            posEditorData.data[0] !== undefined
                ? {
                      [posEditorData.data[0].Id]: {
                          posEditor: posEditorData.data[0][t("Pos Editor")],
                          note: posEditorData.data[0][t("Remark")],
                          isActive: false,
                          isArchived: true,
                          shopId: shopID,
                      },
                  }
                : {};
        let objectData =
            posEditorFranchise.othersData.allData.data[
                posEditorFranchise.othersData.allData.data.findIndex(
                    (el: any) =>
                        el[t("Display name")] === data.designation &&
                        el[t("Pos Editor")] === data.choicePosEditor &&
                        ((el[t("Group")] === data.group &&
                            data.choicePosEditor.startsWith(
                                t("PosEditor Aures Konnect")
                            )) ||
                            !data.choicePosEditor.startsWith(
                                t("PosEditor Aures Konnect")
                            ))
                )
            ];

        Object.assign(objectDataArchive, {
            [objectData.Id]: {
                posEditor: objectData[t("Pos Editor")],
                codeComment: objectData["codeComment"],
                note: objectData[t("Remark")],
                isActive: false,
                isArchived: false,
                shopId: shopID,
                user: objectData.user,
                versionServer: objectData.versionServer,
                keyAuthentication: objectData.keyAuthentication,
                apiEmail: objectData.apiEmail,
                port: objectData.port,
                server: objectData.server,
                apiServer: objectData.apiServer,
                setupId: objectData.setupId,
                apiPassword: objectData.apiPassword,
                modeOfSaleEmpId: objectData.modeOfSaleEmpId,
                modeOfSaleLivId: objectData.modeOfSaleLivId,
                modeOfSaleSPId: objectData.modeOfSaleSPId,
                divisionPriceItems: objectData.divisionPriceItems,
                divisionPriceOptions: objectData.divisionPriceOptions,
                divisionTax: objectData.divisionTax,
                layoutLabel: objectData.layoutLabel,
                apiWebhook: objectData.apiWebhook,
                tcposStoreId: objectData?.tcposStoreId,
                happyHour: objectData?.happyHour,
                happyHourState: objectData?.happyHourState,
                tcposWebhookPort: objectData?.tcposWebhookPort,
                tcposWebhookIPAddess: objectData?.tcposWebhookIPAddess,
                tcposFranchiseSchema: objectData?.tcposFranchiseSchema,
                tcposPaymentCash: objectData?.tcposPaymentCash,
                tcposPaymentCreditCard: objectData?.tcposPaymentCreditCard,
                tcposSetupDisk: objectData?.tcposSetupDisk,
                tcposCodeCountry: objectData?.tcposCodeCountry,
                tcposLangue: objectData?.tcposLangue,
                tcposApiPort: objectData?.tcposApiPort,
                tcposPartnerApiUrl: objectData?.tcposPartnerApiUrl,
                tcposIp: objectData?.tcposIp,
                tcposConnectionCheckTime: objectData?.tcposConnectionCheckTime,
                tcposDeletingDataSate: objectData?.tcposDeletingDataSate,
                codeRetryTable: objectData?.codeRetryTable,
                maxRetry: objectData?.maxRetry,
                retryDelai: objectData?.retryDelai,
                checkStock: objectData?.checkStock,
                orderValidationPreCheck: objectData?.orderValidationPreCheck,
                timeoutAPI: objectData?.timeoutAPI,
                automaticProductionLevel: objectData?.automaticProductionLevel,
                tcposCorrTakeawayDisplayName:
                    objectData?.tcposCorrTakeawayDisplayName,
                tcposCorrTakeawayID: objectData?.tcposCorrTakeawayID,
                tcposCorrOnSiteDisplayName:
                    objectData?.tcposCorrOnSiteDisplayName,
                tcposCorrOnSiteID: objectData?.tcposCorrOnSiteID,
            },
        });

        let posEditor = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data:
                posEditorData.data.length > 0
                    ? objectDataArchive
                    : {
                          [objectData.Id]: {
                              posEditor: PosData.posEditor,
                              codeComment: PosData.codeComment,
                              note: PosData.note,
                              isActive: false,
                              isArchived: false,
                              shopId: shopID,
                              user: PosData.user,
                              versionServer: PosData.versionServer,
                              keyAuthentication: PosData.keyAuthentication,
                              apiEmail: PosData.apiEmail,
                              port: PosData.port,
                              server: PosData.server,
                              apiServer: PosData.apiServer,
                              setupId: PosData.setupId,
                              apiPassword: PosData.apiPassword,
                              modeOfSaleEmpId: PosData.modeOfSaleEmpId,
                              modeOfSaleLivId: PosData.modeOfSaleLivId,
                              modeOfSaleSPId: PosData.modeOfSaleSPId,
                              divisionPriceItems: PosData.divisionPriceItems,
                              divisionPriceOptions:
                                  PosData.divisionPriceOptions,
                              divisionTax: PosData.divisionTax,
                              layoutLabel: PosData.layoutLabel,
                              apiWebhook: PosData.apiWebhook,
                              happyHour: PosData?.happyHour,
                              happyHourState: PosData?.happyHourState,
                              tcposStoreId: PosData?.tcposStoreId,
                              tcposWebhookPort: PosData?.tcposWebhookPort,
                              tcposWebhookIPAddess:
                                  PosData?.tcposWebhookIPAddess,
                              tcposFranchiseSchema:
                                  PosData?.tcposFranchiseSchema,
                              tcposPaymentCash: PosData?.tcposPaymentCash,
                              tcposPaymentCreditCard:
                                  PosData?.tcposPaymentCreditCard,
                              tcposSetupDisk: PosData?.tcposSetupDisk,
                              tcposCodeCountry: PosData?.tcposCodeCountry,
                              tcposLangue: PosData?.tcposLangue,
                              tcposApiPort: PosData?.tcposApiPort,
                              tcposPartnerApiUrl: PosData?.tcposPartnerApiUrl,
                              tcposIp: PosData?.tcposIp,
                              tcposConnectionCheckTime:
                                  PosData?.tcposConnectionCheckTime,
                              tcposDeletingDataSate:
                                  PosData?.tcposDeletingDataSate,
                              codeRetryTable: PosData?.codeRetryTable,
                              maxRetry: PosData?.maxRetry,
                              retryDelai: PosData?.retryDelai,
                              checkStock: PosData?.checkStock,
                              orderValidationPreCheck:
                                  PosData?.orderValidationPreCheck,
                              timeoutAPI: PosData?.timeoutAPI,
                              automaticProductionLevel:
                                  PosData?.automaticProductionLevel,
                              tcposCorrTakeawayDisplayName:
                                  PosData?.tcposCorrTakeawayDisplayName,
                              tcposCorrTakeawayID: PosData?.tcposCorrTakeawayID,
                              tcposCorrOnSiteDisplayName:
                                  PosData?.tcposCorrOnSiteDisplayName,
                              tcposCorrOnSiteID: PosData?.tcposCorrOnSiteID,
                          },
                      },
            isShop: true,
        };
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(posEditor),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Pos Editor has been successfully assigned"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);
                        clearMultiCustomSelectionData();
                        clearData();
                        setIsEdited(false);
                        setChoicePosEditor("");
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {!isArchived
                        ? t(
                              "The Pos Editor already exist in the franchise. Would you like to associate it with this store?"
                          )
                        : t(
                              "Attention ! the Pos Editor is already archived at the franchise level"
                          )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {!isArchived && (
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsEdited(false);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                )}

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isArchived) {
                            AssociatePosEditor();
                        }

                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {!isArchived ? t("Validate") : t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
