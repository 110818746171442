import { mutate } from "swr";
import { keycloak } from "../keycloak";

export async function verifyIfPeripheralAssociate(
    peripheralId: string,
    shopId: string | number,
    franchiseId: string,
    userID: number,
    appId: string,
): Promise<{
    isPeripheralAssociate: boolean;
    appId_children: any[];
    applicationNames: string[];
}> {
    let isPeripheralAssociate = false;
    let isPeripheralAppAssociate = false;
    let appId_children: any = [];
    let applicationList: any = [];
    let applicationNames: any = [];
    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/applications/list?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseId}&appId=${appId}`;

    mutate(
        apiUrl,
        await fetch(apiUrl, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${keycloak.token}`,
            },
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                applicationList = data.data;

                if (data.error) {
                    throw Error("Error while delete selection!");
                }
            })
    );
    isPeripheralAppAssociate = applicationList.map((elt: any) => {
        let isPeripheralAssociateExist = elt.subRows.some((peripheral: any) => {
            if (peripheral.peripheralId === peripheralId) {
                return true;
            } else {
                return false;
            }
        });
        if (isPeripheralAssociateExist === true) {
            applicationNames.push(elt.appName);

            appId_children.push(elt.appId);
        }

        return appId_children.length > 0;
    });

    isPeripheralAssociate =
        isPeripheralAppAssociate && appId_children.length > 0;
    return { isPeripheralAssociate, appId_children, applicationNames };
}
export async function verifyIfPeripheralAssociateApplicationKds(
    peripheralId: string,
    shopId: string | number,
    franchiseId: string,
    userID: number,
    idApp: string,
): Promise<{ isPeripheralAssociate: boolean; appId_children: any[] }> {
    let isPeripheralAssociate = false;
    let isPeripheralAppAssociate = false;
    let appId_children: any = [];
    let applicationList: any = [];
    const apiUrlAppKds = `${process.env.REACT_APP_API_V2_URL}/settings/applications/kds/oneShop?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseId}&appId=${idApp}&typeDisplay=notArchived`;
    mutate(
        apiUrlAppKds,
        await fetch(apiUrlAppKds, {
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${keycloak.token}`,
            },
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                applicationList = data.data;

                if (data.error) {
                    throw Error("Error while delete selection!");
                }
            })
    );
    isPeripheralAppAssociate = applicationList.map((elt: any) => {
        let isPeripheralAssociateExist = elt.subRows.some((peripheral: any) => {
            if (peripheral.peripheralId === peripheralId) {
                return true;
            } else {
                return false;
            }
        });
        if (isPeripheralAssociateExist === true) {
            appId_children.push(elt.appId);
        }

        return appId_children.length > 0;
    });

    isPeripheralAssociate =
        isPeripheralAppAssociate && appId_children.length > 0;
    return { isPeripheralAssociate, appId_children };
}
export async function verifyIfApplicationAssociate(
    shopId: string,
    franchiseId: string,
    userID: number,
    appId: string,
    token:string,
    appIDChildren?: string
): Promise<boolean> {
    let isAppAssociate = [];
    let applicationList: any = [];
    const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/applications/list?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseId}&appId=${appId}`;

    mutate(
        apiUrl,
        await fetch(apiUrl, {
            headers: {
                "Content-Type": "application/json",
                  authorization: `Bearer ${keycloak.token}`,
            },
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                applicationList = data.data;

                if (data.error) {
                    throw Error("Error while delete selection!");
                }
            })
    );

    isAppAssociate = applicationList.filter((elt: any) => {
        if (elt.appId === appIDChildren && elt.subRows?.length > 0) {
            return true;
        } else {
            return false;
        }
    });

    return isAppAssociate?.length > 0;
}
