import React from "react";
import {
    CheckboxIcon,
    StyledButton,
    StyledH2,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { store as device } from "../../../../components/VerticalLayout/store";

import { capitalize } from "@helpers/general";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type PrintersPathsModalType = {
    setIsDeviceDissociateModalOpened: Function;
    isDeviceDissociateModalOpened: boolean;
    selectedRows: any;
    isDataUpdated: boolean | number;
    setDataIsUpdated: Function;
};

export default function DeviceDissociateModal({
    setIsDeviceDissociateModalOpened,
    isDeviceDissociateModalOpened,
    selectedRows,
    isDataUpdated,
    setDataIsUpdated,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { oneShop, userID, shopID, franchiseID, operatorID } = useSnapshot(
        device
    );
    let deviceType =
        selectedRows[0] !== undefined
            ? selectedRows[0].peripheralType === "cash_management"
                ? "cashManagement"
                : selectedRows[0].peripheralType
            : "";

    async function dissociateDevice() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/${deviceType}`;

        let data = {
            userId: userID,
            shopId: oneShop ? shopID : selectedRows[0].shopId,
            franchiseId: franchiseID,
            appId: "SCO",
            appId_children: selectedRows[0]?.appId,
            operatorId: operatorID,
            designation: selectedRows[0].applicationDesignation,
            peripheralName: selectedRows[0]["Périphérique"],
            peripheralId: selectedRows[0]?.peripheralId,
        };
        try {
            fetchWithToken(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
                method: "delete",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t(
                            "Unpairing of this device has been successfully completed"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsDeviceDissociateModalOpened!(
                        !isDeviceDissociateModalOpened
                    );
                    setDataIsUpdated!(true);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <Modal centered isOpen={isDeviceDissociateModalOpened}>
                <ModalHeader
                    toggle={() => {
                        setIsDeviceDissociateModalOpened!(
                            !isDeviceDissociateModalOpened
                        );
                        setDataIsUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t("Please confirm unpairing this device")}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsDeviceDissociateModalOpened!(
                                !isDeviceDissociateModalOpened
                            );
                            setDataIsUpdated!(true);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <StyledButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            dissociateDevice();
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
