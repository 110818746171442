import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as exceptionalHoursStore,
    setNumberActive,
    setNumberArchived,
    setGlobalDataIsUpdated,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationActiveMessageType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    isOneShop: boolean;
    dataIsUpdated: boolean | number;
};

export function ConfirmationActiveExceptionalHours({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    selectedRows,
    dataIsUpdated,
}: ConfirmationActiveMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        numberActive,
        numberArchived,
        shopsExceptionalHoursData,
    } = useSnapshot(exceptionalHoursStore);

    const [error, setError] = React.useState<boolean>(false);

    function ArchivedExceptionalHours(e: any) {
        let idsAssociated = [0];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeExceptionalHours(isValidation: boolean) {
        toast.dismiss();
        let localData: any[] = [];
        let newHours: any[] = [];
        selectedRows.forEach((element: any) => {
            if (element.subRows !== undefined) {
                const id = element.Id;
                element.subRows.forEach((element: any) => {
                    newHours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                    });
                });
                let shopsArchived = ArchivedExceptionalHours(element);
                let dataExceptionalHours = {
                    userId: userID,
                    shopId: isValidation ? shopsArchived : [0],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    isShop: false,
                    data: {
                        [id]: {
                            id: id,
                            designation: element[t("Designation")],
                            state: element.state === true ? "Opened" : "Closed",
                            startDate: element.startDate,
                            endDate: element.endDate,
                            hours: newHours,
                            isArchived: false,
                            shopId: element.shopId,
                            recurrence: element[t("Recurrence")],
                            endDate_recurrence: element.endDate_recurrence,
                            recurrenceDays: element.recurrenceDays,
                        },
                    },
                };
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
                localData.push(
                   fetchWithToken(apiUrlAdd, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataExceptionalHours),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                await Promise.all(localData).then((result) => {
                    result.forEach((res: any) => {
                        if (res.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            toast.success(
                `${t("The exceptional schedule was successfully unarchive")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated(!dataIsUpdated);
            setGlobalDataIsUpdated(true);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(!dataIsUpdated);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the exceptional hours is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeExceptionalHours(false);
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeExceptionalHours(true);
                        setIsModalOpened(!isModalOpened);
                        setNumberActive(
                            selectedRows.length !== 0
                                ? numberActive + selectedRows.length
                                : numberActive + 1
                        );
                        setNumberArchived(
                            selectedRows.length !== 0
                                ? numberArchived - selectedRows.length
                                : numberArchived - 1
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
