import React from "react";
import { StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import storeVatRate from "../store";
import classnames from "classnames";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { useKeycloak } from "@react-keycloak/web";

export default function OriginHistory({
    id,
    isAddVatRateOriginHistoryClicked,
    setIsAddVatRateOriginHistoryClicked,
}: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { urlHistory } = useSnapshot(storeVatRate);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [data, setData] = React.useState<any>({});
    const [vatRateCode, setVatRateCode] = React.useState<any>({});

    React.useEffect(() => {
        setVatRateCode(data?.othersData?.vatRateCode);
    }, [data]);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [, setSelectedRows] = React.useState<any[]>([]);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    function ListItemModification(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].item;
            if (item === "code") {
                item = "VAT code";
            } else if (item === "value") {
                item = "VAT code";
            } else if (item === "pays") {
                item = t("country");
            }
            if (index === e.length - 1) {
                re = re + t(item);
            } else {
                re = re + t(item) + " , ";
            }
        }

        return re;
    }
    function ListItemModificationAfterValues(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].newValue;

            if (index === e.length - 1) {
                re = re + t(item);
            } else {
                re = re + t(item) + " , ";
            }
        }
        if (re[re.length - 2] === ",") {
            re = re.substring(0, re.length - 2);
        }
        return re;
    }
    function ListItemModificationBeforeValues(e: any) {
        let re = "";

        for (let index = 0; index < e.length; index++) {
            let item = e[index].oldValue;

            if (index === e.length - 1) {
                re = re + t(item);
            } else {
                re = re + t(item) + " , ";
            }
        }
        if (re[re.length - 2] === ",") {
            re = re.substring(0, re.length - 2);
        }
        return re;
    }

    const url = urlHistory;
    type CustomColumnProps = {
        indexOFColumn: number;
        columnName: string;
        customJsx: React.ReactNode;
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("VAT code"),

            customJsx: (e: any) => <div>{e.selectedRow.code}</div>,
        },
        {
            indexOFColumn: 1,
            columnName: t("Action"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow["Action"] === "Modification"
                            ? t("Modification") +
                              " " +
                              t("of") +
                              " " +
                              ListItemModification(e.selectedRow["details"])
                            : e.selectedRow["Action"] === "Assignment"
                            ? e.selectedRow["etat"] === "shop"
                                ? t("Creation")
                                : `${t(e.selectedRow["Action"])}  ${
                                      e.selectedRow.details.After
                                  }`
                            : e.selectedRow["Action"] === "Reassignment"
                            ? `${t(e.selectedRow["Action"])}  ${
                                  e.selectedRow.details.After
                              }`
                            : t(e.selectedRow["Action"])
                    }
                />
            ),
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow["Action"] === "Modification"
                            ? ListItemModificationBeforeValues(
                                  e.selectedRow["details"]
                              )
                            : e.selectedRow["Action"] === "Archiving"
                            ? `Status : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Unarchiving"
                            ? `Status : ${t("Archived")}`
                            : e.selectedRow["Action"] === "Activation"
                            ? `${t("State")} : ${t("Desactived")}`
                            : e.selectedRow["Action"] === "Deactivation"
                            ? `${t("State")} : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Creation"
                            ? ""
                            : e.selectedRow["Action"] === "Assignment"
                            ? ""
                            : e.selectedRow["Action"] === "Reassignment"
                            ? ""
                            : t(e.selectedRow["Before"])
                    }
                />
            ),
        },

        {
            indexOFColumn: 3,
            columnName: t("After modification"),

            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow["Action"] === "Modification"
                            ? ListItemModificationAfterValues(
                                  e.selectedRow["details"]
                              )
                            : e.selectedRow["Action"] === "Reassignment" ||
                              e.selectedRow["Action"] === "Assignment"
                            ? ""
                            : e.selectedRow["Action"] === "Archiving"
                            ? `Status : ${t("Archived")}`
                            : e.selectedRow["Action"] === "Unarchiving"
                            ? `Status : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Activation"
                            ? `${t("State")} : ${t("Actived")}`
                            : e.selectedRow["Action"] === "Deactivation"
                            ? `${t("State")} : ${t("Desactived")}`
                            : e.selectedRow["Action"] === "Creation"
                            ? e.selectedRow[t("VAT code")]
                            : t(e.selectedRow[t("After")])
                    }
                />
            ),
        },

        {
            indexOFColumn: 4,
            columnName: t("Operator"),

            customJsx: (e: any) => <div>{t(e.selectedRow[t("Operator")])}</div>,
        },
    ];
    return (
        <StyledModal
            toggle={true}
            isOpen={isAddVatRateOriginHistoryClicked}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setIsAddVatRateOriginHistoryClicked(
                        !isAddVatRateOriginHistoryClicked
                    )
                }
            >
                <StyledH2 className="text-uppercase tva_txt_HStitleHistory">
                    {t("History")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <div
                    className={`${classnames("title__clz", {
                        "not-allowed-icon__clz": vatRateCode === "",
                    })}`}
                >
                    {t("VAT code")} :{vatRateCode}{" "}
                </div>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    setSelectedRows={setSelectedRows}
                    canResize
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="vatRateHistory"
                    setData={setData}
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    className="tva_btn_HSclose"
                    variant="primary"
                    onClick={() => {
                        setIsAddVatRateOriginHistoryClicked(
                            !isAddVatRateOriginHistoryClicked
                        );
                    }}
                    rounded
                >
                    {t("Close")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
