import { CheckboxIcon } from "@aureskonnect/react-ui";
import React from "react";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import moment from "moment";
import { toast } from "react-toastify";

import { store as userStore } from "@components/VerticalLayout/store";
import { store } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import ModalAssociateStore from "./ModalAssociateStore";

import "@assets/swal.css";
import "@assets/theme.css";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CardFooterModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isModalPublishOpened: boolean;
    setIsModalPublishOpened: Function;
};

export default function CardFooterModalPublish({
    rowData,
    setIsDataUpdated,
    isModalPublishOpened,
    setIsModalPublishOpened,
}: CardFooterModalPublishType) {
    const fetchWithToken = useFetchWithKcToken();

    const { userID, franchiseID, oneShop, shopID, operatorID } = useSnapshot(
        userStore
    );
    const { projectData } = useSnapshot(store);
    const { t } = useTranslation();
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isValidatedClicked, setIsValidatedClicked] = React.useState<boolean>(
        false
    );
    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.associates) !== undefined &&
                Object.values(rowData.associates).forEach((el: any) => {
                    idShops.push(el.id_boutique);
                });
        }
        idShops = [...new Set(idShops), "0"];
        return idShops;
    }
    function isAssociate() {
        let projectId: any = [];
        let associateskeys: any = [];
        let isAssociateShops: boolean = false;
        if (projectData !== undefined) {
            projectData.forEach((el: any) => {
                Object.values(el?.associates).forEach((elment: any) => {
                    if (rowData?.associates !== undefined) {
                        Object.values(rowData?.associates).forEach(
                            (item: any) => {
                                if (
                                    elment?.id_boutique === item?.id_boutique &&
                                    el?.[t("Status")] === "Publié"
                                ) {
                                    associateskeys.push(el?.shopKey);
                                    projectId.push(el?.projectId);
                                }
                            }
                        );
                    }
                });
            });
        }
        if (Object.keys(associateskeys).length > 0) {
            isAssociateShops = true;
        }
        return isAssociateShops;
    }

    let isAssociateShops: boolean = isAssociate();

    async function handleSaveButtonOnClickEvent() {
        let localData: any = [];

        let dataAssociate: any[] = AssociateProject();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/orb/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            shopId: oneShop ? [shopID, "0"] : dataAssociate,
            projectId: rowData.projectId,
            status: "Publié",
            modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
        };
        localData.push(
            fetchWithToken(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated(true);
                        setIsModalPublishOpened(!isModalPublishOpened);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    React.useEffect(() => {
        if (isValidatedClicked) {
            handleSaveButtonOnClickEvent();
        }
        // eslint-disable-next-line
    }, [isValidatedClicked]);

    return (
        <React.Fragment>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalPublishOpened!(!isModalPublishOpened);
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isAssociateShops || oneShop) {
                            handleSaveButtonOnClickEvent();
                        } else {
                            setIsModalOpened(true);
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ModalAssociateStore
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidatedClicked={setIsValidatedClicked}
                    rowData={rowData}
                    setIsModalPublishOpened={setIsModalPublishOpened}
                />
            ) : null}
        </React.Fragment>
    );
}
