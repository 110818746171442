import React from "react";
import { useSnapshot } from "valtio";
import { Card } from "reactstrap";
import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";
import {
    AddIcon,
    AllergenIcon,
    FireIcon,
    InfoIcon,
} from "@pages/GeneralConfig/Design/Preview/OrderTaking/Icons";

import "./category.css";
import "./index.css";

export function CardWrapper(): JSX.Element {
    const {
        selectedCategory,
        isProductTitleActive,
        isProductDescriptionActive,
        productAccessIcon,
        calorieIcon,
    } = useSnapshot(designStore);
    const {
        project: {
            files: images,
            template: {
                content: { generalDesign },
            },
        },
        secondColor,
        isAllergenOrderTakingActive,
        isOrderTakingCaloryActive,
        isOrderTakingProductInformationActive,
        isBorderCardProductActive,
        isNutriscoreOnTheProductActive,
        isCoverImageActive,
    } = useSnapshot(generalConfigStore);

    const product = data.products.filter(
        (product) => product.category === selectedCategory.name
    )[0];

    const productAccessIconContent = getImageContentById(
        productAccessIcon.id,
        images
    );
    const imageSrc =
        productAccessIconContent?.includes("http") ||
        productAccessIconContent?.includes("https")
            ? productAccessIconContent
            : `./images/${productAccessIconContent}`;
    const calorieIconContent = getImageContentById(calorieIcon.id, images);
    const imageSrcCalorieIcon =
        calorieIconContent?.includes("http") ||
        calorieIconContent?.includes("https")
            ? calorieIconContent
            : `./images/${calorieIconContent}`;
    return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <Card
                style={{
                    height: "200px",
                    width: "200px",
                    display: "grid",
                    gridTemplateRows:
                        (isOrderTakingCaloryActive ||
                            isNutriscoreOnTheProductActive) &&
                        isProductTitleActive
                            ? "100px 18px 20px 20px 30px"
                            : (isOrderTakingCaloryActive ||
                                  isNutriscoreOnTheProductActive) &&
                              !isProductTitleActive
                            ? "100px 41px 20px 30px"
                            : (!isOrderTakingCaloryActive ||
                                  !isNutriscoreOnTheProductActive) &&
                              isProductTitleActive
                            ? "100px 41px 20px 30px"
                            : (!isOrderTakingCaloryActive ||
                                  !isNutriscoreOnTheProductActive) &&
                              !isProductTitleActive
                            ? "100px 64px 30px"
                            : "100px 18px 20px 20px 30px",
                    gap: "3px",
                    border: isBorderCardProductActive === true ? "" : "unset",
                }}
            >
                <div
                    className="d-flex flex-row justify-content-around align-items-center"
                    style={{ gap: "10px" }}
                >
                    <div className="d-flex justify-content-center align-items-center  h-100 w-100">
                        <img
                            alt={product.name.content}
                            src={product.image}
                            style={{
                                height: "90%",
                                width: "80%",
                                objectFit: isCoverImageActive
                                    ? "cover"
                                    : "contain",
                            }}
                        />
                    </div>
                    <div
                        className="mt-2 d-flex flex-column justify-content-end align-items-center"
                        style={{
                            gap:
                                isOrderTakingProductInformationActive === true
                                    ? "3px"
                                    : "9px",
                        }}
                    >
                        {isOrderTakingProductInformationActive === true ? (
                            productAccessIconContent !== "" ? (
                                <img
                                    alt="productAccessIcon"
                                    src={imageSrc}
                                    style={{
                                        height: "20px",
                                        width: "23px",
                                    }}
                                />
                            ) : (
                                <InfoIcon />
                            )
                        ) : null}

                        {isAllergenOrderTakingActive === true ? (
                            <AllergenIcon
                                globalColor={
                                    (generalDesign as GeneralDesignType)
                                        .colors[0].content
                                }
                            />
                        ) : null}

                        <AddIcon
                            globalColor={
                                (generalDesign as GeneralDesignType).colors[0]
                                    .content
                            }
                            secondColor={secondColor}
                        />
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ gap: "10px" }}
                >
                    {isOrderTakingCaloryActive === true ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "2px",
                            }}
                        >
                            {calorieIconContent !== "" ? (
                                <img
                                    alt="calorieIcon"
                                    src={imageSrcCalorieIcon}
                                    style={{
                                        height: "20px",
                                        width: "23px",
                                    }}
                                />
                            ) : (
                                <FireIcon />
                            )}

                            <span
                                style={{
                                    fontWeight: "normal",
                                    fontSize: "10px",
                                    fontFamily: "Segoe UI",
                                    textAlign: "center",
                                    marginTop: "5px",
                                }}
                            >
                                {product.calorie.content} {"cal"}
                            </span>
                        </div>
                    ) : null}
                    {isNutriscoreOnTheProductActive ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <img
                                alt="closingButton"
                                src="./images/c.png"
                                style={{
                                    height: "20px",
                                    width: "23px",
                                }}
                            />
                        </div>
                    ) : null}
                </div>
                {isProductTitleActive === true ? (
                    <span
                        className="mt-1 d-flex justify-content-center align-items-center"
                        style={{
                            font: "normal normal bold 9px/5px Segoe UI",
                            textAlign: "center",
                            width: "100%",
                            height: "100%",
                            color: "#343434",
                            textTransform: "uppercase",
                        }}
                    >
                        {product.name.content}
                    </span>
                ) : null}
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        color: "#343434",
                        textTransform: "uppercase",
                    }}
                >
                    {product.price.content} {"€"}
                </div>

                {isProductDescriptionActive ? (
                    <span
                        style={{
                            textAlign: "center",
                            color: "#000000",
                            fontWeight: "normal",
                            fontSize: "9px",
                            fontFamily: "Segoe UI",
                        }}
                    >
                        {product.description.content}
                    </span>
                ) : null}
            </Card>
        </div>
    );
}
