import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";

import store from "../store";
import { store as FiscalYear } from "../../../../components/VerticalLayout/store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsFiscalYearArchived,
    isFiscalYearArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        archivedFiscalYearFranchise,
        shopsFiscalYearData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(FiscalYear);

    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    function archiveFiscalYearFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFiscalYearFranchise.othersData
                .archivedFiscalYearFranchise !== undefined
        ) {
            archivedFiscalYearFranchise.othersData.archivedFiscalYearFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    function shopsIdArchiveFiscalYear(e: any) {
        let idsAssociated: any = [];
        let shopSelectedRows: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el.shopId === e.shopId) {
                shopSelectedRows.push(el.shopId);
            }
        });
        if (shopsFiscalYearData.othersData.allFiscalYear !== undefined) {
            shopsFiscalYearData.othersData.allFiscalYear.forEach(
                (element: any) => {
                    if (
                        element.subRows !== undefined &&
                        element.subRows.length === 0 &&
                        ((element.archivedFiscalYear !== undefined &&
                            element.archivedFiscalYear.length === 1) ||
                            (selectedRows.subRows === undefined &&
                                shopSelectedRows.length === 1))
                    ) {
                        element.archivedFiscalYear.forEach((el: any) => {
                            if (el.Id === e.Id && el.shopId === e.shopId)
                                idsAssociated.push(el.shopId);
                        });
                    }
                }
            );
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function activeFiscalYear() {
        toast.dismiss();
        let localData: any = [];
        let isNotEmpty: boolean = false;
        let isEmpty: boolean = false;
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            let shopsIdArchived = shopsIdArchiveFiscalYear(el);
            let dataFiscalOfYear = {
                userId: userID,
                shopId: shopsIdArchived,
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: false,
                data: {
                    [el.Id]: {
                        first_day: el.first_day,
                        first_month: el.first_month,
                        end_day: el.end_day,
                        end_month: el.end_month,
                        isArchived: false,
                        shopId: el.shopId,
                    },
                },
            };
            if (dataFiscalOfYear.shopId.length !== 0) {
                isNotEmpty = true;
            } else {
                isEmpty = true;
            }

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
            localData.push(
               fetchWithToken(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFiscalOfYear),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false && isNotEmpty) {
            toast.success(
                `${t("The fiscal year has been activated successfully")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            setDataIsUpdated!(true);
        }
        if (isEmpty) {
            ErrorToast(
                t("Attention, a shop cannot have more than one fiscal year")
            );

            setDataIsUpdated!(true);
        }
        setLimit(2);
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_BTmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFiscalYearArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Fiscal Year Enabled")} (
                                {numberActiveMultiShop})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFiscalYearArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Archived fiscal year")}(
                                {numberArchiveMultiShop})
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                arrayOfRows.length === 0,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (
                                            selectedRows.length === 1 &&
                                            (selectedRows[0].subRows ===
                                                undefined ||
                                                selectedRows[0].subRows.length <
                                                    2)
                                        ) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BThistory">
                                        {t("Historical")}
                                    </span>
                                </div>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isFiscalYearArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            archiveFiscalYearFranchise().includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                arrayOfRows.length === 0,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (arrayOfRows.length < 2) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            isFiscalYearArchived
                                                ? activeFiscalYear()
                                                : setIsArchiveModalOpened(true);
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BTarchived cmn_btn_BTunarchive">
                                        {isFiscalYearArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
