import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { SettingsIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { ModalBody } from "reactstrap";

import {
    setIsConfigurationCashManagementClicked,
    store,
} from "../../../../store";
import {
    defaultLoadingAlertValue,
    setIsSettingIconLoadingClicked,
    store as alertStore,
    setLoadingAlert,
} from "../../store";
import { store as userStore } from "@components/VerticalLayout/store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

import AlertDenominationModal from "./AlertDenominationModal";
import { Footer } from "../../Footer";

import "./index.css";

type CashManagementLoadingAlert = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};

export function LoadingAlert({
    rowData,
    setDataIsUpdated,
}: CashManagementLoadingAlert) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const { loadingAlertValue, isSettingIconLoadingClicked } = useSnapshot(
        alertStore
    );
    const { isConfigurationCashManagementClicked } = useSnapshot(store);

    const alert_type = "coin_loading_alert";

    const [isThresholdReached, setIsThresholdReached] = React.useState<boolean>(
        Object.values(rowData.coin_loading_alert).length > 0
            ? rowData.coin_loading_alert.sending_frequency
                  .when_minimum_threshold_reached
            : false
    );
    const [isThresholdDayReached, setIsThresholdDayReached] = React.useState<
        boolean
    >(
        Object.keys(rowData.coin_loading_alert).length > 0
            ? rowData.coin_loading_alert.sending_frequency
                  .after_closing_day_if_minimum_threshold_reached
            : false
    );
    const [isDenominationAlert, setIsDenominationAlert] = React.useState<
        boolean
    >(
        Object.keys(rowData.coin_loading_alert).length > 0
            ? rowData.coin_loading_alert.alert_threshold
                  .denomination_alert_threshold.state
            : false
    );

    const [alertForm, setAlertForm] = React.useState<any>(
        Object.keys(rowData.coin_loading_alert).length > 0
            ? rowData.coin_loading_alert.alert_threshold.send_alert_format.value
            : "XLS"
    );
    const [alertSent, setAlertSent] = React.useState<any>(
        Object.keys(rowData.coin_loading_alert).length > 0
            ? [
                  rowData.coin_loading_alert.alert_threshold.send_alert.email &&
                      "E-mail",
                  rowData.coin_loading_alert.alert_threshold.send_alert.sms &&
                      "SMS",
              ]
            : ["SMS"]
    );
    const [isEmailInclude, setIsEmailInclude] = React.useState<boolean>(
        alertSent.includes("E-mail")
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    async function handleEmptyObjectClickEvent() {
        let userId: number;
        let shopId: string;
        let franchiseId: string;
        if (oneShop) {
            franchiseId = franchiseID;
            shopId = shopID.toString();
            userId = userID;
        } else {
            userId = userID;
            shopId = rowData.shopId;
            franchiseId = franchiseID;
        }
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement/loadingAlert`;
        let savedData = {
            userId: userId,
            shopId: shopId,
            franchiseId: franchiseId,
            appId: "SCO",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                sending_frequency: {
                    when_minimum_threshold_reached: false,
                    after_closing_day_if_minimum_threshold_reached: false,
                },
                alert_threshold: {
                    denomination_alert_threshold: {
                        state: false,
                        value: {},
                    },
                    send_alert: {
                        state: false,
                        sms: true,

                        email: false,
                    },
                    send_alert_format: {
                        state: false,
                        value: "XLS",
                    },
                },
            },
        };
        try {
            mutate(
                apiUrl,
                await fetchWithToken(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                         
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while saving selection!")}`
                            );
                        }
                    })
            );
        } catch (e: any) {}
    }

    React.useEffect(() => {
        if (
            !isSettingIconLoadingClicked &&
            Object.keys(rowData.coin_loading_alert).length > 0
        ) {
            defaultLoadingAlertValue(
                rowData.coin_loading_alert.alert_threshold
                    .denomination_alert_threshold.value
            );
        }
    }, [isSettingIconLoadingClicked, rowData]);

    React.useEffect(() => {
        setLoadingAlert({
            sending_frequency: {
                when_minimum_threshold_reached: isThresholdReached,
                after_closing_day_if_minimum_threshold_reached: isThresholdDayReached,
            },
            alert_threshold: {
                denomination_alert_threshold: {
                    state: isDenominationAlert,
                    value: loadingAlertValue,
                },
                send_alert: {
                    state: "",
                    sms:
                        Object.values(rowData.coin_loading_alert).length > 0
                            ? rowData.coin_loading_alert.alert_threshold
                                  .send_alert.sms
                            : alertSent.includes("SMS"),
                    email:
                        Object.values(rowData.coin_loading_alert).length > 0
                            ? rowData.coin_loading_alert.alert_threshold
                                  .send_alert.email
                            : alertSent.includes("E-mail"),
                },
                send_alert_format: {
                    state: "",
                    value: alertForm,
                },
            },
        });
    }, [
        alertForm,
        alertSent,
        isDenominationAlert,
        isThresholdDayReached,
        isThresholdReached,
        loadingAlertValue,
        rowData.coin_loading_alert,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "84.1%", fontSize: "17px" }}>
                <AvForm>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <div>
                            <b>{t("Sending frequency")}</b>
                        </div>
                    </div>
                    <div className="d-flex  align-items-center justify-content-between mt-2">
                        <div>
                            {t(
                                "Send SMS/Email when the minimum threshold is reached"
                            )}
                        </div>
                        <div className="float-right mr-4">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isThresholdReached}
                                onChange={(e: any) => {
                                    if (
                                        Object.keys(rowData.coin_loading_alert)
                                            .length > 0
                                    ) {
                                        rowData.coin_loading_alert.sending_frequency.when_minimum_threshold_reached = !rowData
                                            .coin_loading_alert
                                            .sending_frequency
                                            .when_minimum_threshold_reached;
                                        setIsThresholdReached(
                                            rowData.coin_loading_alert
                                                .sending_frequency
                                                .when_minimum_threshold_reached
                                        );
                                    } else {
                                        setIsThresholdReached(
                                            !isThresholdReached
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            {t(
                                "Send SMS/E-mail after the close of the day if the minimum threshold is reached"
                            )}
                        </div>
                        <div className="float-right mr-4">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isThresholdDayReached}
                                onChange={(e: any) => {
                                    if (
                                        Object.keys(rowData.coin_loading_alert)
                                            .length > 0
                                    ) {
                                        rowData.coin_loading_alert.sending_frequency.after_closing_day_if_minimum_threshold_reached = !rowData
                                            .coin_loading_alert
                                            .sending_frequency
                                            .after_closing_day_if_minimum_threshold_reached;
                                        setIsThresholdDayReached(
                                            rowData.coin_loading_alert
                                                .sending_frequency
                                                .after_closing_day_if_minimum_threshold_reached
                                        );
                                    } else {
                                        setIsThresholdDayReached(
                                            !isThresholdDayReached
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="border-footer__clz mt-3" />
                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <div>
                            <b>{t("Alert threshold")}</b>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{ width: "360px" }}>
                                {t("Enable Alert Threshold by Denomination")}
                            </div>
                            <div>
                                <StyledIconButton
                                    icon="SettingsIcon"
                                    disabled={!isDenominationAlert}
                                >
                                    <SettingsIcon
                                        height={20}
                                        width={20}
                                        onClick={() => {
                                            if (
                                                Object.keys(
                                                    rowData.coin_loading_alert
                                                ).length > 0
                                            ) {
                                                setIsSettingIconLoadingClicked(
                                                    true
                                                );
                                                defaultLoadingAlertValue(
                                                    rowData.coin_loading_alert
                                                        .alert_threshold
                                                        .denomination_alert_threshold
                                                        .value
                                                );
                                            } else if (
                                                !isSettingIconLoadingClicked
                                            ) {
                                                handleEmptyObjectClickEvent();
                                                setIsSettingIconLoadingClicked(
                                                    true
                                                );
                                            } else {
                                                defaultLoadingAlertValue(
                                                    loadingAlertValue
                                                );
                                            }
                                            setIsModalOpened(!isModalOpened);
                                        }}
                                    />
                                </StyledIconButton>
                            </div>
                        </div>
                        <div className="float-right mr-4">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isDenominationAlert}
                                onChange={(e: any) => {
                                    if (
                                        Object.keys(rowData.coin_loading_alert)
                                            .length > 0
                                    ) {
                                        rowData.coin_loading_alert.alert_threshold.denomination_alert_threshold.state = !rowData
                                            .coin_loading_alert.alert_threshold
                                            .denomination_alert_threshold.state;
                                        setIsDenominationAlert(
                                            rowData.coin_loading_alert
                                                .alert_threshold
                                                .denomination_alert_threshold
                                                .state
                                        );
                                    } else {
                                        setIsDenominationAlert(
                                            !isDenominationAlert
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{ width: "385px" }}>
                                {t("Send alerts by")}
                            </div>
                            <div className="d-flex  align-items-center justify-content-between">
                                <span className="mt-2">
                                    <input
                                        type="checkbox"
                                        id="example"
                                        value={`${t("SMS")}`}
                                        defaultChecked={
                                            Object.values(
                                                rowData.coin_loading_alert
                                            ).length > 0
                                                ? rowData.coin_loading_alert
                                                      .alert_threshold
                                                      .send_alert.sms
                                                : true
                                        }
                                        onChange={(e: any) => {
                                            if (
                                                Object.keys(
                                                    rowData.coin_loading_alert
                                                ).length > 0
                                            ) {
                                                if (e.target.checked) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert.sms = !rowData
                                                        .coin_loading_alert
                                                        .alert_threshold
                                                        .send_alert.sms;
                                                } else {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert.sms = !rowData
                                                        .coin_loading_alert
                                                        .alert_threshold
                                                        .send_alert.sms;
                                                }
                                            } else {
                                                if (e.target.checked) {
                                                    setAlertSent([
                                                        ...alertSent,
                                                        "SMS",
                                                    ]);
                                                } else {
                                                    setAlertSent([
                                                        ...alertSent.filter(
                                                            (element: any) =>
                                                                element !==
                                                                "SMS"
                                                        ),
                                                    ]);
                                                }
                                            }
                                        }}
                                        disabled={!isDenominationAlert}
                                    />
                                    <label className="ml-3">{t("SMS")}</label>
                                </span>
                                <span className="ml-3 mt-2">
                                    <input
                                        type="checkbox"
                                        id="example"
                                        value={`${t("E-mail")}`}
                                        defaultChecked={
                                            Object.values(
                                                rowData.coin_loading_alert
                                            ).length > 0
                                                ? rowData.coin_loading_alert
                                                      .alert_threshold
                                                      .send_alert.email
                                                : false
                                        }
                                        onChange={(e: any) => {
                                            if (
                                                Object.keys(
                                                    rowData.coin_loading_alert
                                                ).length > 0
                                            ) {
                                                if (e.target.checked) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert.email = !rowData
                                                        .coin_loading_alert
                                                        .alert_threshold
                                                        .send_alert.email;
                                                    setIsEmailInclude(true);
                                                } else {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert.email = !rowData
                                                        .coin_loading_alert
                                                        .alert_threshold
                                                        .send_alert.email;
                                                    setIsEmailInclude(false);
                                                }
                                            } else {
                                                if (e.target.checked) {
                                                    setAlertSent([
                                                        ...alertSent,
                                                        "E-mail",
                                                    ]);
                                                    setIsEmailInclude(true);
                                                } else {
                                                    setAlertSent([
                                                        ...alertSent.filter(
                                                            (element: any) =>
                                                                element !==
                                                                "E-mail"
                                                        ),
                                                    ]);
                                                    setIsEmailInclude(false);
                                                }
                                            }
                                        }}
                                        disabled={!isDenominationAlert}
                                    />
                                    <label className="ml-3">
                                        {t("E-mail")}
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{ width: "385px" }}>
                                {t("Send alerts in format")}
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <AvForm
                                    model={{
                                        form: alertForm,
                                        checkItOut: true,
                                    }}
                                >
                                    <AvRadioGroup
                                        name="form"
                                        required
                                        inline
                                        errorMessage="Pick one!"
                                        disabled={
                                            !isDenominationAlert ||
                                            !isEmailInclude
                                        }
                                    >
                                        <AvRadio
                                            id="xls"
                                            label={`${t("XLS")}`}
                                            value="XLS"
                                            disabled={false}
                                            onChange={() => {
                                                if (
                                                    Object.keys(
                                                        rowData.coin_loading_alert
                                                    ).length > 0
                                                ) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert_format.value =
                                                        "XLS";
                                                    setAlertForm(
                                                        rowData
                                                            .coin_loading_alert
                                                            .alert_threshold
                                                            .send_alert_format
                                                            .value
                                                    );
                                                } else {
                                                    setAlertForm("XLS");
                                                }
                                            }}
                                        />

                                        <AvRadio
                                            id="twoRadio"
                                            label={`${t("Word")}`}
                                            name="radio"
                                            value="Word"
                                            disabled={false}
                                            onChange={() => {
                                                if (
                                                    Object.keys(
                                                        rowData.coin_loading_alert
                                                    ).length > 0
                                                ) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert_format.value =
                                                        "Word";
                                                    setAlertForm(
                                                        rowData
                                                            .coin_loading_alert
                                                            .alert_threshold
                                                            .send_alert_format
                                                            .value
                                                    );
                                                } else {
                                                    setAlertForm("Word");
                                                }
                                            }}
                                        />

                                        <AvRadio
                                            id="threeRadio"
                                            label={`${t("XML")}`}
                                            name="radio"
                                            value="XML"
                                            disabled={false}
                                            onChange={() => {
                                                if (
                                                    Object.keys(
                                                        rowData.coin_loading_alert
                                                    ).length > 0
                                                ) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert_format.value =
                                                        "XML";
                                                    setAlertForm(
                                                        rowData
                                                            .coin_loading_alert
                                                            .alert_threshold
                                                            .send_alert_format
                                                            .value
                                                    );
                                                } else {
                                                    setAlertForm("XML");
                                                }
                                            }}
                                        />
                                        <AvRadio
                                            id="threeRadio"
                                            label={`${t("Txt")}`}
                                            name="radio"
                                            value="Txt"
                                            checked={true}
                                            disabled={false}
                                            onChange={() => {
                                                if (
                                                    Object.keys(
                                                        rowData.coin_loading_alert
                                                    ).length > 0
                                                ) {
                                                    rowData.coin_loading_alert.alert_threshold.send_alert_format.value =
                                                        "Txt";
                                                    setAlertForm(
                                                        rowData
                                                            .coin_loading_alert
                                                            .alert_threshold
                                                            .send_alert_format
                                                            .value
                                                    );
                                                } else {
                                                    setAlertForm("Txt");
                                                }
                                            }}
                                        />
                                    </AvRadioGroup>
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                rowData={rowData}
                setDataIsUpdated={setDataIsUpdated}
            />
            <AlertDenominationModal
                rowData={rowData}
                oneShop={oneShop}
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                alert_type={alert_type}
            />
        </React.Fragment>
    );
}
