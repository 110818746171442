import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import { uuid } from "uuidv4";

import store, {
    setGlobalDataIsUpdated,
    setIsDataUpdated,
    setIsHistoryShopsModalOpened,
    setSelectedPaymentMethods,
    setSelectedHistoryPaymentMethods,
    setIsLoading,
    setKey,
} from "../store";
import { store as dataStore } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";
import ErrorToast from "@components/Common/ErrorTost";
import {
    setActivationDeactivationMessageModalOpened,
    setMessage,
    setYesFunction,
} from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { generalConfigStore, setItemsDisable } from "@store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomIconsType = {
    selectedRowData: any;
    isArchivedPaymentMethodsClicked: boolean;
};

export function CustomIcons({
    selectedRowData,
    isArchivedPaymentMethodsClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(dataStore);
    const {
        isHistoryShopsModalOpened,
        paymentMethodsData,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const [choiceState, setPaymentMethodState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );

    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    async function handleStateChange(data: boolean) {
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[t("Payment method")],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow.Pays,
                    tag: selectedRowData.selectedRow.Tag,
                    isArchived: selectedRowData.selectedRow.isArchived,
                    isActive: data,
                    languages: selectedRowData.selectedRow.languages,
                    advancedDisplayName:
                        selectedRowData.selectedRow.advancedDisplayName,
                },
            },
        };
        const isStateChange = true;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ dataConfig, isStateChange }),
        };
        mutate(
            apiUrl,
            await fetchWithToken(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        data === true
                            ? `${t(
                                  "Payment method has been successfully activated"
                              )}`
                            : `${t(
                                  "Payment method has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated!(true);
                    setIsDataUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActivePaymentMethod(selectedRowData: any) {
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[t("Payment method")],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow.Pays,
                    tag: selectedRowData.selectedRow.tag,
                    isActive: selectedRowData.selectedRow.isActive,
                    isArchived: false,
                    languages: selectedRowData.selectedRow.languages,
                    advancedDisplayName:
                        selectedRowData.selectedRow.advancedDisplayName,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetchWithToken(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully activated")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated!(true);
                    setIsDataUpdated(true);
                    setKey(uuid());
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function handleArchivePaymentMethod(selectedRowData: any) {
        setIsLoading(true);
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [selectedRowData.id]: {
                    designation: selectedRowData[t("Payment method")],
                    currency: selectedRowData.Devise,
                    display: selectedRowData[t("Display name")],
                    shopId: selectedRowData.idShop,
                    saleSupport: selectedRowData[t("Support de vente")],
                    country: selectedRowData.Pays,
                    tag: selectedRowData.tag,
                    isActive: false,
                    isArchived: true,
                    languages: selectedRowData.languages,
                    advancedDisplayName: selectedRowData.advancedDisplayName,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetchWithToken(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setKey(uuid());
                    setIsValidateClicked(false);
                    setIsArchivedValidationModalOpened(false);
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRowData.selectedRow[t("Sales support")]
                            .split(",")
                            .forEach((support: any) => {
                                if (
                                    elt.subRows.some(
                                        (sale: any) =>
                                            `${sale[t("Sale support")]} ${
                                                sale.shopId
                                            }` ===
                                            `${support} ${selectedRowData.selectedRow.idShop}`
                                    )
                                ) {
                                    isSaleSupportArchived = true;
                                }
                            });
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return isSaleSupportArchived;
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(selectedRowData.selectedRow);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, selectedRowData]);

    async function isInformationModeConsumed() {
        console.log({ selectedRowData });
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${selectedRowData.selectedRow.idShop}&franchiseId=${franchiseID}&type=meansOfPayment`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) =>
                                el.id === selectedRowData.selectedRow.id
                        )
                    ) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }
    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedPaymentMethodsClicked
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setMessage(
                                                choiceState
                                                    ? t(
                                                          "Caution, the Payment method may be used by other modules.\n Would you like to confirm the deactivation of the selected Payment method?"
                                                      )
                                                    : t(
                                                          "Would you like to confirm the activation of the selected Payment method?"
                                                      )
                                            );
                                            setYesFunction(() => {
                                                setPaymentMethodState(
                                                    !choiceState
                                                );
                                                handleStateChange(e);
                                                if (!choiceState === false) {
                                                    const item = [
                                                        ...JSON.parse(
                                                            JSON.stringify(
                                                                itemsDisable
                                                            )
                                                        ),
                                                        {
                                                            id:
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .id,
                                                            state: !choiceState,
                                                            shopId:
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .idShop,
                                                        },
                                                    ];
                                                    setItemsDisable(item);
                                                    localStorage.setItem(
                                                        "itemsDisable",
                                                        JSON.stringify(item)
                                                    );
                                                } else if (
                                                    !choiceState === true
                                                ) {
                                                    const item = itemsDisable.filter(
                                                        (item: any) =>
                                                            item.id !==
                                                            selectedRowData
                                                                .selectedRow.id
                                                    );
                                                    setItemsDisable(item);
                                                    localStorage.setItem(
                                                        "itemsDisable",
                                                        JSON.stringify(item)
                                                    );
                                                }
                                            });

                                            setActivationDeactivationMessageModalOpened(
                                                true
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <div>
                                {!isArchivedPaymentMethodsClicked ? (
                                    <Tooltip title={t("Archive")}>
                                        <div>
                                            <TrashIcon
                                                onClick={async () => {
                                                    let isConsumed = await isInformationModeConsumed();
                                                    if (isConsumed === true) {
                                                        ErrorToast(
                                                            t(
                                                                "Please note, you cannot archive an element used in a template"
                                                            )
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        setIsArchivedValidationModalOpened(
                                                            !isArchivedValidationModalOpened
                                                        );
                                                    }
                                                }}
                                                height={25}
                                                width={25}
                                                fill="red"
                                                style={{ cursor: "pointer" }}
                                                className={`${classnames(
                                                    "ml-2 cmn_icn_BTarchived",
                                                    {
                                                        "not-allowed-icon__clz": isLoading,
                                                    }
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={
                                            paymentMethodsData.find(
                                                (el: any) =>
                                                    el.id ===
                                                    selectedRowData.selectedRow
                                                        .id
                                            ) !== undefined
                                                ? ""
                                                : t("Unarchive")
                                        }
                                    >
                                        <div>
                                            <EyeIcon
                                                height={25}
                                                width={25}
                                                onClick={async (e: any) => {
                                                    setIsLoading(true);
                                                    let isArchived = await isSaleSupportArchived();
                                                    if (isArchived === true) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                            )!}`
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        handleActivePaymentMethod(
                                                            selectedRowData
                                                        );
                                                    }
                                                }}
                                                className={`${classnames(
                                                    "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            paymentMethodsData.find(
                                                                (el: any) =>
                                                                    el.id ===
                                                                    selectedRowData
                                                                        .selectedRow
                                                                        .id
                                                            ) !== undefined ||
                                                            isLoading,
                                                    }
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );
                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                }}
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
