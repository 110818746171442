import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { store as ModeOfSale } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActive,
    setNumberArchived,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { ConfirmationActivationDeactivationMessageModal } from "../ConfirmationActivationDeactivationMessageModal";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setSale,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfSaleArchived,
    setSelectName,
    setSelectSales,
    setInputSaleMode,
    setIsAddModeOfSaleClicked,
    isAddModeOfSaleClicked,
    isFavoriteModified,
    setIsFavoriteModified,
    selectedRows,
    setLocalFilterActive,
    setIsConsult,
    setDataState,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberActive,
        numberArchived,
        shopsModeSaleData,
        archivedModeOfSaleFranchise,
        modeSaleData,
        isActionsClicked,
        isEdited,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, shopID, operatorID } = useSnapshot(
        ModeOfSale
    );

    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isActivationDeactivationModalOpened,
        setIsActivationDeactivationModalOpened,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];
    const shopId = oneShop ? shopID : 0;
    if (!oneShop) {
        dataAssociate = associateModeOfSale();
        dataArchive = archiveModeSale();
    } else {
        dataArchive = archiveModeOfSaleFranchise();
    }

    function associateModeOfSale() {
        let idsAssociated: any = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        if (elt.Id === e.selectedRow.Id) {
                            idsAssociated.push(elt);
                        }
                    });
                }
            });
        }

        return idsAssociated;
    }

    function archiveModeSale() {
        let idsAssociated: any = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function archiveModeOfSaleFranchise() {
        let idsAssociated: any = [];
        if (
            archivedModeOfSaleFranchise.othersData
                .archivedModeOfSaleFranchise !== undefined
        ) {
            archivedModeOfSaleFranchise.othersData.archivedModeOfSaleFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeModeOfSale() {
        toast.dismiss();

        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        designation: e.selectedRow["Mode of sale"],
                        tag: e.selectedRow.tag,
                        display_name: e.selectedRow.display_name,
                        support_vente: e.selectedRow.support_vente,
                        isArchived: false,
                        isFavorite: e.selectedRow.isFavorite,
                        state:
                            e.selectedRow.shopId === "0"
                                ? true
                                : e.selectedRow.state,
                        shopId: e.selectedRow.shopId,
                        advancedDisplayName: e.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Sales method has been successfully activated"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setIsLoading(false);
                        setKey(uuid());
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    async function favoriteModeOfSale() {
        toast.dismiss();

        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: modeSaleData.data.reduce(
                    (updatedModeOfSaleData: any, modeOfSale: any) => {
                        if (modeOfSale.Id === e.selectedRow.Id) {
                            updatedModeOfSaleData[modeOfSale.Id] = {
                                tag: modeOfSale.tag,
                                display_name: modeOfSale.display_name,
                                support_vente: modeOfSale.support_vente,
                                isArchived: modeOfSale.isArchived,
                                state: modeOfSale.state,
                                shopId: modeOfSale.shopId,
                                isFavorite: true,
                                designation: modeOfSale["Mode of sale"],
                                advancedDisplayName:
                                    modeOfSale.advancedDisplayName,
                            };
                        } else {
                            updatedModeOfSaleData[modeOfSale.Id] = {
                                tag: modeOfSale.tag,
                                display_name: modeOfSale.display_name,
                                support_vente: modeOfSale.support_vente,
                                isArchived: modeOfSale.isArchived,
                                state: modeOfSale.state,
                                shopId: modeOfSale.shopId,
                                isFavorite: false,
                                designation: modeOfSale["Mode of sale"],
                                advancedDisplayName:
                                    modeOfSale.advancedDisplayName,
                            };
                        }

                        return updatedModeOfSaleData;
                    },
                    {}
                ),
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${
                                dataAssociate.length > 0
                                    ? t(
                                          "Franchise favorite state has been successfully activated"
                                      )
                                    : t(
                                          "Favorite state has been enabled successfully"
                                      )
                            }`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 0) {
                            setIsFavoriteModified(!isFavoriteModified);
                        }
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function unfavoriteModeOfSale() {
        toast.dismiss();

        let dataModeOfSale = [
            {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        tag: e.selectedRow.tag,
                        display_name: e.selectedRow.display_name,
                        support_vente: e.selectedRow.support_vente,
                        isArchived: e.selectedRow.isArchived,
                        state: e.selectedRow.state,
                        shopId: e.selectedRow.shopId,
                        isFavorite: false,
                        designation: e.selectedRow["Mode of sale"],
                        advancedDisplayName: e.selectedRow.advancedDisplayName,
                    },
                },
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${
                                dataAssociate.length > 0
                                    ? t(
                                          "Franchise favorite state has been successfully deactivated"
                                      )
                                    : t(
                                          "Favorite state has been disabled successfully"
                                      )
                            }`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 0) {
                            setIsFavoriteModified(!isFavoriteModified);
                        }
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function isModeOfSaleUsed() {
        toast.dismiss();
        let isModeOfSaleUsed = false;
        let isSaleModeConsumed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/isModeOfSaleUsed?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&modeOfSale=${e.selectedRow.Id}`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.modeOfSaleUsed.length > 0) {
                        isModeOfSaleUsed = true;
                        ErrorToast(
                            t("Attention, you cannot archive a used element.")
                        );
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );

        if (isModeOfSaleUsed === false) {
            isSaleModeConsumed = await isModeOfSaleConsumed();
            if (isSaleModeConsumed === true) {
                ErrorToast(
                    t(
                        "Please note, you cannot archive an element used in a template"
                    )
                );
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setIsArchiveModalOpened(true);
            }
        }
    }
    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        if (
                            e.selectedRow.support_vente.includes(
                                elt[t("Sale support")]
                            )
                        ) {
                            isSaleSupportArchived = true;
                        }
                    });
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isSaleSupportArchived;
    }
    async function isModeOfSaleConsumed() {
        toast.dismiss();
        let isModeOfSaleUsed = false;

        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&type=salesMethods`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (
                        result.data.some(
                            (el: any) => el.id === e.selectedRow.Id
                        )
                    ) {
                        isModeOfSaleUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isModeOfSaleUsed;
    }
    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                if (!isAddModeOfSaleClicked) {
                                                    setIsAddModeOfSaleClicked(
                                                        !isAddModeOfSaleClicked
                                                    );
                                                }
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setSale(e.selectedRow);
                                                setSelectName(false);
                                                setSelectSales(false);
                                                setInputSaleMode(false);
                                                setLocalFilterActive(false);
                                            });
                                        } else {
                                            if (!isAddModeOfSaleClicked) {
                                                setIsAddModeOfSaleClicked(
                                                    !isAddModeOfSaleClicked
                                                );
                                            }
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                            setSale(e.selectedRow);
                                            setSelectName(false);
                                            setSelectSales(false);
                                            setInputSaleMode(false);
                                            setLocalFilterActive(false);
                                        }
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfSaleArchived
                                        ? ""
                                        : e.selectedRow.isFavorite === false
                                        ? t("Enable favorite state")
                                        : t("Disable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsAddModeOfSaleClicked(
                                                        false
                                                    );
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setSale(e.selectedRow);
                                                    if (
                                                        e.selectedRow.isFavorite
                                                    ) {
                                                        unfavoriteModeOfSale();
                                                    } else {
                                                        favoriteModeOfSale();
                                                    }
                                                });
                                            } else {
                                                setIsAddModeOfSaleClicked(
                                                    false
                                                );
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setSale(e.selectedRow);
                                                if (e.selectedRow.isFavorite) {
                                                    unfavoriteModeOfSale();
                                                } else {
                                                    favoriteModeOfSale();
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            e.selectedRow.isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "pointer__clz mdv_icn_FRlike",
                                            {
                                                "not-allowed-icon__clz": isModeOfSaleArchived,
                                            },
                                            oneShop ? "mr-2" : ""
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={isModeOfSaleArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    if (
                                                        !isAddModeOfSaleClicked
                                                    ) {
                                                        setIsAddModeOfSaleClicked(
                                                            !isAddModeOfSaleClicked
                                                        );
                                                    }
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setSale(e.selectedRow);
                                                    setSelectName(false);
                                                    setSelectSales(false);
                                                    setInputSaleMode(false);
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                if (!isAddModeOfSaleClicked) {
                                                    setIsAddModeOfSaleClicked(
                                                        !isAddModeOfSaleClicked
                                                    );
                                                }
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setSale(e.selectedRow);
                                                setSelectName(false);
                                                setSelectSales(false);
                                                setInputSaleMode(false);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isModeOfSaleArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isModeOfSaleArchived
                                            ? ""
                                            : e.selectedRow.state === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={e.selectedRow.state}
                                            onChange={async () => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddModeOfSaleClicked(
                                                            false
                                                        );
                                                        setIsActivationDeactivationModalOpened(
                                                            true
                                                        );

                                                        setDataState({
                                                            id:
                                                                e.selectedRow
                                                                    .Id,
                                                            state: !e
                                                                .selectedRow
                                                                .state,
                                                        });
                                                    });
                                                } else {
                                                    setIsAddModeOfSaleClicked(
                                                        false
                                                    );
                                                    setIsActivationDeactivationModalOpened(
                                                        true
                                                    );

                                                    setDataState({
                                                        id: e.selectedRow.Id,
                                                        state: !e.selectedRow
                                                            .state,
                                                    });
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz mdv_icn_BTenableDisable",
                                                {
                                                    "not-allowed-icon__clz": isModeOfSaleArchived,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isModeOfSaleArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsLoading(true);
                                                if (isEdited === true) {
                                                    setIsLoading(false);
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsAddModeOfSaleClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        if (
                                                            dataAssociate.length >
                                                            0
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            isModeOfSaleUsed();
                                                        }
                                                    });
                                                } else {
                                                    setIsAddModeOfSaleClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.length > 0
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                        setIsLoading(false);
                                                    } else {
                                                        isModeOfSaleUsed();
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isModeOfSaleArchived ||
                                                        isLoading,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={async () => {
                                                setIsLoading(true);
                                                let isArchived = await isSaleSupportArchived();

                                                if (isArchived === true) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                                        )!}`
                                                    );
                                                    setIsLoading(false);
                                                } else if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    setIsLoading(false);
                                                } else {
                                                    activeModeOfSale();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                                setSale(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isLoading ||
                                                        (dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) &&
                                                            oneShop),
                                                },

                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsAddModeOfSaleClicked(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setRowData(e.selectedRow);
                                            setSelectedRows([e.selectedRow]);
                                            setIsEdited(false);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setRowData(e.selectedRow);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz  cmn_icn_FRhistory",

                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
            {isActivationDeactivationModalOpened ? (
                <ConfirmationActivationDeactivationMessageModal
                    isModalOpened={isActivationDeactivationModalOpened}
                    setIsModalOpened={setIsActivationDeactivationModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    modeOfSaleState={e.selectedRow.state}
                />
            ) : null}
        </React.Fragment>
    );
}
