import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { setActivationDeactivationMessageModalOpened, store } from "./store";
import { useSnapshot } from "valtio";
type ConfirmationActivationDeactivationMessageModalType = {};
// eslint-disable-next-line
export function ConfirmationActivationDeactivationMessageModal({}: ConfirmationActivationDeactivationMessageModalType) {
    const {
        message,
        NoFunction,
        YesFunction,
        activationDeactivationMessageModalOpened,
    } = useSnapshot(store);
    const { t } = useTranslation();

    return (
        <StyledModal
            toggle={true}
            isOpen={activationDeactivationMessageModalOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setActivationDeactivationMessageModalOpened(false);
                }}
            >
                <span
                    className="text-uppercase"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("Alert")}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={async () => {
                        await NoFunction();
                        setActivationDeactivationMessageModalOpened(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={async () => {
                        await YesFunction();
                        setActivationDeactivationMessageModalOpened(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
