import React from "react";
import { useSnapshot } from "valtio";
import { store, setAggregator, setSelectedRowsAggregator } from "../store";
import { setActiveAggregator } from "../store";

import "./index.css";
type UniqueRowSelectionType = {
    e: any;
    selectedAggregator: any;
    isOneShopClicked: boolean;
};
export default function UniqueRowSelection({
    e,
    isOneShopClicked,
    selectedAggregator,
}: UniqueRowSelectionType) {
    const { aggregator } = useSnapshot(store);
    const [aggregatorState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedAggregator.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? selectedAggregator[
                  selectedAggregator.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].isActive
            : true
    );
    React.useEffect(() => {
        setActiveAggregator({
            Id: e.selectedRow.Id,
            state: aggregatorState,
        });

        // eslint-disable-next-line
    }, [aggregatorState]);
    return (
        <React.Fragment>
            <input
                type="checkbox"
                placeholder="checkbox"
                onChange={() => {
                    if (e.selectedRow.Id === aggregator) {
                        setAggregator("");
                    } else {
                        setAggregator(e.selectedRow.Id);
                        setSelectedRowsAggregator(e.selectedRow);
                    }
                }}
                checked={e.selectedRow.Id === aggregator}
                disabled={
                    e.selectedRow.Id !== aggregator &&
                    aggregator !== "" &&
                    aggregator !== undefined
                }
                className="checkbox_fiscalYear__clz"
            />
        </React.Fragment>
    );
}
