import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon, CheckIcon } from "@aureskonnect/react-ui";
import { uuid } from "uuidv4";

import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as Fidelity,
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setFidelityData,
    setArchivedFidelityFranchise,
    setFidelityFranchise,
    setDataArchive,
    setDataFranchiseArchive,
    setSelectedFidelity,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
} from "../store";

import { CustomIcons } from "./CustomIcons";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ActionColumn } from "./ActionColumn";
import { ConfirmationActiveFidelity } from "../ConfirmationActiveFidelity";
import FidelityForm from "./FidelityForm";
import HistoryModal from "../HistoryModal";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { SupportCodeModal } from "@pages/Applications/DeviceAssociateModal/MoneticsApplicationForm/SupportCodeModal";

import { useKeycloak } from "@react-keycloak/web";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function FidelityFranchise({
    isFidelityArchived,
    setIsFidelityArchived,
}: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    
    const didMountRef = React.useRef(false);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        fidelityData,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        modify,
    } = useSnapshot(Fidelity);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [
        isAddNewFidelityButtonClicked,
        setIsAddNewFidelityButtonClicked,
    ] = useState(false);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [fidelity, setFidelity] = useState<any>([]);
    const [shopId, setShopId] = React.useState<string | number>(
        oneShop ? shopID : "0"
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isAddFidelityClicked, setIsAddFidelityClicked] = React.useState<
        boolean
    >(false);
    const [fidelityChoiceIndex, setFidelityChoiceIndex] = React.useState<
        number
    >(0);
    const [isModalOpenedSupport, setIsModalOpenedSupport] = React.useState<
        boolean
    >(false);
    const [codeSupport, setCodeSupport] = React.useState<string>("");
    const [isValidCode, setIsValidCode] = React.useState(false);
    const [key, setKey] = useState<any>("");
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: t("Centralized loyalty"),
            customJsx: (e: any) => (
                <span>
                    {e.selectedRow[t("State")] === true && (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={30}
                            width={30}
                        />
                    )}
                </span>
            ),
            filterName: t("Centralized loyalty"),
            canFilter: true,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setFidelity={setFidelity}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isOneShop={true}
                    isFidelityArchived={isFidelityArchived}
                    isAddFidelityClicked={isAddFidelityClicked}
                    setIsAddFidelityClicked={setIsAddFidelityClicked}
                    selectedRows={selectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    setIsModalOpenedSupport={setIsModalOpenedSupport}
                    setIsConsult={setIsConsult}
                    isConsult={isConsult}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        if (
            dataTable.othersData !== undefined &&
            dataTable.othersData.activated !== undefined
        ) {
            setNumberActive(dataTable.othersData.activated);
            setNumberArchived(dataTable.othersData.archived);
        } else {
            setNumberActive(0);
            setNumberArchived(0);
        }
    }

    React.useEffect(() => {
        if (filterActive) setIsAddFidelityClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });

        setSelectedFidelity(array);
        setFidelityData(dataTable);
        setArchivedFidelityFranchise(dataTable);
        setFidelityFranchise(dataTable);
        setDataArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData?.allData.othersData.listArchived
                : []
        );
        setDataFranchiseArchive(
            dataTable?.othersData?.allData !== undefined
                ? dataTable?.othersData?.allData.othersData.listArchived
                : []
        );
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddFidelityClicked(false);
        setKey(uuid());
    }, [t]);

    React.useEffect(() => {
        setIsAddFidelityClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
    }, [i18nextLng]);

    return (
        <React.Fragment key={key}>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddFidelityClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isFidelityArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    canExpand={false}
                    canSelect
                    customSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddFidelityClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center ml-2">
                                            <span
                                                onClick={() => {
                                                    setFidelity([]);
                                                    if (
                                                        fidelityData.data
                                                            .length > 0 &&
                                                        oneShop
                                                    ) {
                                                        ErrorToast(
                                                            t(
                                                                "Attention, a shop cannot have more than one setting fidelity"
                                                            )
                                                        );
                                                    } else {
                                                        setIsAddFidelityClicked(
                                                            !isAddFidelityClicked
                                                        );
                                                    }

                                                    setLocalFilterActive(false);
                                                }}
                                                className={`${classnames(
                                                    "w-100 ml-3 cmn_btn_openForm",
                                                    {
                                                        "not-allowed-icon__clz": isFidelityArchived,
                                                    }
                                                )}`}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t("Add fidelity")}
                                                </CustomMainColorButtonWithIcon>
                                                <div></div>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className=" fed_btn_FRreset"
                                                rounded
                                                disabled={
                                                    fidelity.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                isFidelityArchived={isFidelityArchived}
                                setIsFidelityArchived={setIsFidelityArchived}
                                setIsAddFidelityClicked={
                                    setIsAddFidelityClicked
                                }
                                fidelityChoiceIndex={fidelityChoiceIndex}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="fidelityForm"
                />
                {isAddFidelityClicked ? (
                    <FidelityForm
                        isAddFidelityClicked={isAddFidelityClicked}
                        setIsAddFidelityClicked={setIsAddFidelityClicked!}
                        setDataIsUpdated={setDataIsUpdated}
                        dataFidelity={fidelity}
                        isAddNewFidelityButtonClicked={
                            isAddNewFidelityButtonClicked
                        }
                        setDataFidelity={setFidelity}
                        setLocalFilterActive={setLocalFilterActive}
                        fidelityChoiceIndex={fidelityChoiceIndex}
                        setFidelityChoiceIndex={setFidelityChoiceIndex}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            <ConfirmationActiveFidelity
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewFidelityButtonClicked}
                    isClicked={isAddNewFidelityButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setDataIsUpdated}
                />
            ) : null}
            {isModalOpenedSupport ? (
                <SupportCodeModal
                    isModalOpened={isModalOpenedSupport}
                    setIsModalOpened={setIsModalOpenedSupport}
                    codeSupport={codeSupport}
                    setCodeSupport={setCodeSupport}
                    isValidCode={isValidCode}
                    setIsValidCode={setIsValidCode}
                />
            ) : null}
        </React.Fragment>
    );
}
