import { useEffect } from "react";
import NProgress from "nprogress";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

type Props = {
    children: JSX.Element;
};

function AuthRoute({ children }: Props): JSX.Element | null {
    const { keycloak } = useKeycloak();
    useEffect(() => {
        NProgress.start();
        NProgress.done();
    }, []);

    let navigate = useNavigate();

    useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (
            uidEntity === null
            //  ||
            // !keycloak?.authenticated ||
            // keycloak?.tokenParsed?.uuid !== uidEntity
        ) {
            navigate("/login-sso");
        }
    });

    return keycloak?.authenticated ? children : null;
}

export default AuthRoute;
