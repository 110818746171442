import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import "./i18n";

import App from "./App";

import { keycloak } from "./keycloak";
// import * as serviceWorker from './serviceWorker';

const initOptions = {
    onLoad: "login-required",
};

ReactDOM.render(
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ReactKeycloakProvider>,
    document.getElementById("root")
);
//  serviceWorker.register("");
