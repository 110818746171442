import { keycloak } from "../../keycloak";
import { proxy, subscribe } from "valtio";

type StoreType = {
    collapsed: boolean;
    getImages: () => Promise<void>;
    images: ImageType[];
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    mainColor: string;
    secondaryColor: string;
};

export const store = proxy<StoreType>({
    collapsed: false,
    images: [],
    getImages: async function () {
        if (localStorage.getItem("images") !== null) {
         //   store.images = JSON.parse(localStorage.getItem("images") as string);
        } else {
            const response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/files`,
                {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                    },
                }
            );

            const result = await response.json();
            store.images = result.data;
        }
    },
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    mainColor: "#323333",
    secondaryColor: "#c4bfbe",
});

store.getImages();

subscribe(store, () => {
    localStorage.setItem("images", JSON.stringify(store.images));
});
