import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../helpers/general";

import { resetStore } from "../../Applications/DeviceAssociateModal/store";
import { store } from "../../../components/VerticalLayout/store";
import { store as storeData } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { useKeycloak } from "@react-keycloak/web";

export default function HistoryModal({
    isModalOpened,
    setIsModalOpened,
    setDataUpdated,
}: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { selectedAggregator, languageData } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [, setArrayIds] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/aggregator/history?userId=${userID}&franchiseId=${franchiseID}&aggregator=${selectedAggregator
        .map((el: any) => JSON.stringify(el))
        .join()}`;
    function getNamesFromOriginCols(arr: any) {
        let jsonOrigin: any = {
            note: "Note",
            user: "User",
            versionServer: "Server version",
            keyAuthentication: "Authentication key (Bearer authentication)",
            apiEmail: "E-mail API (API user mail) ",
            port: "Port",
            server: "Server",
            apiServer: "API Serveur (API Host)",
            setupId: "Setup ID",
            apiPassword: "Mot de passe API ( API User Password)",
            modeOfSaleEmpId: "Mode of sale ID : To take",
            modeOfSaleLivId: "Mode of sale ID : Delivery",
            modeOfSaleSPId: "Mode of sale ID : On the spot",
            divisionPriceItems: "Division price items",
            divisionPriceOptions: "Division price options",
            divisionTax: "Division Tax",
            layoutLabel: "Layout Label",
            apiWebhook: "Webhook API",
        };

        let res: any = [];
        for (let index = 0; index < arr.length; index++) {
            const el = arr[index];

            if (jsonOrigin[el] !== undefined) {
                res.push(jsonOrigin[el]);
            } else {
                res.push(el);
            }
        }

        return res;
    }

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "number_aggregator_points") {
                            actionsArray.push(t("Number of aggregator points"));
                        } else if (el.item === "money_correspondence") {
                            actionsArray.push(t("Money correspondence"));
                        } else if (el.item === "note") {
                            actionsArray.push(t("Remark"));
                        } else if (el.item === "password") {
                            actionsArray.push(t("Password"));
                        } else if (el.item === "authentication_mode") {
                            actionsArray.push(t("Authentication mode"));
                        } else if (el.item === "designation") {
                            actionsArray.push(t("Display name"));
                        } else if (el.item === "sortedVisibility") {
                            actionsArray.push(t("Changing Visibility"));
                        } else if (el.item === "Login") {
                            actionsArray.push(t("Login"));
                        } else if (el.item === "auresKonnectGroup") {
                            actionsArray.push(t("Group"));
                        } else if (el.item === "isCentralizationActive") {
                            actionsArray.push(t("State"));
                        } else if (el.item === "type") {
                            actionsArray.push(t("Aggregator"));
                        }
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow?.actions[0].item === "sortedVisibility") {
                        return <span>{t("Changing Visibility")}</span>;
                    }

                    if (e.selectedRow?.actions[0].item === "isActive") {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Activation")}</span>
                        ) : (
                            <span>{t("Deactivation")}</span>
                        );
                    } else if (
                        e.selectedRow?.actions[0].item === "isArchived"
                    ) {
                        return e.selectedRow?.actions[0].newValue === true ? (
                            <span>{t("Archiving")}</span>
                        ) : (
                            <span>{t("Unarchiving")}</span>
                        );
                    }
                    let listActions = getNamesFromOriginCols(
                        e.selectedRow.actions.map(({ item }: any) => item)
                    );

                    let translateItems: any = [];
                    for (let index = 0; index < listActions.length; index++) {
                        const elx = listActions[index];
                        translateItems.push(t(elx));
                    }

                    return (
                        <span>
                            <CustomColumnDisplay
                                text={`${t(
                                    "Modification"
                                )} : ${translateItems.join(",")}`}
                            />
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation.includes("reassignment")) {
                    return (
                        <span>
                            {t("Reassignment")}
                            {e.selectedRow.operation.substring(12)}
                        </span>
                    );
                } else {
                    return <span>{t("Affectation")}</span>;
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow?.actions[0].item === "sortedVisibility") {
                        return <span>{""}</span>;
                    } else {
                        return (
                            <span>
                                {e.selectedRow?.actions[0]?.item ===
                                "isArchived" ? (
                                    e.selectedRow?.actions[0]?.oldValue ===
                                    true ? (
                                        <span>
                                            {t("Status")}: {t("Archived")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Unarchived")}
                                        </span>
                                    )
                                ) : ["isActive"].indexOf(
                                      e.selectedRow.actions[0].item
                                  ) !== -1 ? (
                                    e.selectedRow.actions[0].oldValue ===
                                        true ||
                                    e.selectedRow.actions[0].oldValue === "" ? (
                                        <span>
                                            {t("Status")}: {t("Activated")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Deactivated")}
                                        </span>
                                    )
                                ) : [
                                      t("State"),
                                      t(
                                          "The TCPOS update overwritres existing data"
                                      ),
                                      t("Order validation pre-check"),
                                      t("Activation"),
                                      t("Deactivation"),
                                  ].indexOf(e.selectedRow.actions[0]?.item) !==
                                  -1 ? (
                                    e.selectedRow.actions.map(
                                        (x: any, i: number) => {
                                            return x?.oldValue === true ? (
                                                <span>
                                                    {t("State")}:{" "}
                                                    {t("Activated")}{" "}
                                                    {i ===
                                                    e.selectedRow.actions
                                                        .length -
                                                        1
                                                        ? ""
                                                        : ","}
                                                </span>
                                            ) : (
                                                <span>
                                                    {t("State")}:{" "}
                                                    {t("Deactivated")}
                                                    {i ===
                                                    e.selectedRow.actions
                                                        .length -
                                                        1
                                                        ? ""
                                                        : ","}
                                                </span>
                                            );
                                        }
                                    )
                                ) : [t("Language")].indexOf(
                                      e.selectedRow.actions[0]?.item
                                  ) !== -1 ? (
                                    <span>
                                        {t(
                                            e.selectedRow?.actions[0]?.oldValue
                                                ?.value !== undefined
                                                ? languageData.find(
                                                      (x: any) => {
                                                          return (
                                                              x.value ===
                                                              e.selectedRow
                                                                  ?.actions[0]
                                                                  ?.oldValue
                                                                  ?.value
                                                          );
                                                      }
                                                  ).label
                                                : ""
                                        )}
                                    </span>
                                ) : (
                                    <span>
                                        {e.selectedRow.actions
                                            .map((el: any) =>
                                                el.item !== "sortedVisibility"
                                                    ? el.item ===
                                                      "isCentralizationActive"
                                                        ? el.oldValue === true
                                                            ? t("Activation")
                                                            : t("Deactivation")
                                                        : el.oldValue
                                                    : ""
                                            )
                                            .join(",")}
                                    </span>
                                )}
                            </span>
                        );
                    }
                } else if (e.selectedRow.operation === "creation") {
                    return null;
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow?.actions[0].item === "sortedVisibility") {
                        return <span>{""}</span>;
                    } else {
                        return (
                            <span>
                                {["isArchived"].indexOf(
                                    e.selectedRow.actions[0].item
                                ) !== -1 ? (
                                    e.selectedRow.actions[0].newValue ===
                                    true ? (
                                        <span>
                                            {t("Status")}: {t("Archived")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Unarchived")}
                                        </span>
                                    )
                                ) : ["isActive"].indexOf(
                                      e.selectedRow.actions[0].item
                                  ) !== -1 ? (
                                    e.selectedRow.actions[0].newValue ===
                                        true ||
                                    e.selectedRow.actions[0].newValue === "" ? (
                                        <span>
                                            {t("Status")}: {t("Activated")}
                                        </span>
                                    ) : (
                                        <span>
                                            {t("Status")}: {t("Deactivated")}
                                        </span>
                                    )
                                ) : [
                                      t("Activation"),
                                      t("Deactivation"),
                                  ].indexOf(e.selectedRow.actions[0]?.item) !==
                                  -1 ? (
                                    e.selectedRow.actions.map(
                                        (x: any, i: number) => {
                                            return x?.newValue === true ? (
                                                <span>
                                                    {t("State")}:{" "}
                                                    {t("Activated")}{" "}
                                                    {i ===
                                                    e.selectedRow.actions
                                                        .length -
                                                        1
                                                        ? ""
                                                        : ","}
                                                </span>
                                            ) : (
                                                <span>
                                                    {t("State")}:{" "}
                                                    {t("Deactivated")}
                                                    {i ===
                                                    e.selectedRow.actions
                                                        .length -
                                                        1
                                                        ? ""
                                                        : ","}
                                                </span>
                                            );
                                        }
                                    )
                                ) : [t("Language")].indexOf(
                                      e.selectedRow.actions[0]?.item
                                  ) !== -1 ? (
                                    <span>
                                        {t(
                                            e.selectedRow?.actions[0]?.newValue
                                                ?.value !== undefined
                                                ? languageData.find(
                                                      (x: any) => {
                                                          return (
                                                              x.value ===
                                                              e.selectedRow
                                                                  ?.actions[0]
                                                                  ?.newValue
                                                                  ?.value
                                                          );
                                                      }
                                                  ).label
                                                : ""
                                        )}
                                    </span>
                                ) : (
                                    <span>
                                        {e.selectedRow.actions
                                            .map((el: any) =>
                                                el.item !== "sortedVisibility"
                                                    ? el.item ===
                                                      "isCentralizationActive"
                                                        ? el.newValue === true
                                                            ? t("Activation")
                                                            : t("Deactivation")
                                                        : el.newValue
                                                    : ""
                                            )
                                            .join(",")}
                                    </span>
                                )}
                            </span>
                        );
                    }
                } else if (e.selectedRow.operation === "creation") {
                    return (
                        e.selectedRow[t("Aggregator")] !== undefined &&
                        e.selectedRow[t("Aggregator")]
                    );
                } else if (e.selectedRow.operation === "Affectation") {
                    return <span>{""}</span>;
                } else {
                    return <span>{""}</span>;
                }
            },
        },
    ];
    React.useEffect(() => {
        setArrayIds(selectedAggregator.map((el) => el.Id));
    }, [selectedAggregator]);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);

                        resetStore();
                    }}
                >
                    <StyledH2 className="fed_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div className="d-flex title__clz">
                        <div
                            className={`${classnames(
                                "title__clz fed_txt_HStype",
                                {
                                    "not-allowed-icon__clz":
                                        selectedAggregator.length > 1,
                                }
                            )}`}
                        >
                            {t("Aggregator")} :
                            <span>
                                {selectedAggregator?.length > 1
                                    ? null
                                    : data?.data !== undefined
                                    ? data?.data?.length > 0
                                        ? data?.data![0][t("Aggregator")]
                                        : ""
                                    : ""}
                            </span>
                        </div>
                    </div>
                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyAggregator"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        variant="primary"
                        className="fed_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
