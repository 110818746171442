import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as saleStore,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIdsLocalShop,
    setIsAssociatedModeOfSale,
    setShopsModeSaleData,
    setRowsShop,
    setAssociatedModeOfSale,
    setGlobalDataIsUpdated,
} from "../store";

import NProgressWrapper from "@components/Common/NProgressWrapper";

import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { ActionColumnModeSale } from "./ActionColumnModeSale";
import { ConfirmationActiveModeOfSale } from "../ConfirmationActiveModeOfSale";
import { ModeOfSaleAssociateModal } from "./ModeOfSaleAssociateModal";
import { ConfirmationUpdate } from "./ConfirmationUpdate";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { useKeycloak } from "@react-keycloak/web";

export default function ShopsModeOfSales({
    isModeOfSaleArchived,
    setIsModeOfSaleArchived,
    setLimit,
}: any) {
    const { keycloak } = useKeycloak();
    
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        isAssociatedModeOfSale,
        globalDataIsUpdated,
        modify,
        key,
        isLoading,
    } = useSnapshot(saleStore);

    const { t } = useTranslation();
    const [rowData, setRowData] = useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [dataTable, setDataTable] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale_archived?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay text={e.selectedRow.display_name} />
            ),
            filterName: t("Display name"),
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Sales method"),
            customJsx: (e: any) => <NumberModeOfSale e={e} />,
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Sale support"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow.support_vente !== undefined
                            ? e.selectedRow.support_vente.join()
                            : ""
                    }
                />
            ),
            filterName: t("Support de vente"),
            canFilter: true,
        },

        {
            indexOFColumn: 5,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow.tag !== undefined
                            ? e.selectedRow.tag.join()
                            : ""
                    }
                />
            ),
            filterName: t("Tag"),
            canFilter: true,
        },
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setRowData={setRowData}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isModeOfSaleArchived={isModeOfSaleArchived}
                    selectedRows={selectedRows}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        let sumActive = 0;
        let sumArchive = 0;
        if (dataTable.data !== undefined) {
            selectedRows.length === 0
                ? dataTable.data.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  })
                : selectedRows.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  });
            setNumberActiveMultiShop(sumActive);
            setNumberArchiveMultiShop(sumArchive);
        }
    }

    function NumberModeOfSale(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz mdv_icn_BTcounter">
                        {e.e.selectedRow?.Boutique !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow["Mode of sale"]
                )}
            </React.Fragment>
        );
    }

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedModeOfSale: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
                associatedModeOfSale.push(el.subRows[i]);
            }
        });
        setAssociatedModeOfSale(associatedModeOfSale);
        setRowsShop(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsModeSaleData(dataTable);
    }, [selectedRows, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={isModeOfSaleArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    setData={setDataTable}
                    canExpand
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            <div className="d-flex justify-content-start align-items-center">
                                {modify === true ? (
                                    <>
                                        <CustomMainColorButton
                                            className={`${classnames(
                                                "w-100 ml-3 mdv_btn_BTassignSalesMode",
                                                {
                                                    "not-allowed-icon__clz": isModeOfSaleArchived,
                                                }
                                            )}`}
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isShopSelected ? false : true
                                            }
                                            onClick={() => {
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Assign sales method")}
                                        </CustomMainColorButton>
                                        <div></div>
                                    </>
                                ) : null}
                            </div>

                            <ActionColumnModeSale
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                setIsModeOfSaleArchived={
                                    setIsModeOfSaleArchived
                                }
                                isModeOfSaleArchived={isModeOfSaleArchived}
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="ModeOfSale"
                />
            </div>

            <ConfirmationActiveModeOfSale
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setGlobalDataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={false}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    isOneShop={false}
                    rowData={rowData}
                    setData={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <ModeOfSaleAssociateModal
                    setData={setGlobalDataIsUpdated}
                    setLimit={setLimit}
                />
            ) : null}

            {isAssociatedModeOfSale ? (
                <ConfirmationUpdate
                    isModalOpened={isAssociatedModeOfSale}
                    setIsModalOpened={setIsAssociatedModeOfSale}
                    setData={setGlobalDataIsUpdated}
                    setLimit={setLimit}
                />
            ) : null}
            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
