import React from "react";
import { useTranslation } from "react-i18next";
import { CardFooter } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import {
    setIsModalConfirmationOpened,
    store as userStore,
} from "@components/VerticalLayout/store";
import { store, resetStore, setIsPrinterModified } from "./store";

import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type PrepheralCardFooterType = {
    dataIsUpdated: boolean | number;
    setIsEdited: Function;
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    setDataIsUpdated: Function;
};

export default function PrepheralCardFooter({
    dataIsUpdated,
    setIsEdited,
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    setDataIsUpdated,
}: PrepheralCardFooterType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    const APPID = "KDS";
    const { printerSelected, checkModificationEffect } = useSnapshot(store);

    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        isModalConfirmationOpened,
        operatorID,
    } = useSnapshot(userStore);

    function checkDisassociatedPeripheral(lastList: any, newList: any) {
        let res: any = [];
        for (let index = 0; index < lastList.length; index++) {
            const e = lastList[index];
            if (newList.indexOf(e) === -1 && e !== undefined) {
                res.push(e);
            }
        }
        return res;
    }

    const [peripheralAarrayError, setPeripheralArrayError] = React.useState<
        string[]
    >([]);

    async function handleValidateButtonClickEvent() {
        // setIsLoadingAssociateValidation(true);
        let ListDisassociatePrinter: any = checkDisassociatedPeripheral(
            // eslint-disable-next-line array-callback-return
            rowData[0].subRows.map(function (item: any) {
                if (item.peripheralType === "printer") {
                    return item["peripheralId"];
                }
            }),
            printerSelected.map(function (item: any) {
                return item["Id"];
            })
        );

        let localDesPrinters: any = [];

        for (let i = 0; i < ListDisassociatePrinter.length; i++) {
            const e = ListDisassociatePrinter[i];
            let name = "";
            rowData[0].subRows.forEach((el: any) => {
                if (el.peripheralId === e) {
                    name = el[t("Device")];
                }
            });

            let obj = {
                appId: APPID,
                appId_children: [rowData[0].appId],
                franchiseId: franchiseID,
                peripheralId: e,
                shopId: oneShop ? shopID.toString() : rowData[0].shopId,
                userId: userID,
                peripheralName: name,
                operatorId: operatorID,
                designation: rowData[0][t("Application")],
            };
            localDesPrinters.push(
                fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/printer`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj),
                    }
                ).then((response) => response.json())
            );
        }

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/printer`,
                await Promise.all(localDesPrinters).then((result) => {})
            );
        } catch (e) {}

        if (printerSelected.length > 0) {
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/printer`;
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = rowData[0].shopId;
                franchiseId = franchiseID;
            }
            let printersObj: any = {};
            let designationPrinter: any[] = [];
            printerSelected.forEach((el: any) => {
                if (
                    el["Id"] !== undefined &&
                    ListDisassociatePrinter.indexOf(el["Id"]) === -1 &&
                    rowData[0].subRows.every(
                        (elt: any) => elt.peripheralId !== el.Id
                    )
                ) {
                    designationPrinter.push(el["Nom de l'imprimante"]);
                    printersObj[el["Id"]] = {
                        id_printer: el["Id"],
                        designation: el["Nom de l'imprimante"],
                        mark: el["Marque"],
                        port: el["port"],
                        note: el["Remarque"],
                        status: "in line",
                        state: "to configure",
                        path: el["path"],
                        font_size: el["Taille police"],
                        edit_date: "",
                        type: el["type"],
                        add_date: "",
                        speed: el["speed"],
                        ip: el["ip"],
                        port_series: el["port_series"],
                        ticket_width: el["ticket_width"],
                        general: {},
                        customer_ticket: {},
                        Kitchen_ticket: {},
                        modelKitchenTicket: {},
                        divers: {},
                        isArchived:
                            el["isArchived"] !== undefined
                                ? el["isArchived"]
                                : false,
                    };
                }
            });
            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                appId: APPID,
                operatorId: operatorID,
                designation: rowData[0][t("Application")],
                peripheralName: designationPrinter.join(","),
                appId_children: [rowData[0].appId],
                data: printersObj,
            };
            if (Object.keys(printersObj).length !== 0) {
                mutate(
                    apiUrl,
                    await fetchWithToken(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                             
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            resetStore();

                            setDataIsUpdated(!dataIsUpdated);
                            setIsEdited(false);
                        })
                        .catch((e: any) => {
                            peripheralAarrayError.push("printer");
                            setPeripheralArrayError(peripheralAarrayError);
                        })
                );
            }
        }

        if (peripheralAarrayError.length > 0) {
            resetStore();
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            // setIsLoadingAssociateValidation(false);
            setIsDeviceAssociateModalOpened(isDeviceAssociateModalOpened);
        } else {
            resetStore();
            setIsDeviceAssociateModalOpened(!isDeviceAssociateModalOpened);
            setDataIsUpdated(!dataIsUpdated);
            toast.success(
                `${t("The association of this device was successful")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
    }

    return (
        <div>
            <CardFooter className="Card-footer__clz">
                <div className="justify-content-between d-flex align-items-right  float-right p-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsDeviceAssociateModalOpened(
                                !isDeviceAssociateModalOpened
                            );
                            setDataIsUpdated(!dataIsUpdated);
                            resetStore();
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        // disabled={isLoadingAssociateValidation}
                        onClick={() => {
                            if (checkModificationEffect === true) {
                                handleValidateButtonClickEvent();
                            } else {
                                setIsModalConfirmationOpened(
                                    !isModalConfirmationOpened
                                );
                            }
                            setIsPrinterModified(false);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </div>
            </CardFooter>
        </div>
    );
}
