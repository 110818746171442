import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedModeOfInformation,
    setSelectedRow,
    setIds,
    setMessage,
    setIsLoading,
} from "../store";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { setItemsDisable } from "@store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { useKeycloak } from "@react-keycloak/web";

export function ModeOfInformationAssociateModal({
    setData,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    const { keycloak } = useKeycloak();
    
    const {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        associatedModeInformation,
        activeModeInformation,
        rowsShop,
        localShopWithInformation,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [modifiedRows, setModifiedRows] = React.useState<any[]>([]);

    const shopId = 0;
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/mode_of_Information?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}${
        localShop.length === 1 ? `&prefix=${localShop[0]}` : ""
    }`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    function checkIfModeOfInformationExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedModeOfInformation !== undefined) {
                    element.archivedModeOfInformation.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Mode of information"),
            customJsx: (e: any) => <NumberModeOfInformation e={e} />,
            filterName: t("Mode of information"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Sales method"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow[t("Sales method")])
                            ? e.selectedRow[t("Sales method")]
                                  .map((x: any) => {
                                      return x.label;
                                  })
                                  .join(",")
                            : e.selectedRow[t("Sales method")]
                    }
                />
            ),
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Support of sale"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow[t("Sales support")] !== undefined &&
                        Object.keys(e.selectedRow[t("Sales support")]).length >
                            0
                            ? e.selectedRow[t("Sales support")]?.label
                            : ""
                    }
                />
            ),
            filterName: t("Support of sale"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow.tag)
                            ? e.selectedRow.tag.join(",")
                            : ""
                    }
                />
            ),
            filterName: t("Tag"),
            canFilter: true,
        },
        {
            indexOFColumn: 5,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    modifiedRows={modifiedRows}
                    setModifiedRows={setModifiedRows}
                    isModeOfInformationArchived={isModeOfInformationArchived}
                    setIsModeOfInformationArchived={
                        setIsModeOfInformationArchived
                    }
                />
            ),
            filterName: t("Actions"),
            canFilter: true,
        },
    ];

    function NumberModeOfInformation(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow[t("Mode of information")]
                )}
            </React.Fragment>
        );
    }
    function getUnion(array1: any, array2: any) {
        let data: any = [];
        let max: any = array2.length > array1.length ? array2 : array1;
        let min: any = array2.length < array1.length ? array2 : array1;
        if (array1.length === array2.length) {
            min = array1;
            max = array2;
        }
        min.forEach((el: any) => {
            if (max.indexOf(el) !== -1) {
                data.push(el);
            }
        });
        return data;
    }
    function canAssociate(shopId: string, MI: any) {
        let can: boolean = false;
        let shopInform: any = localShopWithInformation.find(
            (x: any) => x.shopId === shopId
        );

        let unionMOS: any = getUnion(
            shopInform.modeOfSale,
            MI[t("Sales method")].map((x: any) => {
                return x.value;
            })
        );
        let unionSOS: any = getUnion(shopInform.supportOfSale, [
            MI[t("Sales support")]?.value,
        ]);

        unionMOS = MI[t("Sales method")].filter((x: any) => {
            return unionMOS.indexOf(x.value) !== -1;
        });
        if (unionMOS.length > 0 && unionSOS.length > 0) {
            can = true;
        }
        return {
            can: can,
            unionMOS: unionMOS,
            unionSOS: [MI[t("Sales support")]],
        };
    }
    async function AssociateModeOfInformation() {
        setIsLoading(true);
        toast.dismiss();
        let objectData: any = {};
        let localData: any = [];
        let blockedAssignArray: boolean[] = [];

        for (let elx of localShopWithInformation) {
            objectData = {};
            let element = elx.shopId;

            if (selectedRows.length !== 0) {
                // eslint-disable-next-line no-loop-func
                selectedRows.forEach(async (el: any) => {
                    if (el.Id !== undefined) {
                        let canAssign: any = canAssociate(element, el);

                        if (canAssign.can === true) {
                            Object.assign(objectData, {
                                [el.Id]: {
                                    designation: el[t("Mode of information")],
                                    idMI: el?.idMI,
                                    supportOfSale: canAssign.unionSOS[0],
                                    salesSupportData: [canAssign.unionSOS[0]],
                                    displayName: el[t("Display name")],
                                    modeOfSale: canAssign.unionMOS,
                                    tag: el.tag,
                                    isArchived: el.isArchived,
                                    shopId: [element],
                                    state: activeModeInformation.find(
                                        (element: any) => element.Id === el.Id
                                    ).state,
                                    advancedDisplayName: el.advancedDisplayName,
                                },
                            });
                            blockedAssignArray.push(false);
                        } else {
                            blockedAssignArray.push(true);
                        }
                    }
                });
                let dataModeOfInformation = {
                    userId: userID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    shopId: [element],
                    franchiseId: franchiseID,
                    data: objectData,
                    isAssociate: true,
                    isShop: true,
                };
                localData.push(dataModeOfInformation);
            }
        }
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetchWithToken(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(localData),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }

            if (error === false && blockedAssignArray.includes(false)) {
                const getItemsDisableUrl: Response = await fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                    {
                        method: "GET",
                    }
                );
                const itemsDisable = await getItemsDisableUrl.json();
                if (itemsDisable.error === false) {
                    setItemsDisable(itemsDisable.data);
                    localStorage.setItem(
                        "itemsDisable",
                        JSON.stringify(itemsDisable.data)
                    );
                }
                toast.success(
                    `${t("Information mode assignment was successful")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setData(true);
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length
                );
                setIsLoading(false);
            }
            if (blockedAssignArray.includes(true)) {
                setLimit(2);
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
                setIsLoading(false);
            }
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                }}
            >
                <StyledH2 className="text-uppercase mdi_txt_AFlistOfInformationModes">
                    {t("List of mode of information")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    canSelect
                    customSelect
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    canResize
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateModeOfInformation"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    className="mdi_btn_AFcancel"
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mdi_btn_AFvalidate"
                    variant="primary"
                    onClick={() => {
                        let archived = checkIfModeOfInformationExistAndArchived();

                        CountIds();
                        if (selectedRows.length === 0) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select at least one mode of information"
                                )
                            );
                        } else {
                            try {
                                setSelectedRow(selectedRows);
                                selectedRows.forEach((el: any) => {
                                    associatedModeInformation.forEach(
                                        (element: any) => {
                                            if (
                                                el.Id === element.Id &&
                                                element.state !==
                                                    activeModeInformation.find(
                                                        (elt: any) =>
                                                            elt.Id === el.Id
                                                    ).state
                                            ) {
                                                setMessage(
                                                    t(
                                                        "Warning! This change will affect specific settings in the store(s).\n\nDo you want to confirm the modification?"
                                                    )
                                                );
                                                setIsAssociatedModeOfInformation(
                                                    true
                                                );
                                                // eslint-disable-next-line
                                                throw "Break";
                                            }
                                        }
                                    );
                                });
                                selectedRows.forEach((el: any) => {
                                    if (archived.includes(el.Id)) {
                                        setIsAssociatedModeOfInformation(true);

                                        setMessage(
                                            t(
                                                "Attention! One or more selected mode of information are already assigned and archived at the store level.Would you unarchive them?"
                                            )
                                        );
                                        // eslint-disable-next-line
                                        throw "Break";
                                    }
                                });
                                AssociateModeOfInformation();

                                setIsAssociateModalOpened(
                                    !isAssociateModalOpened
                                );
                                // setIsAssociateModalClosed(
                                //     !isAssociateModalClosed
                                // );
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
