import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { capitalize } from "../../helpers/general";

import { store } from "../../components/VerticalLayout/store";
import { store as storeData } from "../../store/project/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { useKeycloak } from "@react-keycloak/web";

export default function HistoryModal({
    isModalOpened,
    setIsModalOpened,
    setIsDataUpdated,
    setIsCreatedProject,
    setSelectedRows,
}: any) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();

    const { selectedProject } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [, setArrayIds] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/project/history?userId=${userID}&franchiseId=${franchiseID}&project=${selectedProject
        .map((el: any) => JSON.stringify(el))
        .join()}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "designation") {
                            actionsArray.push(t("Designation"));
                        } else if (el.item === "name") {
                            actionsArray.push(t("Désignation"));
                        } else if (el.item === "note") {
                            actionsArray.push(t("Remark"));
                        }
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay
                            text={`${t("Modification")}:${actionsArray.join(
                                ","
                            )}`}
                        />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <CustomColumnDisplay text={t("Creation")} />;
                } else if (e.selectedRow.operation === "Dupplication") {
                    return <CustomColumnDisplay text={t("Duplication")} />;
                } else if (
                    e.selectedRow.operation === "Configuration Template"
                ) {
                    return (
                        <CustomColumnDisplay
                            text={t("Configuration Template")}
                        />
                    );
                } else if (
                    e.selectedRow.operation.includes(
                        "Configuration Template"
                    ) &&
                    e.selectedRow.operation.slice(-4) !== ""
                ) {
                    return (
                        <CustomColumnDisplay text={e.selectedRow.operation} />
                    );
                } else if (e.selectedRow.operation === "Archivage") {
                    return <CustomColumnDisplay text={t("Archiving")} />;
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return <CustomColumnDisplay text={t("Unarchiving")} />;
                } else if (e.selectedRow.operation.search("Dissociation")) {
                    return (
                        <CustomColumnDisplay text={e.selectedRow.operation} />
                    );
                } else if (e.selectedRow.operation.search("Associates")) {
                    return (
                        <CustomColumnDisplay text={e.selectedRow.operation} />
                    );
                } else if (e.selectedRow.operation.search("Publication")) {
                    return <CustomColumnDisplay text={t("Publication")} />;
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay
                            text={e.selectedRow.actions
                                .map(
                                    (el: any) =>
                                        el.item !== "modified_at" && el.oldValue
                                )
                                .join(",")}
                        />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return null;
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return (
                        <CustomColumnDisplay
                            text={` ${t("Status")}: ${t("Archived")}`}
                        />
                    );
                } else if (e.selectedRow.operation === "Archivage") {
                    return (
                        <CustomColumnDisplay
                            text={` ${t("Status")}: ${t("Unarchived")}`}
                        />
                    );
                } else {
                    return <span>{""}</span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay
                            text={e.selectedRow.actions
                                .map((el: any) => el.newValue)
                                .join(",")}
                        />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return (
                        <CustomColumnDisplay
                            text={
                                e.selectedRow[t("Designation")] !== undefined
                                    ? e.selectedRow[t("Designation")]
                                    : "--"
                            }
                        />
                    );
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return (
                        <CustomColumnDisplay
                            text={`${t("Status")}: ${t("Unarchived")}`}
                        />
                    );
                } else if (e.selectedRow.operation === "Archivage") {
                    return (
                        <CustomColumnDisplay
                            text={` ${t("Status")}: ${t("Archived")}`}
                        />
                    );
                } else {
                    return <span>{""}</span>;
                }
            },
        },
    ];

    React.useEffect(() => {
        setArrayIds(selectedProject.map((el) => el.Id));
    }, [selectedProject]);

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        setIsCreatedProject(false);
                        setSelectedRows([]);
                    }}
                    className="dev_icn_HSclose"
                >
                    <StyledH2 className="dev_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames("title__clz", {
                            "not-allowed-icon__clz": selectedProject.length > 1,
                        })}`}
                    >
                        <CustomColumnDisplay
                            text={`${t("Designation")}:${
                                selectedProject.length > 1
                                    ? null
                                    : data?.data !== undefined
                                    ? data?.data![0][t("Designation")]
                                    : ""
                            }`}
                        />
                    </div>

                    <DynamicTable
                        url={url}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyProject"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        variant="primary"
                        className="fed_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsCreatedProject(false);
                            setSelectedRows([]);
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
