import React from "react";
import classnames from "classnames";
import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import storeVatRate, {
    setDataIsUpdatedShops,
    setUrlHistory,
    setVatRateId,
    setVatRateShopId,
    setFnDelete,
    setIsDeleteCheckClick,
    setIsAddVatRateClicked,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";
import { setActivationDeactivationMessageModalOpened, setMessage, setYesFunction } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomIconsComponentType = {
    e: any;
    dataVatRate: any;
    selectedRows: any;
    setVatRate: Function;
    setIsEdited: Function;
    selectedIds: Function;
    setIsAddVatRateOriginHistoryClicked: Function;
    isAddVatRateOriginHistoryClicked: boolean;
    data: any;
    typeDisplayShop: string;
    setTypeDisplayShop: Function;
};
export function CustomIconsComponent({
    e,
    dataVatRate,
    selectedRows,
    setVatRate,
    setIsEdited,
    selectedIds,
    setIsAddVatRateOriginHistoryClicked,
    isAddVatRateOriginHistoryClicked,
    data,
    typeDisplayShop,
    setTypeDisplayShop,
}: CustomIconsComponentType) {
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { t } = useTranslation();

    const { isClickedModalDelete, dataFranchise, modify } = useSnapshot(
        storeVatRate
    );

    const [
        vatRateActivationStatus,
        setVatRateActivationStatus,
    ] = React.useState<boolean>(e.selectedRow.status);
    const uuidUser = localStorage.getItem("uuidUser");
    async function changeStatusAssos(object: any) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateStatusAssocEdit`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };
        try {
            mutate(
                apiUrl,
                await fetchWithToken(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (object.data[0].status === true) {
                            toast.success(
                                `${t(
                                    "The vat rate is successfully activate"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else if (object.data[0].status === false) {
                            toast.success(
                                `${t(
                                    "The vat rate is successfully deactivate"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }

                        setDataIsUpdatedShops!(true);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function archiveVatRate(
        franchise: boolean,
        typeDisplay: string,
        Id: string,
        shopId: string
    ) {
        let api =
            typeDisplay === "notArchived"
                ? `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=true&franchiseId=${franchiseID}&idVatRate=${Id}&shopId=${shopId}&operatorId=${operatorID}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=false&franchiseId=${franchiseID}&idVatRate=${Id}&shopId=${shopId}&operatorId=${operatorID}&uuidUser=${uuidUser}`;
        let message =
            typeDisplay === "notArchived"
                ? t("The vat rate is successfully archived")
                : t("The vat rate is successfully restored");

        try {
            mutate(
                api,
                await fetchWithToken(api, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    method: "DELETE",
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while delete selection!");
                        }

                        setDataIsUpdatedShops!(true);
                        toast.success(message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <React.Fragment>
            {e.selectedRow.subRows === undefined ? (
                <div className="d-flex justify-content-between w-50 ">
                    {modify === true && (
                        <div className="p-1">
                            <Tooltip
                                title={
                                    typeDisplayShop === "archived"
                                        ? ""
                                        : vatRateActivationStatus === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz":
                                                    typeDisplayShop ===
                                                    "archived",
                                            }
                                        )}`}
                                        offHandleColor="#E30613"
                                        checked={vatRateActivationStatus}
                                        onChange={async () => {
                                            setMessage(
                                                vatRateActivationStatus
                                                    ? t(
                                                          "Caution, the VAT rate may be used by other modules.\n Would you like to confirm the deactivation of the selected VAT rate?"
                                                      )
                                                    : t(
                                                          "Would you like to confirm the activation of the selected VAT rate?"
                                                      )
                                            );
                                            setYesFunction(() => {
                                                setVatRateActivationStatus(
                                                    !vatRateActivationStatus
                                                );
                                                let obj = {
                                                    userId: userID,
                                                    operatorId: operatorID,
                                                    uuidUser: uuidUser,
                                                    franchiseId: franchiseID,
                                                    shopId:
                                                        e.selectedRow.shopId,
                                                    data: [
                                                        {
                                                            id:
                                                                e.selectedRow
                                                                    .Id,
                                                            status: !vatRateActivationStatus,
                                                        },
                                                    ],
                                                };
                                                changeStatusAssos(obj);
                                            });

                                            setActivationDeactivationMessageModalOpened(
                                                true
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    {typeDisplayShop === "archived" && modify === true ? (
                        <div className="p-1">
                            <Tooltip title={t("Unarchive")}>
                                <div>
                                    <EyeIcon
                                        onClick={() => {
                                            setVatRateId(e.selectedRow.Id);
                                            setVatRateShopId(
                                                e.selectedRow.shopId
                                            );

                                            if (isClickedModalDelete) {
                                                setTypeDisplayShop("archived");
                                            } else if (
                                                typeDisplayShop ===
                                                    "archived" &&
                                                isClickedModalDelete
                                            ) {
                                                setTypeDisplayShop(
                                                    "notArchived"
                                                );
                                            }

                                            archiveVatRate(
                                                false,
                                                typeDisplayShop,
                                                e.selectedRow.Id,
                                                e.selectedRow.shopId
                                            );
                                        }}
                                        height={25}
                                        width={25}
                                        fill="black"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    // eslint-disable-next-line array-callback-return
                                                    dataFranchise.filter(
                                                        function (x: any) {
                                                            if (
                                                                x.archived &&
                                                                x.Id ===
                                                                    e
                                                                        .selectedRow
                                                                        .Id &&
                                                                x
                                                            ) {
                                                                return x;
                                                            } else {
                                                                return null;
                                                            }
                                                        }
                                                    ).length > 0,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    ) : modify === true ? (
                        <div className="p-1">
                            <Tooltip title={t("Archive")}>
                                <div>
                                    <TrashIcon
                                        onClick={() => {
                                            setVatRateId(e.selectedRow.Id);
                                            setIsAddVatRateClicked(false);
                                            setVatRateShopId(
                                                e.selectedRow.shopId
                                            );

                                            setFnDelete(() => {
                                                archiveVatRate(
                                                    false,
                                                    typeDisplayShop,
                                                    e.selectedRow.Id,
                                                    e.selectedRow.shopId
                                                );
                                            });
                                            setIsDeleteCheckClick(true);
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{
                                            cursor: "pointer ",
                                        }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTarchived ",
                                            {}
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    ) : null}
                    <div className="p-1">
                        <Tooltip title={t("Consult history")}>
                            <div>
                                <TimePastSvgIcon
                                    height={25}
                                    width={25}
                                    fill="black"
                                    onClick={() => {
                                        setUrlHistory(
                                            `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=associate&idVatRates=${e.selectedRow.Id}&shopIds=${e.selectedRow.shopId}`
                                        );

                                        setIsAddVatRateOriginHistoryClicked(
                                            !isAddVatRateOriginHistoryClicked
                                        );
                                        setVatRateId(e.selectedRow.Id);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_BThistory",
                                        {}
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
