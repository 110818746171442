import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../../helpers/general";
import { store } from "../../../../components/VerticalLayout/store";
import { store as storeService, setNumberActive } from "../store";

import { clearMultiCustomSelectionData } from "../../../../components/Common/MultiCustomSelect/store";
import { clearData } from "../../../../components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    designation: string;
    setDataIsUpdated: Function;
    setDesignation: Function;
    setLocalFilterActive: Function;
    setTag: Function;
    setStartTime: Function;
    setEndTime: Function;
    setActiveDays: Function;
    setTagsData: Function;
    setDayOfTheWeek: Function;
    setIsClickedBouttonValider: Function;
    dayOfTheWeek: string;
    startTime: string | null;
    endTime: string | null;
    allData: any;
    tagsData: any;
};

export function ConfirmationAssociationBoutiqueModal({
    isModalOpened,
    setIsModalOpened,
    designation,
    setDataIsUpdated,
    setDesignation,
    setLocalFilterActive,
    setTag,
    setStartTime,
    setEndTime,
    setActiveDays,
    setTagsData,
    setDayOfTheWeek,
    setIsClickedBouttonValider,
    dayOfTheWeek,
    startTime,
    endTime,
    allData,
    tagsData,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { numberActive } = useSnapshot(storeService);
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);

    async function AssociateService() {
        const apiUrlupdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        setIsClickedBouttonValider(false);
        toast.dismiss();
        allData = [...allData?.data];
        let objectData =
            allData[
                allData.findIndex(
                    (el: any) =>
                        el[t("Designation")] === designation &&
                        el[t("Start hour")] === startTime &&
                        el[t("End hour")] === endTime &&
                        el[t("Day of the week")] === dayOfTheWeek
                )
            ];

        let dataService = {
            affectation: true,
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [objectData.Id]: {
                    designation: designation,
                    tag: tagsData.join(","),
                    isArchived: objectData.isArchived,
                    heure_début: objectData[t("Start hour")],
                    heure_fin: objectData[t("End hour")],
                    activeDays: objectData.activeDays,
                    isActive: objectData.isActive,
                    jour_de_la_semaine: objectData[t("Day of the week")],
                },
            },
        };

        try {
            mutate(
                apiUrlupdate,
                await fetchWithToken(apiUrlupdate, {
                    headers: {
                        "Content-Type": "application/json",                        
                    },
                    body: JSON.stringify(dataService),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The assignment was successful")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);
                        setTag("");
                        setStartTime("");
                        setEndTime("");
                        setActiveDays([]);
                        setTagsData([]);
                        setDayOfTheWeek("");
                        setDesignation("");
                        setNumberActive(numberActive + 1);
                        setLocalFilterActive(false);
                        clearData();
                        clearMultiCustomSelectionData();
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "The service already exists in the franchise. do you want to associate it with this shop?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        AssociateService();
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
