import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store as userStore } from "../../components/VerticalLayout/store";
import { setKey } from "./store";
import { setIsLoading } from "../../store/project/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import ErrorToast from "@components/Common/ErrorTost";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationArchiveType = {
    setIsModalDissociateOpened: Function;
    isModalDissociateOpened: boolean;
    selectedRows: any;
    setSelectedRows: any;
};

export function ConfirmationDissociateTemplate({
    setIsModalDissociateOpened,
    isModalDissociateOpened,
    selectedRows,
    setSelectedRows,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, shopID, operatorID } = useSnapshot(userStore);
    const uuidUser = localStorage.getItem("uuidUser");

    function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        let isPublished = false;
        setIsLoading(true);

        selectedRows.forEach((row: any) => {
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                shopId: shopID,
                projectId: "PROJECT",
                project_id: row.projectId,
                operatorId: operatorID,
                uuidUser: uuidUser,
            };
            if (
                row.Status !== "Publié" &&
                (row.projectId ?? row.id_project) !== undefined &&
                row.subRows !== undefined
            )
                localData.push(
                    fetchWithToken(apiUrl, {
                        method: "delete",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            else if (row.Status === "Publié" && row.projectId !== undefined)
                isPublished = true;
        });
        try {
            mutate(
                apiUrl,
                Promise.all(localData).then(async (result: any) => {
                    await dissociationShops();
                    if (localData.length !== 0) {
                        toast.success(
                            `${t(
                                "The Template has been successfully deleted."
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                    }
                    if (isPublished === true) {
                        setIsLoading(false);
                        return ErrorToast(
                            `${t(
                                "Attention, impossible to delete a published Template"
                            )!}`
                        );
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
    }

    async function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        let localData: any = [];
        for (let row of selectedRows) {
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: row.projectId,
                shopsKey: shopID,
                operatorId: operatorID,
                uuidUser: uuidUser,
            };
            if (row.Status !== "Publié" && row.projectId !== undefined)
                localData.push(
                    await fetchWithToken(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
        }
        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    setKey(Math.floor(Math.random() * 10000));
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    return (
        <StyledModal toggle={true} isOpen={isModalDissociateOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalDissociateOpened(!isModalDissociateOpened);
                    setSelectedRows([]);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Do you want to confirm the deletion of the selected Template?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalDissociateOpened(!isModalDissociateOpened);
                        setSelectedRows([]);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        handleSaveButtonOnClickEvent();
                        setIsModalDissociateOpened(!isModalDissociateOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
