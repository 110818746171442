import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function ActivateStateScreenPreview({
    activeScreen,
}: {
    activeScreen: StandbyScreenItemType;
}): JSX.Element {
    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                width: "470px",
                height: "700px",
            }}
        >
            {activeScreen.type.includes("video") ? (
                <video
                    autoPlay
                    muted
                    loop
                    className="h-100 w-100"
                    style={{
                        objectFit: "contain",
                    }}
                    src={activeScreen.content}
                ></video>
            ) : (
                <img
                    alt="img"
                    className="h-100 w-100 mr-2"
                    style={{
                        objectFit: "contain",
                    }}
                    src={activeScreen.content}
                />
            )}
        </div>
    );
}
