import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as saleStore,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIdsLocalShop,
    setShopsAggregatorData,
    setRowsShop,
    setAssociatedAggregator,
    setGlobalDataIsUpdated,
    setSelectedAggregator,
} from "../store";

import { ActionColumn } from "./ActionColumn";
import { ConfirmationActiveAggregator } from "../ConfirmationActiveAggregator";
import { AggregatorAssociateModal } from "./AggregatorAssociateModal";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import InformationAggregator from "../InformationAggregator";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import BadgeNumber from "@components/Common/BadgeNumber";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { useKeycloak } from "@react-keycloak/web";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function ShopsAggregator({
    isAggregatorArchived,
    setIsAggregatorArchived,
    setLimit,
}: any) {
    const { keycloak } = useKeycloak();
    
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        globalDataIsUpdated,
        isConsultModalOpened,
        modify,
    } = useSnapshot(saleStore);

    const { t } = useTranslation();
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [dataTable, setDataTable] = React.useState<any>({});

    const [i18nextLng] = React.useState<string>(
        localStorage.getItem("i18nextLng") || "fr"
    );
    const uuidUser = localStorage.getItem("uuidUser");

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Aggregator"),
            customJsx: (e: any) =>
                e.selectedRow[t("Shop")] !== undefined ? (
                    <BadgeNumber value={e.selectedRow?.subRows?.length} />
                ) : (
                    e.selectedRow[t("Aggregator")]
                ),
            disableFilter: false,
        },
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isAggregatorArchived={isAggregatorArchived}
                    selectedRows={selectedRows}
                    type={e.selectedRow[t("Aggregator")]}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedAggregator: any[] = [];
        Object.values(selectedRows).forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);
            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
                associatedAggregator.push(el.subRows[i]);
            }
        });
        setAssociatedAggregator(associatedAggregator);
        setRowsShop(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsAggregatorData(dataTable);
    }, [selectedRows, dataTable, t]);
    React.useEffect(() => {
        setGlobalDataIsUpdated(true);
    }, [i18nextLng]);
    React.useEffect(() => {
        let localArchived: number = 0;
        let localActive: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element?.Boutique !== undefined) {
                    localActive += element?.AggregatorNumber.activated;

                    localArchived += element?.AggregatorNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el.Boutique).length > 0) {
                setNumberActiveMultiShop(localActive);

                setNumberArchiveMultiShop(localArchived);
            } else {
                setNumberActiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.activated
                        : 0
                );

                setNumberArchiveMultiShop(
                    dataTable.othersData !== undefined
                        ? dataTable.othersData.archived
                        : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.activated
                    : 0
            );

            setNumberArchiveMultiShop(
                dataTable.othersData !== undefined
                    ? dataTable.othersData.archived
                    : 0
            );
        }
    }, [dataTable.othersData, selectedRows, dataTable]);

    React.useEffect(() => {
        let array: any = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });

        setSelectedAggregator(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);
    const ActionColumnList = (e: any) => {
        return "";
    };
    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isAggregatorArchived ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    setData={setDataTable}
                    canExpand
                    canSelect
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    <div className=" d-flex justify-content-start align-items-center">
                                        <CustomMainColorButton
                                            className={`${classnames(
                                                "w-100 ml-3 fed_btn_BTassignLoyalty ",
                                                {
                                                    "not-allowed-icon__clz": isAggregatorArchived,
                                                }
                                            )}`}
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isShopSelected ? false : true
                                            }
                                            onClick={() => {
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Affect Aggregator")}
                                        </CustomMainColorButton>
                                        <div></div>
                                    </div>
                                </>
                            ) : null}
                            <ActionColumn
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                isAggregatorArchived={isAggregatorArchived}
                                setIsAggregatorArchived={
                                    setIsAggregatorArchived
                                }
                                setLimit={setLimit}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    canMovedCheckboxLeftOnExpand
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={ActionColumnList}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Aggregator"
                />
            </div>

            <ConfirmationActiveAggregator
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    setDataUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <AggregatorAssociateModal
                    setData={setGlobalDataIsUpdated}
                    selectedShopsRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}

            {isConsultModalOpened ? (
                <InformationAggregator
                    selectedShopsRows={selectedRows}
                    setGlobalDataIsUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
