import React from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import moment from "moment";

import { store } from "@components/VerticalLayout/store";
import {
    setNumberMultiShopActive,
    setNumberMultiShopArchived,
    store as generalSettingStore,
    setLocalShop,
    setShopsExceptionalHoursData,
    setIdsLocalShop,
    setShopRowData,
    setAssociatedExceptionalHours,
    setGlobalDataIsUpdated,
    setIsAssociatedExceptionalHours,
    setModify,
} from "../store";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { CustomSideFilter } from "./CustomSideFilter";
import { NextException } from "../NextException";
import { CustomIcons } from "./CustomIcons";
import { ExceptionalHoursInfoModal } from "../ExceptionalHoursIfoModal";
import { ExceptionalHoursAssociateModal } from "./ExceptionelHoursAssociateModal";
import { ConfirmationAssociatedMessage } from "../ConfirmationAssociateMessage";
import { History } from "../History";
import { ConfirmationArchiveExceptionalHours } from "../ConfirmationArchiveExceptionalHours";
import BadgeNumber from "@components/Common/BadgeNumber";

import { useKeycloak } from "@react-keycloak/web";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ShopExceptionalHoursType = {
    setIsArchivedExceptionalHoursClicked: Function;
    isArchivedExceptionalHoursClicked: boolean;
};

export function ShopExceptionalHours({
    setIsArchivedExceptionalHoursClicked,
    isArchivedExceptionalHoursClicked,
}: ShopExceptionalHoursType) {
    const { t } = useTranslation();
    const location = useLocation();
    const { keycloak } = useKeycloak();
    
    setModify(getPathAuthorization(location.pathname).Modifie);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        globalDataIsUpdated,
        isAssociatedExceptionalHours,
        isValidateShopArchiveOpened,
    } = useSnapshot(generalSettingStore);
    const uuidUser = localStorage.getItem("uuidUser");
    const urlArchive = `${process.env.REACT_APP_API_V2_URL}/settings/general/archived_exceptionalHours?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;
    const urlActive = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`;

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [isInfoModalOpened, setIsInfoModalOpened] = React.useState<boolean>(
        false
    );
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [infoHours, setInfoHours] = React.useState<string>("");
    const [rowData, setRowData] = React.useState<any>([]);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Designation"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("Shop")] !== "" &&
                    e.selectedRow[t("Shop")] !== undefined ? (
                        <BadgeNumber value={e.selectedRow.subRows?.length} />
                    ) : (
                        t(e.selectedRow[t("Designation")])
                    )}
                </div>
            ),
        },
        {
            indexOFColumn: 3,
            columnName: t("State"),
            customJsx: (e: any) => <div>{t(e.selectedRow.state)}</div>,
        },
        {
            indexOFColumn: 4,
            columnName: t("Start date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined &&
                e.selectedRow[t("Shop")] === "" ? (
                    <div>
                        {moment(e.selectedRow.startDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 5,
            columnName: t("End date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined &&
                e.selectedRow[t("Shop")] === "" ? (
                    <div>
                        {moment(e.selectedRow.endDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 900,
            columnName: t("Next exception"),
            customJsx: (e: any) => (
                <NextException
                    e={e}
                    setRowData={setRowData}
                    setIsModalOpened={setIsInfoModalOpened}
                    setHours={setInfoHours}
                />
            ),
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setRowData={setRowData}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    dataIsUpdated={globalDataIsUpdated}
                    isArchivedExceptionalHoursClicked={
                        isArchivedExceptionalHoursClicked
                    }
                />
            ),
        },
    ];

    const ActiveArchiveCount = () => {
        let sumActive = 0;
        let sumArchive = 0;
        if (dataTable.data !== undefined) {
            selectedRows.length === 0 ||
            selectedRows.every(
                (el: any) => el[t("Shop")] === "" || el.subRows === undefined
            )
                ? dataTable.data.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  })
                : selectedRows.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  });
        }
        setNumberMultiShopActive(sumActive);
        setNumberMultiShopArchived(sumArchive);
    };

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedLanguage: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined) {
                localShop.push(el.shopId);
            }
            if (el.subRows !== undefined) {
                el.subRows.forEach((element: any) => {
                    idsLocalShop.push(element.Id);
                    associatedLanguage.push(element);
                });
            }
        });
        setAssociatedExceptionalHours(associatedLanguage);
        setShopRowData(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsExceptionalHoursData(dataTable.data);
        ActiveArchiveCount();
        // eslint-disable-next-line
    }, [selectedRows, dataTable.data]);

    return (
        <React.Fragment>
            <div>
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    canMovedCheckboxLeftOnExpand
                    url={
                        isArchivedExceptionalHoursClicked
                            ? urlArchive
                            : urlActive
                    }
                    setData={setDataTable}
                    canSelect
                    canExpand={true}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            selectedRows={selectedRows}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsArchivedExceptionalHoursClicked={
                                setIsArchivedExceptionalHoursClicked
                            }
                            isArchivedExceptionalHoursClicked={
                                isArchivedExceptionalHoursClicked
                            }
                            isShopSelected={isShopSelected}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="shopExceptionalHours"
                />
            </div>
            {isInfoModalOpened === true ? (
                <ExceptionalHoursInfoModal
                    isModalOpened={isInfoModalOpened}
                    setIsModalOpened={setIsInfoModalOpened}
                    rowData={rowData}
                    infoHours={infoHours}
                />
            ) : null}
            {isHistoryModalOpened === true ? (
                <History
                    ids={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    rowData={rowData}
                    setDataShopIsUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? <ExceptionalHoursAssociateModal /> : null}
            {isAssociatedExceptionalHours && !oneShop ? (
                <ConfirmationAssociatedMessage
                    isModalOpened={isAssociatedExceptionalHours}
                    setIsModalOpened={setIsAssociatedExceptionalHours}
                />
            ) : null}
            {isValidateShopArchiveOpened ? (
                <ConfirmationArchiveExceptionalHours
                    selectedRows={selectedRows}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    dataIsUpdated={globalDataIsUpdated}
                />
            ) : null}
        </React.Fragment>
    );
}
