import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    store as languageStore,
    setNumberActive,
    setNumberArchived,
    setGlobalDataIsUpdated,
    setKey,
    setIsLoading,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationDeleteMessageType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    isOneShop: boolean;
    dataIsUpdated: boolean | number;
};

export function ConfirmationActiveLanguage({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    selectedRows,
    dataIsUpdated,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { numberActive, numberArchived, shopsLanguagesData } = useSnapshot(
        languageStore
    );

    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedLanguages(e: any) {
        let idsAssociated = [0];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeLanguage(isValidation: boolean) {
        setIsLoading(true);
        toast.dismiss();
        let localData: any[] = [];
        selectedRows.forEach((element: any) => {
            const id = element.Id;
            let shopsArchived = ArchivedLanguages(element);
            let dataLanguage = {
                userId: userID,
                shopId: isValidation ? shopsArchived : [0],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [id]: {
                        id: id,
                        language: languages.find(
                            (lg: any) =>
                                t(lg.label) === t(element[t("Language")])
                        )?.label,
                        display_name: element.display_name,
                        country: element.country,
                        urlImage: element.url_image,
                        isArchived: false,
                        isFavorite: element.isFavorite,
                        isFavoriteShop: element.isFavorite,
                        isActive:
                            element.shopId === "0" ? true : element.isActive,
                        shopId: element.shopId,
                        isActiveShop:
                            element.shopId === "0" ? true : element.isActive,
                        abbreviation: element.abbreviation,
                        advancedDisplayName: element.advancedDisplayName,
                    },
                },
            };
            const data = new FormData();
            data.append("dataLanguage", JSON.stringify(dataLanguage));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
            localData.push(
               fetchWithToken(apiUrlAdd, {
                    method: "POST",
                    body: data,
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                await Promise.all(localData).then((result) => {
                    result.forEach((res: any) => {
                        if (res.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
        if (error === false) {
            toast.success(`${t("The language was successfully unarchived")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated(!dataIsUpdated);
            setGlobalDataIsUpdated(true);
            setKey(uuid());
            setIsLoading(false);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(!dataIsUpdated);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the language is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeLanguage(false);
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeLanguage(true);
                        setIsModalOpened(!isModalOpened);
                        setNumberActive(
                            selectedRows.length !== 0
                                ? numberActive + selectedRows.length
                                : numberActive + 1
                        );
                        setNumberArchived(
                            selectedRows.length !== 0
                                ? numberArchived - selectedRows.length
                                : numberArchived - 1
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
