import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    setIsAssociateServiceOpened,
    store,
    setIsHistoryShopsModalOpened,
    setIsValidateClicked,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function CustomSideFilter({
    isShopSelected,
    setService,
    selectedRows,
    setDataIsUpdated,
    setLocalFilterActive,
    setIsServicesArchived,
    isServicesArchived,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, operatorID } = useSnapshot(useStore);
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        servicesData,
        modify,
    } = useSnapshot(store);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    const [error, setError] = React.useState<boolean>(false);

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === undefined) {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    const getIsArrayOfRowsShop = () => {
        let isArrayShop: boolean = false;
        selectedRows.forEach(async (el: any) => {
            if (Object.values(el).includes(el.Id)) {
                isArrayShop = true;
            }
        });
        return isArrayShop;
    };
    let isArrayShop = getIsArrayOfRowsShop();
    let arrayOfRows = getArrayOfRows();
    function ArchivedService() {
        let idsAssociated: any[] = [];
        if (servicesData !== undefined) {
            servicesData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedService();
    async function activeService() {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === undefined) {
                const id = el.Id;
                let dataService = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    data: {
                        [id]: {
                            designation: el[t("Designation")],
                            heure_début: el[t("Start hour")],
                            heure_fin: el[t("End hour")],
                            jour_de_la_semaine: el[t("Day of the week")],
                            activeDays: el.activeDays,
                            shopId: el.shopId,

                            tag: el.tag,
                            isActive: false,
                            isArchived: false,
                        },
                    },
                };
                localData.push(
                   fetchWithToken(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataService),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(apiUrl, await Promise.all(localData).then((result) => {}));
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(`${t("The service was successfully unarchived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
            setIsValidateClicked(true);
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className={
                            isServicesArchived
                                ? "w-100 ml-2 not-allowed-icon__clz srv_btn_BTassignService"
                                : "w-100 ml-2 srv_btn_BTassignService"
                        }
                        rounded
                        disabled={isShopSelected ? false : true}
                        variant="primary"
                        onClick={() => {
                            setService([]);
                            setIsAssociateServiceOpened(true);
                            setLocalFilterActive(false);
                        }}
                    >
                        {t("Assign service")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistActivated"
                    )}
                    onClick={() => {
                        setIsServicesArchived(false);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Enabled services")} ({numberActiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistArchived"
                    )}
                    onClick={() => {
                        setIsServicesArchived(true);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Archived services")} ({numberArchiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_BThistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 ||
                                arrayOfRows.length === 0 ||
                                !isArrayShop,
                        }
                    )}`}
                    onClick={() => {
                        if (selectedRows.length === 1) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryShopsModalOpened(
                                !isHistoryShopsModalOpened
                            );
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_BTunarchive cmn_btn_BTarchived",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0 ||
                                        (arrayOfRows.some((element: any) =>
                                            dataArchive.includes(element.Id)
                                        ) &&
                                            isServicesArchived) ||
                                        !isArrayShop ||
                                        arrayOfRows.length === 1,
                                }
                            )}`}
                            onClick={() => {
                                if (arrayOfRows.length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else if (isServicesArchived) {
                                    activeService();
                                    setDataIsUpdated!(true);
                                } else {
                                    setIsArchiveModalOpened(
                                        !isArchiveModalOpened
                                    );
                                }
                            }}
                        >
                            {isServicesArchived
                                ? `${t("Dearchive")}`
                                : `${t("Archive")}`}
                        </div>
                    </>
                ) : null}
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </div>
    );
}
