import React, { useEffect } from "react";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import classNames from "classnames";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import { store, setOptions, setOptionComposite } from "../store";

import "./index.css";

export default function Options({ selectedRows }: any) {
    const { t } = useTranslation();
    const [activateButton, setActiveButton] = React.useState<boolean>(
        selectedRows.length > 0
            ? selectedRows[0][t("Customer login")] !== undefined
                ? selectedRows[0][t("Customer login")]
                : false
            : false
    );
    const { optionComposite } = useSnapshot(store);
    const [mandatoryEmail, setMandatoryEmail] = React.useState<boolean>(
        selectedRows.length > 0
            ? selectedRows[0]["mandatory Email"] !== undefined
                ? selectedRows[0]["mandatory Email"]
                : false
            : false
    );

    const [validatingOrder, setValidatingOrder] = React.useState<boolean>(
        selectedRows.length > 0
            ? selectedRows[0]["Contact shop"] !== undefined
                ? selectedRows[0]["Contact shop"]
                : false
            : false
    );
    const [compositeProducts, setCompositeProducts] = React.useState<string>(
        Object.values(selectedRows).length > 0
            ? selectedRows[0]["composite Products"] !== undefined
                ? selectedRows[0]["composite Products"].REGLEGRATUITE === "1"
                    ? `${t("The Next In The List")}`
                    : selectedRows[0]["composite Products"].REGLEGRATUITE ===
                      "2"
                    ? `${t("The cheapest")}`
                    : selectedRows[0]["composite Products"].REGLEGRATUITE ===
                      "3"
                    ? `${t("The most expensive")}`
                    : `${t("The Next In The List")}`
                : `${t("The Next In The List")}`
            : `${t("The Next In The List")}`
    );
    let products =
        compositeProducts === `${t("The Next In The List")}`
            ? "1"
            : compositeProducts === `${t("The cheapest")}`
            ? "2"
            : compositeProducts === `${t("The most expensive")}`
            ? "3"
            : "";

    useEffect(() => {
        let composite: any = {
            REGLEGRATUITE: products,
            CMD_DIFFERE: optionComposite.CMD_DIFFERE,
        };
        composite = { ...composite };

        setOptionComposite(composite);

        composite.REGLEGRATUITE = products;
        const dataOptions = {
            activateButton,
            mandatoryEmail,
            validatingOrder,
        };

        setOptions(dataOptions);
        // eslint-disable-next-line
    }, [
        activateButton,
        mandatoryEmail,
        validatingOrder,
        compositeProducts,
        selectedRows,
        t,
        products,
    ]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 mt-2"
            >
                {t("Options")}
            </StyledH2>
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-2"
            >
                {t("Customer login")}
            </StyledH2>
            <div className="ml-5 d-flex justify-content-between p-2">
                <span
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t("Activate the ''Quick connect'' button")}
                </span>

                <ReactSwitch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={activateButton}
                    onChange={(e: any) => {
                        setActiveButton(!activateButton);
                    }}
                />
            </div>
            <span className="not-allowed-icon__clz ml-5 px-1 pt-2 p-2">
                {t("Allow order taking without customer authentication")}
            </span>
            <div className="d-flex justify-content-between p-2 ml-5">
                <span
                    className={classNames("", {
                        "not-allowed-icon__clz": activateButton === false,
                    })}
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t(
                        "Mandatory email for the case of a ''Quick connection''"
                    )}
                </span>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    disabled={!activateButton}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={mandatoryEmail}
                    onChange={(e: any) => {
                        setMandatoryEmail(!mandatoryEmail);
                    }}
                />
            </div>
            <div className="border-footer__clz m-1 px-2 option__clz" />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-3"
            >
                {t("Contact shop")}
            </StyledH2>
            <div className="d-flex justify-content-between p-2 ml-5">
                <span
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t(
                        "Activate the ''Call the shop'' icon when validating the order"
                    )}
                </span>

                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={validatingOrder}
                    onChange={(e: any) => {
                        setValidatingOrder(!validatingOrder);
                    }}
                />
            </div>
            <span className=" not-allowed-icon__clz ml-5 px-1 pt-2 pb-2 ">
                {t("This option is available for the app onssly")}
            </span>
            <div className="border-footer__clz px-2 option-free__clz " />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-3"
            >
                {t("Free rules for composite products")}
            </StyledH2>

            <AvForm model={{ form: compositeProducts, checkItOut: true }}>
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="p-2 ml-5">
                        <AvRadio
                            id="oneRadio"
                            label={`${t("The Next In The List")}`}
                            value={t("The Next In The List")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(
                                    `${t("The Next In The List")}`
                                );
                            }}
                        />
                    </div>

                    <div className="p-2 ml-5">
                        <AvRadio
                            id="twoRadio"
                            label={`${t("The cheapest")}`}
                            value={t("The cheapest")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(`${t("The cheapest")}`);
                            }}
                        />
                    </div>
                    <div className="p-2 ml-5">
                        <AvRadio
                            id="threeRadio"
                            label={`${t("The most expensive")}`}
                            value={t("The most expensive")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(
                                    `${t("The most expensive")}`
                                );
                            }}
                        />
                    </div>
                </AvRadioGroup>
            </AvForm>
        </React.Fragment>
    );
}
