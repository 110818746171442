import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "../../../../../components/VerticalLayout/store";
import {
    setIsAddExceptionalHoursClicked,
    setIsPlusIconClicked,
    store as generalSettingStore,
    setIsValidateArchiveOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomSideFilterType = {
    setRowData: Function;
    setShopId: Function;
    selectedRows: any[];
    rowData: any[];
    isEdited: boolean;
    setIsInInitializationModalOpened: Function;
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setLocalFilterActive: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setIsArchivedExceptionalHoursClicked: Function;
    isArchivedExceptionalHoursClicked: boolean;
};

export function CustomSideFilter({
    setRowData,
    setShopId,
    selectedRows,
    rowData,
    isEdited,
    setIsInInitializationModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setLocalFilterActive,
    setIsModalOpened,
    isModalOpened,
    setIsArchivedExceptionalHoursClicked,
    isArchivedExceptionalHoursClicked,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        store
    );
    const {
        isAddExceptionalHoursClicked,
        numberArchived,
        numberActive,
        shopsExceptionalHoursData,
        archivedExceptionalHoursFranchise,
        isActionsClicked,
        modify,
    } = useSnapshot(generalSettingStore);
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = !oneShop
            ? ArchivedExceptionalHours()
            : ArchivedExceptionalHoursFranchise();
    } else {
        dataArchive = ArchivedExceptionalHoursFranchise();
    }

    function ArchivedExceptionalHoursFranchise() {
        let idsAssociated: any[] = [];
        if (archivedExceptionalHoursFranchise !== undefined) {
            archivedExceptionalHoursFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedExceptionalHours() {
        let idsAssociated: any[] = [];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function AssociateExceptionalHours() {
        let idsAssociated: any[] = [];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isExceptionalHoursExist() {
        let dataAssociate = !oneShop ? AssociateExceptionalHours() : [];
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist)
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        else {
            setIsValidateArchiveOpened(true);
        }
    }

    async function archiveActiveAllExceptionalHours(isArchive: boolean) {
        toast.dismiss();
        let localData: any = {};
        selectedRows.forEach(async (el: any) => {
            let newHours: any[] = [];
            if (el.subRows !== undefined) {
                el.subRows.forEach((element: any) => {
                    newHours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                    });
                });
                Object.assign(localData, {
                    [el.Id]: {
                        id: el.Id,
                        designation: el[t("Designation")],
                        state: el.state === true ? "Opened" : "Closed",
                        startDate: el.startDate,
                        endDate: el.endDate,
                        hours: newHours,
                        isArchived: isArchive,
                        shopId: el.shopId,
                        recurrence: el[t("Recurrence")],
                        endDate_recurrence: el.endDate_recurrence,
                        recurrenceDays: el.recurrenceDays,
                    },
                });
            }
        });
        let dataExceptionalHours = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: localData,
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataExceptionalHours),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                isArchive === true
                                    ? "The exceptional schedule was successfully archived"
                                    : "The exceptional schedule was successfully unarchive"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {modify === true ? (
                <>
                    {isAddExceptionalHoursClicked !== true ? (
                        <span
                            onClick={() => {
                                setRowData([]);
                                setIsAddExceptionalHoursClicked(
                                    !isAddExceptionalHoursClicked
                                );
                                setShopId(oneShop ? shopID : 0);
                                setLocalFilterActive(false);
                            }}
                            className={`${classnames("cmn_btn_openForm w-100", {
                                "not-allowed-icon__clz": isArchivedExceptionalHoursClicked,
                            })}`}
                        >
                            <CustomMainColorButtonWithIcon
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant="primary"
                                style={{ marginLeft: "5px !important" }}
                            >
                                {t("Add an exceptional schedule")}
                            </CustomMainColorButtonWithIcon>
                        </span>
                    ) : (
                        <CustomMainColorIconButton
                            icon="PlusIcon"
                            className="hex_btn_FRreset "
                            rounded
                            disabled={rowData.length === 0 ? false : true}
                            onClick={() => {
                                if (isEdited === true)
                                    setIsInInitializationModalOpened(true);
                                else {
                                    setIsPlusIconClicked(true);
                                }
                            }}
                        >
                            <PlusIcon height={20} width={20} fill="white" />
                        </CustomMainColorIconButton>
                    )}
                </>
            ) : null}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_FRlistActivated pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedExceptionalHoursClicked(false);
                    }}
                >
                    {t("Enabled exceptional(s) schedule(s)")}({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_FRlistArchived pl-1 "
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsAddExceptionalHoursClicked(false);
                                setIsArchivedExceptionalHoursClicked(true);
                            });
                        } else {
                            setIsAddExceptionalHoursClicked(false);
                            setIsArchivedExceptionalHoursClicked(true);
                        }
                    }}
                >
                    {t("Archived exceptional(s) schedule(s)")}({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item cmn_btn_FRhistory pl-1",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 ||
                                selectedRows.every(
                                    (element: any) =>
                                        element[t("Designation")] === undefined
                                ),
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                let hoursOfSelectedRow = 0;
                                selectedRows.forEach((element: any) => {
                                    if (
                                        element[t("Designation")] !== undefined
                                    ) {
                                        hoursOfSelectedRow += 1;
                                    }
                                });
                                if (hoursOfSelectedRow === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                            });
                        } else {
                            let hoursOfSelectedRow = 0;
                            selectedRows.forEach((element: any) => {
                                if (element[t("Designation")] !== undefined) {
                                    hoursOfSelectedRow += 1;
                                }
                            });
                            if (hoursOfSelectedRow === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item cmn_btn_FRarchived cmn_btn_FRunarchive pl-1",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        selectedRows.every(
                                            (element: any) =>
                                                element[t("Designation")] ===
                                                undefined
                                        ) ||
                                        (isArchivedExceptionalHoursClicked &&
                                            oneShop &&
                                            selectedRows.some((element: any) =>
                                                dataArchive.includes(element.Id)
                                            )),
                                }
                            )}`}
                            onClick={(e: any) => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        let hoursOfSelectedRow = 0;
                                        selectedRows.forEach((element: any) => {
                                            if (
                                                element[t("Designation")] !==
                                                undefined
                                            ) {
                                                hoursOfSelectedRow += 1;
                                            }
                                        });
                                        if (hoursOfSelectedRow === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else
                                            isArchivedExceptionalHoursClicked
                                                ? selectedRows.some(
                                                      (element: any) =>
                                                          dataArchive.includes(
                                                              element.Id
                                                          )
                                                  ) && !oneShop
                                                    ? setIsModalOpened(
                                                          !isModalOpened
                                                      )
                                                    : archiveActiveAllExceptionalHours(
                                                          false
                                                      )
                                                : isExceptionalHoursExist();
                                    });
                                } else {
                                    let hoursOfSelectedRow = 0;
                                    selectedRows.forEach((element: any) => {
                                        if (
                                            element[t("Designation")] !==
                                            undefined
                                        ) {
                                            hoursOfSelectedRow += 1;
                                        }
                                    });
                                    if (hoursOfSelectedRow === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else
                                        isArchivedExceptionalHoursClicked
                                            ? selectedRows.some(
                                                  (element: any) =>
                                                      dataArchive.includes(
                                                          element.Id
                                                      )
                                              ) && !oneShop
                                                ? setIsModalOpened(
                                                      !isModalOpened
                                                  )
                                                : archiveActiveAllExceptionalHours(
                                                      false
                                                  )
                                            : isExceptionalHoursExist();
                                }
                            }}
                        >
                            {isArchivedExceptionalHoursClicked
                                ? t("Unarchive")
                                : t("Archive")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
