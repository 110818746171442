import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import {
    setIsHistoryShopsModalOpened,
    store,
    setIsLoading,
    setKey,
} from "../store";
import { store as dataStore } from "@components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomSideFilterType = {
    setLimit: Function;
    isShopSelected: boolean;
    selectedRows: any;
    isAssociateModalOpened: boolean;
    setIsAssociateModalOpened: Function;
    setLocalFilterActive: Function;
    isArchivedPaymentMethodsClicked: boolean;
    setIsArchivedPaymentMethodsClicked: Function;
};

export function CustomSideFilter({
    isShopSelected,
    selectedRows,
    isAssociateModalOpened,
    setIsAssociateModalOpened,
    setLocalFilterActive,
    isArchivedPaymentMethodsClicked,
    setIsArchivedPaymentMethodsClicked,
    setLimit,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        shopsNumberActive,
        shopsNumberArchived,
        isHistoryShopsModalOpened,
        listFranchiseArchived,
        modify,
        isLoading,
    } = useSnapshot(store);
    const { userID, franchiseID, operatorID } = useSnapshot(dataStore);

    const [error, setError] = React.useState(false);
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] !== undefined) {
                el.subRows.forEach((elt: any) => arrayOfRows.push(elt));
            } else {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    function ArchivedPaymentMethodFranchise() {
        let idsAssociated: any[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function handleArchivePaymentMethod() {
        setLimit(2);
        setIsLoading(true);
        let localData: any = [];
        let isSomeConsumed = false;
        let arrayOfRows = getArrayOfRows();
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        for (let el of arrayOfRows) {
            let isConsumed: any = false;
            if (isActive) {
                isConsumed = await isInformationModeConsumed(el.idShop, el.id);
            }
            if (isConsumed === true) {
                isSomeConsumed = true;
            } else {
                let objectData = {
                    userId: userID,
                    shopId: [el.idShop],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: {
                        [el.id]: {
                            designation: el[t("Payment method")],
                            currency: el.Devise,
                            display: el[t("Display name")],
                            saleSupport: el[t("Support de vente")],
                            shopId: el.idShop,
                            country: el.Pays,
                            tag: el.Tag,
                            isActive: false,
                            isArchived: isActive,
                            languages: el.languages,
                            advancedDisplayName: el.advancedDisplayName,
                        },
                    },
                };
                localData.push(
                   fetchWithToken(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(objectData),
                    }).then((response) => response.json())
                );
            }
        }
        try {
            mutate(apiUrl, await Promise.all(localData).then((result) => {}));
        } catch (e) {
            setError(true);
            setIsLoading(false);
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        console.log({ localData });
        if (error === false && localData.length !== 0) {
            setIsValidateClicked(false);
            toast.success(
                !isActive
                    ? `${t("The Payment method is successfully unarchived")!}`
                    : `${t("The Payment method is successfully archived")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setIsLoading(false);
            setKey(uuid());
        }
        if (isSomeConsumed) {
            ErrorToast(
                t(
                    "Please note, you cannot archive an element used in a template"
                )
            );
            setIsLoading(false);
        }
    }

    async function isInformationModeConsumed(shopId: string, id: string) {
        toast.dismiss();
        let isInformationModeUsed = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${shopId}&franchiseId=${franchiseID}&type=meansOfPayment`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.some((el: any) => el.id === id)) {
                        isInformationModeUsed = true;
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
        return isInformationModeUsed;
    }

    async function isSaleSupportArchived() {
        toast.dismiss();
        let isSaleSupportArchived = false;
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            })
                .then((response) => response.json())
                .then((data) => {
                    data.data.forEach((elt: any) => {
                        selectedRows.forEach((element: any) => {
                            element[t("Sales support")]
                                .split(",")
                                .forEach((support: any) => {
                                    if (
                                        elt.subRows.some(
                                            (sale: any) =>
                                                `${sale[t("Sale support")]} ${
                                                    sale.shopId
                                                }` ===
                                                `${support} ${element.idShop}`
                                        )
                                    ) {
                                        isSaleSupportArchived = true;
                                    }
                                });
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        );
        return isSaleSupportArchived;
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod();
            setIsArchivedValidationModalOpened(false);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, isActive]);

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className="w-100 ml-2 mdr_btn_BTassignPaymentMethod"
                        disabled={
                            isShopSelected && !isArchivedPaymentMethodsClicked
                                ? false
                                : true
                        }
                        rounded
                        variant="primary"
                        onClick={() => {
                            setLocalFilterActive(false);
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                        }}
                    >
                        {t("Assign payment method")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistActivated"
                    )}
                    onClick={() => {
                        setIsArchivedPaymentMethodsClicked(false);
                    }}
                >
                    {t("Enabled payment methods")}({shopsNumberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistArchived"
                    )}
                    onClick={() => {
                        setIsArchivedPaymentMethodsClicked(true);
                    }}
                >
                    {t("Archived payment methods")}({shopsNumberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_BThistory",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        setIsHistoryShopsModalOpened(
                            !isHistoryShopsModalOpened
                        );
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames("dropdown-item pl-1", {
                                "not-allowed-icon__clz":
                                    isLoading ||
                                    selectedRows.length === 0 ||
                                    (isArchivedPaymentMethodsClicked &&
                                        getArrayOfRows().some((element: any) =>
                                            ArchivedPaymentMethodFranchise().includes(
                                                element.id
                                            )
                                        )),
                            })}`}
                            onClick={async () => {
                                setIsLoading(true);
                                let isArchived = await isSaleSupportArchived();
                                if (
                                    selectedRows.length === 1 &&
                                    (selectedRows[0].subRows === undefined ||
                                        selectedRows[0].subRows.length < 2)
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                    setIsLoading(false);
                                } else if (
                                    isArchived === true &&
                                    isArchivedPaymentMethodsClicked
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Please note that  the unarchiving process could not be successfully completed. Please check the settings of the store(s) data."
                                        )!}`
                                    );
                                    setIsLoading(false);
                                } else if (!isArchivedPaymentMethodsClicked) {
                                    setIsArchivedValidationModalOpened(true);
                                    setIsActive(true);
                                    setIsLoading(false);
                                } else {
                                    handleArchivePaymentMethod();
                                    setIsActive(false);
                                }
                            }}
                        >
                            <span
                                className={classnames(
                                    "cmn_btn_BTarchived cmn_btn_BTunarchive",
                                    {
                                        "not-allowed-icon__clz": isLoading,
                                    }
                                )}
                            >
                                {isArchivedPaymentMethodsClicked
                                    ? t("Unarchive")
                                    : t("Archive")}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </div>
    );
}
