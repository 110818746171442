import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { setMultiShopSwitch, store } from "@components/VerticalLayout/store";

import { CustomIconsComponent } from "./CustomIcons";
import PageTitle from "@components/Common/PageTitle";
import NotAuthorizationPage from "@components/VerticalLayout/NotAuthorizationPage";
import CardSpinnerWrapper from "@components/Common/CardSpinnerWrapper";
import AccountIdentifier from "./AccountIdentifier";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { useKeycloak } from "@react-keycloak/web";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function Compte() {
    const { t, i18n } = useTranslation();
    const { keycloak } = useKeycloak();
    const fetchWithToken = useFetchWithKcToken();

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { franchiseID } = useSnapshot(store);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isHaveAccess, setIsHaveAccess] = React.useState<boolean | number>(
        false
    );
    const [isLoading, setIsLoading] = React.useState<boolean | number>(false);

    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const uuidUser = localStorage.getItem("uuidUser");

    const urlApiEntity = `${process.env.REACT_APP_ACCESS_API_URL}/entity/${uidEntity}`;
    const urlApi = `${process.env.REACT_APP_ACCESS_API_URL}/accounts/${uidEntity}/${uuidUser}`;

    const dataFetch = React.useCallback(async () => {
        const data = await (await fetchWithToken(urlApiEntity)).json();
        localStorage.setItem("schemaFranchise", data.data[0].franchise_schema);
        localStorage.setItem("i18nextLng", i18n.language);
        setMultiShopSwitch(
            franchiseID,
            data.data[0][t("Commercial name")] ?? data.data[0]["Nom commercial"]
        );
        // eslint-disable-next-line
    }, [urlApiEntity, franchiseID, i18n.language, t]);

    React.useEffect(() => {
        dataFetch();
        setIsLoading(true);
        fetchWithToken(urlApi)
            .then((value) => value.json())
            .then((result) => {
                if (result.data.length !== 0) setIsHaveAccess(true);
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, [dataFetch, urlApi]);

    React.useEffect(() => {
        setDataIsUpdated(true);
    }, [i18n.language]);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 8,
            columnName: t("Identifier"),
            customJsx: AccountIdentifier,
        },
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return <CustomIconsComponent e={e} />;
            },
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title={t("Home")} />
            {isLoading === false ? (
                <div>
                    {isHaveAccess === false ? (
                        <NotAuthorizationPage />
                    ) : (
                        <div
                            className="page-content__clz"
                            style={{ width: "100%" }}
                        >
                            <h2 className="ml-5 mt-3">
                                {t("Choose an account")}
                            </h2>
                            <div
                                style={{ padding: "20px", margin: "9px" }}
                                className="card"
                            >
                                <DynamicTable
                                    url={
                                        `${process.env.REACT_APP_VERIFY}` ===
                                            "localhost" ||
                                        process.env.REACT_APP_VERIFY ===
                                            undefined
                                            ? "./user.json"
                                            : urlApi
                                    }
                                    requestHeader={{
                                        "Accept-Language": i18n.language,
                                        Authorization: `Bearer ${keycloak.token}`,
                                    }}
                                    canExpand={false}
                                    setDataIsUpdated={setDataIsUpdated}
                                    dataIsUpdated={dataIsUpdated}
                                    showFilter
                                    canSelect={false}
                                    setLocalFilterActive={setLocalFilterActive}
                                    filterActive={filterActive}
                                    actionColumn={() => <></>}
                                    arrayOfCustomColumns={arrayOfCustomColumns}
                                    canResize
                                    showGlobalFilter
                                    elevationTable={1}
                                    name="Choice"
                                    minHeight={"500px"}
                                    maxHeight={"500px"}
                                />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <CardSpinnerWrapper />
            )}
        </React.Fragment>
    );
}
