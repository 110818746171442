import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CheckboxIcon,
    PlusIcon,
    StyledBreadcrumb,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "@components/Common/PageTitle";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { useLocation } from "react-router-dom";
import { getPathAuthorization } from "@components/VerticalLayout";
import classnames from "classnames";

import { store } from "../../../components/VerticalLayout/store";
import {
    setIsMultiDeletePeripheralModalOpened,
    setIsUpdateOperation,
    setModify,
    store as beeperStore,
} from "./store";

import { UpdateResourceIp } from "@helpers/general";

import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import MessageInfo from "@components/Common/MessageInfo";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { ConfirmationMultiDeleteMessage } from "@components/Common/ConfirmationMultiDeleteMessage";
import BadgeNumber from "@components/Common/BadgeNumber";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import { CustomIconsComponent } from "./CustomIcons";
import BeeperConfig from "./BeeperConfig";
import DropdownAction from "./DropdownAction";
import OriginHistory from "./OriginHistory";

import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { useKeycloak } from "@react-keycloak/web";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Beepers() {
    const location = useLocation();
    const fetchWithToken = useFetchWithKcToken();
    const { keycloak } = useKeycloak();

    const { oneShop, operatorID, userID, franchiseID, shopID } = useSnapshot(
        store
    );
    const { isMultiDeletePeripheralModalOpened, modify } = useSnapshot(
        beeperStore
    );

    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [
        isAddNewBeeperButtonClicked,
        setIsAddNewBeeperButtonClicked,
    ] = useState(false);
    const [isAddBeeperClicked, setIsAddBeeperClicked] = useState(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [
        isAddBeeperOriginHistoryClicked,
        setIsAddBeeperOriginHistoryClicked,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedShopId, setSelectedShopId] = React.useState<any>({});
    // eslint-disable-next-line
    const [localShop, setLocalShop] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>([]);
    const [editedData, setEditedData] = React.useState<any>([]);
    const [beeperId, setBeeperId] = React.useState<string>("");

    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);

    const [selectedIds, setSelectedIds] = React.useState<any>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [ListNumberOrigin, setListNumberOrigin] = React.useState<any>({
        nbrNotArchived: 0,
        nbrArchived: 0,
    });
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [inputIp, setInputIp] = React.useState<boolean>(false);

    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    const [url, setUrl] = React.useState<string>(
        oneShop
            ? `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
    );
    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );

        setDataIsUpdated(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeDisplay, t]);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 7 : 8,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return (
                    <CustomIconsComponent
                        e={e}
                        setShopId={setShopId}
                        handleTestBeeperButtonOnClickEvent={
                            handleTestBeeperButtonOnClickEvent
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        setEditedData={setEditedData}
                        setIsModalOpened={setIsModalOpened}
                        setBeeperId={setBeeperId}
                        isModalOpened={isModalOpened}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                        setInputIp={setInputIp}
                        setIsConfirmationEditRunning={
                            setIsConfirmationEditRunning
                        }
                        typeDisplay={typeDisplay}
                        setIsModalOpenedHistory={
                            setIsAddBeeperOriginHistoryClicked
                        }
                        isModalOpenedHistory={isAddBeeperOriginHistoryClicked}
                        setSelectedShopId={setSelectedShopId}
                        setIsConsult={setIsConsult}
                        isAddBeeperClicked={isAddBeeperClicked}
                        setIsAddBeeperClicked={setIsAddBeeperClicked}
                        isConsult={isConsult}
                    />
                );
            },
        },

        {
            indexOFColumn: oneShop ? 1 : 2,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {!oneShop ? (
                            e.selectedRow[t("Shop")] !== undefined ||
                            (e.selectedRow[t("Shop")] !== "" &&
                                e.selectedRow.subRows) !== undefined ? (
                                <BadgeNumber
                                    value={e.selectedRow.subRows?.length}
                                />
                            ) : (
                                e.selectedRow[t("Designation")]
                            )
                        ) : (
                            e.selectedRow[t("Designation")]
                        )}
                    </div>
                );
            },
        },
    ];
    const disableCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.add("not-allowed-icon-check__clz");
        }
    };

    async function handleTestBeeperButtonOnClickEvent(
        ip: string,
        port: string
    ) {
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/testPrint?ip=${ip}${
            port.length !== 0 ? `&port=${port}` : ""
        }`;

        try {
            mutate(
                apiUrl,
                await fetchWithToken(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while delete selection!");
                        }
                        toast.success(`${t("Printing successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                    })
            );
        } catch (e: any) {
            toast.warning(
                `${t(
                    "A problem detected. Please check your beeper settings."
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }
    React.useEffect(() => {
        if (editedData.Id) {
            if (
                data.data
                    .filter((bt: any) => {
                        return bt.shopId === editedData.shopId;
                    })[0]
                    ?.subRows.filter((imp: any) => {
                        return imp.Id === editedData.Id;
                    })[0] !== undefined
            ) {
                setEditedData(
                    data.data
                        .filter((bt: any) => {
                            return bt.shopId === editedData.shopId;
                        })[0]
                        ?.subRows.filter((imp: any) => {
                            return imp.Id === editedData.Id;
                        })[0]
                );
            }
        }

        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/beepers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );
        setDataIsUpdated(true);
        // eslint-disable-next-line
    }, [oneShop, franchiseID, userID, i18nextLng]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line
    }, [dataIsUpdated]);
    React.useEffect(() => {
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
        // eslint-disable-next-line
    }, [data]);
    React.useEffect(() => {
        let localShop: string[] = [];
        let ids: any[] = [];
        var localShopId: number[] = [];

        selectedRows.forEach((el: any) => {
            if (el[`${t("Shop")}`] !== "" && el[`${t("Shop")}`] !== undefined) {
                localShop.push(el.shopId);
                localShopId.push(el.shopId);
            } else {
                ids.push({
                    Id: el.Id,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                });
            }
        });

        setLocalShop(localShop);
        setSelectedIds(ids);

        setIsShopSelected(localShop.length >= 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, oneShop]);

    React.useEffect(() => {
        if (filterActive) {
            setIsAddBeeperClicked(false);
            setEditedData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);
    React.useEffect(() => {
        if (isAddBeeperClicked === true) {
            setLocalFilterActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddBeeperClicked]);
    return (
        <React.Fragment>
            <PageTitle title={t("Peripherals")} />
            <div className="page-content__clz">
                <div className="imp_txt_beeper">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("Peripherals").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: t(
                                    typeDisplay === "notArchived"
                                        ? "Beepers"
                                        : "Archived beepers"
                                ).toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "In this section, you can add and configure the beeper."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddBeeperClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                        canExpand
                        canSelect
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setData={setData}
                        customSelect
                        actionColumn={() => <></>}
                        canMovedCheckboxLeftOnExpand
                        setSelectedRows={setSelectedRows}
                        customJsxSideFilterButton={
                            <div className="d-flex justify-content-start align-items-center ml-2">
                                {modify === true &&
                                isAddBeeperClicked !== true ? (
                                    <div>
                                        <span
                                            onClick={() => {
                                                disableCheckbox();
                                                setIsAddBeeperClicked(
                                                    !isAddBeeperClicked
                                                );
                                                setShopId(
                                                    oneShop === false
                                                        ? selectedRows[0].shopId
                                                        : shopID
                                                );
                                                setLocalFilterActive(false);
                                            }}
                                            className={`cmn_btn_openForm ${classnames(
                                                {
                                                    "not-allowed-icon__clz ":
                                                        (!isShopSelected &&
                                                            !oneShop) ||
                                                        typeDisplay ===
                                                            "archived",
                                                }
                                            )}`}
                                        >
                                            <CustomMainColorButtonWithIcon
                                                icon="PlusIcon"
                                                iconPosition="left"
                                                className="w-100"
                                                style={{ height: "0px" }}
                                                rounded
                                                variant="primary"
                                            >
                                                {t("Add device")}
                                            </CustomMainColorButtonWithIcon>
                                        </span>
                                    </div>
                                ) : modify === true ? (
                                    <div className="d-flex justify-content-start cmn_btn_openForm">
                                        <StyledIconButton
                                            icon="PlusIcon"
                                            className="bg-dark"
                                            rounded
                                            disabled={
                                                editedData?.length === 0
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                if (isEdited === true)
                                                    setIsInInitializationModalOpened(
                                                        true
                                                    );
                                                else {
                                                    setInputDesignation(false);
                                                    setInputIp(false);
                                                }
                                            }}
                                        >
                                            <PlusIcon
                                                height={20}
                                                width={20}
                                                fill="white"
                                                className="imp_btn_reset"
                                            />
                                        </StyledIconButton>
                                    </div>
                                ) : null}
                                <DropdownAction
                                    setIsAddBeeperClicked={
                                        setIsAddBeeperClicked
                                    }
                                    typeDisplay={typeDisplay}
                                    setTypeDisplay={setTypeDisplay}
                                    selectedIds={selectedIds}
                                    ListNumberOrigin={ListNumberOrigin}
                                    setDataIsUpdated={setDataIsUpdated}
                                />
                            </div>
                        }
                        canResize
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="beeper"
                    />
                    {isAddBeeperClicked ? (
                        <BeeperConfig
                            setIsAddBeeperClicked={setIsAddBeeperClicked!}
                            isAddBeeperClicked={isAddBeeperClicked}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={editedData}
                            setEditedData={setEditedData}
                            isAddNewBeeperButtonClicked={
                                isAddNewBeeperButtonClicked
                            }
                            shopIdBeeper={shopId}
                            localShopId={oneShop ? [shopID] : localShop}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            inputIp={inputIp}
                            setInputIp={setInputIp}
                            dataTable={data}
                            setIsUpdateOperation={setIsUpdateOperation}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : null}
                </div>
                <ToastContainer limit={1} />
                {isModalDeleteAssociatePeripheralOpened === false ? (
                    <ConfirmationDeleteMessage
                        isModalOpened={isModalOpened}
                        setIsModalOpened={setIsModalOpened}
                        setDataIsUpdated={setDataIsUpdated}
                        text={t(
                            typeDisplay === "notArchived"
                                ? "Please confirm archivation of this device"
                                : "Please confirm unarchivation of this device"
                        )}
                        message={t(
                            typeDisplay === "notArchived"
                                ? "The device was successfully archived"
                                : "The device was successfully unarchived"
                        )}
                        apiUrl={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/beeper?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&id=${beeperId}&isArchived=${
                            typeDisplay === "notArchived" ? "true" : "false"
                        }&operatorId=${operatorID}`}
                        data={{ shopId: shopId }}
                    />
                ) : (
                    <AlertDeletePeripheralMessage
                        text={t(
                            "This device is associated with one or more applications! Unable to perform delete action"
                        )}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                    />
                )}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewBeeperButtonClicked}
                    isClicked={isAddNewBeeperButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddBeeperClicked}
                    archive={typeDisplay === "archived"}
                />
            ) : null}
            {isMultiDeletePeripheralModalOpened === true && (
                <ConfirmationMultiDeleteMessage
                    isModalOpened={isMultiDeletePeripheralModalOpened}
                    setIsModalOpened={setIsMultiDeletePeripheralModalOpened}
                />
            )}
            {isAddBeeperOriginHistoryClicked && (
                <OriginHistory
                    url={`${process.env.REACT_APP_API_V2_URL}/settings/beepers/beeper/history?userId=${userID}&shopId=${selectedShopId}&franchiseId=${franchiseID}&id=${beeperId}`}
                    setIsModalOpened={setIsAddBeeperOriginHistoryClicked}
                    isModalOpened={isAddBeeperOriginHistoryClicked}
                />
            )}
        </React.Fragment>
    );
}
