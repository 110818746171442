import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedAggregator,
    setSelectedRow,
    setIds,
    setMessage,
    setAggregator,
    setGlobalDataIsUpdated,
} from "../store";

import UniqueRowSelection from "./UniqueRowSelection";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { ConfirmationUpdate } from "./ConfirmationUpdate";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { useKeycloak } from "@react-keycloak/web";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function AggregatorAssociateModal({
    setData,
    selectedShopsRows,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    const { keycloak } = useKeycloak();
    
    let {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        rowsShop,
        selectedRowsAggregator,
        activeAggregator,
        isAssociatedAggregator,
        aggregator,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [selectedAggregator, setSelectedAggregator] = React.useState<any[]>(
        []
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/list/aggregator?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: " ",
            customJsx: (e: any) => (
                <UniqueRowSelection
                    e={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedAggregator={selectedAggregator}
                />
            ),
        },

        {
            indexOFColumn: 6,
            columnName: t("Action"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedAggregator={selectedAggregator}
                />
            ),
        },
    ];

    function checkIfAggregatorAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    async function RedisPublishPeripheral() {
        let redisApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/peripheral/redis`;
        for (let element of localShop) {
            try {
                mutate(
                    redisApiUrl,
                    await fetchWithToken(redisApiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                             
                        },
                        body: JSON.stringify({
                            shopId: element,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }
    async function AssociateAggregator() {
        toast.dismiss();
        let isError: boolean = false;
        let isData: boolean = false;
        let localData: any = [];
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            setLimit(1);

            for (let element of localShop) {
                let associated = checkIfAggregatorAssociated(element);
                let objectData: any = {};

                if (aggregator !== "") {
                    setAggregator(activeAggregator);

                    let isActive = activeAggregator.find(
                        (element: any) =>
                            element.Id === selectedRowsAggregator.Id
                    ).state;

                    if (
                        associated.findIndex(
                            (elt: any) => elt.Id === selectedRowsAggregator.Id
                        ) === -1 &&
                        associated.length !== 0
                    ) {
                        isError = true;
                    } else if (
                        associated.findIndex(
                            (elt: any) =>
                                elt.Id === selectedRowsAggregator.Id &&
                                elt.isActive !== isActive
                        ) !== -1 ||
                        associated.length === 0 ||
                        associated.findIndex(
                            (elt: any) =>
                                elt.Id === selectedRowsAggregator.Id &&
                                elt.isActive === isActive
                        ) === -1
                    ) {
                        Object.assign(objectData, {
                            [selectedRowsAggregator.Id]: {
                                aggregator:
                                    selectedRowsAggregator[t("Aggregator")],

                                note: selectedRowsAggregator[t("Remark")],
                                isActive: activeAggregator.find(
                                    (element: any) =>
                                        element.Id === selectedRowsAggregator.Id
                                ).state,
                                isArchived: selectedRowsAggregator.isArchived,
                                shopId: element,
                                state: selectedRowsAggregator.state,
                                stateShop: selectedRowsAggregator.stateShop,
                            },
                        });
                    }
                    let dataAggregator = {
                        userId: userID,
                        shopId: [element],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: objectData,
                        affectation: true,
                    };

                    if (Object.keys(dataAggregator.data).length !== 0) {
                        isData = true;
                    }
                    localData.push(
                        fetchWithToken(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(dataAggregator),
                            }
                        ).then((response) => response.json())
                    );
                }
                try {
                    mutate(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`,
                        await Promise.all(localData).then((result) => {
                            result.forEach((element: any) => {
                                if (element.error === true) setError(true);
                            });
                        })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            if (error === false) {
                if (isError && !isData) {
                    ErrorToast(
                        t(
                            "Please note that one or more Aggregator could not be assigned. A store cannot have more than one Aggregator setting."
                        )
                    );
                } else if (isData && isError) {
                    toast.success(
                        `${t(
                            "Aggregator assignment was successfully assigned"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    ErrorToast(
                        t(
                            "Please note that one or more Aggregator could not be assigned. A store cannot have more than one Aggregator setting."
                        )
                    );
                    RedisPublishPeripheral();
                    setIsValidateButtonDisabled(false);
                    setLimit(2);
                } else {
                    toast.success(
                        `${t(
                            "Aggregator assignment was successfully assigned"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
                RedisPublishPeripheral();
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length
                );

                setIsAssociateModalOpened(!isAssociateModalOpened);
                setIsAssociateModalClosed(!isAssociateModalClosed);
                setData(true);
                setGlobalDataIsUpdated(true);
                setIsValidateButtonDisabled(false);
            }
            setData(true);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }
    function checkIfFiscalYearExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedAggregator !== undefined) {
                    element.archivedAggregator.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }
    React.useEffect(() => {
        let local: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    local.push(element);
                });
        });
        setSelectedAggregator(local);
        let notChanged: any = [];
        local.forEach((el: any) => {
            if (selectedRowsAggregator.Id === el.Id) {
                let test: any = activeAggregator.find(
                    (element: any) => element.Id === el.Id
                );
                notChanged.push(test.state === el.isActive);
            }
        });
        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRowsAggregator, activeAggregator]);
    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setAggregator("")
                    : setAggregator(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.Id
                              : rowsShop[0].Id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setAggregator("")
                    : setAggregator(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.Id
                              : rowsShop[0].Id
                      );
            }
        }
    }, [rowsShop, localShop]);
    React.useEffect(() => {
        if (isValidateClickedModal === true) {
            AssociateAggregator();
        }
        // eslint-disable-next-line
    }, [isValidateClickedModal]);
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                    setData(true);
                }}
            >
                <span
                    className="text-uppercase fed_txt_AFloyaltyList"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of Aggregator")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateAggregator"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="fed_btn_AFcancel"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                        setData(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="fed_btn_AFvalidate"
                    onClick={() => {
                        let archived = checkIfFiscalYearExistAndArchived();
                        CountIds();
                        if (aggregator === "" || aggregator === undefined) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select least one Aggregator"
                                )
                            );
                        } else if (isStateChanged) {
                            setIsModalOpened(!isModalOpened);
                        } else {
                            try {
                                setSelectedRow(selectedRows);

                                if (
                                    archived.includes(selectedRowsAggregator.Id)
                                ) {
                                    setIsAssociatedAggregator(true);
                                    setMessage(
                                        t(
                                            "Attention! One or more selected Aggregator are already assigned and archived at the store level.\nWould you unarchive them?"
                                        )
                                    );
                                    // eslint-disable-next-line
                                    throw "Break";
                                }

                                AssociateAggregator();
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Warning! This change will affect specific settings in the store(s).\n\nDo you want to confirm the modification?"
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isAssociatedAggregator ? (
                <ConfirmationUpdate
                    isModalOpened={isAssociatedAggregator}
                    setIsModalOpened={setIsAssociatedAggregator}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
        </StyledModal>
    );
}
