import React from "react";
import { useTranslation } from "react-i18next";
import { CrossIcon, CheckboxIcon } from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";
import NumInput from "react-numeric-input";
import { Col } from "reactstrap";
import classNames from "classnames";

import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { store } from "@components/VerticalLayout/store";
import { setEmptyAlertValue, setLoadingAlertValue } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";
import "./index.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type AlertDenominationFormType = {
    setIsAddAlertlevelClicked: Function;

    isAddAlertlevelClicked: boolean;
    setDataIsUpdated: Function;
    setIsAddNewAlertlevelButtonClicked: Function;
    isAddNewAlertlevelButtonClicked: boolean;
    isEdited: boolean;
    setIsEdited: Function;
    rowData: any;
    alertlevelData: any;
    userID: number;
    alert_type: string;
};

export function AlertDenominationForm({
    setIsAddAlertlevelClicked,
    isAddAlertlevelClicked,
    setIsAddNewAlertlevelButtonClicked,
    isAddNewAlertlevelButtonClicked,
    setDataIsUpdated,
    setIsEdited,
    rowData,
    alertlevelData,
    userID,
    alert_type,
}: AlertDenominationFormType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { franchiseID } = useSnapshot(store);
    const { shopID } = useSnapshot(store);
    const { oneShop } = useSnapshot(store);
    const [title, setTitle] = React.useState<string>("");
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        true
    );
    const [denomination, setDenomination] = React.useState<any>([
        {
            coin: "0.01 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].state
                    : false,
            maxvalue: 350,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].number
                    : 0,
        },
        {
            coin: "0.02 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].state
                    : false,
            maxvalue: 250,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].number
                    : 0,
        },
        {
            coin: "0.05 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].state
                    : false,
            maxvalue: 200,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].number
                    : 0,
        },
        {
            coin: "0.10 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].state
                    : false,
            maxvalue: 200,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].number
                    : 0,
        },
        {
            coin: "0.20 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].state
                    : false,
            maxvalue: 160,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].number
                    : 0,
        },
        {
            coin: "0.50 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].number
                    : 0,
        },
        {
            coin: "1.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].number
                    : 0,
        },
        {
            coin: "2.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].number
                    : 0,
        },
        {
            coin: "5.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].number
                    : 0,
        },
        {
            coin: "10.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].number
                    : 0,
        },
        {
            coin: "20.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].number
                    : 0,
        },
    ]);

    const Denomination = [
        {
            coin: "0.01 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].state
                    : false,
            maxvalue: 350,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[0].number
                    : 0,
        },
        {
            coin: "0.02 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].state
                    : false,
            maxvalue: 250,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[1].number
                    : 0,
        },
        {
            coin: "0.05 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].state
                    : false,
            maxvalue: 200,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[2].number
                    : 0,
        },
        {
            coin: "0.10 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].state
                    : false,
            maxvalue: 200,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[3].number
                    : 0,
        },
        {
            coin: "0.20 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].state
                    : false,
            maxvalue: 160,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[4].number
                    : 0,
        },
        {
            coin: "0.50 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[5].number
                    : 0,
        },
        {
            coin: "1.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[6].number
                    : 0,
        },
        {
            coin: "2.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[7].number
                    : 0,
        },
        {
            coin: "5.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[8].number
                    : 0,
        },
        {
            coin: "10.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[9].number
                    : 0,
        },
        {
            coin: "20.00 €",
            state:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].state
                    : false,
            maxvalue: 150,
            value:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].value
                    : "0",
            number:
                Object.keys(alertlevelData).length > 0
                    ? alertlevelData.value[10].number
                    : 0,
        },
    ];

    const handleStateChange = (item: any, e: any, key: number) => {
        item.state = !item.state;
        let newDenomination = [...denomination] as any;
        newDenomination[key].state = item.state;
        setDenomination(newDenomination);
    };
    const handleValueChange = (
        item: any,

        event: any,
        key: any
    ) => {
        let newDenomination = [...denomination] as any;

        newDenomination[key].value = event;

        setDenomination(newDenomination);
    };

    const handleNumberChange = (item: any, e: any, key: any) => {
        let newDenomination = [...denomination] as any;
        if (item.state) {
            newDenomination[key].number = (e * 100) / item.maxvalue;
        } else {
            newDenomination[key].number = 0;
            newDenomination[key].value = 0;
        }

        setDenomination(newDenomination);
    };

    const resetDenomination = (item: any, e: any, key: any) => {
        let newDenomination = [...denomination] as any;
        newDenomination[key].number = 0;
        newDenomination[key].value = 0;
        setDenomination(newDenomination);
    };

    async function addAlertLevel() {
        toast.dismiss();
        if (isValidateClicked) {
            setIsValidateClicked(false);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/Denomination`;
            let data = {
                userId: userID,
                shopId: oneShop ? shopID.toString() : rowData.shopId,
                franchiseId: franchiseID,
                appId: "KIOSK",
                appId_children: rowData.appId,
                peripheralId: rowData.peripheralId,
                alertType: alert_type,
                data: { [uuid()]: Object.assign({}, denomination) },
            };
            if (alert_type === "empty_coin_alert")
                setEmptyAlertValue(data.data);
            else setLoadingAlertValue(data.data);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            setIsAddAlertlevelClicked(!isAddAlertlevelClicked);
                            setIsEdited(false);
                            setDataIsUpdated!(true);
                        })
                );
                toast.success(`${t("Alert level added successfully")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function EditAlertLevel() {
        toast.dismiss();
        if (isValidateClicked) {
            setIsValidateClicked(false);
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/Denomination`;
            let data = {
                userId: userID,
                shopId: oneShop ? shopID.toString() : rowData.shopId,
                franchiseId: franchiseID,
                appId: "KIOSK",
                appId_children: rowData.appId,
                peripheralId: rowData.peripheralId,
                alertType: alert_type,
                data: { [alertlevelData.Id]: Object.assign({}, denomination) },
            };
            if (alert_type === "empty_coin_alert")
                setEmptyAlertValue(data.data);
            else setLoadingAlertValue(data.data);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            setIsAddAlertlevelClicked(!isAddAlertlevelClicked);
                            setIsEdited(false);
                            setDataIsUpdated!(true);
                        })
                );
                toast.success(`${t("Alert level changed successfully")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    React.useEffect(() => {
        if (alertlevelData.length !== 0) {
            setTitle(t("Modify a pattern"));
            setDenomination(Denomination);
        } else {
            setTitle(t("Add an alert level"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertlevelData, t, isAddNewAlertlevelButtonClicked]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="pt-3">{title}</h5>
                    <CrossIcon
                        className="icon-close-notification__clz"
                        height={13}
                        width={13}
                        onClick={() => {
                            setIsAddAlertlevelClicked(false);
                            setIsAddNewAlertlevelButtonClicked(false);
                            setDataIsUpdated!(true);
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    style={{
                        maxHeight: "650px",
                        overflow: "hidden auto",
                    }}
                >
                    <AvGroup>
                        {denomination.map((d: any, key: number) => (
                            <div className="d-flex justify-content-between mt-2">
                                <Col xl="2">
                                    <div style={{ width: "160px" }}>
                                        <h5 className="p-3">{d.coin}</h5>
                                    </div>
                                </Col>
                                <Col xl="6">
                                    <NumInput
                                        autoComplete="off"
                                        placeholder="0(0.00%)"
                                        className={classNames(
                                            "num__clz",
                                            "placeholder__clz"
                                        )}
                                        type="number"
                                        disabled={!d.state}
                                        id="example-input"
                                        name="example-input"
                                        format={(e: any) =>
                                            `${d.value} (${d.number.toFixed(
                                                2
                                            )}%) `
                                        }
                                        value={d.value !== 0 ? d.value : null}
                                        onChange={(e: any) => {
                                            handleValueChange(d, e, key);
                                            handleNumberChange(d, e, key);
                                        }}
                                        max={d.maxvalue}
                                        min={0}
                                        onFocus={(e: any) =>
                                            (e.target.placeholder = "")
                                        }
                                        onBlur={(e: any) =>
                                            (e.target.placeholder = "0(0.00%)")
                                        }
                                        strict
                                        style={{
                                            input: {
                                                background: `linear-gradient(to left,red 0%, red ${
                                                    100 - d.number
                                                }%, green ${
                                                    100 - d.number
                                                }%),green 100%`,
                                            },
                                        }}
                                    />
                                </Col>
                                <Col xl="2">
                                    <h5
                                        className="mt-3"
                                        style={{
                                            color: "red",
                                            marginLeft: "34px",
                                        }}
                                    >
                                        {d.maxvalue}
                                    </h5>
                                </Col>
                                <Col xl="2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={d.state}
                                        onChange={(e: any) => {
                                            handleStateChange(d, e, key);
                                            resetDenomination(d, e, key);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="mt-3 ml-4"
                                    />
                                </Col>
                            </div>
                        ))}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2"
                        variant="light"
                        onClick={() => {
                            setIsAddAlertlevelClicked(!isAddAlertlevelClicked);
                            setDataIsUpdated!(true);
                            setIsAddNewAlertlevelButtonClicked(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            if (alertlevelData.Id) EditAlertLevel();
                            else addAlertLevel();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
        </React.Fragment>
    );
}
