import React from "react";

import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";

import storeVatRate, {
    setConfirmationModificationClick,
    setDataIsUpdatedFranchise,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";

import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function ConfirmationModification({
    handleValidate,
    obj,
    type,
    canReplace,
    setIsAddVatRateClicked,
    setIsEdited,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { confirmationModificationClick } = useSnapshot(storeVatRate);
    const { operatorID } = useSnapshot(store);

    const uuidUser = localStorage.getItem("uuidUser");

    async function addTag(result: any, userID: string, franchiseID: string) {
        let objectData: any = {};
        let localData: any = [];

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/tag`;

        result.forEach(async (el: any) => {
            Object.assign(objectData, {
                id: uuid(),
                designation: el,
            });

            let data = {
                userId: userID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                shopId: "0",
                franchiseId: franchiseID,
                deviceType: "tag",
                data: [objectData],
            };

            localData.push(
               fetchWithToken(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (errors.includes(true)) {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e) {}
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={confirmationModificationClick}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setConfirmationModificationClick(
                        !confirmationModificationClick
                    )
                }
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Do you want to apply these changes for all shops? \n Attention ! , If you click on “Validate”, the settings of the franchise and its shops will be modified."
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        obj.canReplace = false;
                        // handleValidate(obj, type);
                        setDataIsUpdatedFranchise(true);
                        setConfirmationModificationClick(false);
                        setIsAddVatRateClicked(false);
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={async () => {
                        handleValidate(obj, type, "shopsUpdate");
                        await addTag(
                            obj.data[0].tag,
                            obj.userId,
                            obj.franchiseId
                        );
                        setConfirmationModificationClick(false);
                        setIsAddVatRateClicked(false);
                        setIsEdited(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
