import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    setLimit?: Function;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    setLimit = () => 1,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const [error, setError] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    async function archiveAggregator() {
        toast.dismiss();

        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            arrayOfRows.forEach(async (el: any) => {
                let dataAggregator = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [el.Id]: {
                            aggregator: el[t("Aggregator")],
                            note: el[t("Remark")],
                            isArchived: true,
                            shopId: el.shopId,
                            state: el.state,
                            stateShop: el.stateShop,
                        },
                    },
                };

                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataAggregator),
                };
                try {
                    mutate(
                        apiUrlAdd,
                        await fetchWithToken(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    setError(true);
                                }
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            if (error === false) {
                toast.success(
                    `${t("The Aggregator have been successfully archived")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            setDataIsUpdated(true);
        } else {
            let dataAggregator = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                isShop: false,
                data: {
                    [e.selectedRow.Id]: {
                        aggregator: e.selectedRow[t("Aggregator")],
                        note: e.selectedRow[t("Remark")],
                        isActive: false,
                        isArchived: true,
                        shopId: e.selectedRow.shopId,
                        state: e.selectedRow.state,
                        stateShop: e.selectedRow.stateShop,
                    },
                },
            };
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/aggregator`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataAggregator),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Aggregator have been successfully archived"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }

        setLimit(1);
    }
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, this Aggregator can be used by other modules.\n Do you want to confirm the archiving of the selected Aggregator(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveAggregator();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
