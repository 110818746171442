import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import Router from "./routes";
import { SpinnerWrapper } from "@components/Common/SpinnerWrapper";

export default function App(): JSX.Element {
    const { initialized } = useKeycloak();

    if (!initialized) {
        return <React.Suspense fallback={<SpinnerWrapper />}> </React.Suspense>;
    }

    return <Router />;
}
