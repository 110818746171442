import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import { mutate } from "swr";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { store as FiscalYear } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActive,
    setNumberArchived,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setFiscalYear,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isFiscalYearArchived,
    setIsAddFiscalYearClicked,
    isAddFiscalYearClicked,
    selectedRows,
    setLocalFilterActive,
    setIsConsult,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberActive,
        numberArchived,
        shopsFiscalYearData,
        archivedFiscalYearFranchise,
        fiscalYearData,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(
        FiscalYear
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateFiscalYear();
        dataArchive = ArchiveFiscalYear();
    } else {
        dataArchive = ArchiveFiscalYearFranchise();
    }

    function AssociateFiscalYear() {
        let idsAssociated: any = [];
        if (shopsFiscalYearData.data !== undefined) {
            shopsFiscalYearData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveFiscalYear() {
        let idsAssociated: any = [];
        if (shopsFiscalYearData.data !== undefined) {
            shopsFiscalYearData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchiveFiscalYearFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFiscalYearFranchise.othersData
                .archivedFiscalYearFranchise !== undefined
        ) {
            archivedFiscalYearFranchise.othersData.archivedFiscalYearFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeFiscalYear() {
        toast.dismiss();
        let dataModeOfSale = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: {
                [e.selectedRow.Id]: {
                    first_day: e.selectedRow.first_day,
                    first_month: e.selectedRow.first_month,
                    end_day: e.selectedRow.end_day,
                    end_month: e.selectedRow.end_month,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fiscal year has been activated successfully"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                if (!isAddFiscalYearClicked) {
                                                    setIsAddFiscalYearClicked(
                                                        !isAddFiscalYearClicked
                                                    );
                                                }
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setFiscalYear(e.selectedRow);
                                                setLocalFilterActive(false);
                                            });
                                        } else {
                                            if (!isAddFiscalYearClicked) {
                                                setIsAddFiscalYearClicked(
                                                    !isAddFiscalYearClicked
                                                );
                                            }
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                            setFiscalYear(e.selectedRow);
                                            setLocalFilterActive(false);
                                        }
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isFiscalYearArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    if (
                                                        !isAddFiscalYearClicked
                                                    ) {
                                                        setIsAddFiscalYearClicked(
                                                            !isAddFiscalYearClicked
                                                        );
                                                    }
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setFiscalYear(
                                                        e.selectedRow
                                                    );
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                if (!isAddFiscalYearClicked) {
                                                    setIsAddFiscalYearClicked(
                                                        !isAddFiscalYearClicked
                                                    );
                                                }
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setFiscalYear(e.selectedRow);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit ml-3",
                                            {
                                                "not-allowed-icon__clz":
                                                    isFiscalYearArchived ||
                                                    oneShop,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {!isFiscalYearArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsAddFiscalYearClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setIsArchiveModalOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddFiscalYearClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setIsArchiveModalOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",

                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    activeFiscalYear();
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else if (
                                                    fiscalYearData.othersData.allFiscalYear.filter(
                                                        (element: any) =>
                                                            element.shopId ===
                                                            e.selectedRow.shopId
                                                    )[0] !== undefined &&
                                                    fiscalYearData.othersData.allFiscalYear.filter(
                                                        (element: any) =>
                                                            element.shopId ===
                                                            e.selectedRow.shopId
                                                    )[0].subRows.length !== 0 &&
                                                    oneShop
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention, a shop cannot have more than one fiscal year"
                                                        )
                                                    );
                                                } else {
                                                    activeFiscalYear();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }

                                                setFiscalYear(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-3 cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddFiscalYearClicked(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setRowData(e.selectedRow);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setRowData(e.selectedRow);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",

                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
