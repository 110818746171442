import React from "react";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import { ImCross } from "react-icons/im";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import {
    setSelectedData,
    store,
    setRemoveSelectedData,
    clearData,
} from "./store";
import { store as storeData } from "@components/VerticalLayout/store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export const ValuesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;
export const Value = styled.div`
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    margin: 5px;
    font-size: 0.75rem;
    color: black;
    background-color: hsl(0, 0%, 90%);
    user-select: none;
    border-radius: 0.3rem;
    max-width: 257px;
`;

export const CustomSelectInput = styled.div`
    position: relative;
`;

export const XButton = styled.button`
    all: unset;
    margin-left: 0.3rem;
    color: black;
    transition: fill 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
        color: #bb392d;
    }
    &:focus {
        color: #c82f21;
    }
`;

export const ClearButton = styled.button`
    position: absolute;
    right: 48px;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    background-color: white;
    transform: translateX(2px);
    opacity: 0.3;
    svg {
        width: 10px;
    }
`;

type MultiCustomCreatableSelectionType = {
    onDelete?: Function;
    onChange: Function;
    className?: any;
    placeholder: any;
    value: any;
    data: any;
    setResult: Function;
    name: string;
    onCreateOption?: any;
    formatCreateLabel?: any;
    noOptionsMessage?: any;
    result: any[];
    isSendingTag: boolean;
};

export default function MultiCustomCreatableSelection({
    onChange,
    className,
    placeholder,
    value,
    data,
    setResult,
    name,
    onCreateOption,
    formatCreateLabel,
    noOptionsMessage,
    result,
    onDelete,
    isSendingTag,
}: MultiCustomCreatableSelectionType) {
    const fetchWithToken = useFetchWithKcToken();
    const { selectedData } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(storeData);
    const [filteredData, setFilteredData] = React.useState<any>([]);

    const handleXButton = (e: any) => {
        setRemoveSelectedData(e, name);
        if (onDelete !== undefined) {
            onDelete!();
        }
    };

    const addTag = React.useCallback(async () => {
        let objectData: any = {};
        let localData: any = [];

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/tag`;

        result.forEach(async (el: any) => {
            Object.assign(objectData, {
                id: uuid(),
                designation: el,
            });

            let data = {
                userId: userID,
                shopId: "0",
                franchiseId: franchiseID,
                deviceType: "tag",
                data: [objectData],
            };

            localData.push(
                fetchWithToken(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (errors.includes(true)) {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e) {}
        // eslint-disable-next-line
    }, [result, userID, franchiseID]);

    React.useEffect(() => {
        let filteredResult: any = result.filter((el: any) => {
            return el !== "";
        });

        if (selectedData[name] === undefined) {
            filteredResult.forEach((el: any) => {
                setSelectedData(el, name);
            });
        }

        let localData = data.filter(
            (item: any) => !filteredResult.includes(item)
        );

        setFilteredData(
            localData.map((el: any) => {
                return { label: el, value: el };
            })
        );
    }, [data, result, name, selectedData]);

    React.useEffect(() => {
        setResult(selectedData[name] !== undefined ? selectedData[name] : []);
    }, [setResult, selectedData, name]);

    React.useEffect(() => {
        if (isSendingTag === true) {
            addTag();
        }
    }, [addTag, isSendingTag]);

    return (
        <React.Fragment>
            <CustomSelectInput>
                <CreatableSelect
                    noOptionsMessage={noOptionsMessage}
                    formatCreateLabel={formatCreateLabel}
                    onCreateOption={(e: any) => {
                        if (onCreateOption !== undefined) {
                            onCreateOption(e);
                        }
                        if (result.indexOf(e) === -1) {
                            setResult(
                                result.indexOf(e) === -1
                                    ? [...result, e]
                                    : result
                            );
                            setSelectedData(e, name);
                        }
                    }}
                    placeholder={placeholder}
                    value={value}
                    isClearable
                    className={className || ""}
                    options={filteredData}
                    autoComplete="off"
                    name={name}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setSelectedData(e.value, name);
                            onChange(e);
                        }
                    }}
                    type="text"
                    maxMenuHeight={90}
                />
                {result.filter((el: any) => {
                    return el !== "";
                }).length > 0 ? (
                    <ClearButton
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            setResult([]);
                            clearData();
                        }}
                    >
                        <ImCross height={6} width={6} />
                    </ClearButton>
                ) : null}
            </CustomSelectInput>

            <div className="d-flex flex-wrap cmn_txt_selectedBox">
                {result
                    .filter((el: any) => {
                        return el !== "";
                    })
                    .map((el: any, index: number) => {
                        return (
                            <ValuesContainer key={index}>
                                <Value key={index}>
                                    {el}
                                    <XButton
                                        className="cmn_icn_selectedBox"
                                        key={index}
                                        onClick={(
                                            e: React.MouseEvent<HTMLElement>
                                        ) => {
                                            handleXButton(el);
                                        }}
                                    >
                                        ✕
                                    </XButton>
                                </Value>
                            </ValuesContainer>
                        );
                    })}
            </div>
        </React.Fragment>
    );
}
