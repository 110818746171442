import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { SettingsIcon, StyledTextInput } from "@aureskonnect/react-ui";
import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { ModalBody } from "reactstrap";

import {
    setIsConfigurationCashManagementClicked,
    store,
} from "../../../Applications/Kiosk/store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    store as alertStore,
    resetEmptyAlertValue,
    defaultEmptyAlertValue,
    setIsSettingIconEmptyClicked,
} from "../store";

import AlertDenominationModal from "../LoadingAlert/AlertDenominationModal";
import { Footer } from "../Footer";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";

import "./index.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CashManagementEmptyAlert = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};
export function EmptyAlert({
    rowData,
    setDataIsUpdated,
}: CashManagementEmptyAlert) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const { emptyAlertValue, isSettingIconEmptyClicked } = useSnapshot(
        alertStore
    );
    const { isConfigurationCashManagementClicked } = useSnapshot(store);

    const TICKETNUMBERMAX = 330;
    const ALERTTYPE = "empty_coin_alert";

    const [isThresholdReached, setIsThresholdReached] = React.useState<boolean>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.sending_frequency
                  .when_maximum_threshold_reached
            : false
    );
    const [isThresholdDayReached, setIsThresholdDayReached] = React.useState<
        boolean
    >(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.sending_frequency
                  ?.after_closing_day_if_maximum_threshold_reached
            : false
    );
    const [isDenominationAlert, setIsDenominationAlert] = React.useState<
        boolean
    >(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.alert_threshold
                  ?.denomination_alert_threshold.state
            : false
    );

    const [alertForm, setAlertForm] = React.useState<string>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.alert_threshold?.send_alert_format.value
            : "XLS"
    );
    const [alertFormDenomination, setAlertFormDenomination] = React.useState<
        string
    >(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.alert_threshold
                  ?.send_alert_format_denomination.value
            : "XLS"
    );
    const [price, setPrice] = React.useState<string>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.alert_threshold?.amount_alert_threshold
                  .amount
            : ""
    );
    const [isPrice, setIsPrice] = React.useState<boolean>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.alert_threshold?.amount_alert_threshold
                  .state
            : false
    );
    const [isAlertNum, setIsAlertNum] = React.useState<boolean>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.ticket_number_alert_threshold?.state
            : false
    );

    const [alertFormNum, setAlertFormNum] = React.useState<string>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.send_alert_format.value
            : "XLS"
    );
    const [ticketNumber, setTicketNumber] = React.useState<string>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? rowData.empty_coin_alert.ticket_number_alert_threshold
                  .ticket_number
            : ""
    );
    const [alertSent, setAlertSent] = React.useState<any>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? [
                  rowData.empty_coin_alert.alert_threshold.send_alert.email &&
                      "E-mail",
                  rowData.empty_coin_alert.alert_threshold.send_alert.sms &&
                      "SMS",
              ]
            : ["SMS"]
    );

    const [alertSentTickets, setAlertSentTickets] = React.useState<any>(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? [
                  rowData.empty_coin_alert.send_alert.email && "E-mail",
                  rowData.empty_coin_alert.send_alert.sms && "SMS",
              ]
            : ["SMS"]
    );
    const [alertSentDenomination, setAlertSentDenomination] = React.useState<
        any
    >(
        Object.keys(rowData.empty_coin_alert).length > 0
            ? [
                  rowData.empty_coin_alert.alert_threshold
                      .send_alert_denomination.email && "E-mail",
                  rowData.empty_coin_alert.alert_threshold
                      .send_alert_denomination.sms && "SMS",
              ]
            : ["SMS"]
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const numberMatchWord = new RegExp(/^[0-9]\d*$/);
    const [isEmailInclude, setIsEmailInclude] = React.useState<boolean>(
        alertSent.includes("E-mail")
    );
    const [isEmailIncludeTickets, setIsEmailIncludeTickets] = React.useState<
        boolean
    >(alertSentTickets.includes("E-mail"));
    const [
        isEmailIncludeDenomination,
        setIsEmailIncludeDenomination,
    ] = React.useState<boolean>(alertSentDenomination.includes("E-mail"));

    async function handleValidateButtonClickEvent() {
        if (
            ((ticketNumber !== "" &&
                ticketNumber.match(numberMatchWord) &&
                Number(ticketNumber) <= TICKETNUMBERMAX) ||
                ticketNumber === "") &&
            ((price !== "" && price.match(numberMatchWord)) || price === "")
        ) {
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                franchiseId = franchiseID;
                shopId = shopID.toString();
                userId = userID;
            } else {
                userId = userID;
                shopId = rowData.shopId;
                franchiseId = franchiseID;
            }

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cashManagement/emptyAlert`;
            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                appId: "KIOSK",
                appId_children: rowData.appId,
                peripheralId: rowData.peripheralId,
                data: {
                    sending_frequency: {
                        when_maximum_threshold_reached: isThresholdReached,
                        after_closing_day_if_maximum_threshold_reached: isThresholdDayReached,
                    },
                    alert_threshold: {
                        denomination_alert_threshold: {
                            state: isDenominationAlert,
                            value: emptyAlertValue,
                        },
                        send_alert_denomination: {
                            state: "",
                            sms:
                                Object.keys(rowData.empty_coin_alert).length > 0
                                    ? rowData.empty_coin_alert.alert_threshold
                                          .send_alert_denomination.sms
                                    : alertSentDenomination.includes("SMS"),
                            email:
                                Object.keys(rowData.empty_coin_alert).length > 0
                                    ? rowData.empty_coin_alert.alert_threshold
                                          .send_alert_denomination.email
                                    : alertSentDenomination.includes("E-mail"),
                        },
                        send_alert_format_denomination: {
                            state: "",
                            value: alertFormDenomination,
                        },
                        amount_alert_threshold: {
                            state: isPrice,
                            amount: price,
                        },
                        send_alert: {
                            state: "",
                            sms:
                                Object.keys(rowData.empty_coin_alert).length > 0
                                    ? rowData.empty_coin_alert.alert_threshold
                                          .send_alert.sms
                                    : alertSent.includes("SMS"),
                            email:
                                Object.keys(rowData.empty_coin_alert).length > 0
                                    ? rowData.empty_coin_alert.alert_threshold
                                          .send_alert.email
                                    : alertSent.includes("E-mail"),
                        },
                        send_alert_format: {
                            state: "",
                            value: alertForm,
                        },
                    },
                    ticket_number_alert_threshold: {
                        state: isAlertNum,
                        ticket_number: ticketNumber,
                    },
                    send_alert: {
                        state: "",
                        sms:
                            Object.keys(rowData.empty_coin_alert).length > 0
                                ? rowData.empty_coin_alert.send_alert.sms
                                : alertSentTickets.includes("SMS"),
                        email:
                            Object.keys(rowData.empty_coin_alert).length > 0
                                ? rowData.empty_coin_alert.send_alert.email
                                : alertSentTickets.includes("E-mail"),
                    },
                    send_alert_format: {
                        state: "",
                        value: alertFormNum,
                    },
                },
            };
            try {
                mutate(
                    apiUrl,
                    await fetchWithToken(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                             
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error(
                                    `${t("Error while saving selection!")}`
                                );
                            }
                            resetEmptyAlertValue();
                            toast.success(
                                `${t("Registration successfully")}!`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                }
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${"There's an error"}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function handleEmptyObjectClickEvent() {
        let userId: number;
        let shopId: string;
        let franchiseId: string;
        if (oneShop) {
            franchiseId = franchiseID;
            shopId = shopID.toString();
            userId = userID;
        } else {
            userId = userID;
            shopId = rowData.shopId;
            franchiseId = franchiseID;
        }

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cashManagement/emptyAlert`;
        let savedData = {
            userId: userId,
            shopId: shopId,
            franchiseId: franchiseId,
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: {
                sending_frequency: {
                    when_maximum_threshold_reached: false,
                    after_closing_day_if_maximum_threshold_reached: false,
                },
                alert_threshold: {
                    denomination_alert_threshold: {
                        state: false,
                        value: {},
                    },
                    send_alert_denomination: {
                        state: "",
                        sms: true,
                        email: false,
                    },
                    send_alert_format_denomination: {
                        state: "XLS",
                        value: alertFormDenomination,
                    },
                    amount_alert_threshold: {
                        state: false,
                        amount: "",
                    },
                    send_alert: {
                        state: false,
                        sms: true,

                        email: false,
                    },
                    send_alert_format: {
                        state: false,
                        value: "XLS",
                    },
                },
                ticket_number_alert_threshold: {
                    state: false,
                    ticket_number: "",
                },
                send_alert: {
                    state: "",
                    sms: true,
                    email: false,
                },

                send_alert_format: {
                    state: false,
                    value: "XLS",
                },
            },
        };
        try {
            mutate(
                apiUrl,
                await fetchWithToken(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                         
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while saving selection!")}`
                            );
                        }
                    })
            );
        } catch (e: any) {}
    }

    React.useEffect(() => {
        if (
            !isSettingIconEmptyClicked &&
            Object.keys(rowData.empty_coin_alert).length > 0
        ) {
            defaultEmptyAlertValue(
                rowData.empty_coin_alert.alert_threshold
                    .denomination_alert_threshold.value
            );
        }
    }, [isSettingIconEmptyClicked, rowData]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "84.1%", fontSize: "17px" }}>
                <div className="d-flex align-items-center justify-content-between">
                    <b>{t("Sending frequency")}</b>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                    {t("Send SMS/Email when the maximum threshold is reached")}
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isThresholdReached}
                            onChange={(e: any) => {
                                if (
                                    Object.keys(rowData.empty_coin_alert)
                                        .length > 0
                                ) {
                                    rowData.empty_coin_alert.sending_frequency.when_maximum_threshold_reached = !rowData
                                        .empty_coin_alert.sending_frequency
                                        .when_maximum_threshold_reached;
                                    setIsThresholdReached(
                                        rowData.empty_coin_alert
                                            .sending_frequency
                                            .when_maximum_threshold_reached
                                    );
                                } else {
                                    setIsThresholdReached(!isThresholdReached);
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                    {t(
                        "Send SMS/E-mail after the end of the day if the maximum threshold is reached"
                    )}
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isThresholdDayReached}
                            onChange={(e: any) => {
                                if (
                                    Object.keys(rowData.empty_coin_alert)
                                        .length > 0
                                ) {
                                    rowData.empty_coin_alert.sending_frequency.after_closing_day_if_maximum_threshold_reached = !rowData
                                        .empty_coin_alert.sending_frequency
                                        .after_closing_day_if_maximum_threshold_reached;
                                    setIsThresholdDayReached(
                                        rowData.empty_coin_alert
                                            .sending_frequency
                                            .after_closing_day_if_maximum_threshold_reached
                                    );
                                } else {
                                    setIsThresholdDayReached(
                                        !isThresholdDayReached
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="border-footer__clz mt-3" />
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>
                        <b>{t("Alert threshold")}</b>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>{t("Enable Alert Threshold by Denomination")}</div>
                        <div className="pl-5">
                            <CustomMainColorIconButton
                                icon="SettingsIcon"
                                disabled={!isDenominationAlert}
                            >
                                <SettingsIcon
                                    height={20}
                                    width={20}
                                    onClick={() => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            setIsSettingIconEmptyClicked(true);
                                            defaultEmptyAlertValue(
                                                rowData.empty_coin_alert
                                                    .alert_threshold
                                                    .denomination_alert_threshold
                                                    .value
                                            );
                                        } else if (!isSettingIconEmptyClicked) {
                                            handleEmptyObjectClickEvent();
                                            setIsSettingIconEmptyClicked(true);
                                        } else {
                                            defaultEmptyAlertValue(
                                                emptyAlertValue
                                            );
                                        }
                                        setIsModalOpened(!isModalOpened);
                                    }}
                                />
                            </CustomMainColorIconButton>
                        </div>
                    </div>
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isDenominationAlert}
                            onChange={(e: any) => {
                                if (
                                    Object.keys(rowData.empty_coin_alert)
                                        .length > 0
                                ) {
                                    rowData.empty_coin_alert.alert_threshold.denomination_alert_threshold.state = !rowData
                                        .empty_coin_alert.alert_threshold
                                        .denomination_alert_threshold.state;
                                    setIsDenominationAlert(
                                        rowData.empty_coin_alert.alert_threshold
                                            .denomination_alert_threshold.state
                                    );
                                } else {
                                    setIsDenominationAlert(
                                        !isDenominationAlert
                                    );
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                        <div style={{ width: "315px" }}>
                            {t("Send alerts by")}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("SMS")}`}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .alert_threshold
                                                  .send_alert_denomination.sms
                                            : true
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_denomination.sms = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold
                                                    .send_alert_denomination
                                                    .sms;
                                            } else {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_denomination.sms = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold
                                                    .send_alert_denomination
                                                    .sms;
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSentDenomination([
                                                    ...alertSentDenomination,
                                                    "SMS",
                                                ]);
                                            } else {
                                                setAlertSentDenomination([
                                                    ...alertSentDenomination.filter(
                                                        (element: any) =>
                                                            element !== "SMS"
                                                    ),
                                                ]);
                                            }
                                        }
                                    }}
                                    disabled={!isDenominationAlert}
                                />
                                <label className="ml-3">{t("SMS")}</label>
                            </span>
                            <span className="ml-3 mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("E-mail")}`}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .alert_threshold
                                                  .send_alert_denomination.email
                                            : false
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_denomination.email = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold
                                                    .send_alert_denomination
                                                    .email;
                                                setIsEmailIncludeDenomination(
                                                    true
                                                );
                                            } else {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_denomination.email = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold
                                                    .send_alert_denomination
                                                    .email;
                                                setIsEmailIncludeDenomination(
                                                    false
                                                );
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSentDenomination([
                                                    ...alertSentDenomination,
                                                    "E-mail",
                                                ]);
                                                setIsEmailIncludeDenomination(
                                                    true
                                                );
                                            } else {
                                                setAlertSentDenomination([
                                                    ...alertSentDenomination.filter(
                                                        (element: any) =>
                                                            element !== "E-mail"
                                                    ),
                                                ]);
                                                setIsEmailIncludeDenomination(
                                                    false
                                                );
                                            }
                                        }
                                    }}
                                    disabled={!isDenominationAlert}
                                />
                                <label className="ml-3">{t("E-mail")}</label>
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginTop: "15px" }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="mr-5">{t("Send alerts in format")}</div>

                        <div className="d-flex align-items-center justify-content-between">
                            <AvForm
                                model={{
                                    form: alertFormDenomination,
                                    checkItOut: true,
                                }}
                            >
                                <AvRadioGroup
                                    name="form"
                                    required
                                    inline
                                    disabled={
                                        !isDenominationAlert ||
                                        !isEmailIncludeDenomination
                                    }
                                >
                                    <AvRadio
                                        id="xls"
                                        label={`${t("XLS")}`}
                                        value="XLS"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format_denomination.value =
                                                    "XLS";
                                                setAlertFormDenomination(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format_denomination
                                                        .value
                                                );
                                            } else {
                                                setAlertFormDenomination("XLS");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="twoRadio"
                                        label={`${t("Word")}`}
                                        name="radio"
                                        value="Word"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format_denomination.value =
                                                    "Word";
                                                setAlertFormDenomination(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format_denomination
                                                        .value
                                                );
                                            } else {
                                                setAlertFormDenomination(
                                                    "Word"
                                                );
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("XML")}`}
                                        name="radio"
                                        value="XML"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format_denomination.value =
                                                    "XML";
                                                setAlertFormDenomination(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format_denomination
                                                        .value
                                                );
                                            } else {
                                                setAlertFormDenomination("XML");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("Txt")}`}
                                        name="radio"
                                        value="Txt"
                                        checked={true}
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format_denomination.value =
                                                    "Txt";
                                                setAlertFormDenomination(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format_denomination
                                                        .value
                                                );
                                            } else {
                                                setAlertFormDenomination("Txt");
                                            }
                                        }}
                                    />
                                </AvRadioGroup>
                            </AvForm>
                        </div>
                    </div>
                </div>
                <div className="border-footer__clz mt-2" />
                <div className="d-flex align-items-center justify-content-between">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ height: "10px" }}
                    >
                        <div className="mr-5" style={{ marginTop: "5%" }}>
                            {t("Enable Alert Threshold by Amount")}
                        </div>
                        <div className="pl-5 mt-3" style={{ height: "36px" }}>
                            <AvForm>
                                <StyledTextInput
                                    disabled={!isPrice}
                                    id="example-input"
                                    name="example-input"
                                    placeholder="0,00"
                                    type="text"
                                    value={price}
                                    style={{
                                        width: "134px",
                                    }}
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            rowData.empty_coin_alert.alert_threshold.amount_alert_threshold.amount =
                                                e.target.value;
                                            setPrice(
                                                rowData.empty_coin_alert
                                                    .alert_threshold
                                                    .amount_alert_threshold
                                                    .amount
                                            );
                                        } else {
                                            setPrice(e.target.value);
                                        }
                                    }}
                                    validate={{
                                        pattern: {
                                            value: numberMatchWord,
                                            errorMessage: t(
                                                "Prohibited characters"
                                            ),
                                        },
                                    }}
                                    errorMessage={t("This field is invalid")}
                                />
                            </AvForm>
                        </div>
                    </div>
                    <div className="float-right mr-4 mt-1">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isPrice}
                            onChange={(e: any) => {
                                if (
                                    Object.keys(rowData.empty_coin_alert)
                                        .length > 0
                                ) {
                                    rowData.empty_coin_alert.alert_threshold.amount_alert_threshold.state = !rowData
                                        .empty_coin_alert.alert_threshold
                                        .amount_alert_threshold.state;
                                    setIsPrice(
                                        rowData.empty_coin_alert.alert_threshold
                                            .amount_alert_threshold.state
                                    );
                                } else {
                                    setIsPrice(!isPrice);
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginTop: "43px", height: "1px" }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div style={{ width: "315px" }}>
                            {t("Send alerts by")}
                        </div>
                        <div className="d-flex  align-items-center justify-content-between">
                            <span className="mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("SMS")}`}
                                    disabled={!isPrice}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .alert_threshold.send_alert
                                                  .sms
                                            : true
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert.sms = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold.send_alert
                                                    .sms;
                                            } else {
                                                rowData.empty_coin_alert.alert_threshold.send_alert.sms = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold.send_alert
                                                    .sms;
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSent([
                                                    ...alertSent,
                                                    "SMS",
                                                ]);
                                            } else {
                                                setAlertSent([
                                                    ...alertSent.filter(
                                                        (element: any) =>
                                                            element !== "SMS"
                                                    ),
                                                ]);
                                            }
                                        }
                                    }}
                                />
                                <label className="ml-3">{t("SMS")}</label>
                            </span>
                            <span className="ml-3 mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("E-mail")}`}
                                    disabled={!isPrice}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .alert_threshold.send_alert
                                                  .email
                                            : false
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert.email = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold.send_alert
                                                    .email;
                                                setIsEmailInclude(true);
                                            } else {
                                                rowData.empty_coin_alert.alert_threshold.send_alert.email = !rowData
                                                    .empty_coin_alert
                                                    .alert_threshold.send_alert
                                                    .email;
                                                setIsEmailInclude(false);
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSent([
                                                    ...alertSent,
                                                    "E-mail",
                                                ]);
                                                setIsEmailInclude(true);
                                            } else {
                                                setAlertSent([
                                                    ...alertSent.filter(
                                                        (element: any) =>
                                                            element !== "E-mail"
                                                    ),
                                                ]);
                                                setIsEmailInclude(false);
                                            }
                                        }
                                    }}
                                />
                                <label className="ml-3">{t("E-mail")}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginTop: "30px", height: "1px" }}
                >
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginTop: "56px" }}
                    >
                        <div className="mr-5">{t("Send alerts in format")}</div>

                        <div className="d-flex align-items-center justify-content-between">
                            <AvForm
                                model={{
                                    form: alertForm,
                                    checkItOut: true,
                                }}
                            >
                                <AvRadioGroup
                                    name="form"
                                    required
                                    inline
                                    disabled={!isPrice || !isEmailInclude}
                                >
                                    <AvRadio
                                        id="xls"
                                        label={`${t("XLS")}`}
                                        value="XLS"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format.value =
                                                    "XLS";
                                                setAlertForm(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertForm("XLS");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="twoRadio"
                                        label={`${t("Word")}`}
                                        name="radio"
                                        value="Word"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format.value =
                                                    "Word";
                                                setAlertForm(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertForm("Word");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("XML")}`}
                                        name="radio"
                                        value="XML"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format.value =
                                                    "XML";
                                                setAlertForm(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertForm("XML");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("Txt")}`}
                                        name="radio"
                                        value="Txt"
                                        checked={true}
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.alert_threshold.send_alert_format.value =
                                                    "Txt";
                                                setAlertForm(
                                                    rowData.empty_coin_alert
                                                        .alert_threshold
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertForm("Txt");
                                            }
                                        }}
                                    />
                                </AvRadioGroup>
                            </AvForm>
                        </div>
                    </div>
                </div>
                <div className="border-footer-empty__clz mt-5" />
                <div className="d-flex align-items-center justify-content-between">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ height: "10px", marginTop: "1%" }}
                    >
                        <div>
                            {t(
                                "Activate the alert threshold by the number of banknotes in the cassette"
                            )}
                        </div>
                        <div className="d-flex align-items-center pl-5">
                            <AvForm style={{ height: "36px" }}>
                                <StyledTextInput
                                    id="example-input1"
                                    name="example-input1"
                                    placeholder="0,00"
                                    type="text"
                                    value={ticketNumber}
                                    disabled={!isAlertNum}
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            rowData.empty_coin_alert.ticket_number_alert_threshold.ticket_number =
                                                e.target.value;
                                            setTicketNumber(
                                                rowData.empty_coin_alert
                                                    .ticket_number_alert_threshold
                                                    .ticket_number
                                            );
                                        } else {
                                            setTicketNumber(e.target.value);
                                        }
                                    }}
                                    style={{ width: "134px" }}
                                    validate={{
                                        pattern: {
                                            value: numberMatchWord,
                                            errorMessage: t(
                                                "Prohibited characters"
                                            ),
                                        },
                                    }}
                                    errorMessage={t("This field is invalid")}
                                    max="330"
                                />
                            </AvForm>
                            <span
                                className="ml-5"
                                style={{ marginTop: "4%" }}
                            >{`{330}`}</span>
                        </div>
                    </div>
                    <div className="float-right mr-4 mt-1">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isAlertNum}
                            onChange={(e: any) => {
                                if (
                                    Object.keys(rowData.empty_coin_alert)
                                        .length > 0
                                ) {
                                    rowData.empty_coin_alert.ticket_number_alert_threshold.state = !rowData
                                        .empty_coin_alert
                                        .ticket_number_alert_threshold.state;
                                    setIsAlertNum(
                                        rowData.empty_coin_alert
                                            .ticket_number_alert_threshold.state
                                    );
                                } else {
                                    setIsAlertNum(!isAlertNum);
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>

                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ marginTop: "30px", height: "25px" }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div style={{ width: "315px" }}>
                            {t("Send alerts by")}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("SMS")}`}
                                    disabled={!isAlertNum}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .send_alert.sms
                                            : true
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.send_alert.sms = !rowData
                                                    .empty_coin_alert.send_alert
                                                    .sms;
                                            } else {
                                                rowData.empty_coin_alert.send_alert.sms = !rowData
                                                    .empty_coin_alert.send_alert
                                                    .sms;
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSentTickets([
                                                    ...alertSentTickets,
                                                    "SMS",
                                                ]);
                                            } else {
                                                setAlertSentTickets([
                                                    ...alertSentTickets.filter(
                                                        (element: any) =>
                                                            element !== "SMS"
                                                    ),
                                                ]);
                                            }
                                        }
                                    }}
                                />
                                <label className="ml-3">{t("SMS")}</label>
                            </span>
                            <span className="ml-3 mt-2">
                                <input
                                    type="checkbox"
                                    id="example"
                                    value={`${t("E-mail")}`}
                                    disabled={!isAlertNum}
                                    defaultChecked={
                                        Object.keys(rowData.empty_coin_alert)
                                            .length > 0
                                            ? rowData.empty_coin_alert
                                                  .send_alert.email
                                            : false
                                    }
                                    onChange={(e: any) => {
                                        if (
                                            Object.keys(
                                                rowData.empty_coin_alert
                                            ).length > 0
                                        ) {
                                            if (e.target.checked) {
                                                rowData.empty_coin_alert.send_alert.email = !rowData
                                                    .empty_coin_alert.send_alert
                                                    .email;
                                                setIsEmailIncludeTickets(true);
                                            } else {
                                                rowData.empty_coin_alert.send_alert.email = !rowData
                                                    .empty_coin_alert.send_alert
                                                    .email;
                                                setIsEmailIncludeTickets(false);
                                            }
                                        } else {
                                            if (e.target.checked) {
                                                setAlertSentTickets([
                                                    ...alertSentTickets,
                                                    "E-mail",
                                                ]);
                                                setIsEmailIncludeTickets(true);
                                            } else {
                                                setAlertSentTickets([
                                                    ...alertSentTickets.filter(
                                                        (element: any) =>
                                                            element !== "E-mail"
                                                    ),
                                                ]);
                                                setIsEmailIncludeTickets(false);
                                            }
                                        }
                                    }}
                                />
                                <label className="ml-3">{t("E-mail")}</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ marginTop: "30px", height: "2px" }}
                    >
                        <div className="mr-5">{t("Send alerts in format")}</div>
                        <div className="d-flex align-items-center justify-content-between">
                            <AvForm
                                model={{
                                    formNum: alertFormNum,
                                    checkItOut: true,
                                }}
                            >
                                <AvRadioGroup
                                    name="formNum"
                                    required
                                    inline
                                    errorMessage="Pick one!"
                                    disabled={
                                        !isAlertNum || !isEmailIncludeTickets
                                    }
                                >
                                    <AvRadio
                                        id="xls"
                                        label={`${t("XLS")}`}
                                        value="XLS"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.send_alert_format.value =
                                                    "XLS";
                                                setAlertFormNum(
                                                    rowData.empty_coin_alert
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertFormNum("XLS");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="twoRadio"
                                        label={`${t("Word")}`}
                                        name="radio"
                                        value="Word"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.send_alert_format.value =
                                                    "Word";
                                                setAlertFormNum(
                                                    rowData.empty_coin_alert
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertFormNum("Word");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("XML")}`}
                                        name="radio"
                                        value="XML"
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.send_alert_format.value =
                                                    "XML";
                                                setAlertFormNum(
                                                    rowData.empty_coin_alert
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertFormNum("XML");
                                            }
                                        }}
                                    />

                                    <AvRadio
                                        id="threeRadio"
                                        label={`${t("Txt")}`}
                                        name="radio"
                                        value="Txt"
                                        checked={true}
                                        onChange={() => {
                                            if (
                                                Object.keys(
                                                    rowData.empty_coin_alert
                                                ).length > 0
                                            ) {
                                                rowData.empty_coin_alert.send_alert_format.value =
                                                    "Txt";
                                                setAlertFormNum(
                                                    rowData.empty_coin_alert
                                                        .send_alert_format.value
                                                );
                                            } else {
                                                setAlertFormNum("Txt");
                                            }
                                        }}
                                    />
                                </AvRadioGroup>
                            </AvForm>
                        </div>
                    </div>
                </div>
            </ModalBody>

            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                handleValidateButtonClickEvent={handleValidateButtonClickEvent}
                setDataIsUpdated={setDataIsUpdated}
            />
            <AlertDenominationModal
                rowData={rowData}
                oneShop={oneShop}
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                alert_type={ALERTTYPE}
            />
        </React.Fragment>
    );
}
