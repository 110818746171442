import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next/";

import { useSnapshot } from "valtio";
import PageTitle from "@components/Common/PageTitle";
import {
    ShieldExclamationIcon,
    StyledBreadcrumb,
} from "@aureskonnect/react-ui";
import { CARD_MENU_LIST } from "./CardMenuList";
import { getPathAuthorization } from "@components/VerticalLayout";
import CardMenu from "./CardMenu";
import {
    setIsDefault,
    store as userStore,
} from "@components/VerticalLayout/store";
import { ToastContainer, toast } from "react-toastify";
import { ModalDefaultSettingInformation } from "@components/Common/ModalDefaultSettingInformation";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function SiteCC() {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const { franchiseID, shopID, oneShop } = useSnapshot(userStore);
    React.useEffect(() => {
        // eslint-disable-next-line
        let array: any[] = [];
        fetchWithToken(
            `${process.env.REACT_APP_API_V2_URL}/settings/list/setting/default?franchiseId=${franchiseID}&shopId=${shopID}`
        )
            .then((value) => value.json())
            .then((result) => {
                for (let elt of result) {
                    if (
                        elt.saleMode === true &&
                        elt.PayementMethode === true &&
                        elt.vateRate === true
                    ) {
                        array.push(true);
                    } else {
                        array.push(false);
                    }
                }
                array = [...new Set(array)];

                setIsDefault(!array.includes(false));
                if (array.includes(false)) {
                    toast.error(
                        `${t(
                            "An issue has been detected with the C&C section. Please verify your general settings"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <ShieldExclamationIcon
                                    width={25}
                                    height={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
            });
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <PageTitle title={"C&C"} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className="page-content__clz"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Settings".toUpperCase(),
                            link: `${process.env.REACT_APP_PORTAIL_URL}`,
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: oneShop
                                ? "/setting/customer-account"
                                : "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("C&C").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />

                <div className="row p-4">
                    {CARD_MENU_LIST.map((cardMenu: any, index: any) => {
                        if (
                            getPathAuthorization(cardMenu.path).Visible === true
                        ) {
                            return (
                                <CardMenu
                                    key={index}
                                    title={t(cardMenu.title).toUpperCase()}
                                    Icon={cardMenu.Icon}
                                    description={t(cardMenu.description)}
                                    path={cardMenu.path}
                                    setIsModalOpened={setIsModalOpened}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </motion.div>
            {isModalOpened && (
                <ModalDefaultSettingInformation
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                />
            )}

            <ToastContainer limit={1} />
        </React.Fragment>
    );
}
