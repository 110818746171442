import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "../../../../../components/VerticalLayout/store";
import {
    setIsAssociateModalOpened,
    store as generalSettingStore,
    setIsValidateShopArchiveOpened,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomSideFilterType = {
    isShopSelected: boolean;
    selectedRows: any[];
    setDataIsUpdated: Function;
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setLocalFilterActive: Function;
    setIsArchivedExceptionalHoursClicked: Function;
    isArchivedExceptionalHoursClicked: boolean;
};

export function CustomSideFilter({
    isShopSelected,
    selectedRows,
    setDataIsUpdated,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setLocalFilterActive,
    setIsArchivedExceptionalHoursClicked,
    isArchivedExceptionalHoursClicked,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        numberMultiShopActive,
        numberMultiShopArchived,
        archivedExceptionalHoursFranchise,
        modify,
    } = useSnapshot(generalSettingStore);

    const [error, setError] = React.useState<boolean>(false);

    function ArchivedExceptionalHoursFranchise() {
        let idsAssociated: any[] = [];
        if (archivedExceptionalHoursFranchise !== undefined) {
            archivedExceptionalHoursFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === "") {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    async function archiveActiveExceptionalHours(isArchive: boolean) {
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            const id = el.Id;
            let hours: any[] = [];
            if (el.subRows !== undefined) {
                el.subRows.forEach((element: any) => {
                    hours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                    });
                });
                let dataExceptionalHours = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    isShop: true,
                    data: {
                        [id]: {
                            id: id,
                            designation: el[t("Designation")],
                            state: el.state,
                            startDate: el.startDate,
                            endDate: el.endDate,
                            hours: hours,
                            isArchived: isArchive,
                            shopId: el.shopId,
                            recurrence: el[t("Recurrence")],
                            endDate_recurrence: el.endDate_recurrence,
                            recurrenceDays: el.recurrenceDays,
                        },
                    },
                };

                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
                localData.push(
                   fetchWithToken(apiUrlAdd, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataExceptionalHours),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }
        if (error === false) {
            toast.success(
                `${t("The exceptional schedule was successfully archived")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className={`${classnames(
                            "hex_btn_BTassignExceptionalSchedule cmn_btn_BTassign w-100 ml-2",
                            {
                                "not-allowed-icon__clz":
                                    isArchivedExceptionalHoursClicked ||
                                    selectedRows.length === 0,
                            }
                        )}`}
                        rounded
                        disabled={isShopSelected ? false : true}
                        variant="primary"
                        onClick={() => {
                            setIsAssociateModalOpened(true);
                            setLocalFilterActive(false);
                        }}
                    >
                        {t("Assign an exceptional schedule")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_BTlistActivated pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedExceptionalHoursClicked(false);
                    }}
                >
                    {t("Enabled exceptional(s) schedule(s)")}(
                    {numberMultiShopActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_BTlistArchived pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedExceptionalHoursClicked(true);
                    }}
                >
                    {t("Archived exceptional(s) schedule(s)")}(
                    {numberMultiShopArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item cmn_btn_BThistory pl-1 ",
                        {
                            "not-allowed-icon__clz":
                                getArrayOfRows().length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (getArrayOfRows().length < 2) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item cmn_btn_BTarchived cmn_btn_BTunarchive pl-1 ",
                                {
                                    "not-allowed-icon__clz":
                                        getArrayOfRows().length === 0 ||
                                        selectedRows.length === 0 ||
                                        (isArchivedExceptionalHoursClicked &&
                                            getArrayOfRows().some(
                                                (element: any) =>
                                                    ArchivedExceptionalHoursFranchise().includes(
                                                        element.Id
                                                    )
                                            )),
                                }
                            )}`}
                            onClick={() => {
                                if (getArrayOfRows().length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    isArchivedExceptionalHoursClicked
                                        ? archiveActiveExceptionalHours(false)
                                        : setIsValidateShopArchiveOpened(true);
                                }
                            }}
                        >
                            {isArchivedExceptionalHoursClicked
                                ? t("Unarchive")
                                : t("Archive")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
