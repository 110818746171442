import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";

import CardFooterListShop from "./CardFooterListShop";

import "./index.css";
import { useKeycloak } from "@react-keycloak/web";

type ModalAssociateStoreType = {
    rowData: any;
    setIsModalAssociateStore: Function;
    isModalAssociateStore: boolean;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function ModalStore({
    rowData,
    setIsModalAssociateStore,
    isModalAssociateStore,
    setIsDataUpdated,
}: ModalAssociateStoreType) {
    const { keycloak } = useKeycloak();
    const { userID, franchiseID } = useSnapshot(userStore);
    const i18nextLng = localStorage.getItem("i18nextLng") || "";
    const uuidUser = localStorage.getItem("uuidUser");
     

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            <div className="style-table__clz">
                <DynamicTable
                    url={`${process.env.REACT_APP_API_V2_URL}/settings/shops?userId=${userID}&franchiseId=${franchiseID}&uuidUser=${uuidUser}`}
                    showGlobalFilter
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    elevationTable={1}
                    minHeight="530px"
                    maxHeight="570px"
                    canSelect
                    filterActive={filterActive}
                    setLocalFilterActive={setLocalFilterActive}
                    customSelect
                    setSelectedRows={setSelectedRows}
                />
            </div>
            <CardFooterListShop
                dataListShops={selectedRows}
                rowData={rowData}
                setIsModalAssociateStore={setIsModalAssociateStore}
                isModalAssociateStore={isModalAssociateStore}
                setIsDataUpdated={setIsDataUpdated}
            />
        </React.Fragment>
    );
}
