import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import storeMI, { setGlobalDataIsUpdated, setCanValidate } from "../store";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { getDifferenceOfSaleMethodItemsInInformationMode } from "./InformationForm";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsEdited: Function;
    setDataIsUpdated: Function;
    dataModeInformation: any;
    associated: any;
    setIsSendingTag: Function;
    dataTable?: any;
    setIsAddModeOfInformationClicked: Function;
    setLimit: Function;
};
export function elementsCommuns(arrayShop: any, arrayMI: any) {
    // Utilisation de la méthode filter pour obtenir les éléments communs
    var elementsCommuns = arrayMI.filter(function (element: any) {
        return arrayShop.includes(element);
    });

    return elementsCommuns;
}
export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    setIsEdited,
    setDataIsUpdated,
    dataModeInformation,
    associated,
    setIsSendingTag,
    dataTable,
    setIsAddModeOfInformationClicked,
    setLimit,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { shopsModeInformationData } = useSnapshot(storeMI);
    const [error] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    function getUnion(array1: any, array2: any) {
        let data: any = [];
        let max: any = array2.length > array1.length ? array2 : array1;
        let min: any = array2.length < array1.length ? array2 : array1;
        if (array1.length === array2.length) {
            min = array1;
            max = array2;
        }
        max.forEach((el: any) => {
            if (min.indexOf(el) !== -1) {
                data.push(el);
            }
        });
        return data;
    }
    async function getShopsRelations() {
        let data: any = [];
        let shops: any = dataTable?.othersData?.dataShops;
        for (let index = 0; index < shops.length; index++) {
            let id = shops[index].shopId;

            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            let apiUrl: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${id}&franchiseId=${franchiseID}`;
            let dataMOS: any = [];
            let dataSOS: any = [];
            try {
                mutate(
                    apiUrl,
                    await fetchWithToken(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            dataMOS = [];
                            if (data.data.length > 0) {
                                dataMOS = data.data.map((x: any) => x["Id"]);
                            }
                        })
                );
                apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${id}&franchiseId=${franchiseID}&type=activated`;

                mutate(
                    apiUrl,
                    await fetchWithToken(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            dataSOS = [];
                            if (data.data.length > 0) {
                                dataSOS = data.data.map((x: any) => x["Id"]);
                            }
                        })
                );
            } catch (e) {}
            data.push({
                supportOfSale: dataSOS,
                modeOfSale: dataMOS,
                shopId: id,
            });
        }
        return data;
    }

    async function canAssociate(
        shopId: string,
        MI: any,
        data: any,
        id_MI: string
    ) {
        let can: boolean = false;
        let shopInform: any = data.find((x: any) => x.shopId === shopId);

        let unionMOS: any = getUnion(
            shopInform.modeOfSale,
            MI.modeOfSale.map((x: any) => {
                return x.value;
            })
        );
        let unionSOS: any = getUnion(shopInform.supportOfSale, [
            MI.supportOfSale?.value,
        ]);
        if (unionMOS.length > 0 && unionSOS.length > 0) {
            can = true;
        }

        let MIdata: any = [];
        shopsModeInformationData.othersData.dataShops.forEach((e: any) => {
            if (e.shopId === shopId && e.subRows.length > 0) {
                e.subRows.forEach((ex: any) => {
                    if (ex.Id === id_MI) {
                        MIdata = ex;
                    }
                });
            }
        });
        shopsModeInformationData.othersData.dataShopsArchive.forEach(
            (e: any) => {
                if (e.shopId === shopId && e.subRows.length > 0) {
                    e.subRows.forEach((ex: any) => {
                        if (ex.Id === id_MI) {
                            MIdata = ex;
                        }
                    });
                }
            }
        );
        let originMI = shopsModeInformationData.othersData.dataShops
            .find((x: any) => {
                return x.shopId === shopId;
            })
            ?.subRows.find((i: any) => {
                return i.Id === id_MI;
            });
        unionMOS = MIdata[t("Sales method")].filter((x: any) => {
            return unionMOS.indexOf(x.value) !== -1;
        });

        let commMosIds = elementsCommuns(
            shopInform.modeOfSale,
            MI.modeOfSale.map((x: any) => {
                return x.value;
            })
        );
        let MOS = MI.modeOfSale.filter((x: any) => {
            return commMosIds.indexOf(x.value) !== -1;
        });

        return {
            can: can,
            mix:
                getUnion(originMI["tag"], MI["tag"]).length !==
                    MI["tag"].length ||
                originMI[t("Display name")].trim().toUpperCase() !==
                    MI["displayName"].trim().toUpperCase()
                    ? true
                    : false,
            // unionMOS:
            //     unionMOS.length === 0 ? MIdata[t("Sales method")] : unionMOS,
            unionMOS: MOS,
            unionSOS:
                unionSOS.length > 0
                    ? [MI.supportOfSale]
                    : [MIdata[t("Sales support")]],
        };
    }
    async function editModeOfInformation() {
        toast.dismiss();
        let dataConfig: any[] = [];
        let blockedAssignArray: boolean[] = [];
        let updatedKIOSKData: any = {};
        let dataShopRelation = await getShopsRelations();

        for (let index = 0; index < associated.length; index++) {
            const el = associated[index];
            if (el.shopId !== 0) {
                let canAssoc = await canAssociate(
                    el.shopId,
                    dataModeInformation.data[el.Id],
                    dataShopRelation,
                    el.Id
                );

                if (el.Id !== undefined) {
                    if (canAssoc.mix === true) {
                        blockedAssignArray.push(false);
                    }
                    if (canAssoc.can === false) {
                        blockedAssignArray.push(true);
                    }

                    dataConfig.push({
                        userId: userID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data:
                            canAssoc.can === true
                                ? {
                                      [el.Id]: {
                                          designation:
                                              dataModeInformation.data[el.Id]
                                                  .designation,
                                          idMI:
                                              dataModeInformation.data[el.Id]
                                                  ?.idMI,

                                          supportOfSale: canAssoc.unionSOS[0],
                                          salesSupportData: [
                                              canAssoc.unionSOS[0],
                                          ],
                                          modeOfSale: canAssoc.unionMOS,
                                          displayName:
                                              dataModeInformation.data[el.Id]
                                                  .displayName,
                                          tag:
                                              dataModeInformation.data[el.Id]
                                                  .tag,
                                          isArchived: el.isArchived,
                                          shopId: el.shopId,
                                          state: el.state,
                                          stateShop: true,
                                          advancedDisplayName:
                                              dataModeInformation.data[el.Id]
                                                  .advancedDisplayName,
                                      },
                                  }
                                : {},
                    });

                    if (
                        canAssoc.unionSOS[0].value ===
                        "3ef7df0f-6a8e-4470-8851-8978e0ba71c2"
                    ) {
                        let oldData = {
                            modeOfSale: el[t("Sales method")],
                            advancedDisplayName: el.advancedDisplayName,
                        };

                        updatedKIOSKData = getDifferenceOfSaleMethodItemsInInformationMode(
                            oldData,
                            {
                                modeOfSale: canAssoc.unionMOS,
                                advancedDisplayName:
                                    dataModeInformation.data[el.Id]
                                        .advancedDisplayName,
                            }
                        );
                    }
                }
            }
        }
        if (dataConfig.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                    await fetchWithToken(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                dataConfig,
                                updatedKIOSKData,
                            }),
                        }
                    ).then((response) => response.json())
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (error === false && blockedAssignArray.includes(false)) {
                toast.success(
                    `${t(
                        "The shop(s) mode of information has been successfully changed"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            if (blockedAssignArray.includes(true)) {
                setLimit(2);
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
            if (error === false && blockedAssignArray.length === 0) {
                toast.success(
                    `${t(
                        "The shop(s) mode of information has been successfully changed"
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }

            setIsSendingTag(true);
            setDataIsUpdated(true);
            setGlobalDataIsUpdated(true);
            setIsAddModeOfInformationClicked(false);
            setIsEdited(false);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setCanValidate(true);
                }}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setCanValidate(true);
                        setIsModalOpened(!isModalOpened);
                        setDataIsUpdated(true);
                        setIsAddModeOfInformationClicked(false);
                        setIsEdited(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setCanValidate(true);
                        setIsModalOpened(!isModalOpened);
                        editModeOfInformation();
                        setIsAddModeOfInformationClicked(false);
                        setIsEdited(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
