import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
import { Tooltip } from "@mui/material";

import { store } from "@components/VerticalLayout/store";
import {
    setIsAddExceptionalHoursClicked,
    store as generalSettingStore,
    setNumberActive,
    setNumberArchived,
    setIsValidateArchiveOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    dataIsUpdated: boolean | number;
    isArchivedExceptionalHoursClicked: boolean;
    setSelectedRows: Function;
    setIsModalOpened: Function;
    setIsModification: Function;
    isModalOpened: boolean;
    setIsConsult: Function;
};

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedExceptionalHoursClicked,
    dataIsUpdated,
    setIsModalOpened,
    setIsModification,
    isModalOpened,
    setIsConsult,
}: CustomIconsType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { userID, franchiseID, oneShop, operatorID } = useSnapshot(store);
    const {
        numberArchived,
        numberActive,
        shopsExceptionalHoursData,
        archivedExceptionalHoursFranchise,
        activeExceptionalHours,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(generalSettingStore);
    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    const momentRange = extendMoment(Moment);

    const handleCheckIfDatesOverLapped = () => {
        let isOverLapped = false;
        let localDate = momentRange.range(
            moment(e.selectedRow.startDate, "YYYY-MM-DD"),
            moment(e.selectedRow.endDate, "YYYY-MM-DD")
        );
        activeExceptionalHours.forEach((element: any) => {
            element.recurrenceList.forEach((elt: any) => {
                let recurrenceDate = momentRange.range(
                    moment(
                        moment(elt[t("Start date")], "DD/MM/YYYY").format(
                            "YYYY-MM-DD"
                        )
                    ),
                    moment(
                        moment(elt[t("End date")], "DD/MM/YYYY").format(
                            "YYYY-MM-DD"
                        )
                    )
                );
                if (
                    recurrenceDate.overlaps(localDate) ||
                    recurrenceDate.adjacent(localDate) ||
                    moment(e.selectedRow.startDate, "YYYY-MM-DD").isBetween(
                        moment(
                            moment(elt[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            ),
                            "YYYY-MM-DD"
                        ),
                        moment(
                            moment(elt[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            ),
                            "YYYY-MM-DD"
                        )
                    )
                ) {
                    isOverLapped = true;
                }
            });
        });
        return isOverLapped;
    };

    async function archiveActiveExceptionalHours(isArchive: boolean) {
        toast.dismiss();
        let isOverlap = false;
        if (isArchive === false) {
            isOverlap = handleCheckIfDatesOverLapped();
            if (isOverlap === true) {
                toast.warn(
                    t(
                        "Please note that activing is not possible. Overlap detected."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        }
        if (isOverlap === false) {
            let hours: any[] = [];
            e.selectedRow.subRows.forEach((element: any) => {
                hours.push({
                    startTime: element[t("Start hour")],
                    endTime: element[t("End hour")],
                    overLappedStart: false,
                    overLappedEnd: false,
                    inputEndTime: false,
                    inputStartTime: false,
                });
            });
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
            let id = e.selectedRow.Id;
            let data = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: oneShop ? true : false,
                data: {
                    [id]: {
                        id: id,
                        designation: e.selectedRow[t("Designation")],
                        state: e.selectedRow.state,
                        startDate: e.selectedRow.startDate,
                        endDate: e.selectedRow.endDate,
                        hours: hours,
                        isArchived: isArchive,
                        isActive: false,
                        shopId: e.selectedRow.shopId,
                        recurrence: e.selectedRow[t("Recurrence")],
                        endDate_recurrence: e.selectedRow.endDate_recurrence,
                        recurrenceDays: e.selectedRow.recurrenceDays,
                    },
                },
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    isArchive === true
                                        ? "The exceptional schedule was successfully archived"
                                        : "The exceptional schedule was successfully unarchive"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated(!dataIsUpdated);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function AssociateExceptionalHours() {
        let associated: any[] = [e.selectedRow];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.selectedRow.Id) {
                        associated.push(el);
                    }
                });
            });
        }
        return associated;
    }

    function ArchivedExceptionalHoursFranchise() {
        let idsAssociated: any[] = [];
        if (archivedExceptionalHoursFranchise !== undefined) {
            archivedExceptionalHoursFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedExceptionalHours() {
        let idsAssociated: any[] = [];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    if (!oneShop) {
        dataAssociate = AssociateExceptionalHours();
        dataArchive = ArchivedExceptionalHours();
    } else {
        dataArchive = ArchivedExceptionalHoursFranchise();
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        bottom: "5px",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        setIsConsult(true);
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsModification(true);
                                                setIsAddExceptionalHoursClicked(
                                                    true
                                                );
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                            });
                                        } else {
                                            setIsModification(true);
                                            setIsAddExceptionalHoursClicked(
                                                true
                                            );
                                            setRowData(e.selectedRow);
                                            setShopId(e.selectedRow.shopId);
                                        }
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            {!isArchivedExceptionalHoursClicked ? (
                                <Tooltip
                                    title={
                                        oneShop ||
                                        moment(
                                            e.selectedRow.startDate,
                                            "YYYY-MM-DD"
                                        ).isBefore(
                                            moment(
                                                moment().format("YYYY-MM-DD"),
                                                "YYYY-MM-DD"
                                            )
                                        )
                                            ? ""
                                            : t("Edit")
                                    }
                                >
                                    <div>
                                        <PencilIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsModification(true);
                                                        setIsAddExceptionalHoursClicked(
                                                            true
                                                        );
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                    });
                                                } else {
                                                    setIsModification(true);
                                                    setIsAddExceptionalHoursClicked(
                                                        true
                                                    );
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRedit ml-3",
                                                {
                                                    "not-allowed-icon__clz":
                                                        oneShop ||
                                                        moment(
                                                            e.selectedRow
                                                                .startDate,
                                                            "YYYY-MM-DD"
                                                        ).isBefore(
                                                            moment(
                                                                moment().format(
                                                                    "YYYY-MM-DD"
                                                                ),
                                                                "YYYY-MM-DD"
                                                            )
                                                        ),
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : null}
                            {!isArchivedExceptionalHoursClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsAddExceptionalHoursClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        if (
                                                            dataAssociate.length >
                                                            1
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsValidateArchiveOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddExceptionalHoursClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.length > 1
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsValidateArchiveOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        oneShop &&
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            onClick={() => {
                                                setIsModification(false);
                                                setRowData(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else {
                                                    archiveActiveExceptionalHours(
                                                        false
                                                    );
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive ml-3",
                                                {
                                                    "not-allowed-icon__clz":
                                                        oneShop &&
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ),
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsModification(false);
                                            setIsAddExceptionalHoursClicked(
                                                true
                                            );
                                            setSelectedRows([e.selectedRow]);
                                            setRowData(e.selectedRow);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        });
                                    } else {
                                        setIsModification(false);
                                        setSelectedRows([e.selectedRow]);
                                        setRowData(e.selectedRow);
                                        setIsAddExceptionalHoursClicked(true);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    {
                                        "not-allowed-icon__clz":
                                            oneShop &&
                                            dataArchive.includes(
                                                e.selectedRow.Id
                                            ),
                                    },
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
