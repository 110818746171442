import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setDesignCategories,
    setIsOrderTakingCategoryTitleActive,
    setPageOrderTaking,
} from "@store";

import "./index.css";

export function Menu(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
        isOrderTakingCategoryTitleActive,
        designCategories,
    } = useSnapshot(generalConfigStore);

    const [isDesignCategoryAuto, setIsDesignCategoryAuto] = React.useState(
        designCategories === "auto" ? true : false
    );
    const [isFocused, setIsFocused] = React.useState(false);

    const handleChange = (e: any): void => {
        const newValue = e.target.value;

        if (newValue >= 1 && newValue <= 12) {
            setDesignCategories(Number(newValue));
            setPageOrderTaking({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .orderTakingProduct,
                designCategories: Number(newValue),
            });
        } else if (Number(newValue) < 1) {
            setDesignCategories(1);
            setPageOrderTaking({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .orderTakingProduct,
                designCategories: 1,
            });
        } else {
            setDesignCategories(12);
            setPageOrderTaking({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .orderTakingProduct,
                designCategories: 12,
            });
        }
    };

    const handleFocus = (): void => {
        setIsFocused(true);
    };

    const handleBlur = (): void => {
        setIsFocused(false);
    };
    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE", height: "93%" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Menu")}
            </div>
            <div className="p-4">
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>
                        {t("Category titles")}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {
                                (template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct
                                    .categoryTitle
                            }
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingCategoryTitleActive}
                        onChange={() => {
                            setIsOrderTakingCategoryTitleActive(
                                !isOrderTakingCategoryTitleActive
                            );
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                categoryTitle: !isOrderTakingCategoryTitleActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-4">
                    <div>{t("Number of categories to display")}</div>
                    <div className="py-3 d-flex flex-rows justify-content-between align-items-center">
                        <div style={{ marginLeft: "100px" }}>{t("Auto")}</div>

                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isDesignCategoryAuto}
                            onChange={() => {
                                setIsDesignCategoryAuto(!isDesignCategoryAuto);
                                if (!isDesignCategoryAuto === true) {
                                    setDesignCategories("auto" as string);
                                    setPageOrderTaking({
                                        ...(template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .orderTakingProduct,
                                        designCategories: "auto" as string,
                                    });
                                } else {
                                    setDesignCategories(7);
                                    setPageOrderTaking({
                                        ...(template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .orderTakingProduct,
                                        designCategories: 7,
                                    });
                                }
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="py-3 d-flex flex-rows justify-content-between align-items-center">
                        <div
                            style={{
                                marginLeft: "100px",
                            }}
                        >
                            {t("Manually")}{" "}
                            <span
                                style={{
                                    color:
                                        isDesignCategoryAuto === false
                                            ? "#B7B7B7"
                                            : "unset",
                                }}
                            >
                                {isDesignCategoryAuto === false
                                    ? " (Minimum 1 : Maximum 12) "
                                    : null}
                            </span>
                        </div>

                        <input
                            className={` form-control input-container ${
                                isFocused ? "focused" : ""
                            }`}
                            type="number"
                            value={
                                designCategories !== "auto"
                                    ? (designCategories as number)
                                    : 7
                            }
                            onChange={handleChange}
                            style={{ width: "60px" }}
                            max={12}
                            min={1}
                            disabled={
                                isDesignCategoryAuto === true ? true : false
                            }
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
