import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as services,
    setIsHistoryModalOpened,
    setSevicesData,
    setLocalShop,
    setIdsLocalShop,
    setSelectedServices,
    setDesignationService,
    setListFranchiseArchived,
    setDataFranchiseService,
    setDataListDateService,
    setNumberActive,
    setNumberArchived,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "../store";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import { ConfirmationActiveService } from "./ConfirmationActiveService";
import ServiceForm from "./ServiceForm";
import { ConfirmationModal } from "./ConfirmationModal";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { useKeycloak } from "@react-keycloak/web";

type servicesFranchisesType = {
    isServicesArchived: boolean;
    setIsServicesArchived: Function;
};

export default function ServiceFranchise({
    isServicesArchived,
    setIsServicesArchived,
}: servicesFranchisesType) {
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        store
    );
    const {
        isHistoryModalOpened,
        isConfirmationModal,
        isHistoryShopsModalOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(services);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isAddServiceClicked, setIsAddServiceClicked] = React.useState<
        boolean
    >(false);
    const [data, setData] = React.useState<any>({});
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isAddNewLogoButtonClicked, setIsAddNewLogoButtonClicked] = useState(
        false
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [, setIsShopSelected] = React.useState<boolean>(false);

    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);

    const [service, setService] = useState<any>({});
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/services?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/services?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: t("Tag"),
            customJsx: (e: any) => {
                return (
                    <span className="preview-text">{`${e.selectedRow?.tag}`}</span>
                );
            },
            filterName: "tag",
            canFilter: true,
        },
        {
            indexOFColumn: 5,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    selectedRowData={e}
                    userData={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                    }}
                    setShopId={setShopId}
                    setSelectedRows={setSelectedRows}
                    setService={setService}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    isAddServiceClicked={isAddServiceClicked}
                    setIsAddServiceClicked={setIsAddServiceClicked}
                    setLocalFilterActive={setLocalFilterActive}
                    dataArchived={
                        data?.othersData?.archivedFranchise !== undefined
                            ? data?.othersData?.archivedFranchise
                            : []
                    }
                    isServicesArchived={isServicesArchived}
                    selectedRows={selectedRows}
                    setIsConsult={setIsConsult}
                />
            ),
            disableFilter: false,
        },
    ];

    React.useEffect(() => {
        if (filterActive) setIsAddServiceClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        if (!isHistoryShopsModalOpened) {
            setDataIsUpdated(true);
        }
    }, [isHistoryShopsModalOpened]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        Object.values(selectedRows).forEach((el: any) => {
            if (el.Boutique !== "" && el.Boutique !== undefined)
                localShop.push(el.shopId);
            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
            }
        });
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });
        setSelectedServices(array);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archived : 0
        );
    }, [selectedRows, data]);

    useEffect(() => {
        setSevicesData(data.data !== undefined ? data.data : []);

        setDataFranchiseService(
            data?.othersData?.allData !== undefined
                ? data?.othersData?.allData?.data
                : []
        );
        setDesignationService(
            data?.othersData !== undefined
                ? data?.othersData?.dataDesignation
                : []
        );
        setListFranchiseArchived(
            data?.othersData?.allData !== undefined
                ? data?.othersData?.allData.othersData.listArchived
                : []
        );
        setDataListDateService(
            data?.othersData?.allData !== undefined
                ? data?.othersData?.allData.othersData.listDateServices
                : []
        );
    }, [data]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddServiceClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isServicesArchived ? urlArchive : urlActive}
                    canSelect
                    setData={setData}
                    customSelect
                    canResize
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setService={setService}
                            setShopId={setShopId}
                            selectedRows={selectedRows}
                            service={service}
                            isEdited={isEdited}
                            setDataIsUpdated={setDataIsUpdated}
                            setIsModalConfirmationOpened={
                                setIsModalConfirmationOpened
                            }
                            isModalConfirmationOpened={
                                isModalConfirmationOpened
                            }
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            isAddServiceClicked={isAddServiceClicked}
                            setIsAddServiceClicked={setIsAddServiceClicked}
                            dataArchived={
                                data?.othersData?.archivedFranchise !==
                                undefined
                                    ? data?.othersData?.archivedFranchise
                                    : []
                            }
                            setIsServicesArchived={setIsServicesArchived}
                            isServicesArchived={isServicesArchived}
                        />
                    }
                    showGlobalFilter
                    showFilter
                    actionColumn={ActionColumn}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="Service"
                />
                {isAddServiceClicked ? (
                    <ServiceForm
                        allData={
                            data.othersData.activedFranchise !== undefined
                                ? data.othersData.activedFranchise
                                : []
                        }
                        isAddServiceClicked={isAddServiceClicked}
                        setIsAddServiceClicked={setIsAddServiceClicked}
                        setDataIsUpdated={setDataIsUpdated}
                        rowData={service}
                        setDataService={setService}
                        setLocalFilterActive={setLocalFilterActive}
                        isAddNewLogoButtonClicked={isAddNewLogoButtonClicked}
                        setIsConsult={setIsConsult}
                        isConsult={isConsult}
                    />
                ) : null}
            </div>
            {isConfirmationModal ? <ConfirmationModal /> : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            <ConfirmationActiveService
                setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                isModalConfirmationOpened={isModalConfirmationOpened}
                setDataIsUpdated={setDataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={true}
            />
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewLogoButtonClicked}
                    isClicked={isAddNewLogoButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
