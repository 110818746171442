import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";

import { store as FiscalYear } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedRowsFiscalYear,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export function CustomIcons({
    e,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isFiscalYearArchived,
    selectedRows,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        fiscalYearData,
        shopsFiscalYearData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        FiscalYear
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archiveFiscalYear();
    }
    function archiveFiscalYear() {
        let idsAssociated: any = [];
        if (fiscalYearData.data !== undefined) {
            fiscalYearData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeFiscalYear() {
        toast.dismiss();
        let dataModeOfSale = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: {
                [e.selectedRow.Id]: {
                    first_day: e.selectedRow.first_day,
                    first_month: e.selectedRow.first_month,
                    end_day: e.selectedRow.end_day,
                    end_month: e.selectedRow.end_month,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fiscal year has been activated successfully"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            {!isFiscalYearArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsArchiveModalOpened(true);
                                                setSelectedRowsFiscalYear([]);
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className="ml-2 cmn_icn_BTarchived"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    shopsFiscalYearData.othersData.allFiscalYear.filter(
                                                        (element: any) =>
                                                            element.shopId ===
                                                            e.selectedRow.shopId
                                                    )[0].subRows.length !== 0
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention, a shop cannot have more than one fiscal year"
                                                        )
                                                    );
                                                } else {
                                                    activeFiscalYear();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop +
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop -
                                                            1
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setRowData(e.selectedRow);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
