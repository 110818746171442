import React from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";

import { setIsMobile, store } from "./store";

import Header from "./Header";
import SidebarContent, { menuItems } from "./SidebarContent";
import Footer from "./Footer";

import NotAuthorizationPage from "./NotAuthorizationPage";
import { getAuthorizationStates } from "./StyledSidebarMenu";

import "./layout.css";

function getIdFromMenuItems(path: string) {
    let find = { id: "" };
    try {
        let menu = menuItems(() => {});
        const searchTree = (fn: any): any => (obj: any) =>
            Array.isArray(obj)
                ? obj.length === 0
                    ? null
                    : searchTree(fn)(obj[0]) || searchTree(fn)(obj.slice(1))
                : fn(
                      obj,
                      () => searchTree(fn)(obj?.subMenu || []),
                      () => obj
                  );

        const findId = (target: any, obj: any) =>
            searchTree((node: any, next: any, found: any) =>
                node.link === target ? found() : next()
            )(menu);
        find = findId(path, menu);
        if (
            path === "/compte" ||
            path === "" ||
            path === "/test-auto" ||
            path === "/"
        ) {
            find = { id: "idCompte" };
        }
    } catch (error) {
        find = { id: "" };
    }

    return find;
}
export function getPathAuthorization(path: string) {
    let jsonPath = getIdFromMenuItems(path);
    let auto = getAuthorizationStates(jsonPath?.id);
    return auto;
}

export default function VerticalLayout() {
    const location = useLocation();
    let matchHomeRoute = "/home" === location.pathname;
    let matchLoginRoute = "/login" === location.pathname;
    let matchCompteRoute = "/compte" === location.pathname;
    let matchTestAutoRoute = "/test-auto" === location.pathname;
    let matchProjectRoute = "/project" === location.pathname;
    let matchApplicationRoute =
        "/kiosk-peripheral-setting" === location.pathname;
    const { collapsed, isMobileBurgerButtonClicked } = useSnapshot(store);

    const handleResize = () => {
        if (window.innerWidth <= 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    // create an event listener
    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    function OutletAuthorization({ path }: any) {
        let auto = getPathAuthorization(path);
        return auto?.Consult === true ? (
            <Outlet />
        ) : path === "/test-auto" ? (
            <Outlet />
        ) : (
            <NotAuthorizationPage />
        );
    }
    return (
        <React.Fragment>
            <div
                className={classnames("vertical-menu__clz", {
                    "d-none":
                        matchHomeRoute ||
                        matchLoginRoute ||
                        matchCompteRoute ||
                        matchTestAutoRoute ||
                        (collapsed === true &&
                            isMobileBurgerButtonClicked === true),
                })}
            >
                <SidebarContent />
            </div>
            <div
                className={classnames({
                    "d-none": matchHomeRoute || matchLoginRoute,
                })}
            >
                <Header />
            </div>
            {collapsed && isMobileBurgerButtonClicked ? (
                <div
                    className="ml-2 main-content"
                    style={{ minHeight: "calc(100vh - 67.5px)" }}
                >
                    <OutletAuthorization path={location.pathname} />
                </div>
            ) : collapsed ? (
                <div
                    className="main-content"
                    style={{
                        minHeight: "calc(100vh - 67.5px)",
                        backgroundColor: "#f3f3f9",
                        marginLeft: "4rem",
                    }}
                >
                    <OutletAuthorization path={location.pathname} />
                </div>
            ) : (
                <div
                    className={
                        matchHomeRoute || matchCompteRoute || matchTestAutoRoute
                            ? "ml-0 main-content"
                            : "main-content__clz"
                    }
                    style={{
                        minHeight: "calc(100vh - 67.5px)",
                        backgroundColor: "#f3f3f9",
                    }}
                >
                    <OutletAuthorization path={location.pathname} />
                </div>
            )}
            <Footer />
            {matchProjectRoute || matchApplicationRoute ? (
                <ToastContainer limit={1} />
            ) : null}
        </React.Fragment>
    );
}
