import React, { useEffect } from "react";
import { StyledH2, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";

import { NeptingConfiguration } from "./NeptingConfiguration";
import { ValinaConfiguration } from "./ValinaConfiguration";
import { capitalize } from "@helpers/general";

import "react-toastify/dist/ReactToastify.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type PeripheralsModalConfigurationsType = {
    setIsMoneticsPathsModalOpened: Function;
    isMoneticsPathsModalOpened: boolean;
    data: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataM: any;
};

export function PeripheralsConfigurations({
    setIsMoneticsPathsModalOpened,
    isMoneticsPathsModalOpened,
    data,
    dataM,
    setDataIsUpdated,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const [identifier, setIdentifier] = React.useState<string>("");
    const [nomTpe, setNomTpe] = React.useState<string>("");
    const [mark, setMark] = React.useState<string>("");
    const [ipAddress, setIpAddress] = React.useState<string>("");
    const [merchantCode, setMerchantCode] = React.useState<string>("");
    const [remark, setRemark] = React.useState<string>("");
    const [paymentType, setPaymentType] = React.useState<string>("");
    const [deviceCode, setDeviceCode] = React.useState<string>("");
    const [speed, setSpeed] = React.useState<number | string>("");
    const [designation, setDesignation] = React.useState<string>("");
    const [reference, setReference] = React.useState<string>("");
    const [kiss, setKiss] = React.useState<boolean>(false);
    const [ipExist, setIpExist] = React.useState<boolean>(false);
    const [url, setUrl] = React.useState<string>("");
    const [port, setPort] = React.useState<string>("");
    const [typeConfig, setTypeConfig] = React.useState<string>("");
    function addConfiguration() {
        toast.dismiss();

        let dataConfig = {
            userId: dataM.userId,
            shopId: dataM.shopId,
            franchiseId: dataM.franchiseId,
            data: [
                {
                    id: identifier,
                    designation: data.Désignation,
                    tpe_name: nomTpe,
                    reference: data.Référence,
                    note: remark,
                    mark: mark,
                    merchant_code: merchantCode,
                    shopId: dataM.shopId,
                    device_code: deviceCode,
                    port: port,
                    url: url,
                    kiss: kiss,
                    speed: speed,
                    setup_type: typeConfig,
                    ip: ipAddress,
                    payment_type: paymentType,
                    Mark: data.Marque,
                },
            ],
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        fetchWithToken(
            `${process.env.REACT_APP_API_V2_URL}/settings/monetics/configuration`,
            requestOptions
        )
            .then(async () => {
                toast.success(t("The device is configured successfully"), {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                });
                setIsMoneticsPathsModalOpened(false);
                setDataIsUpdated!(true);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setIdentifier(data.Id);
            setNomTpe(data.name_tpe);
            setIpAddress(data.ip);
            setMerchantCode(data.merchant_code);
            setRemark(data.Remarque);
            setMark(data.mark);
            setPort(data.port);
            setPaymentType(data.payment_type);
            setDeviceCode(data.device_code);
            setTypeConfig(data.setup_type);
            setUrl(data.url);
            setSpeed(data.speed);
            setMark(data.Marque);
            setDesignation(data.Désignation);
            setReference(data.Référence);
            setKiss(data.kiss);
        }
    }, [data]);

    return (
        <React.Fragment>
            <AvForm>
                <Modal size="xl" centered isOpen={isMoneticsPathsModalOpened}>
                    <ModalHeader
                        toggle={() => {
                            setIsMoneticsPathsModalOpened!(
                                !isMoneticsPathsModalOpened
                            );
                            setDataIsUpdated!(true);
                        }}
                    >
                        <StyledH2>
                            {capitalize(t("Configure a device"))}
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        <fieldset className="border p-2">
                            <legend className="w-auto px-2">
                                <StyledLabel htmlFor="text">
                                    {t("Device declared")}
                                </StyledLabel>
                            </legend>

                            <div className="d-flex">
                                <Col xs="12" sm="6">
                                    <StyledLabel htmlFor="example-input">
                                        {t("Designation")}
                                    </StyledLabel>
                                    <AvField
                                        style={{ backgroundColor: "#f1f1f1" }}
                                        id="Designation"
                                        disabled
                                        value={designation}
                                        name="Designation"
                                        type="text"
                                    />
                                </Col>
                                <Col xs="12" sm="6">
                                    <StyledLabel htmlFor="example-input">
                                        {t("Marque")}
                                    </StyledLabel>
                                    <AvField
                                        id="Mark"
                                        disabled
                                        value={mark}
                                        name="Designation"
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col>
                            </div>

                            <div className="d-flex">
                                <Col xs="12" sm="6">
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Reference")}
                                    </StyledLabel>
                                    <AvField
                                        id="reference"
                                        disabled
                                        value={reference}
                                        name="reference"
                                        placeholder={t("Write")}
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col>
                                <Col xs="12" sm="6">
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Remark")}
                                    </StyledLabel>
                                    <AvField
                                        id="remark"
                                        disabled
                                        value={remark}
                                        name="remark"
                                        placeholder={t("Write")}
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col>
                            </div>
                        </fieldset>
                        <fieldset className="border p-2">
                            <legend className="w-auto px-2">
                                <StyledLabel htmlFor="text">
                                    {t("Please complete the following")}
                                </StyledLabel>
                            </legend>
                            {mark === "Nepting" ? (
                                <NeptingConfiguration
                                    data={data}
                                    url={url}
                                    addConfiguration={addConfiguration}
                                    port={port}
                                    typeConfig={typeConfig}
                                    paymentType={paymentType}
                                    merchantCode={merchantCode}
                                    ipAddress={ipAddress}
                                    setUrl={setUrl}
                                    setPort={setPort}
                                    setTypeConfig={setTypeConfig}
                                    setPaymentType={setPaymentType}
                                    setMerchantCode={setMerchantCode}
                                    setIpAddress={setIpAddress}
                                    setIpExist={setIpExist}
                                    ipExist={ipExist}
                                    setIsMoneticsPathsModalOpened={
                                        setIsMoneticsPathsModalOpened
                                    }
                                    isMoneticsPathsModalOpened={
                                        isMoneticsPathsModalOpened
                                    }
                                    setDataIsUpdated={setDataIsUpdated}
                                />
                            ) : (
                                <ValinaConfiguration
                                    port={port}
                                    speed={speed}
                                    kiss={kiss}
                                    setPort={setPort}
                                    setSpeed={setSpeed}
                                    setKiss={setKiss}
                                    addConfiguration={addConfiguration}
                                    isMoneticsPathsModalOpened={
                                        isMoneticsPathsModalOpened
                                    }
                                    setIsMoneticsPathsModalOpened={
                                        setIsMoneticsPathsModalOpened
                                    }
                                    setDataIsUpdated={setDataIsUpdated}
                                />
                            )}
                        </fieldset>
                    </ModalBody>
                </Modal>
            </AvForm>
        </React.Fragment>
    );
}
