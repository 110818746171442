import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    projectStore,
    setIsProjectModesModalOpened,
    setIsModalOpened,
} from "@store";
import { store } from "@store";
import {
    setIsConsult,
    setIsModalOpenedSimpleProject,
} from "../../../store/project";

import { ModalWrapper } from "@components/Common/ModalWrapper";
import { ModalWrapperSimple } from "@components/Common/ModalWrapperSimple";
import { ModalContentWrapper } from "@components/ModalContentWrapper";
import { ModalContentWrapperConfigure } from "@components/Common/ModalContentWrapperConfigure";
import { capitalize } from "@helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type PropsType = {
    rowData?: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setIsCreatedProject: Function;
    setSelectedRows: Function;
};

export function ModesModal({
    rowData,
    setIsDataUpdated,
    isDataUpdated,
    setIsCreatedProject,
    setSelectedRows,
}: PropsType) {
    const { t } = useTranslation();
    const { isModesModalOpened } = useSnapshot(projectStore);
    const { mainColor } = useSnapshot(store);
    const handleKeyDown = (event: any) => {
        // Check if the pressed key is the "Escape" key
        if (event.key === "Escape") {
            setIsProjectModesModalOpened(false);
        }
    };
    window.addEventListener("keydown", handleKeyDown);

    return (
        <React.Fragment>
            <StyledModal
                toggle={setIsProjectModesModalOpened}
                isOpen={isModesModalOpened}
                centered
                backdrop="static"
            >
                <ModalHeader
                    className="text-uppercase"
                    toggle={() => {
                        setIsProjectModesModalOpened(!isModesModalOpened);
                        setIsConsult(false);
                        setSelectedRows([]);
                    }}
                    tag="div"
                >
                    <StyledH2>
                        {capitalize(t("Configure the template 1"))}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t("Do you want to configure your template in")}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <StyledButton
                        outline
                        variant={mainColor}
                        className="Cgt_btn_modeExpert"
                        onClick={() => {
                            setIsModalOpened(true);
                            setIsConsult(false);
                        }}
                        rounded
                    >
                        {t("Expert mode")}
                    </StyledButton>
                    <CustomMainColorButton
                        rounded
                        disabled={true}
                        className="mr-2 Cgt_btn_modeSimple"
                        variant="primary"
                        onClick={() => {
                            setIsModalOpenedSimpleProject(true);
                            setIsConsult(false);
                        }}
                    >
                        {t("Simple mode")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>

            <ModalWrapper
                title={t("Expert mode settings")}
                rowData={rowData}
                setIsDataUpdated={setIsDataUpdated}
                isDataUpdated={isDataUpdated}
                setIsCreatedProject={setIsCreatedProject}
                setSelectedRows={setSelectedRows}
            >
                <ModalContentWrapper />
            </ModalWrapper>

            <ModalWrapperSimple
                title={t("Simple mode")}
                rowData={rowData}
                setIsDataUpdated={setIsDataUpdated}
                isDataUpdated={isDataUpdated}
                setIsCreatedProject={setIsCreatedProject}
            >
                <ModalContentWrapperConfigure />
            </ModalWrapperSimple>
        </React.Fragment>
    );
}
