import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../../components/VerticalLayout/store";
import {
    store as hoursStore,
    setAssociatedHours,
    setIdsLocalShop,
    setLocalShop,
    setShopDataIsUpdated,
    setShopRowData,
    setIsHistoryShopsModalOpened,
    setSelectedHours,
    setShopsHoursSettingData,
} from "../../store";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { CustomIcons } from "./CustomIcons";
import HoursSettingModal from "./HoursSettingModal";
import { CustomSideFilter } from "./CustomSideFilter";
import { HoursAssociateModal } from "./HoursAssociateModal";
import HistoryModal from "../HistoryModal";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";

import { useKeycloak } from "@react-keycloak/web";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function HoursShop() {
    const { keycloak } = useKeycloak();
    
    const { oneShop, userID, franchiseID, operatorID } = useSnapshot(store);
    const { shopDataIsUpdated, isHistoryShopsModalOpened } = useSnapshot(
        hoursStore
    );
    const { isAssociateModalOpened } = useSnapshot(hoursStore);
    const { t } = useTranslation();

    const [
        isAddNewModeOfSaleButtonClicked,
        setIsAddNewModeOfSaleButtonClicked,
    ] = useState(false);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [daysData, setDaysData] = React.useState<any[]>([]);
    const [isConfigureHoursClicked, setIsConfigureHoursClicked] = useState(
        false
    );
    const [isAllHoursEqual, setIsAllHoursEqual] = React.useState<boolean>(
        false
    );
    const [daysDesignations, setDaysDesignations] = React.useState<any>([]);
    const [shopsHoursData, setShopsHoursData] = React.useState<any[]>([]);
    const uuidUser = localStorage.getItem("uuidUser");
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour?userId=${userID}&franchiseId=${franchiseID}&type=activated&operatorId=${operatorID}&uuidUser=${uuidUser}`;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Day of week"),
            customJsx: (e: any) => (
                <div>{t(e.selectedRow[t("Day of week")])}</div>
            ),
        },
        {
            indexOFColumn: 3,
            columnName: t("State"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("State")] === true
                        ? t("Opened")
                        : e.selectedRow[t("State")] === false
                        ? t("Closed")
                        : ""}
                </div>
            ),
        },
        {
            indexOFColumn: 6,
            columnName: t("Status"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("Status")] === true
                        ? t("Opened")
                        : e.selectedRow[t("Status")] === false
                        ? t("Closed")
                        : ""}
                </div>
            ),
        },
        {
            indexOFColumn: 8,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setSelectedRows={setSelectedRows}
                    setIsConfigureHoursClicked={setIsConfigureHoursClicked}
                    setDaysData={setDaysData}
                    setShopsHoursData={setShopsHoursData}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (filterActive) {
            setIsConfigureHoursClicked(false);
        }
    }, [filterActive]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        let associatedHour: any[] = [];
        let array: any = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Day of week")] === "") {
                localShop.push(el.shopId);
                for (let i in el.subRows) {
                    idsLocalShop.push(el.subRows[i].Id);
                    associatedHour.push(el.subRows[i]);
                    array.push({ [el.subRows[i].shopId]: el.subRows[i].Id });
                }
            } else {
                array.push({ [el.shopId]: el.Id });
            }
        });
        setAssociatedHours(associatedHour);
        setShopRowData(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);

        setSelectedHours(removeArrayOfObjectsDuplicates(array));

        setShopsHoursSettingData(dataTable.data);
        // eslint-disable-next-line
    }, [selectedRows, dataTable.data, t]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                    canExpand
                    canSelect
                    setData={setDataTable}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            selectedRows={selectedRows}
                            setDataIsUpdated={setShopDataIsUpdated}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsModalOpened={setIsConfigureHoursClicked}
                            isModalOpened={isConfigureHoursClicked}
                            setDaysDesignations={setDaysDesignations}
                            isAllHoursEqual={isAllHoursEqual}
                            setIsAllHoursEqual={setIsAllHoursEqual}
                            setSelectedRows={setSelectedRows}
                            setShopsHoursData={setShopsHoursData}
                            daysData={selectedRows}
                            setDaysData={setDaysData}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    filterActive={filterActive}
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setShopDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={shopDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="saleSupport"
                />
            </div>

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfSaleButtonClicked}
                    isClicked={isAddNewModeOfSaleButtonClicked}
                />
            ) : null}
            {isConfigureHoursClicked ? (
                <HoursSettingModal
                    selectedRowData={selectedRows}
                    setIsModalOpened={setIsConfigureHoursClicked}
                    isModalOpened={isConfigureHoursClicked}
                    setDaysDesignations={setDaysDesignations}
                    isAllHoursEqual={isAllHoursEqual}
                    setIsAllHoursEqual={setIsAllHoursEqual}
                    daysDesignations={daysDesignations}
                    shopsHoursData={shopsHoursData}
                    setShopsHoursData={setShopsHoursData}
                    daysData={daysData}
                    setDaysData={setDaysData}
                />
            ) : null}
            {isAssociateModalOpened ? <HoursAssociateModal /> : null}
            {isHistoryShopsModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryShopsModalOpened}
                    isModalOpened={isHistoryShopsModalOpened}
                />
            ) : null}
        </React.Fragment>
    );
}
