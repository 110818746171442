import { StyledH2, StyledTextInput } from "@aureskonnect/react-ui";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";
import { Button } from "reactstrap";
import { useSnapshot } from "valtio";
import moment from "moment";
import { addMinutes } from "date-fns";
import { AvGroup, AvForm } from "availity-reactstrap-validation";

import {
    setDuration,
    setIsModalOpenedOrders,
    setOptionComposite,
    setOrders,
    setPattern,
    store,
} from "../store";

import { numberMatchWord } from "@constants/index";

import ModalOrders from "./ModalOrders";

export default function Orders({ selectedRows }: any) {
    const { t } = useTranslation();
    const {
        isModalOpenedOrders,
        duration,
        pattern,
        dataSetupApk,
        optionComposite,
        deliveryCity,
    } = useSnapshot(store);

    const [isMinimumPrice, setIsMinimumPrice] = React.useState<boolean>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.isMinimumPrice
            ? dataSetupApk[0].orders.isMinimumPrice
            : false
    );
    const [isDeferredOrders, setIsDeferredOrders] = React.useState<boolean>(
        Object.values(selectedRows).length > 0
            ? selectedRows[0]["composite Products"].CMD_DIFFERE !== undefined
                ? selectedRows[0]["composite Products"].CMD_DIFFERE.ACITVE
                : false
            : false
    );
    const [isValidationnumDelivrey, setIsValidationnumDelivrey] = useState<
        boolean
    >(false);
    const [temps, setTemps] = useState<any>("");
    const [isValidationnumTheSpot, setIsValidationnumTheSpot] = useState<
        boolean
    >(false);
    const [isValidationnumToTake, setIsValidationnumToTake] = useState<boolean>(
        false
    );

    const [isPauseOrders, setIsPauseOrders] = useState<boolean>(
        selectedRows.length > 0 && selectedRows[0]["Pause orders"]
            ? selectedRows[0]["Pause orders"]
            : false
    );
    useEffect(() => {
        let localTemps: any =
            selectedRows[0] && selectedRows[0]["Temps de préparation"];
        localTemps =
            selectedRows[0] &&
            selectedRows[0]["Temps de préparation"] !== undefined &&
            selectedRows[0]["Temps de préparation"].replaceAll(";", ",");
        localTemps = localTemps && localTemps.split(",");
        setTemps(localTemps);
        // setLocalPattern(selectedRows[0].motif_interompre);
    }, [selectedRows]);

    const [delivery, setDelivery] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[2])
                : 30
            : 30
    );

    const [onTheSpot, setOnTheSpot] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[0])
                : 15
            : 15
    );

    const [toTake, setToTake] = React.useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["Temps de préparation"] !== undefined
                ? Number(temps[1])
                : 15
            : 15
    );

    const [localDuration, setLocalDuration] = React.useState<any>(duration);

    const [localPattern, setLocalPattern] = React.useState<any>(pattern);

    const price =
        selectedRows[0] &&
        selectedRows[0]["minimum price"] !== undefined &&
        JSON.parse(selectedRows[0]["minimum price"]);

    const [numberToTake, setNumberToTake] = useState<number>(
        Object.values(selectedRows).length > 0
            ? price !== null && price.ST_MinCmd.MinEmporter
                ? price.ST_MinCmd.MinEmporter
                : 0
            : 0
    );

    const [numberOnTheSpot, setNumberOnTheSpot] = useState<number>(
        Object.values(selectedRows).length > 0
            ? price !== null && price.ST_MinCmd.MinSurplace
                ? price.ST_MinCmd.MinSurplace
                : 0
            : 0
    );

    const [maximumPeriod, setMaximumPeriod] = useState<number>(
        selectedRows.length > 0
            ? selectedRows[0]["composite Products"].CMD_DIFFERE !== undefined
                ? Number(
                      selectedRows[0]["composite Products"].CMD_DIFFERE.NBR_JOUR
                  )
                : 0
            : 0
    );

    function dateDiff(date1: any, date2: any): any {
        var diff: any = {};
        var tmp = date2 - date1;

        tmp = Math.floor(tmp / 1000);
        diff.sec = tmp % 60;

        tmp = Math.floor((tmp - diff.sec) / 60);
        diff.min = tmp % 60;
        tmp = Math.floor((tmp - diff.min) / 60);
        diff.hour = tmp % 24;

        tmp = Math.floor((tmp - diff.hour) / 24);
        diff.day = tmp;

        return diff;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let ordersTime: any = new Date(Date.parse(selectedRows[0]?.date));

    let timeOrders: any = addMinutes(new Date(), localDuration.label);

    let date1 = new Date();
    let date2 = new Date(ordersTime);

    let diff = dateDiff(date1, date2);

    useEffect(() => {
        if (duration !== "" && duration.label !== t("Undetermined")) {
            setTimeout(() => {
                setLocalDuration("");
                setDuration("");
                setPattern("");
                setIsMinimumPrice(false);
            }, duration.label * 60000);
        }
        // eslint-disable-next-line
    }, [duration, t]);

    useEffect(() => {
        setOnTheSpot(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[0])
                    : 15
                : 15
        );
        setDelivery(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[2])
                    : 30
                : 30
        );
        setToTake(
            selectedRows.length > 0
                ? selectedRows[0]["Temps de préparation"] !== undefined
                    ? Number(temps[1])
                    : 15
                : 15
        );
    }, [selectedRows, temps]);

    useEffect(() => {
        if (
            diff.day < 0 ||
            (diff.day === 0 && diff.hour < 0) ||
            (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
            (diff.day === 0 &&
                diff.hour === 0 &&
                diff.min === 0 &&
                diff.sec < 0)
        ) {
            setIsPauseOrders(false);
        }
        // eslint-disable-next-line
    }, [selectedRows]);
    useEffect(() => {
        if (isDeferredOrders === false) {
            setMaximumPeriod(0);
        }
        // eslint-disable-next-line
    }, [isDeferredOrders]);
    useEffect(() => {
        let interrompreIndeterliné: boolean =
            localDuration.label === t("Undetermined");
        let interompreJusqua = ` ${moment(timeOrders).format(
            "DD-MM-YYYY"
        )} ${moment(timeOrders).format("HH:mm")}`;

        let timePrepartion = `${onTheSpot};${toTake};${delivery}`;

        let dataPrice: any;
        if (selectedRows[0] && selectedRows[0]["minimum price"] !== null) {
            dataPrice = JSON.parse(selectedRows[0]["minimum price"]);
            dataPrice.ST_MinCmd.MinSurplace = numberOnTheSpot;
            dataPrice.ST_MinCmd.MinEmporter = numberToTake;
            let localCity: any = [];
            deliveryCity.forEach((el: any) => {
                localCity.push({
                    CodePostale: el["Code Postal"],
                    FraisGestion: el["Frais de gestion"],
                    FraisLivraison: el["Frais de livraison"],
                    id_apk: el.id_apk,
                    MinCommande:
                        el["Montant minimale pour passer une commande"],
                    Pays: el.Pays,
                    TauxTva: el.Tva,
                    NomVille: el.Ville,
                });
            });
            dataPrice.STVilleLivraison =
                deliveryCity !== undefined ? localCity : [];
        }

        let composite: any = {
            REGLEGRATUITE: optionComposite.REGLEGRATUITE,
            CMD_DIFFERE: {
                ACITVE: isDeferredOrders,
                NBR_JOUR: maximumPeriod.toString(),
            },
        };
        setOptionComposite(composite);

        setOrders({
            localDuration,
            localPattern,
            isMinimumPrice,
            isDeferredOrders,
            isPauseOrders,
            delivery,
            onTheSpot,
            toTake,
            numberToTake,
            numberOnTheSpot,
            maximumPeriod,
            interrompreIndeterliné,
            interompreJusqua,
            timePrepartion,
            dataPrice,
        });

        // eslint-disable-next-line
    }, [
        deliveryCity,
        onTheSpot,
        duration,
        pattern,
        isMinimumPrice,
        isDeferredOrders,
        isPauseOrders,
        delivery,
        toTake,
        maximumPeriod,
        localDuration,
        selectedRows,
        numberOnTheSpot,
        numberToTake,
        localPattern,
        // eslint-disable-next-line react-hooks/exhaustive-deps

        t,
    ]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 30px/41px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 mt-2"
            >
                {t("Configuring commands")}
            </StyledH2>
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-3 pt-2"
            >
                {t("Preparation time required for an order (in minutes)")}
            </StyledH2>
            <AvForm>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5">{t("Delivery")}</span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setDelivery(e.target.value);
                                setIsValidationnumDelivrey(false);
                            } else {
                                setIsValidationnumDelivrey(true);
                            }
                        }}
                        value={delivery.toString()}
                        className={classnames("", {
                            input__clz:
                                isValidationnumDelivrey || delivery > 60,
                        })}
                        style={{
                            width: "110px",
                            marginLeft: "15rem",
                            marginTop: "5px",
                            height: "40px",
                        }}
                    />

                    {isValidationnumDelivrey ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "75%",
                                color: "#f46a6a",
                                // marginBottom: "0.25rem",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("Please insert a number")}
                        </div>
                    ) : null}
                    {delivery > 60 ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>

                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5">{t("On the spot")}</span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setOnTheSpot(e.target.value);
                                setIsValidationnumTheSpot(false);
                            } else {
                                setIsValidationnumTheSpot(true);
                            }
                        }}
                        value={onTheSpot.toString()}
                        className={classnames("mt-2", {
                            input__clz:
                                isValidationnumTheSpot || onTheSpot > 60,
                        })}
                        style={{
                            width: "110px",
                            marginLeft: "15rem",
                            height: "40px",
                        }}
                    />

                    {isValidationnumTheSpot ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "75%",
                                color: "#f46a6a",
                                // marginBottom: "0.25rem",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("Please insert a number")}
                        </div>
                    ) : null}
                    {onTheSpot > 60 ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5">{t("To take")}</span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setToTake(e.target.value);
                                setIsValidationnumToTake(false);
                            } else {
                                setIsValidationnumToTake(true);
                            }
                        }}
                        value={toTake.toString()}
                        className={classnames("mt-2", {
                            input__clz: isValidationnumToTake || toTake > 60,
                        })}
                        style={{
                            width: "110px",
                            marginLeft: "14rem",
                            height: "40px",
                        }}
                    />

                    {isValidationnumToTake ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                // marginBottom: "0.25rem",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("Please insert a number")}
                        </div>
                    ) : null}
                    {toTake > 60 ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "3rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                                marginLeft: "21.75rem",
                                position: "absolute",
                            }}
                        >
                            {t("The entered value must be between 0 and 60")}
                        </div>
                    ) : null}
                </AvGroup>
            </AvForm>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Minimum price to place an order")}
                </StyledH2>
            </div>
            <AvForm>
                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5">{t("To take")}</span>
                    <StyledTextInput
                        // autocomplete="off"
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setNumberToTake(e.target.value);
                            }
                        }}
                        value={numberToTake.toString()}
                        style={{
                            width: "110px",
                            marginLeft: "14rem",
                            marginTop: "5px",
                            height: "40px",
                        }}
                    />
                </AvGroup>

                <AvGroup className="ml-5 d-flex justify-content-align p-2">
                    <span className="mt-2 ml-5">{t("On the spot")}</span>
                    <StyledTextInput
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setNumberOnTheSpot(e.target.value);
                            }
                        }}
                        value={numberOnTheSpot.toString()}
                        className={classnames("mt-2", {})}
                        style={{
                            width: "110px",
                            marginLeft: "15rem",
                            height: "40px",
                        }}
                    />
                </AvGroup>
            </AvForm>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-1">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Pause orders")}
                </StyledH2>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isPauseOrders}
                    onChange={(e: any) => {
                        setIsPauseOrders(!isPauseOrders);
                        if (
                            diff.day < 0 ||
                            (diff.day === 0 && diff.hour < 0) ||
                            (diff.day === 0 &&
                                diff.hour === 0 &&
                                diff.min < 0) ||
                            (diff.day === 0 &&
                                diff.hour === 0 &&
                                diff.min === 0 &&
                                diff.sec < 0)
                        ) {
                            setIsModalOpenedOrders(true);
                        }
                    }}
                />
            </div>

            <div className="d-flex justify-content-between p-1 ml-5">
                <span
                    style={{
                        font: "normal normal normal 16px/21px Segoe UI",
                        marginLeft: "54px",
                    }}
                >
                    {selectedRows.length > 0 &&
                    selectedRows[0]["Undetermined"] !== true &&
                    isPauseOrders &&
                    localDuration.label === undefined &&
                    localPattern.label === undefined &&
                    selectedRows[0]["date"] !== undefined &&
                    !(
                        diff.day < 0 ||
                        (diff.day === 0 && diff.hour < 0) ||
                        (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
                        (diff.day === 0 &&
                            diff.hour === 0 &&
                            diff.min === 0 &&
                            diff.sec < 0)
                    )
                        ? `${t("Orders are suspended until")} ${moment(
                              ordersTime
                          ).format("DD-MM-YYYY")} ${moment(ordersTime).format(
                              "HH:mm"
                          )}, ${t("Pattern")}:${
                              selectedRows.length > 0 &&
                              selectedRows[0].motif_interompre ===
                                  "PROBLEME TECHNIQUE"
                                  ? t("Technical problem")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].motif_interompre ===
                                        "RUPTURE DE STOCK"
                                  ? t("Out of stock")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].motif_interompre ===
                                        "FERMETURE ANNUELLE"
                                  ? t("Annual closure")
                                  : selectedRows.length > 0 &&
                                    selectedRows[0].motif_interompre
                          }`
                        : isPauseOrders &&
                          localDuration.label !== undefined &&
                          localDuration.label !== t("Undetermined") &&
                          localDuration !== ""
                        ? `${t("Orders are suspended untitest")} ${moment(
                              timeOrders
                          ).format("DD-MM-YYYY")} ${moment(timeOrders).format(
                              "HH:mm"
                          )}, ${t("Pattern")}:${localPattern.label}`
                        : localDuration.label === t("Undetermined") ||
                          (selectedRows.length > 0 &&
                              selectedRows[0]["Undetermined"] === true)
                        ? `${t("Orders are suspended until")}  ${t(
                              "Undetermined"
                          )}, ${t("Pattern")}:${
                              localPattern.label !== undefined
                                  ? localPattern.label
                                  : selectedRows[0].motif_interompre
                          }`
                        : diff.day < 0 ||
                          (diff.day === 0 && diff.hour < 0) ||
                          (diff.day === 0 && diff.hour === 0 && diff.min < 0) ||
                          (diff.day === 0 &&
                              diff.hour === 0 &&
                              diff.min === 0 &&
                              diff.sec < 0)
                        ? `${t("No interrupt commands are enabled")}`
                        : `${t("No interrupt commands are enabled")}`}
                </span>
                <Button
                    className={classnames("style-button-order__clz", {
                        "not-allowed-icon__clz": !isPauseOrders,
                    })}
                    onClick={() => {
                        setIsModalOpenedOrders(true);
                    }}
                >
                    <span
                        style={{
                            font: "normal normal normal 16px/21px Segoe UI",
                            color: "rgb(0, 112, 255)",
                            textDecoration: "underline",
                        }}
                    >
                        {t("Edit")}
                    </span>
                </Button>
            </div>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-1 px-1 pt-2"
                >
                    {t("Deferred orders")}
                </StyledH2>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isDeferredOrders}
                    onChange={(e: any) => {
                        setIsDeferredOrders(!isDeferredOrders);
                    }}
                />
            </div>
            <AvForm>
                <AvGroup
                    className={classnames("ml-5 d-flex justify-content-align", {
                        "not-allowed-icon__clz": !isDeferredOrders,
                    })}
                >
                    <span
                        style={{
                            marginLeft: "54px",

                            paddingTop: "7px",
                        }}
                    >
                        {t("Maximum withdrawal period to respect (in days)")}
                    </span>
                    <StyledTextInput
                        className=""
                        id="commande"
                        name="commande"
                        type="number"
                        onChange={(e: any) => {
                            if (
                                e.target.value.match(numberMatchWord) ||
                                e.target.value === "0"
                            ) {
                                setMaximumPeriod(e.target.value);
                            }
                        }}
                        value={maximumPeriod.toString()}
                        style={{
                            width: "110px",
                            marginLeft: "1rem",
                            marginTop: "-0.25rem",
                            height: "40px",
                        }}
                    />
                </AvGroup>

                <span
                    className="not-allowed-icon__clz  px-4"
                    style={{ marginLeft: "76px" }}
                >
                    {t(
                        "If the value entered is 5 days, then the end customer has the possibility of ordering up to D+5 from the date of order taking."
                    )}
                </span>
            </AvForm>
            {isModalOpenedOrders ? (
                <ModalOrders
                    setLocalDuration={setLocalDuration}
                    localDuration={localDuration}
                    setLocalPattern={setLocalPattern}
                    localPattern={localPattern}
                    isPauseOrders={isPauseOrders}
                    selectedRows={selectedRows}
                    setIsPauseOrders={setIsPauseOrders}
                />
            ) : null}
        </React.Fragment>
    );
}
