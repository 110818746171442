import React, { useState } from "react";
import {
    Button,
    ModalFooter,
    ModalBody,
    Input,
    Modal,
    ModalHeader,
    Alert,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import ToastHeader from "react-bootstrap/ToastHeader";
import { useStep } from "react-hooks-helper";
import { useTranslation } from "react-i18next";

import NProgress from "nprogress";

import ShowPasswordSvgIcon from "../../../components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "../../../components/Common/SvgIcons/HidePasswordSvgIcon";

import {
    FORGOT_PASSWORD_CODE_LENGHT,
    EMAIL_VALIDATION_REGEX,
} from "../../../constants/index";

import "./index.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

export default function ForgotPasswordModal({
    loginemail,
    hundlesetemail,
    isModalOpened,
    toggleOpeningForgotPasswordModal,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(true);
    const toggleShowB = () => setShowA(false);
    const [isPasswordDisplayed, setIsPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const [isPasswordDisplayed2, setIsPasswordDisplayed2] = React.useState<
        boolean
    >(false);
    const [email, setEmail] = React.useState<any>(loginemail);
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [newPasswordControle, setNewPasswordControle] = React.useState<
        string
    >("");
    const [newPasswordControle2, setNewPasswordControle2] = React.useState<
        string
    >("");

    const [forgotPasswordCode, setForgotPasswordCode] = React.useState<string>(
        ""
    );

    const [isUnvalidEmail, setIsUnvalidEmail] = React.useState<boolean>(false);
    const [isUnvalidCode, setIsUnvalidCode] = React.useState<boolean>(false);
    const [isUnverifiedEmail, setIsUnverifiedEmail] = React.useState<boolean>(
        false
    );
    const [isUnverifiedCode, setIsUnverifiedCode] = React.useState<boolean>(
        false
    );
    const [isPasswordNotUpdated, setIsPasswordNotUpdated] = React.useState<
        boolean
    >(false);

    const [isPasswordConfirmed, setIsPasswordConfirmed] = React.useState<
        boolean
    >(false);
    const newPasswordRef = React.useRef<HTMLInputElement | null>(null);
    const confirmNewPasswordRef = React.useRef<HTMLInputElement | null>(null);

    const {
        index,
        navigation: { next, previous },
    } = useStep({ steps: 3 });

    function handleEmailInputOnChangeEvent(e: any) {
        setEmail(e.target.value);
        setIsUnvalidEmail(false);
        validateForgotPasswordEmail();
        hundlesetemail(e);
    }

    function validateForgotPasswordEmail() {
        if (!EMAIL_VALIDATION_REGEX.test(email)) {
            setIsUnvalidEmail(true);
        }
    }

    async function sendEmailVerificationRequest() {
        NProgress.start();

        await fetchWithToken(`${process.env.REACT_APP_API_URL}/auth/verify-email`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.isValid) {
                    setIsUnverifiedEmail(false);
                    sendResetCodeRequest(email);
                    next();
                } else {
                    setIsUnverifiedEmail(true);
                }
            })
            .catch((e: any) => {
                NProgress.done();
            });
    }

    async function sendResetCodeRequest(email: string) {
        NProgress.start();

        await fetchWithToken(`${process.env.REACT_APP_API_URL}/auth/send-reset-code`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();
            })
            .catch((e: any) => {
                NProgress.done();
            });
    }

    function handleCodeInputOnChangeEvent(e: any) {
        setForgotPasswordCode(e.target.value);
        setIsUnvalidCode(false);
        validateCode(e.target.value);
    }

    function validateCode(codeToValide: string) {
        if (String(codeToValide).length !== FORGOT_PASSWORD_CODE_LENGHT) {
            setIsUnvalidCode(true);
        }
    }

    async function sendCodeVerificationRequest(
        email: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetchWithToken(`${process.env.REACT_APP_API_URL}/auth/verify-code`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                validationCode: forgotPasswordCode,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.isValid) {
                    setIsUnverifiedCode(false);
                    next();
                } else {
                    setIsUnverifiedCode(true);
                }
            })
            .catch((e: any) => {
                NProgress.done();
            });
    }

    function checkConfirmedPasswords() {
        if (
            newPasswordRef.current?.value !== undefined &&
            confirmNewPasswordRef.current?.value !== undefined &&
            newPasswordRef.current?.value !==
                confirmNewPasswordRef.current?.value
        ) {
            setIsPasswordConfirmed(false);
        } else {
            setIsPasswordConfirmed(true);
            setNewPassword(newPasswordRef.current?.value as string);
        }
    }

    async function sendUpdatePasswordRequest(
        email: string,
        newPassword: string,
        forgotPasswordCode: string
    ) {
        NProgress.start();

        await fetchWithToken(`${process.env.REACT_APP_API_URL}/auth/update-password`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                password: newPassword,
                email: email,
                validationCode: forgotPasswordCode,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                NProgress.done();

                if (data.error) {
                    setIsPasswordNotUpdated(false);
                } else {
                    setIsPasswordNotUpdated(true);
                    toggleOpeningForgotPasswordModal();
                }
            })
            .catch((e: any) => {
                NProgress.done();
            });
    }

    if (index === 1 && !isModalOpened) {
        previous();
    }
    if (index === 2 && !isModalOpened) {
        previous();
        previous();
    }

    const closeBtn = (
        <button
            className="close"
            onClick={() => {
                toggleOpeningForgotPasswordModal();
                setIsUnverifiedEmail(false);
            }}
        >
            &times;
        </button>
    );

    const hundlehundle = () => {
        sendResetCodeRequest(email);
        toggleShowA();
    };
    return (
        <Modal isOpen={isModalOpened} centered>
            {index === 0 && (
                <React.Fragment>
                    <ModalHeader
                        toggle={toggleOpeningForgotPasswordModal}
                        close={closeBtn}
                    >
                        {t("Forgot your password?")}
                    </ModalHeader>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your e-mail")}
                        </p>
                        <Input
                            defaultValue={loginemail}
                            type="email"
                            placeholder="E-mail address"
                            onChange={handleEmailInputOnChangeEvent}
                        />
                        {isUnvalidEmail ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t(
                                    "Please enter a valid email address in the format name@example.com"
                                )}
                            </Alert>
                        ) : null}
                        {isUnverifiedEmail && !isUnvalidEmail && (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("Sorry, we can't find your e-mail")}.
                            </Alert>
                        )}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <Button
                            color="secondary"
                            outline
                            onClick={toggleOpeningForgotPasswordModal}
                            className="w-25"
                        >
                            {t("Return")}
                        </Button>
                        <Button
                            disabled={isUnvalidEmail}
                            onClick={() => {
                                validateForgotPasswordEmail();
                                sendEmailVerificationRequest();
                            }}
                            type="button"
                            color="dark"
                            className="w-25"
                        >
                            {t("Validate")}
                        </Button>
                    </ModalFooter>
                </React.Fragment>
            )}
            {index === 1 && (
                <React.Fragment>
                    <ModalHeader toggle={toggleOpeningForgotPasswordModal}>
                        {t("Forgot your password?")}
                    </ModalHeader>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t(
                                "Enter the 6-digit verification code sent to your e-mail address"
                            )}
                        </p>
                        <Input
                            placeholder="6 digit code"
                            onChange={handleCodeInputOnChangeEvent}
                        />
                        {isUnvalidCode ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("The code must be 6 digit length")}
                            </Alert>
                        ) : null}
                        {isUnverifiedCode ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("Sorry, we can't find your code")}.
                            </Alert>
                        ) : null}
                        <Button
                            type="button"
                            className="pl-0 text-muted "
                            color="link"
                            style={{
                                textDecoration: "underline",
                                color: "#495057",
                            }}
                            onClick={hundlehundle}
                        >
                            {t("Resend code")}
                        </Button>
                        <Toast
                            show={showA}
                            onClose={toggleShowB}
                            className="bg-success w-52   "
                        >
                            <ToastHeader className="">
                                {t("check your e-mail inbox")}
                            </ToastHeader>
                        </Toast>
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <Button
                            color="secondary"
                            outline
                            onClick={toggleOpeningForgotPasswordModal}
                            className="w-25"
                        >
                            {t("Return")}
                        </Button>
                        <Button
                            disabled={isUnvalidCode}
                            onClick={() => {
                                sendCodeVerificationRequest(
                                    email,
                                    forgotPasswordCode
                                );
                            }}
                            type="button"
                            color="dark"
                            className="w-25"
                        >
                            {t("Validate")}
                        </Button>
                    </ModalFooter>
                </React.Fragment>
            )}

            {index === 2 && (
                <React.Fragment>
                    <ModalHeader toggle={toggleOpeningForgotPasswordModal}>
                        {t("Password reset")}
                    </ModalHeader>
                    <ModalBody className="justify-content-center mx-4 my-2">
                        <p className="font-size-14">
                            {t("Please enter your new password")}
                        </p>

                        <div className=" d-flex align-items-center low-upper-case">
                            <div className="password-toggle__1">
                                {newPasswordControle !== "" &&
                                newPasswordControle !== undefined ? (
                                    isPasswordDisplayed ? (
                                        <HidePasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <ShowPasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            <input
                                name="password"
                                ref={newPasswordRef}
                                type={isPasswordDisplayed ? "text" : "password"}
                                className="form-control"
                                placeholder={t("New password")}
                                onChange={() => {
                                    checkConfirmedPasswords();
                                    setNewPasswordControle(
                                        newPasswordRef.current?.value as string
                                    );
                                }}
                            />
                        </div>
                        <div className=" d-flex align-items-center low-upper-case">
                            <div className="password-toggle__2 ">
                                {newPasswordControle2 !== "" &&
                                newPasswordControle2 !== undefined ? (
                                    isPasswordDisplayed2 ? (
                                        <HidePasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed2(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    ) : (
                                        <ShowPasswordSvgIcon
                                            onClick={() =>
                                                setIsPasswordDisplayed2(
                                                    (prevState: boolean) =>
                                                        !prevState
                                                )
                                            }
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                            </div>
                            <input
                                name="password"
                                type={
                                    isPasswordDisplayed2 ? "text" : "password"
                                }
                                ref={confirmNewPasswordRef}
                                className="form-control mt-3"
                                placeholder={t("Confirm new password")}
                                onChange={() => {
                                    setNewPasswordControle2(
                                        confirmNewPasswordRef.current
                                            ?.value as string
                                    );
                                    checkConfirmedPasswords();
                                }}
                            />
                        </div>

                        {!isPasswordConfirmed &&
                        (newPasswordRef.current?.value !== "" ||
                            confirmNewPasswordRef.current?.value !== "") &&
                        (newPasswordRef.current?.value !== undefined ||
                            confirmNewPasswordRef.current?.value !==
                                undefined) ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("Sorry, your passwords not confirmed")}.
                            </Alert>
                        ) : null}
                        {isPasswordConfirmed && isPasswordNotUpdated ? (
                            <Alert className="mt-2 mb-0" color="danger">
                                {t("There's an error")}.
                            </Alert>
                        ) : null}
                    </ModalBody>
                    <ModalFooter className="justify-content-end">
                        <Button
                            onClick={() => {
                                sendUpdatePasswordRequest(
                                    email,
                                    newPassword,
                                    forgotPasswordCode
                                );
                            }}
                            disabled={
                                !isPasswordConfirmed ||
                                newPasswordRef.current?.value === "" ||
                                confirmNewPasswordRef.current?.value === ""
                            }
                            type="button"
                            color="dark"
                            className="w-25"
                        >
                            {t("Validate")}
                        </Button>
                    </ModalFooter>
                </React.Fragment>
            )}
        </Modal>
    );
}
