import React, { useEffect } from "react";
import {
    StyledH2,
    StyledButton,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-dropzone-uploader/dist/styles.css";

import CasdroConfigurations from "./CashdroConfiguration";

import GloryConfigurations from "./GloryConfiguration";
import { capitalize } from "@helpers/general";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type PeripheralsModalConfigurationsType = {
    setIsConfiguredCashManagementClicked: Function;
    isConfiguredCashManagementClicked: boolean;
    data: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataM?: any;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function ModalConfigurations({
    setIsConfiguredCashManagementClicked,
    isConfiguredCashManagementClicked,
    data,
    setDataIsUpdated,
    dataM,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const [identifier, setIdentifier] = React.useState<string>("");
    const [Designation, setDesignation] = React.useState<string>("");
    const [mark, setMark] = React.useState<string>("");
    const [reference, setReference] = React.useState<string>("");
    const [remark, setRemark] = React.useState<string>("");
    const [ipAddress, setIpAddress] = React.useState<string>("");
    const [serverDirectory, setServerDirectory] = React.useState<string>(
        ".\\logMonnayeur"
    );
    const [port, setPort] = React.useState<string>("");
    const [activateState, setActivateState] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const [pos_id, setPosId] = React.useState<string>("");
    let arrayOfCustomColumns: CustomColumnProps[] = [];
    arrayOfCustomColumns.push({
        indexOFColumn: 1,
        columnName: t("Actions"),
        customJsx: () => (
            <StyledButton rounded variant="light">
                <span className="text-dark">{t("Select")}</span>
            </StyledButton>
        ),
    });

    function addConfiguration() {
        toast.dismiss();
        let dataConfig = {
            userId: dataM.userId,
            shopId: dataM.shopId,
            franchiseId: dataM.franchiseId,
            data: [
                {
                    id: identifier,
                    designation: data.Désignation,
                    reference: data.Référence,
                    note: remark,
                    mark: mark,
                    port_number: port,
                    ip: ipAddress,
                    server_directory: serverDirectory,
                    activate: activateState,
                    user: user,
                    password: password,
                    pos_id: pos_id,
                    shopId: dataM.shopId,
                    isArchived: false,
                },
            ],
        };

        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        fetchWithToken(
            `${process.env.REACT_APP_API_V2_URL}/settings/cash_management/configuration`,
            requestOptions
        )
            .then(async (_) => {
                toast.success(
                    t("The device is configured successfully", { mark }),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsConfiguredCashManagementClicked(false);
                setDataIsUpdated!(true);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => {
        if (
            !data ||
            Object.keys(data).length !== 0 ||
            Object.getPrototypeOf(data) !== Object.prototype
        ) {
            setIdentifier(data.Id);
            setIpAddress(data.ip);
            setRemark(data[t("Remark")]);
            setMark(data[t("Mark")]);
            setPort(data.port_number);

            setDesignation(data[t("Designation")]);
            setReference(data[t("Reference")]);
            setServerDirectory(data.server_directory);
            setActivateState(data.activate);
            setUser(data.user);
            setPassword(data.password);
            setPosId(data.pos_id);
        }
    }, [data, t]);

    return (
        <React.Fragment>
            <Modal
                size="xl"
                centered
                isOpen={isConfiguredCashManagementClicked}
            >
                <AvForm>
                    <ModalHeader
                        toggle={() => {
                            setIsConfiguredCashManagementClicked!(
                                !isConfiguredCashManagementClicked
                            );
                            setDataIsUpdated!(true);
                        }}
                    >
                        <StyledH2>
                            {capitalize(t("Configure a device"))}
                        </StyledH2>
                    </ModalHeader>
                    <ModalBody>
                        <fieldset className="border p-2">
                            <legend className="w-auto px-2">
                                <StyledLabel htmlFor="text">
                                    {t("Périphérique déclaré")}
                                </StyledLabel>
                            </legend>

                            <div className="d-flex">
                                <Col xs="12" sm="6">
                                    <StyledLabel htmlFor="example-input">
                                        {t("Designation")}
                                    </StyledLabel>
                                    <AvField
                                        style={{ backgroundColor: "#f1f1f1" }}
                                        id="Designation"
                                        disabled
                                        value={Designation}
                                        name="Designation"
                                        type="text"
                                    />
                                </Col>
                                <Col xs="12" sm="6">
                                    <StyledLabel htmlFor="example-input">
                                        {t("Mark")}
                                    </StyledLabel>
                                    <AvField
                                        id="Mark"
                                        disabled
                                        value={mark}
                                        name="Designation"
                                        placeholder={t("Write")}
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col>
                            </div>

                            <div className="d-flex">
                                <Col xs="12" sm="6">
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Reference")}
                                    </StyledLabel>
                                    <AvField
                                        id="reference"
                                        disabled
                                        value={reference}
                                        name="reference"
                                        placeholder={t("Write")}
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col>
                                {/* <Col xs="12" sm="6">
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Remark")}
                                    </StyledLabel>
                                    <AvField
                                        id="remark"
                                        disabled
                                        value={remark}
                                        name="remark"
                                        // placeholder={t("Write")}
                                        defaultValue={t("None")}
                                        type="text"
                                        style={{ backgroundColor: "#f1f1f1" }}
                                    />
                                </Col> */}
                            </div>
                        </fieldset>
                        <fieldset className="border p-2">
                            <legend className="w-auto px-2">
                                <StyledLabel htmlFor="text">
                                    {t("Please complete the following")}
                                </StyledLabel>
                            </legend>
                            {mark === "Cashdro" ? (
                                <CasdroConfigurations
                                    ipAddress={ipAddress}
                                    port={port}
                                    user={user}
                                    password={password}
                                    pos_id={pos_id}
                                    setIpAddress={setIpAddress}
                                    setPort={setPort}
                                    setUser={setUser}
                                    setPosId={setPosId}
                                    serverDirectory={serverDirectory}
                                    setServerDirectory={setServerDirectory}
                                    setIsConfiguredCashManagementClicked={
                                        setIsConfiguredCashManagementClicked
                                    }
                                    isConfiguredCashManagementClicked={
                                        isConfiguredCashManagementClicked
                                    }
                                    data={data}
                                    setDataIsUpdated={setDataIsUpdated}
                                    setPassword={setPassword}
                                    addConfiguration={addConfiguration}
                                />
                            ) : (
                                <GloryConfigurations
                                    ipAddress={ipAddress}
                                    port={port}
                                    setIpAddress={setIpAddress}
                                    setPort={setPort}
                                    serverDirectory={serverDirectory}
                                    setServerDirectory={setServerDirectory}
                                    setIsConfiguredCashManagementClicked={
                                        setIsConfiguredCashManagementClicked
                                    }
                                    isConfiguredCashManagementClicked={
                                        isConfiguredCashManagementClicked
                                    }
                                    data={data}
                                    setDataIsUpdated={setDataIsUpdated}
                                    addConfiguration={addConfiguration}
                                />
                            )}
                        </fieldset>
                    </ModalBody>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
