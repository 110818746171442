import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setProjectFiles,
    setImageKey,
    setConfigOptions,
    setIsCustomerAccountActive,
    setIsStateChanged,
    setItems,
} from "@store";
import kioskSettingStore from "../store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

import { OptionsNumberLessThanFour } from "./OptionsNumberLessThanFour";
import { OptionsNumberBetweenFourAndSix } from "./OptionsNumberBetweenFourAndSix";
import { OptionsNumberBetweenSixAndEight } from "./OptionsNumberBetweenSixAndEight";
import { OptionsNumberGreaterThanEight } from "./OptionsNumberGreaterThanEight";
import { LanguagesSelectWrapper } from "./LanguagesSelectWrapper";
import { removeItemFromList, addItemInList } from "../Ways/DragList";

export function Options(): JSX.Element {
    const { t } = useTranslation();
    const {
        project: { template, files: images },

        isResetModalOpened,
        templates,
        selectedOptionsLanguage,
        isCustomerAccountActive,
        isStateChanged,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    const [options, setOptions] = React.useState<OptionsItemType[]>([]);
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    const initOptions = (templates.filter(
        (element) => element.id === template.id
    )[0].content.options as OptionsType).items;

    function handleResetOnClickEvent() {
        setOptions(initOptions);
        setConfigOptions(initOptions);
        const imagesArray = [...images];

        setIsCustomerAccountActive(
            initOptions.filter(
                (element) => element.title === "Customer account"
            )[0].active as boolean
        );

        options
            .map((item: OptionsItemType) => item.id)
            .forEach((id: string) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [selectedOptionsLanguage]: {
                                ...localContent[selectedOptionsLanguage],
                                path:
                                    localContent[selectedOptionsLanguage]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    React.useEffect(() => {
        setOptions((template.content.options as OptionsType).items);
    }, [template]);

    React.useEffect(() => {
        if (isCustomerAccountActive === false && isStateChanged === true) {
            const localPagesItems: ItemsPagesType = { ...template.pages };

            const authenticationModesIndex = Object.keys(
                localPagesItems.ways
            ).includes("authenticationModes")
                ? Object.keys(localPagesItems.ways).indexOf(
                      "authenticationModes"
                  )
                : -1;

            let connectionIndex = Object.keys(localPagesItems.ways).includes(
                "connection"
            )
                ? Object.keys(localPagesItems.ways).indexOf("connection")
                : -1;

            let loyaltyConsommationIndex = Object.keys(
                localPagesItems.ways
            ).includes("loyaltyConsommation")
                ? Object.keys(localPagesItems.ways).indexOf(
                      "loyaltyConsommation"
                  )
                : -1;

            if (
                localPagesItems.ways.authenticationModes === undefined &&
                localPagesItems.ways.connection === undefined &&
                localPagesItems.ways.loyaltyConsommation === undefined
            ) {
                setIsStateChanged(false);

                return;
            } else {
                let newLocalPageItem: ItemsPagesType = {
                    ...localPagesItems,
                };

                if (authenticationModesIndex !== -1) {
                    const [removedElement, newSourceList] = removeItemFromList(
                        Object.values(newLocalPageItem["ways"]),
                        authenticationModesIndex
                    );

                    const removedItem = { ...removedElement };

                    removedItem.prefix = "elements";

                    newLocalPageItem["ways"] = Object.assign(
                        {},
                        ...newSourceList.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList = Object.values(
                        newLocalPageItem["elements"]
                    );

                    let newDestinationList = addItemInList(
                        destinationList,
                        authenticationModesIndex,
                        removedItem
                    ) as PageType[];

                    newLocalPageItem["elements"] = Object.assign(
                        {},
                        ...newDestinationList.map((element) => ({
                            [element.name]: {
                                ...element,
                            },
                        }))
                    );
                }

                let data: ItemsPagesType = { ...newLocalPageItem };

                if (connectionIndex !== -1) {
                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(data["ways"]),
                        connectionIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    data["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(data["elements"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        connectionIndex,
                        removedItem1
                    ) as PageType[];

                    data["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map((element) => ({
                            [element.name]: {
                                ...element,
                            },
                        }))
                    );
                }

                let newData: ItemsPagesType = { ...data };
                loyaltyConsommationIndex = Object.keys(newData.ways).includes(
                    "loyaltyConsommation"
                )
                    ? Object.keys(newData.ways).indexOf("loyaltyConsommation")
                    : -1;
                if (loyaltyConsommationIndex !== -1) {
                    const [
                        removedElement1,
                        newSourceList1,
                    ] = removeItemFromList(
                        Object.values(newData["ways"]),
                        loyaltyConsommationIndex
                    );

                    const removedItem1 = { ...removedElement1 };

                    removedItem1.prefix = "elements";

                    newData["ways"] = Object.assign(
                        {},
                        ...newSourceList1.map((element) => ({
                            [element.name]: { ...element },
                        }))
                    );

                    const destinationList1 = Object.values(newData["elements"]);

                    let newDestinationList1 = addItemInList(
                        destinationList1,
                        loyaltyConsommationIndex,
                        removedItem1
                    ) as PageType[];

                    newData["elements"] = Object.assign(
                        {},
                        ...newDestinationList1.map((element) => ({
                            [element.name]: {
                                ...element,
                            },
                        }))
                    );
                }
                setIsStateChanged(false);
                return setItems(newData);
            }
        }

        if (
            isCustomerAccountActive === true &&
            isStateChanged === true &&
            (template.pages.elements.authenticationModes as PageType) !==
                undefined &&
            (template.pages.elements.connection as PageType) !== undefined &&
            (template.pages.elements.loyaltyConsommation as PageType) !==
                undefined
        ) {
            const localPagesItems: ItemsPagesType = { ...template.pages };

            let data: ItemsPagesType = { ...localPagesItems };

            let connectionIndex = Object.keys(data.elements).includes(
                "connection"
            )
                ? Object.keys(data.elements).indexOf("connection")
                : -1;

            if (connectionIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(data["elements"]),
                    connectionIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                data["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(data["ways"]);

                let newDestinationList1 = addItemInList(
                    destinationList1,
                    1,
                    removedItem1
                ) as PageType[];

                data["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }
            let newLocalPageItem: ItemsPagesType = {
                ...data,
            };
            const authenticationModesIndex = Object.keys(
                newLocalPageItem.elements
            ).includes("authenticationModes")
                ? Object.keys(newLocalPageItem.elements).indexOf(
                      "authenticationModes"
                  )
                : -1;
            if (authenticationModesIndex !== -1) {
                const [removedElement, newSourceList] = removeItemFromList(
                    Object.values(newLocalPageItem["elements"]),
                    authenticationModesIndex
                );

                const removedItem = { ...removedElement };

                removedItem.prefix = "ways";

                newLocalPageItem["elements"] = Object.assign(
                    {},
                    ...newSourceList.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList = Object.values(newLocalPageItem["ways"]);

                let newDestinationList = addItemInList(
                    destinationList,
                    2,
                    removedItem
                ) as PageType[];

                newLocalPageItem["ways"] = Object.assign(
                    {},
                    ...newDestinationList.map((element, index) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            let newData: ItemsPagesType = { ...newLocalPageItem };
            let loyaltyConsommationIndex = Object.keys(
                newData.elements
            ).includes("loyaltyConsommation")
                ? Object.keys(newData.elements).indexOf("loyaltyConsommation")
                : -1;
            if (loyaltyConsommationIndex !== -1) {
                const [removedElement1, newSourceList1] = removeItemFromList(
                    Object.values(newData["elements"]),
                    loyaltyConsommationIndex
                );

                const removedItem1 = { ...removedElement1 };

                removedItem1.prefix = "ways";

                newData["elements"] = Object.assign(
                    {},
                    ...newSourceList1.map((element) => ({
                        [element.name]: { ...element },
                    }))
                );

                const destinationList1 = Object.values(newData["ways"]);
                const orderTakingIndex = Object.keys(newData.ways).indexOf(
                    "orderTaking"
                );
                let newDestinationList1 = addItemInList(
                    destinationList1,
                    orderTakingIndex + 1,
                    removedItem1
                ) as PageType[];

                newData["ways"] = Object.assign(
                    {},
                    ...newDestinationList1.map((element, index) => ({
                        [element.name]: {
                            ...element,
                            index: index,
                        },
                    }))
                );
            }

            setIsStateChanged(false);
            setItems(newData);
        }
    }, [isCustomerAccountActive, template.pages, isStateChanged]);

    return (
        <CustomErrorBoundary>
            <div
                className="d-grid__clz h-100"
                style={{
                    gridTemplateRows: "0.2fr 0.8fr",
                    gap: isKioskSetting && options.length < 4 ? "138px" : "5px",
                }}
            >
                <div>
                    <FlexboxGrid
                        alignItemsCentered={true}
                        className="justify-content-between"
                        gap="5px"
                    >
                        <div
                            className="cursor__clz"
                            style={{
                                font: "normal normal 600 35px/61px Nunito Sans",
                            }}
                        >
                            {t("Options")}
                        </div>
                        {!isKioskSetting ? (
                            <StyledIconButton
                                title={t("Reset to default settings")}
                                outline={true}
                                variant="danger"
                                className="m-0 p-0"
                                icon="RefreshIcon"
                                style={{ height: "20%" }}
                                onClick={() => {
                                    setLocalIsResetModalOpened(
                                        !localIsResetModalOpened
                                    );
                                }}
                            >
                                <RefreshIcon
                                    height={20}
                                    width={20}
                                    className="Cmn_btn_resetModifications"
                                />
                            </StyledIconButton>
                        ) : null}
                    </FlexboxGrid>

                    {!isKioskSetting ? <LanguagesSelectWrapper /> : null}
                </div>
                {options.length <= 4 ? (
                    <OptionsNumberLessThanFour
                        options={options}
                        setOptions={setOptions}
                    />
                ) : options.length > 4 && options.length <= 6 ? (
                    <OptionsNumberBetweenFourAndSix
                        options={options}
                        setOptions={setOptions}
                    />
                ) : options.length > 6 && options.length <= 8 ? (
                    <OptionsNumberBetweenSixAndEight
                        options={options}
                        setOptions={setOptions}
                    />
                ) : (
                    <OptionsNumberGreaterThanEight
                        options={options}
                        setOptions={setOptions}
                    />
                )}
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
