import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";
import classnames from "classnames";
import { setGlobalDataIsUpdated } from "../store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";

import { store } from "@components/VerticalLayout/store";
import {
    store as fidelity,
    setNumberActive,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setIsArchived,
    setEdit
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import FidelityAuresKonnect from "./FidelityAuresKonnect";
import FidelitySynapsy from "./FidelitySynapsy";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import { numberMatchWord } from "@constants/index";
import ErrorToast from "@components/Common/ErrorTost";
import FidelityZerosix from "./FidelityZerosix";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type FidelityFormType = {
    setIsAddFidelityClicked: Function;
    isAddFidelityClicked: boolean;
    setDataIsUpdated: Function;
    dataFidelity: React.SetStateAction<any>;
    isAddNewFidelityButtonClicked: boolean;
    setDataFidelity: Function;
    setLocalFilterActive: Function;
    fidelityChoiceIndex: number;
    setFidelityChoiceIndex: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function FidelityForm({
    setIsAddFidelityClicked,
    isAddFidelityClicked,
    setDataIsUpdated,
    dataFidelity,
    isAddNewFidelityButtonClicked,
    setDataFidelity,
    setLocalFilterActive,
    fidelityChoiceIndex,
    setFidelityChoiceIndex,
    setIsConsult,
    isConsult,
}: FidelityFormType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsFidelityData,
        fidelityData,
        fidelityFranchise,
        dataArchive,
        dataFranchiseArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(fidelity);

    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const shopId = oneShop ? shopID : "0";
    const uuidUser = localStorage.getItem("uuidUser");

    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [isExist, setIsExist] = React.useState<boolean>(false);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [designation, setDesignation] = React.useState<string>("");
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [choiceFidelity, setChoiceFidelity] = React.useState<string>("");
    const [selectChoiceFidelity, setSelectChoiceFidelity] = React.useState<
        boolean
    >(false);
    const [numberFidelityPoints, setNumberFidelityPoints] = React.useState<
        string
    >("100");

    const [password, setPassword] = React.useState<string>("");
    // const [remark, setRemark] = React.useState<string>("");
    const [login, setLogin] = React.useState<string>("");
    const [user, setUser] = React.useState<string>("");
    const [moneyCorrespondence, setMoneyCorrespondence] = React.useState<
        string
    >("1");
    const [dashboardLink, setDashboardLink] = React.useState<string>("");
    const [apiKey, setApiKey] = React.useState<string>("");
    const [authenticationMode, setAuthenticationMode] = React.useState<any>([]);
    const [sortedVisibility, setSortedVisibility] = React.useState<any>([]);

    const [
        isValidateButtonEnabled,
        setIsValidateButtonEnabled,
    ] = React.useState<boolean>(true);

    const [
        inputAuthenticationMode,
        setInputAuthenticationMode,
    ] = React.useState<boolean>(false);
    const [inputLogin, setInputLogin] = React.useState<boolean>(false);
    const [inputPassword, setInputPassword] = React.useState<boolean>(false);
    const [inputUser, setInputUser] = React.useState<boolean>(false);
    const [inputDashboardLink, setInputDashboardLink] = React.useState<boolean>(
        false
    );
    const [
        isFidelityNumberInValid,
        setIsFidelityNumberInValid,
    ] = React.useState<boolean>(false);
    const [inputApiKey, setInputApiKey] = React.useState<boolean>(false);
    const authenticationModeData: string[] = [
        t("Loyalty card"),
        t("Phone number"),
        t("Email and password"),
    ];
    const [auresKonnectGroup, setAuresKonnectGroup] = React.useState<string>(
        ""
    );
    const [inputGroup, setInputGroup] = React.useState<boolean>(false);
    const [
        inputNumberFidelityPoints,
        setInputNumberFidelityPoints,
    ] = React.useState<boolean>(false);
    const [isCentralizationActive, setIsCentralizationActive] = React.useState<
        boolean
    >(false);
    const [isVisibiliteActive, setIsVisibiliteActive] = React.useState<boolean>(
        false
    );

    
    const fetchModeOfSale = React.useCallback(() => {
        const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

        let url= `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
        fetchWithToken(
            url, {
                headers: {
                    "Content-Type": "application/json",
                     
                    "Accept-Language": i18nextLng,
                },
                method: "GET",
            }
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModes: any[] = response.data.map((saleMode: any) => {
                    
                    return {
                       
                        designation: saleMode["Mode of sale"],
                        state: false,
                        saleSupports: saleMode.support_vente.map((el: any) => {
                            return {
                                designation: el,
                                state: false,
                            };
                        }),
                    };
                });
                console.log({saleModes})
                setSortedVisibility(saleModes);
            });
      // eslint-disable-next-line
    }, [franchiseID, shopId, userID]);

    function checkIfFidelityExist() {
        let isFidelityExist = false;
        if (fidelityData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...fidelityData.data, ...dataArchive];
            allData.forEach((el: any) => {
                if (
                    (el[t("Display name")] === designation &&
                        el[t("Loyalty")] === choiceFidelity &&
                        ((el[t("Group")] === auresKonnectGroup))) ||
                    (el.designation === designation &&
                        el.type === choiceFidelity &&
                        ((el.group === auresKonnectGroup )
                            ))
                )
                    isFidelityExist = true;

                return;
            });
        }

        return isFidelityExist;
    }

    function checkIfFidelityFranchiseExist() {
        let isFidelityExist = false;
        if (fidelityFranchise.othersData.allData.data !== undefined) {
            fidelityFranchise.othersData.allData.data.forEach((el: any) => {
                if (
                    el[t("Display name")] === designation &&
                    el[t("Loyalty")] === choiceFidelity &&
                    ((el[t("Group")] === auresKonnectGroup &&
                        choiceFidelity.startsWith(
                            t("Fidelity Aures Konnect")
                        )) ||
                        !choiceFidelity.startsWith(t("Fidelity Aures Konnect")))
                )
                    isFidelityExist = true;
                return;
            });
        }
        return isFidelityExist;
    }

    function checkIfFidelityFranchiseExistAndArchive() {
        let isFidelityExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (
                    el.designation === designation &&
                    el.type === choiceFidelity &&
                    ((el.group === auresKonnectGroup &&
                        choiceFidelity.startsWith(
                            t("Fidelity Aures Konnect")
                        )) ||
                        !choiceFidelity.startsWith(t("Fidelity Aures Konnect")))
                )
                    isFidelityExist = true;
                return;
            });

        return isFidelityExist;
    }

    async function addFidelity() {
        let isFidelityFranchiseExist = checkIfFidelityFranchiseExist();
        let isFidelityFranchiseExistAndArchived = checkIfFidelityFranchiseExistAndArchive();

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, "0"] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            adding: true,
            data: {
                [uuid()]: {
                    designation: designation,
                    type: choiceFidelity,
                    number_fidelity_points: numberFidelityPoints,
                    money_correspondence: moneyCorrespondence,
                    authentication_mode: authenticationMode.join(","),
                    sortedVisibility: sortedVisibility,
                    Login: login,
                    password: password,
                    note: "remark",
                    isActive: false,
                    isArchived: false,
                    shopId: shopId,
                    dashboardLink: dashboardLink,
                    user: user,
                    apiKey: apiKey,
                    auresKonnectGroup: auresKonnectGroup,
                    isCentralizationActive: isCentralizationActive,
                    isVisibiliteActive: isVisibiliteActive,
                    fidelityChoiceIndex: fidelityChoiceIndex,
                },
            },
        };

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (fidelityData.data.length > 0 && oneShop) {
            ErrorToast(
                t(
                    "Attention, a shop cannot have more than one setting fidelity"
                )
            );
        } else if (isFidelityFranchiseExistAndArchived && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(true);
        } else if (isFidelityFranchiseExist && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(false);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setDataIsUpdated!(true);
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The fidelity setting has been successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            setDataFidelity([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            setDataIsUpdated!(true);
                            setDesignation("");
                            setChoiceFidelity("");
                            setAuthenticationMode([]);
                            clearData();
                            clearMultiCustomSelectionData();
                            setFidelityChoiceIndex(0);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editFidelity() {
        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        let dataConfig = {
            userId: userID,
            shopId: [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            data: {
                [dataFidelity.Id]: {
                    designation: designation,
                    type: choiceFidelity,
                    number_fidelity_points: numberFidelityPoints,
                    money_correspondence: moneyCorrespondence,
                    authentication_mode: authenticationMode.join(","),
                    sortedVisibility: sortedVisibility,
                    Login: login,
                    password: password,
                    note: "remark",
                    isActive: dataFidelity.isActive,
                    isArchived: false,
                    shopId: shopId,
                    dashboardLink: dashboardLink,
                    user: user,
                    apiKey: apiKey,
                    auresKonnectGroup: auresKonnectGroup,
                    isCentralizationActive: isCentralizationActive,
                    isVisibiliteActive: isVisibiliteActive,
                    fidelityChoiceIndex: fidelityChoiceIndex,
                    test: "test",
                },
            },
        };
        setDataEdited(dataConfig);

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetchWithToken(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fidelity setting has been successfully modified"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setDataIsUpdated!(true);
                        setGlobalDataIsUpdated(true);
                        setIsAddFidelityClicked(!isAddFidelityClicked);
                        setIsEdited(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function AssociateFidelity() {
        let idsAssociated = [0];
        let associated: any[] = [dataFidelity];
        if (shopsFidelityData.data !== undefined) {
            shopsFidelityData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataFidelity.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    function handleValidate() {
        let verifSale: boolean = false;
        sortedVisibility.forEach((v: any) => {
            if (v.state === true) {
                verifSale = true;
            }
        });
        if (isCentralizationActive === true && isVisibiliteActive === false) {
            toast.warning(
                `${t(
                    "Attention, for loyalty centralization, please activate the loyalty configuration"
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            return false;
        }

        if (verifSale === false && isCentralizationActive === true) {
            toast.warning(
                `${t("Attention, please activate at least one sales mode")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            return false;
        }

        setIsValidateButtonEnabled(false);
        if (designation === "") {
            setInputDesignation(true);
        }
        if (choiceFidelity === "") {
            setSelectChoiceFidelity(true);
        }
        if (login === "") {
            setInputLogin(true);
        }
        if (password === "") {
            setInputPassword(true);
        }
        if (user === "") {
            setInputUser(true);
        }
        if (numberFidelityPoints === "") {
            setInputNumberFidelityPoints(true);
        }
        if (dashboardLink === "") {
            setInputDashboardLink(true);
        }
        if (apiKey === "") {
            setInputApiKey(true);
        }
        if (auresKonnectGroup === "") {
            setInputGroup(true);
        }
        if (authenticationMode.length === 0) {
            setInputAuthenticationMode(true);
        }

        if (dataFidelity.Id !== undefined) {
            if (!isEdited) {
                setIsModalOpened(!isModalOpened);
                return false
            } else if (
                designation !== "" &&
                choiceFidelity !== "" &&
                !isExist &&
                !isFidelityNumberInValid
            ) {
                if (
                    auresKonnectGroup !== "" &&
                    authenticationMode.length !== 0 &&
                    numberFidelityPoints.match(numberMatchWord)
                ) {
                  
                    editFidelity();
                }
            }
        } else if (
            designation !== "" &&
            choiceFidelity !== "" &&
            !isExist &&
            !isFidelityNumberInValid
        ) {
            if (
                (choiceFidelity.startsWith(t("Fidelity Aures Konnect")) &&
                    auresKonnectGroup !== "" &&
                    // authenticationMode.length !== 0 &&
                    numberFidelityPoints.match(numberMatchWord)) ||
                (choiceFidelity === t("Fidelity Synapsy") &&
                    oneShop &&
                    login !== "" &&
                    password !== "") ||
                (choiceFidelity === t("Fidelity Synapsy") && !oneShop) ||
                (choiceFidelity === t("Fidelity Zerosix") &&
                    oneShop &&
                    user !== "" &&
                    password !== "" &&
                    dashboardLink !== "" &&
                    apiKey !== "") ||
                (choiceFidelity === t("Fidelity Zerosix") && !oneShop)
            ) {
                addFidelity();
            }
        }
        setTimeout(() => {
            setIsValidateButtonEnabled(true);
        }, 500);

        setEdit(uuid());
    }

    useEffect(() => {
        if (dataFidelity.Id !== undefined) {
            setTitle(t(isConsult ? "View fidelity" : "Modify fidelity"));
            setDesignation(dataFidelity[t("Display name")]);
            setChoiceFidelity(dataFidelity[t("Loyalty")]);
            setNumberFidelityPoints(dataFidelity.number_fidelity_points);
            setMoneyCorrespondence(dataFidelity.moneyCorrespondence);
            setAuthenticationMode(dataFidelity.authentication_mode.split(","));
            setSortedVisibility(dataFidelity.sortedVisibility);
            // setRemark(dataFidelity[t("Remark")]);
            setLogin(dataFidelity.Login);
            setPassword(dataFidelity[t("Password")]);
            setUser(dataFidelity.user);
            setDashboardLink(dataFidelity["Dashboard Link"]);
            setApiKey(dataFidelity["API key"]);
            setAuresKonnectGroup(dataFidelity[t("Group")]);
            setIsCentralizationActive(dataFidelity[t("State")]);
            setIsVisibiliteActive(dataFidelity[t("isVisibiliteActive")]);

            setFidelityChoiceIndex(dataFidelity.fidelityChoiceIndex);
        } else {
            setTitle(t("Add fidelity"));
            setDesignation("");
            setChoiceFidelity("");
            setNumberFidelityPoints("100");
            setMoneyCorrespondence("1");
            setAuthenticationMode([]);
            // setRemark("");
            setLogin("");
            setPassword("");
            setSortedVisibility([]);
            setInputDesignation(false);
            setInputPassword(false);
            setInputLogin(false);
            setSelectChoiceFidelity(false);
            setUser("");
            setDashboardLink("");
            setApiKey("");
            setAuresKonnectGroup("");
            setIsCentralizationActive(false);
            setIsVisibiliteActive(false);
        }

        setAssociated(AssociateFidelity);

        if (oneShop) {
            setIsValidateButtonEnabled(false);
        }

        // eslint-disable-next-line
    }, [dataFidelity, t, isAddNewFidelityButtonClicked, isAddFidelityClicked]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddFidelityClicked, dataFidelity.Id]);
    useEffect(() => {
        if ((numberFidelityPoints as any) % 100 !== 0) {
            setIsFidelityNumberInValid(true);
        } else {
            setIsFidelityNumberInValid(false);
        }
    }, [numberFidelityPoints]);

    useEffect(() => {
        let isFidelityExist = checkIfFidelityExist();
        if (
            isFidelityExist &&
            (dataFidelity.Id === undefined ||
                (dataFidelity.Id !== undefined &&
                    (dataFidelity[t("Display name")] !== designation ||
                        dataFidelity[t("Loyalty")] !== choiceFidelity ||
                        (dataFidelity[t("Group")] !== auresKonnectGroup &&
                            choiceFidelity.startsWith(
                                t("Fidelity Aures Konnect")
                            )))))
        ) {
            setIsExist(true);
        } else {
            setIsExist(false);
        }
        //eslint-disable-next-line
    }, [
        designation,
        choiceFidelity,
        t,
        dataFidelity,
        auresKonnectGroup,
        isCentralizationActive,
        isVisibiliteActive,
    ]);

    useEffect(() => {
        if (dataFidelity.Id === undefined) fetchModeOfSale();
    }, [fetchModeOfSale, dataFidelity]);

    React.useEffect(() => {
        setInputDesignation(false);
        setInputPassword(false);
        setInputLogin(false);
    }, [dataFidelity.Id]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5
                        className={`${classnames("pt-3", {
                            fed_txt_FRtitleFormLoyalty:
                                dataFidelity.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="fed_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataFidelity([]);
                                    setIsAddFidelityClicked!(
                                        !isAddFidelityClicked
                                    );
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataFidelity([]);
                                setIsAddFidelityClicked!(!isAddFidelityClicked);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className={`pl-3 pr-5`}
                    style={{
                        maxHeight: "470px",
                        overflowY: "auto",
                    }}
                >
                    <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="choiceFidelity"
                        >
                            {t("Loyalty")}
                        </StyledLabel>

                        <StyledSelectInput
                            value={
                                choiceFidelity === ""
                                    ? null
                                    : {
                                          label: choiceFidelity,
                                          value: choiceFidelity,
                                      }
                            }
                            className={classnames("fed_inp_FRfidelityType", {
                                invalid__clz: selectChoiceFidelity || isExist,
                                "not-allowed-fidelity__clz":
                                    dataFidelity.Id !== undefined,
                            })}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setChoiceFidelity(e.label);
                                setSelectChoiceFidelity(false);
                                setInputDesignation(false);
                                setInputPassword(false);
                                setInputLogin(false);
                                setInputGroup(false);
                                setInputAuthenticationMode(false);
                                setInputDashboardLink(false);
                                setInputApiKey(false);
                                setInputUser(false);
                                setDesignation(e.label);
                                setFidelityChoiceIndex(e.value);
                            }}
                            options={[
                                {
                                    label: t("Fidelity Aures Konnect"),
                                    value: 1,
                                },
                                // {
                                //     label: t("Fidelity Synapsy"),
                                //     value: 2,
                                // },
                                // {
                                //     label: t("Fidelity Zerosix"),
                                //     value: 3,
                                // },
                            ]}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a fidelity choice"
                                    )!}`,
                                },
                            }}
                            placeholder={t("Select")}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                            autoFocus={dataFidelity.Id === undefined}
                        />
                        {selectChoiceFidelity ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a fidelity type")}
                            </div>
                        ) : null}
                        {isExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {choiceFidelity.startsWith(
                                    t("Fidelity Aures Konnect")
                                )
                                    ? t(
                                          "Display name, loyalty type and group combination already exists"
                                      )!
                                    : t(
                                          "The display name, fidelity type combination already exists"
                                      )!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup style={{ display: "none" }}>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("fed_inp_FRname", {
                                input__clz: inputDesignation || isExist,
                                "not-allowed-icon__clz":
                                    dataFidelity.Id !== undefined,
                            })}
                            autocomplete="off"
                            id="Display name"
                            name="Display name"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setDesignation(e.target.value.trim());
                                setIsEdited(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                        {isExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {choiceFidelity.startsWith(
                                    t("Fidelity Aures Konnect")
                                )
                                    ? t(
                                          "Display name, loyalty type and group combination already exists"
                                      )!
                                    : t(
                                          "The display name, fidelity type combination already exists"
                                      )!}
                            </div>
                        ) : null}
                    </AvGroup>

                    {fidelityChoiceIndex === 1 ? (
                        <FidelityAuresKonnect
                            numberFidelityPoints={numberFidelityPoints}
                            setNumberFidelityPoints={setNumberFidelityPoints}
                            moneyCorrespondence={moneyCorrespondence}
                            setMoneyCorrespondence={setMoneyCorrespondence}
                            authenticationMode={authenticationMode}
                            setAuthenticationMode={setAuthenticationMode}
                            authenticationModeData={authenticationModeData}
                            sortedVisibility={sortedVisibility}
                            setSortedVisibility={setSortedVisibility}
                            inputAuthenticationMode={inputAuthenticationMode}
                            setInputAuthenticationMode={
                                setInputAuthenticationMode
                            }
                            setIsEdited={setIsEdited}
                            dataFidelity={dataFidelity}
                            auresKonnectGroup={auresKonnectGroup}
                            setAuresKonnectGroup={setAuresKonnectGroup}
                            inputGroup={inputGroup}
                            setInputGroup={setInputGroup}
                            isCentralizationActive={isCentralizationActive}
                            setIsCentralizationActive={
                                setIsCentralizationActive
                            }
                            isVisibiliteActive={isVisibiliteActive}
                            setIsVisibiliteActive={setIsVisibiliteActive}
                            setChoiceFidelity={setChoiceFidelity}
                            choiceFidelity={choiceFidelity}
                            designation={designation}
                            setDesignation={setDesignation}
                            isExist={isExist}
                            isFidelityNumberInValid={isFidelityNumberInValid}
                            inputNumberFidelityPoints={
                                inputNumberFidelityPoints
                            }
                            setInputNumberFidelityPoints={
                                setInputNumberFidelityPoints
                            }
                            isTest={false}
                            isConsult={isConsult}
                        />
                    ) : null}
                    {fidelityChoiceIndex === 2 && oneShop ? (
                        <FidelitySynapsy
                            password={password}
                            setPassword={setPassword}
                            login={login}
                            setLogin={setLogin}
                            inputLogin={inputLogin}
                            setInputLogin={setInputLogin}
                            inputPassword={inputPassword}
                            setInputPassword={setInputPassword}
                            setIsEdited={setIsEdited}
                            isConsult={isConsult}
                        />
                    ) : null}
                    {fidelityChoiceIndex === 3 && oneShop ? (
                        <FidelityZerosix
                            password={password}
                            setPassword={setPassword}
                            user={user}
                            setUser={setUser}
                            inputUser={inputUser}
                            setInputUser={setInputUser}
                            inputPassword={inputPassword}
                            setInputPassword={setInputPassword}
                            setIsEdited={setIsEdited}
                            dashboardLink={dashboardLink}
                            setDashboardLink={setDashboardLink}
                            setApiKey={setApiKey}
                            apiKey={apiKey}
                            inputDashboardLink={inputDashboardLink}
                            inputApiKey={inputApiKey}
                            setInputDashboardLink={setInputDashboardLink}
                            setInputApiKey={setInputApiKey}
                            isConsult={isConsult}
                        />
                    ) : null}
                    {/* <AvGroup className={` ${isConsult ? "pe-none__clz" : ""}`}>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("fed_inp_FRremark", {
                                "not-allowed-fidelity__clz": isConsultModalOpened,
                            })}
                            autocomplete="off"
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setRemark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remark}
                        />
                    </AvGroup> */}
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddFidelityClicked(
                                        !isAddFidelityClicked
                                    );
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setDataFidelity([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setIsAddFidelityClicked(!isAddFidelityClicked);
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataFidelity([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                            }
                            setIsConsult(false);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className={`fed_btn_FRvalidate cmn_btn_validate ${
                            isConsult ? "not-allowed-input__clz" : ""
                        }`}
                        disabled={!isValidateButtonEnabled}
                        onClick={() => {
                            handleValidate();
                           
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddFidelityClicked}
                setIsAddClicked={setIsAddFidelityClicked}
                setEditedData={setDataFidelity}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataFidelity={dataEdited}
                    associated={associated}
                    setIsAddFiscalYearClicked={setIsAddFidelityClicked}
                    isAddFiscalYearClicked={isAddFidelityClicked}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        designation: designation,
                        choiceFidelity: choiceFidelity,
                        group: auresKonnectGroup,
                    }}
                    setDesignation={setDesignation}
                    setChoiceFidelity={setChoiceFidelity}
                    setAuthenticationMode={setAuthenticationMode}
                />
            )}
        </React.Fragment>
    );
}
