import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import { setIsLoading, setKey } from "./store";
import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    setLimit?: Function | undefined;
    listSaleMode?: any;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    setLimit = () => {},
    listSaleMode,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(store);

    const [error, setError] = React.useState(false);
    const uuidUser = localStorage.getItem("uuidUser");
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };
    async function isModeOfSaleConsumed(shopId: string, id: string) {
        toast.dismiss();
        let isModeOfSaleUsed = false;

        const url = `${process.env.REACT_APP_API_V2_URL}/settings/consumedSettingGeneral?shopId=${shopId}&franchiseId=${franchiseID}&type=salesMethods`;
        mutate(
            url,
            await fetchWithToken(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.data.some((el: any) => el.id === id)) {
                        isModeOfSaleUsed = true;
                    }
                })
                .catch((_) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        return isModeOfSaleUsed;
    }
    async function archiveModeOfSale() {
        setIsLoading(true);
        toast.dismiss();
        let objectData: any = [];
        let listSaleModeConsumed = false;
        let isInclude: boolean = false;
        let isNotInclude: boolean = false;
        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            for (let el of arrayOfRows) {
                let isConsumed = await isModeOfSaleConsumed(el.shopId, el.Id);
                if (
                    !listSaleMode.flat(1).includes(`${el.Id} ${el.shopId}`) &&
                    isConsumed === false
                ) {
                    isNotInclude = true;
                    let dataModeOfSale = {
                        userId: userID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        data: {
                            [el.Id]: {
                                designation: el["Mode of sale"],
                                tag: el.tag,
                                display_name: el.display_name,
                                support_vente: el.support_vente,
                                isArchived: true,
                                state: false,
                                isFavorite: false,
                                shopId: el.shopId,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        },
                    };

                    objectData.push(dataModeOfSale);
                } else if (
                    listSaleMode.flat(1).includes(`${el.Id} ${el.shopId}`)
                ) {
                    isInclude = true;
                } else {
                    listSaleModeConsumed = true;
                }
            }

            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    await fetchWithToken(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(objectData),
                        }
                    ).then((result) => console.log(result))
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
                setIsLoading(false);
            }

            if (error === false && isNotInclude && listSaleModeConsumed) {
                toast.success(
                    `${t("The sales method has been successfully archived")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setDataIsUpdated!(true);
                setIsLoading(false);
                setKey(uuid());
            }
            if (isInclude) {
                setIsLoading(false);
                ErrorToast(t("Attention, you cannot archive a used element."));
                setDataIsUpdated!(true);
            }
            if (listSaleModeConsumed) {
                setIsLoading(false);
                ErrorToast(
                    t(
                        "Please note, you cannot archive an element used in a template"
                    )
                );
                setDataIsUpdated!(true);
            }

            setLimit(3);
        } else {
            let dataModeOfSale = [
                {
                    userId: userID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation: e.selectedRow["Mode of sale"],
                            tag: e.selectedRow.tag,
                            display_name: e.selectedRow.display_name,
                            support_vente: e.selectedRow.support_vente,
                            isArchived: true,
                            isFavorite: false,
                            state: false,
                            shopId: e.selectedRow.shopId,
                            advancedDisplayName:
                                e.selectedRow.advancedDisplayName,
                        },
                    },
                },
            ];
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfSale),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetchWithToken(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The sales method has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                            setIsLoading(false);
                            setKey(uuid());
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, note that this sales method(s) can be used by other modules.\n Do you want to confirm the archive of the selected sales method(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveModeOfSale();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
