import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";
import { uid } from "react-uid";
import { Nav } from "reactstrap";

import {
    setActiveTabPrinterOptionsConfirmationEmail,
    setProjectFiles,
    designStore,
    setImageKey,
    generalConfigStore,
    setInformationPrinterOptionsConfirmationEmailItems,
    setInformationPrinterOptionsEntryEmailItems,
    setIsBackgroundPrinterOptionsConfirmationEmailActive,
    setIsConfirmationEmailActive,
    setIsLogoPrinterOptionsConfirmationEmailActive,
    setIsTopBannerPrinterOptionsConfirmationEmailActive,
    setFiles,
} from "@store";
import { Uploader } from "@components/Uploader";

import { Translation } from "./Translation/index";

import "@components/Common/Design/index.css";
import "simplebar-react/dist/simplebar.min.css";
import "@assets/css/global.css";
import { VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB } from "@constants/index";

import { MainContentTranslationEntryEmail } from "./Translation/MainContentTranslationEntryEmail";
import { MainContentTranslationVerificationEmail } from "./Translation/MainContentTranslationVerificationEmail";

export function EmailConfirmation(): JSX.Element {
    const { t } = useTranslation();

    const {
        project,
        project: { template },
        files: images,
        imageKey,
        isConfirmationEmailActive,
    } = useSnapshot(generalConfigStore);

    const {
        activeTabPrinterOptionsConfirmationEmail,
        // activeTabPrinterOptionsSetupOptions,
        selectedLanguage,
    } = useSnapshot(designStore);

    const informationPrinterOptionsEntryEmailItems = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.entryemail;

    const informationPrinterOptionsConfirmationEmailItems = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).header
        .informationMessage.confirmationemail;

    const isBackgroundPrinterOptionsConfirmationEmailActive = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).background
        .active;

    const isTopBannerPrinterOptionsConfirmationEmailActive = ((project.template
        .pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).topBanner
        .active;

    const isLogoPrinterOptionsConfirmationEmailActive = ((project.template.pages
        .subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).topBanner.logo
        .active;

    const background = ((template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).background;
    const topBanner = ((template.pages.subSteps[
        "printerOptionsConfirmationEmail"
    ] as unknown) as PrinterOptionsConfirmationEmailSubStepType).topBanner;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleIsConfirmationEmailActiveSwitchOnClickEvent() {
        setIsConfirmationEmailActive(!isConfirmationEmailActive);
    }

    function handleIsBackgroundActiveSwitchOnClickEvent() {
        setIsBackgroundPrinterOptionsConfirmationEmailActive(
            !isBackgroundPrinterOptionsConfirmationEmailActive
        );
    }

    function handleIsTopBannerActiveSwitchOnClickEvent() {
        setIsTopBannerPrinterOptionsConfirmationEmailActive(
            !isTopBannerPrinterOptionsConfirmationEmailActive
        );
        if (isTopBannerPrinterOptionsConfirmationEmailActive) {
            setIsLogoPrinterOptionsConfirmationEmailActive(false);
        }
    }

    function handleIsLogoActiveSwitchOnClickEvent() {
        setIsLogoPrinterOptionsConfirmationEmailActive(
            !isLogoPrinterOptionsConfirmationEmailActive
        );
    }

    function handleInformationMessageSwitchOnClickEvent() {
        if (activeTabPrinterOptionsConfirmationEmail === 0) {
            setInformationPrinterOptionsConfirmationEmailItems({
                ...informationPrinterOptionsConfirmationEmailItems,
                active: !informationPrinterOptionsConfirmationEmailItems.active,
            });
        } else {
            setInformationPrinterOptionsEntryEmailItems({
                ...informationPrinterOptionsEntryEmailItems,
                active: !informationPrinterOptionsEntryEmailItems.active,
            });
        }
    }

    function toggleNav(tab: number) {
        if (activeTabPrinterOptionsConfirmationEmail !== tab) {
            setActiveTabPrinterOptionsConfirmationEmail(tab);
        }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex align-items-center flex-row"
                style={{ marginTop: "10px" }}
            >
                <Nav
                    tabs
                    className="d-grid__clz"
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        gridTemplateColumns: "1fr 1fr",
                        border: "unset",
                        gap: "15px",
                        alignItems: "center",
                    }}
                >
                    {[
                        {
                            title: t("Email verification"),
                            content: (
                                <MainContentTranslationVerificationEmail />
                            ),
                        },
                        {
                            title: t("Email entry"),
                            content: <MainContentTranslationEntryEmail />,
                        },
                    ].map((element, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fill: "red",
                                    top: "301px",
                                    left: "557px",
                                    width: "155px",
                                    height: "34px",
                                    background:
                                        activeTabPrinterOptionsConfirmationEmail ===
                                        index
                                            ? "#323333 0% 0% no-repeat padding-box"
                                            : "#E9E9E9 0% 0% no-repeat padding-box",
                                    borderRadius: "5px",
                                    opacity: 1,
                                    gap: "10px",
                                    textAlign: "center",
                                    font:
                                        "normal normal 600 15px/20px Segoe UI",
                                    letterSpacing: "0px",
                                    color:
                                        activeTabPrinterOptionsConfirmationEmail ===
                                        index
                                            ? "#FFFFFF"
                                            : "#000000",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    toggleNav(index);
                                }}
                            >
                                {element.title}
                            </div>
                        );
                    })}
                </Nav>
            </div>
            <div
                className=" rounded border"
                style={{
                    width: "93%",
                    height: "513px",
                    borderColor: "#CECECE",
                    marginTop: "10px",
                }}
            >
                <div
                    className="text-left px-4 d-flex align-items-center "
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("General")}
                </div>
                <div className=" p-4 d-flex flex-column justify-content-between">
                    <div className=" d-flex flex-row justify-content-between align-items-center">
                        <label>{t("Enable option")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isConfirmationEmailActive}
                            onChange={() => {
                                handleIsConfirmationEmailActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div
                        className="align-items-center"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "242px 241px auto",
                        }}
                    >
                        <label>{t("General background")}</label>
                        <div
                            className="d-flex flex-row align-items-center justify-content-start"
                            style={{ gap: "10px" }}
                        >
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetImagesOnClickEvent(
                                        background.id as string
                                    );
                                }}
                                disabled={
                                    isBackgroundPrinterOptionsConfirmationEmailActive ===
                                    true
                                        ? false
                                        : true
                                }
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={background.content}
                                id={background.id}
                                disabled={
                                    isBackgroundPrinterOptionsConfirmationEmailActive ===
                                    true
                                        ? false
                                        : true
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={
                                    isBackgroundPrinterOptionsConfirmationEmailActive
                                }
                                onChange={() => {
                                    handleIsBackgroundActiveSwitchOnClickEvent();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div
                        className="align-items-center"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "242px 241px auto",
                        }}
                    >
                        <label>{t("Banner")}</label>
                        <div
                            className="d-flex flex-row align-items-center justify-content-start"
                            style={{ gap: "10px" }}
                        >
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetImagesOnClickEvent(topBanner.id);
                                }}
                                disabled={
                                    isTopBannerPrinterOptionsConfirmationEmailActive ===
                                    true
                                        ? false
                                        : true
                                }
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <Uploader
                                key={imageKey}
                                uploadedFileTitle={topBanner.background.content}
                                id={topBanner.id}
                                disabled={
                                    isTopBannerPrinterOptionsConfirmationEmailActive ===
                                    true
                                        ? false
                                        : true
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={
                                    isTopBannerPrinterOptionsConfirmationEmailActive
                                }
                                onChange={() => {
                                    handleIsTopBannerActiveSwitchOnClickEvent();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={false}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <label>{t("Logo")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={
                                isLogoPrinterOptionsConfirmationEmailActive
                            }
                            onChange={() => {
                                handleIsLogoActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            disabled={
                                isTopBannerPrinterOptionsConfirmationEmailActive ===
                                true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div className=" mt-4 d-flex justify-content-between align-items-center">
                            <div>
                                <div>{t("Information message")} </div>
                                <div
                                    style={{
                                        color: "#B7B7B7",
                                        fontSize: "13px",
                                    }}
                                >
                                    {activeTabPrinterOptionsConfirmationEmail ===
                                    VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB
                                        ? informationPrinterOptionsConfirmationEmailItems
                                              .languages[selectedLanguage]
                                              .defaultContent !== undefined
                                            ? informationPrinterOptionsConfirmationEmailItems
                                                  .languages[selectedLanguage]
                                                  .defaultContent
                                            : null
                                        : informationPrinterOptionsEntryEmailItems
                                              .languages[selectedLanguage]
                                              .defaultContent !== undefined
                                        ? informationPrinterOptionsEntryEmailItems
                                              .languages[selectedLanguage]
                                              .defaultContent
                                        : null}
                                </div>
                            </div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={
                                    activeTabPrinterOptionsConfirmationEmail ===
                                    0
                                        ? informationPrinterOptionsConfirmationEmailItems.active
                                        : informationPrinterOptionsEntryEmailItems.active
                                }
                                onChange={() => {
                                    handleInformationMessageSwitchOnClickEvent();
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
                {activeTabPrinterOptionsConfirmationEmail ===
                VERIFICATION_EMAIL_PRINTER_OPTIONS_TAB ? (
                    informationPrinterOptionsConfirmationEmailItems.active ===
                    true ? (
                        <Translation />
                    ) : null
                ) : informationPrinterOptionsEntryEmailItems.active === true ? (
                    <Translation />
                ) : null}
            </div>
        </React.Fragment>
    );
}
