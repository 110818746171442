import React from "react";

import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as saleMode,
    setGlobalDataIsUpdated,
    
} from "../store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationFavoriteType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    e: any;
    setDataIsUpdated: Function;
};

export function ConfirmationFavorite({
    isModalOpened,
    setIsModalOpened,
    e,
    setDataIsUpdated,
}: ConfirmationFavoriteType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const { modeSaleData, shopsModeSaleData } = useSnapshot(saleMode);

    const [error, setError] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    async function favoriteShopModeOfSale(isFavorite: boolean) {
        toast.dismiss();
       
        let localData: any = [];
        modeSaleData.data.forEach((el: any) => {
            shopsModeSaleData.data.forEach((shop: any) => {
                shop.subRows.forEach((modeOfSale: any) => {
                    if (e.Id === modeOfSale.Id) {
                        let objectData = {
                            [modeOfSale.Id]: {
                                tag: modeOfSale.tag,
                                display_name: modeOfSale.display_name,
                                support_vente: modeOfSale.support_vente,
                                isArchived: modeOfSale.isArchived,
                                state: modeOfSale.state,
                                shopId: modeOfSale.shopId,
                                isFavorite: isFavorite,
                                designation: modeOfSale["Mode of sale"],
                                advancedDisplayName:
                                    modeOfSale.advancedDisplayName,
                            },
                        };
                        let dataModeOfSale = {
                            userId: userID,
                            shopId: [shop.shopId],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            isShop: false,
                            data: objectData,
                        };

                        localData.push(dataModeOfSale);
                    } else if (
                        el.Id === modeOfSale.Id &&
                        shop.subRows.findIndex(
                            (elt: any) => elt.Id === e.Id
                        ) !== -1
                    ) {
                        let objectData = {
                            [modeOfSale.Id]: {
                                tag: modeOfSale.tag,
                                display_name: modeOfSale.display_name,
                                support_vente: modeOfSale.support_vente,
                                isArchived: modeOfSale.isArchived,
                                state: modeOfSale.state,
                                shopId: modeOfSale.shopId,
                                isFavorite: false,
                                designation: modeOfSale["Mode of sale"],
                                advancedDisplayName:
                                    modeOfSale.advancedDisplayName,
                            },
                        };
                        let dataModeOfSale = {
                            userId: userID,
                            shopId: [shop.shopId],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                            isShop: true,
                            data: objectData,
                        };

                        localData.push(dataModeOfSale);
                    }
                });
            });
        });

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(
                `${
                    isFavorite
                        ? `${t(
                              "Shop favorite state has been successfully activated"
                          )}`
                        : `${t(
                              "Shop favorite state has been successfully deactivated"
                          )}`
                }`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
        setDataIsUpdated(true);
        setGlobalDataIsUpdated(true);
        
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setDataIsUpdated(true);
                    setGlobalDataIsUpdated(true);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setDataIsUpdated(true);
                        setGlobalDataIsUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        favoriteShopModeOfSale(!e.isFavorite);
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
