import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { AvForm, AvGroup } from "availity-reactstrap-validation";

import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../components/VerticalLayout/store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import {
    store as pathStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "./store";

import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";
import { getUniqueId } from "@helpers/general";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import ErrorToast from "@components/Common/ErrorTost";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import Switch from "react-switch";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type BeepersType = {
    setIsAddBeeperClicked: Function;
    setDataIsUpdated: Function;
    isAddBeeperClicked: boolean;
    editedData: any;
    setEditedData: Function;
    isAddNewBeeperButtonClicked: boolean;
    shopIdBeeper: string;
    localShopId?: any;
    inputDesignation: boolean;
    setInputDesignation: Function;
    inputIp: boolean;
    setInputIp: Function;
    dataIsUpdated?: boolean | number;
    dataTable?: any;
    setIsUpdateOperation: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function BeepersConfig({
    setIsAddBeeperClicked,
    isAddBeeperClicked,
    setDataIsUpdated,
    editedData,
    setEditedData,
    isAddNewBeeperButtonClicked,
    shopIdBeeper,
    localShopId,
    inputDesignation,
    setInputDesignation,
    inputIp,
    setInputIp,
    dataIsUpdated,
    dataTable,
    setIsUpdateOperation,
    isConsult,
    setIsConsult,
}: BeepersType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        userID,
        franchiseID,
        shopID,
        oneShop,

        operatorID,
    } = useSnapshot(store);
    const {
        selected,
        path,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(pathStore);

    const matchWordDesignation = new RegExp(
        /^[\w|é|è|û|É|È|Û|à|À|\s]+([\s][A-Za-z0-9]+)*$/i
    );
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [communicationCanal, setCommunicationCanal] = React.useState<string>(
        ""
    );
    const [
        communicationCanalAutorisation,
        setCommunicationCanalAutorisation,
    ] = React.useState<boolean>(true);

    const localNamesBeepers: string[] = [];
    const pathname = window.location.pathname.toString();

    const [designation, setDesignation] = useState<string>("");
    const [selectMark] = React.useState<boolean>(false);
    const [mark, setMark] = useState<string>("");
    const [remark, setRemark] = useState<string>("");

    const [portNbrs, setPortNbrs] = useState<string>("COM 2");

    const [exist, setExist] = useState({
        isNameExist: false,
        isIpExist: false,
    });
    const portNumArray: {
        label: string;
        value: string;
    }[] = [...Array(32).keys()].map((e: any, index: number) => {
        return {
            label: `COM ${index + 1}`,
            value: `COM ${index + 1}`,
        };
    });

    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    React.useEffect(() => {
        fetchDataIp_Name();
        uniqueDesignationIp(designation, "designation");

        // eslint-disable-next-line
    }, [dataIsUpdated, localShopId, designation]);

    const fetchDataIp_Name = () => {
        let beeperData: any = [];

        if (dataTable.data !== undefined) {
            if (dataTable !== undefined && dataTable.data.length > 0) {
                if (dataTable.data[0][t("Shop")] !== undefined) {
                    if (localShopId.length >= 1) {
                        localShopId.forEach((shopId: any) => {
                            beeperData = [
                                ...beeperData,
                                ...dataTable.data.find((x: any) => {
                                    return x.shopId === shopId;
                                })?.subRows,
                            ];
                        });
                    }
                } else if (dataTable.data[0][t("Shop")] === undefined) {
                    beeperData = dataTable.data;
                }
            }
        }

        if (beeperData.length > 0) {
            beeperData.forEach((datum: any) => {
                localNamesBeepers.push(
                    datum[t("Designation")]?.toUpperCase().trim()
                );
            });
        }
    };

    function uniqueDesignationIp(data: any, type: string) {
        switch (type) {
            case "designation":
                if (
                    (localNamesBeepers.includes(data.toUpperCase().trim()) &&
                        editedData.Id === undefined) ||
                    (localNamesBeepers.includes(data.toUpperCase().trim()) &&
                        editedData.Id !== undefined &&
                        editedData[t("Designation")].toUpperCase().trim() !==
                            data.toUpperCase().trim())
                ) {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: true,
                    }));
                } else {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: false,
                    }));
                }
                break;
        }
    }

    async function handleEditButtonClickEvent() {
        toast.dismiss();
        resetAllPeripherals();
        if (canValidate) {
            setCanValidate(false);
            let userId: number;
            let shopId: string;
            let franchiseId: string;

            userId = userID;
            shopId = editedData["shopId"].toString();
            franchiseId = franchiseID;

            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                editedData["Id"],
                shopId,
                franchiseID,
                userID,
                appId
            );
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/beeper?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                operatorId: operatorID,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData["Id"],
                data: [
                    {
                        id: editedData["Id"],
                        designation: designation,
                        mark: mark,
                        note: remark,
                        shopId: shopId,
                        port: portNbrs,
                        channelAuthorization: communicationCanalAutorisation,
                        channel: communicationCanal,
                        isArchived: false,
                    },
                ],
            };

            try {
                mutate(
                    apiUrl,
                    await fetchWithToken(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                             
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setCanValidate(true);
                                throw Error("Error!");
                            }
                            setCanValidate(true);
                            toast.success(
                                `${t("The device is successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsAddBeeperClicked(!isAddBeeperClicked);
                            setDataIsUpdated(true);
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                        })
                );
            } catch (e: any) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    function IsExistPeripheral(data: any, des: string) {
        let res: boolean = true;
        for (let index = 0; index < data.length; index++) {
            const element = data[index][t("Designation")]?.toString();
            if (element.toUpperCase() === des.toUpperCase()) {
                res = false;
            }
        }
        return res;
    }
    async function handleValidateButtonClickEvent() {
        toast.dismiss();
        resetAllPeripherals();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/beeper`;
        let userId: number;
        let franchiseId: string;
        if (oneShop) {
            localShopId = [shopID.toString()];
        }

        if (localShopId.length === 0) {
            ErrorToast(`${t("Attention ! you must select a store.")!}`);
        } else if (localShopId.length >= 1) {
            if (canValidate) {
                setCanValidate(false);
                try {
                    let action: boolean = false;
                    for (let index = 0; index < localShopId.length; index++) {
                        const element = localShopId[index];
                        userId = userID;
                        franchiseId = franchiseID;
                        let savedData = {
                            userId: userId,
                            shopId: element,
                            franchiseId: franchiseId,
                            operatorId: operatorID,
                            data: [
                                {
                                    id: getUniqueId(10),
                                    designation: designation,
                                    mark: mark,
                                    note: remark,
                                    shopId: element,
                                    port: portNbrs,
                                    channelAuthorization: communicationCanalAutorisation,
                                    channel: communicationCanal,
                                    isArchived: false,
                                },
                            ],
                        };

                        let dataSearch: any = [];
                        if (dataTable.data.length > 0) {
                            dataSearch =
                                oneShop ||
                                dataTable.data[0][t("Shop")] === undefined
                                    ? dataTable.data
                                    : dataTable.data.find((x: any) => {
                                          return x.shopId === element;
                                      }).subRows;
                        }

                        if (IsExistPeripheral(dataSearch, designation.trim())) {
                            action = true;
                            mutate(
                                apiUrl,
                                await fetchWithToken(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                       
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        }
                                    })
                            );
                        }
                    }
                    if (action === true) {
                        toast.success(
                            `${t("The device is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                    setDataIsUpdated(true);
                    setEditedData([]);
                    setIsEdited(false);
                    activateCheckbox();
                    setCanValidate(true);
                    setRemark("");
                } catch (e: any) {
                    setCanValidate(true);
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    fetchDataIp_Name();

    useEffect(() => {
        if (editedData.length !== 0) {
            setDesignation(
                editedData[t(`Designation`)] ? editedData[t(`Designation`)] : ""
            );
            setMark(editedData[t("Mark")]);
            setRemark(editedData[t("Remark")]);

            setCommunicationCanal(editedData[t("channel")]);
            setCommunicationCanalAutorisation(
                editedData[t("channelAuthorization")]
            );
            setPortNbrs(editedData[t("Port")]);
            setInputDesignation(false);
        } else {
            setDesignation("");
            setMark("");
            setCommunicationCanal("8888");
            setCommunicationCanalAutorisation(true);
            setPortNbrs("COM 2");
            setInputDesignation(false);
            setInputIp(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, isAddNewBeeperButtonClicked, t, oneShop]);
    useEffect(() => {
        if (editedData.length !== 0 && editedData?.Id !== undefined) {
            setIsUpdateOperation(true);
        } else {
            setIsUpdateOperation(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData]);

    useEffect(() => {
        if (path !== "" && selected !== "") {
            setIsEdited(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, selected]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <AvForm
                className="card"
                model={{
                    checkItOut: true,
                }}
                style={{ marginBottom: "0px" }}
            >
                <CustomCardHeader
                    className="d-flex align-items-center justify-content-between"
                    style={{ maxHeight: "76px" }}
                >
                    <h5 className="pt-3 imp_txt_addBeeperDevice">
                        {isConsult === true
                            ? t("View peripheral: beeper")
                            : editedData.length === 0
                            ? t("Add device: beeper")
                            : t("Modify a device: beeper")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="imp_icn_closeForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    activateCheckbox();
                                    setEditedData([]);
                                    setIsAddBeeperClicked!(!isAddBeeperClicked);
                                    setIsConsult(false);
                                    resetAllPeripherals();
                                    setIsAddBeeperClicked!(!isAddBeeperClicked);
                                    setIsEdited(false);
                                });
                            } else {
                                activateCheckbox();
                                setEditedData([]);
                                setIsAddBeeperClicked!(!isAddBeeperClicked);
                                setIsConsult(false);
                                setIsEdited(false);
                                resetAllPeripherals();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/beepers"
                                ? "550px"
                                : pathname === "/kiosk-peripheral-setting"
                                ? "610px"
                                : "600px",

                        overflowY: " scroll",
                    }}
                >
                    <AvGroup style={{ width: "430px" }}>
                        <StyledLabel
                            htmlFor="name"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>

                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("bip_inp_name", {
                                input__clz:
                                    inputDesignation ||
                                    exist.isNameExist ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                                readOnly__clz: isConsult,
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                                uniqueDesignationIp(
                                    e.target.value,
                                    "designation"
                                );
                            }}
                            value={designation}
                            validate={{
                                pattern: {
                                    value: matchWordDesignation,
                                    errorMessage: t("Prohibited characters"),
                                },
                            }}
                            autoFocus={isConsult === true ? false : true}
                        />
                        {inputDesignation ||
                        (designation.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {exist.isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup style={{ width: "430px" }}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Mark")}
                        </StyledLabel>
                        <React.Fragment>
                            <StyledSelectInput
                                className={classnames("bip_inp_mark", {
                                    invalid__clz: selectMark,
                                    "not-allowed-input__clz":
                                        editedData.Id !== undefined,
                                    readOnly__clz: isConsult,
                                })}
                                placeholder={t("Select")}
                                onChange={(e: any) => {
                                    setMark(e.value);
                                }}
                                options={[
                                    {
                                        label: "MMCALL",
                                        value: "MMCALL",
                                    },
                                    {
                                        label: "Syscall GP100DT RS-232C",
                                        value: "Syscall GP100DT RS-232C",
                                    },
                                ]}
                                value={
                                    mark === ""
                                        ? null
                                        : {
                                              label: mark,
                                              value: mark,
                                          }
                                }
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                        </React.Fragment>

                        {selectMark ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mark")}
                            </div>
                        ) : null}
                    </AvGroup>
                    {mark === "Syscall GP100DT RS-232C" ? (
                        <div>
                            <AvGroup style={{ width: "430px" }}>
                                <StyledLabel
                                    className="mt-3"
                                    htmlFor="example-input"
                                >
                                    {t("Port number")}
                                </StyledLabel>
                                <StyledSelectInput
                                    name="port_num"
                                    id="port_num"
                                    options={portNumArray}
                                    onChange={(e: any) => {
                                        setPortNbrs(e.label);
                                        setIsEdited(true);
                                    }}
                                    placeholder={t("Write")}
                                    value={{
                                        value: portNbrs,
                                        label: portNbrs,
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                    className={classnames(
                                        "bip_inp_portNumber",
                                        {
                                            readOnly__clz: isConsult,
                                        }
                                    )}
                                />
                            </AvGroup>
                            <AvGroup style={{ width: "430px" }}>
                                <StyledLabel className="mt-3" htmlFor="text">
                                    {t("Allow communication channel")}
                                </StyledLabel>
                                <div className="float-right">
                                    <Switch
                                        offColor="#faafb4"
                                        offHandleColor="#E30613"
                                        onColor="#c2eec4"
                                        className={classnames(
                                            "bip_icn_allowCommunicationChannel mt-3  pl-2",
                                            {
                                                readOnly__clz: isConsult,
                                            }
                                        )}
                                        onHandleColor="#34C38F"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        checked={communicationCanalAutorisation}
                                        height={20}
                                        handleDiameter={24}
                                        name="activate-protocol-kiss"
                                        onChange={(e: any) => {
                                            setCommunicationCanalAutorisation(
                                                !communicationCanalAutorisation
                                            );
                                            setIsEdited(true);
                                        }}
                                    />
                                </div>
                            </AvGroup>
                            <AvGroup
                                style={{ width: "430px" }}
                                className={classnames("", {})}
                            >
                                <StyledLabel className="mt-3" htmlFor="remark">
                                    {t("Communication channel")}
                                </StyledLabel>
                                <StyledTextInput
                                    id="com"
                                    name="com"
                                    placeholder={t("Write")}
                                    type="text"
                                    onChange={(e: any) => {
                                        setCommunicationCanal(e.target.value);
                                        setIsEdited(true);
                                    }}
                                    value={communicationCanal}
                                    className={classnames(
                                        "bip_inp_communicationChannel",
                                        {
                                            readOnly__clz: isConsult,
                                            "not-allowed-input__clz":
                                                communicationCanalAutorisation ===
                                                false,
                                            input__clz:
                                                communicationCanalAutorisation ===
                                                    true &&
                                                communicationCanal.trim() ===
                                                    "",
                                        }
                                    )}
                                />
                                {communicationCanalAutorisation === true &&
                                communicationCanal.trim() === "" ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {
                                            t(
                                                "Please enter a channel of communication"
                                            )!
                                        }
                                    </div>
                                ) : null}
                            </AvGroup>
                        </div>
                    ) : null}
                    <AvGroup style={{ width: "430px" }}>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            id="remark"
                            name="remark"
                            placeholder={t("Write")}
                            type="text"
                            onChange={(e: any) => {
                                setRemark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remark}
                            className={classnames("bip_inp_remark", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 imp_btn_cancel cmn_btn_cancel"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setEditedData([]);
                                    setIsAddBeeperClicked!(!isAddBeeperClicked);
                                    setIsConsult(false);

                                    activateCheckbox();
                                    setInputIp(false);
                                    setIsEdited(false);
                                });
                            } else {
                                setEditedData([]);
                                setIsAddBeeperClicked!(!isAddBeeperClicked);
                                setIsConsult(false);

                                activateCheckbox();
                                setInputIp(false);
                            }
                        }}
                    >
                        <span>{t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={classnames(
                            "bip_btn_validate cmn_btn_validate",
                            {
                                "not-allowed-icon__clz": isConsult,
                            }
                        )}
                        variant="primary"
                        onClick={() => {
                            if (designation === "") {
                                setInputDesignation(true);
                            }
                            if (mark !== "") {
                                setInputIp(true);
                            }

                            if (editedData.length === 0) {
                                if (
                                    (mark === "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        portNbrs.length !== 0 &&
                                        communicationCanalAutorisation ===
                                            true &&
                                        communicationCanal !== "") ||
                                    (mark === "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        portNbrs.length !== 0 &&
                                        communicationCanalAutorisation ===
                                            false) ||
                                    (mark !== "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist)
                                ) {
                                    if (
                                        designation.match(
                                            matchWordDesignation
                                        ) !== null
                                    ) {
                                        handleValidateButtonClickEvent();
                                    }
                                }
                            } else {
                                if (isEdited === false) {
                                    setIsModalOpened(true);
                                } else if (
                                    (mark === "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        portNbrs.length !== 0 &&
                                        communicationCanalAutorisation ===
                                            true &&
                                        communicationCanal !== "") ||
                                    (mark === "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        portNbrs.length !== 0 &&
                                        communicationCanalAutorisation ===
                                            false) ||
                                    (mark !== "Syscall GP100DT RS-232C" &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist)
                                ) {
                                    if (
                                        designation.match(
                                            matchWordDesignation
                                        ) !== null
                                    ) {
                                        handleEditButtonClickEvent();
                                    }
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddBeeperClicked}
                setIsAddClicked={setIsAddBeeperClicked}
                setEditedData={setEditedData}
            />
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
