import { useKeycloak } from "@react-keycloak/web";

export const useFetchWithKcToken = (): ((
    url: string,
    options?: RequestInit
) => Promise<Response>) => {
    const { keycloak, initialized } = useKeycloak();

    const kcToken = keycloak?.token ?? "";

    const fetchWithToken = async (url: string, options?: RequestInit) => {
        const headers = {
            ...options?.headers,
            Authorization: initialized === true ? `Bearer ${kcToken}` : "",
        };

        const response = await fetch(url, {
            ...options,
            headers,
        });

        if (response.ok !== true) {
            throw new Error("Network response was not ok");
        }

        return response;
    };

    return fetchWithToken;
};
