import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberMultiShopActive,
    setIsArchived,
    setGlobalDataIsUpdated,
    setIsLoading,
} from "./store";

import { capitalize } from "@helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { languages } from "@constants/index";
import { setItemsDisable } from "@store";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationAssociatedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated?: Function;
    setRowData?: Function;
};

export function ConfirmationAssociatedMessage({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    setRowData,
}: ConfirmationAssociatedMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(store);
    const {
        localShop,
        numberMultiShopActive,
        selectedRows,
        ids,
        activeLanguages,
        languageFavored,
        message,
        shopRowData,
        isArchived,
        globalDataIsUpdated,
        languagesData,
    } = useSnapshot(generalSettingStore);
    const languagesOptions: {
        label: string;
        value: string;
    }[] = languages.map((element: any) => {
        return {
            label: t(element.label),
            value: element.value,
        };
    });
    const [error] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");
    function checkIfLanguageAssociated(shopId: string) {
        let associated = shopRowData.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    async function AssociateLanguage() {
        setIsLoading(true);
        toast.dismiss();
        let isReassignment = false;
        let localData: any = [];
        for (let element of localShop) {
            let associated = checkIfLanguageAssociated(element);
            let objectData = {};
            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    let indexInAssociated =
                        !oneShop && associated !== undefined
                            ? associated.findIndex(
                                  (item: any) => item.Id === el.Id
                              )
                            : -1;
                    let indexInActiveLanguage = !oneShop
                        ? activeLanguages.findIndex(
                              (elt: any) => elt.Id === el.Id
                          )
                        : -1;
                    let indexInFavoredLanguage = !oneShop
                        ? languageFavored.findIndex(
                              (elt: any) => elt.id === el.Id
                          )
                        : -1;
                    if (
                        oneShop ||
                        indexInAssociated === -1 ||
                        (indexInAssociated !== -1 &&
                            (activeLanguages[indexInActiveLanguage].state !==
                                associated[indexInAssociated].isActive ||
                                languageFavored[indexInFavoredLanguage]
                                    .status !==
                                    associated[indexInAssociated].isFavorite))
                    ) {
                        let indexOfLanguage = languagesOptions.findIndex(
                            (elt: any) =>
                                elt.label ===
                                JSON.parse(JSON.stringify(el))[t("Language")]
                        );
                        const id: any = el.Id;
                        Object.assign(objectData, {
                            [id]: {
                                id: id,
                                language: languages[indexOfLanguage].label,
                                display_name: el.display_name,
                                country: el.country,
                                urlImage: el.url_image,
                                isArchived: el.isArchived,
                                abbreviation: el.abbreviation,
                                isFavorite: oneShop
                                    ? languagesData.length === 0
                                        ? true
                                        : false
                                    : languageFavored.find(
                                          (elt: any) => elt.id === el.Id
                                      ).status,
                                isActive: oneShop
                                    ? true
                                    : activeLanguages.find(
                                          (elt: any) => elt.Id === el.Id
                                      ).state,
                                shopId: element,
                                isReassignment:
                                    associated !== undefined
                                        ? associated.findIndex(
                                              (elt: any) => elt.Id === id
                                          ) !== -1
                                        : false,
                                advancedDisplayName: el.advancedDisplayName,
                            },
                        });
                    }
                });
                if (!oneShop)
                    associated.forEach((el: any) => {
                        let existingFavored = Object.values(
                            objectData
                        ).findIndex(
                            (language: any) => language.isFavorite === true
                        );
                        if (
                            !Object.keys(objectData).includes(el.Id) &&
                            existingFavored !== -1
                        ) {
                            let indexOfLanguage = languagesOptions.findIndex(
                                (elt: any) => elt.label === el[t("Language")]
                            );
                            Object.assign(objectData, {
                                [el.Id]: {
                                    id: el.Id,
                                    language: languages[indexOfLanguage].label,
                                    display_name: el.display_name,
                                    country: el.country,
                                    urlImage: el.url_image,
                                    isArchived: el.isArchived,
                                    abbreviation: el.abbreviation,
                                    isFavorite: false,
                                    isActive: el.isActive,
                                    shopId: element,
                                    advancedDisplayName: el.advancedDisplayName,
                                },
                            });
                        }
                    });

                let dataLanguage = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                // eslint-disable-next-line
                Object.values(objectData).forEach((elt: any) => {
                    if (elt.isReassignment === true) {
                        isReassignment = true;
                    }
                });
                const data = new FormData();
                data.append("dataLanguage", JSON.stringify(dataLanguage));
                data.append("image", "");
                localData.push(
                   fetchWithToken(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                        {
                            method: "POST",
                            body: data,
                        }
                    ).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
        if (error === false) {
            const getItemsDisableUrl: Response = await fetchWithToken(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/items?franchiseId=${franchiseID}&shopId=0`,
                {
                    method: "GET",
                }
            );
            const itemsDisable = await getItemsDisableUrl.json();
            if (itemsDisable.error === false) {
                setItemsDisable(itemsDisable.data);
                localStorage.setItem(
                    "itemsDisable",
                    JSON.stringify(itemsDisable.data)
                );
            }
            toast.success(
                `${t(
                    isReassignment
                        ? "The reassignment was successful"
                        : "The assignment was successful"
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setNumberMultiShopActive(
                numberMultiShopActive +
                    selectedRows.length * localShop.length -
                    ids
            );
            setDataIsUpdated !== undefined && setDataIsUpdated(true);
            setRowData !== undefined && setRowData([]);
            setGlobalDataIsUpdated(!globalDataIsUpdated);
            setIsLoading(false);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {isArchived ? (
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsArchived(false);
                        }}
                    >
                        {t("Ok")}
                    </CustomMainColorButton>
                ) : (
                    <React.Fragment>
                        <CustomSecondaryColorButton
                            outline
                            variant="light"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                            }}
                            rounded
                        >
                            {t("Cancel")}
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            className="mr-2"
                            variant="primary"
                            onClick={() => {
                                AssociateLanguage();
                                setIsModalOpened(!isModalOpened);
                                setIsAssociateModalOpened(false);
                            }}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </React.Fragment>
                )}
            </ModalFooter>
        </StyledModal>
    );
}
