import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CheckboxIcon,
    PlusIcon,
    StyledBreadcrumb,
} from "@aureskonnect/react-ui";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { toast, ToastContainer } from "react-toastify";
import PageTitle from "@components/Common/PageTitle";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../components/VerticalLayout/store";
import {
    setIsMultiDeletePeripheralModalOpened,
    setIsUpdateOperation,
    setIsEdited,
    setDataIsUpdated as setPrinterDataIsUpdated,
    store as printerStore,
    setModify,
    setIsPrinterConfigOpened,
    setIsLoading,
    setKey,
} from "./store";

import {
    arrayGroupedString,
    UpdateResourceIp,
    getTypePrinter,
} from "@helpers/general";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import MessageInfo from "@components/Common/MessageInfo";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { ConfirmationMultiDeleteMessage } from "@components/Common/ConfirmationMultiDeleteMessage";
import BadgeNumber from "@components/Common/BadgeNumber";

import { CustomIconsComponent } from "./CustomIcons";
import PrinterConfig from "./PrinterConfig";
import DropdownAction from "./DropdownAction";
import OriginHistory from "./OriginHistory";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { ModalResponsiveMobileForm } from "@components/Common/ModalResponsiveMobileForm";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import PrinterSetting from "./PrinterSetting";
import { ActionColumn } from "./ActionColumn";

import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { useKeycloak } from "@react-keycloak/web";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Printers() {
    const fetchWithToken = useFetchWithKcToken();
    const { keycloak } = useKeycloak();

    const {
        oneShop,
        operatorID,
        userID,
        franchiseID,
        shopID,
        isMobile,
    } = useSnapshot(store);
    const {
        isMultiDeletePeripheralModalOpened,
        modify,
        isEdited,
        isSettingModalOpened,
        dataIsUpdated: isUpdatedData,
        key,
    } = useSnapshot(printerStore);

    const { t } = useTranslation();
    const location = useLocation();

    setModify(getPathAuthorization(location.pathname).Modifie);
    const [
        isAddNewPrinterButtonClicked,
        setIsAddNewPrinterButtonClicked,
    ] = useState(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean>(false);
    const [isAddPrinterClicked, setIsAddPrinterClicked] = useState(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [
        isAddPrinterOriginHistoryClicked,
        setIsAddPrinterOriginHistoryClicked,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedShopId, setSelectedShopId] = React.useState<any>({});
    // eslint-disable-next-line
    const [localShop, setLocalShop] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>([]);
    const [editedData, setEditedData] = React.useState<any>([]);
    const [printerId, setPrinterId] = React.useState<string>("");

    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [localShopIdList, setLocalShopIdList] = React.useState<any>([]);
    const [selectedIds, setSelectedIds] = React.useState<any>([]);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [ListNumberOrigin, setListNumberOrigin] = React.useState<any>({
        nbrNotArchived: 0,
        nbrArchived: 0,
    });
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);

    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [inputIp, setInputIp] = React.useState<boolean>(false);

    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);

    const uuidUser = localStorage.getItem("uuidUser");
    const didMountRef = React.useRef(false);
    const [url, setUrl] = React.useState<string>(
        oneShop
            ? `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
    );
    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeDisplay, t]);

    React.useEffect(() => {
        if (isUpdatedData === true) {
            setDataIsUpdated(true);
            setPrinterDataIsUpdated(false);
        }
    }, [isUpdatedData]);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 6 : 8,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return (
                    <CustomIconsComponent
                        e={e}
                        setShopId={setShopId}
                        handleTestPrinterButtonOnClickEvent={
                            handleTestPrinterButtonOnClickEvent
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        setEditedData={setEditedData}
                        setIsModalOpened={setIsModalOpened}
                        setPrinterId={setPrinterId}
                        isModalOpened={isModalOpened}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                        setInputIp={setInputIp}
                        setIsConfirmationEditRunning={
                            setIsConfirmationEditRunning
                        }
                        typeDisplay={typeDisplay}
                        setIsModalOpenedHistory={
                            setIsAddPrinterOriginHistoryClicked
                        }
                        isModalOpenedHistory={isAddPrinterOriginHistoryClicked}
                        setSelectedShopId={setSelectedShopId}
                        setIsConsult={setIsConsult}
                        isAddPrinterClicked={isAddPrinterClicked}
                        setIsAddPrinterClicked={setIsAddPrinterClicked}
                        isConsult={isConsult}
                    />
                );
            },
        },

        {
            indexOFColumn: oneShop ? 0 : 2,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {!oneShop ? (
                            e.selectedRow[t("Shop")] !== undefined ||
                            (e.selectedRow[t("Shop")] !== "" &&
                                e.selectedRow.subRows) !== undefined ? (
                                <BadgeNumber
                                    value={e.selectedRow.subRows?.length}
                                />
                            ) : (
                                e.selectedRow[t("Printer name")]
                            )
                        ) : (
                            e.selectedRow[t("Printer name")]
                        )}
                    </div>
                );
            },
        },
        {
            indexOFColumn: oneShop ? 1 : 3,
            columnName: t("Type of configuration"),
            customJsx: (e: any) => {
                return (
                    <div>
                        {!oneShop
                            ? e.selectedRow[t("Shop")] !== undefined ||
                              (e.selectedRow[t("Shop")] !== "" &&
                                  e.selectedRow.subRows) !== undefined
                                ? ""
                                : getTypePrinter(e.selectedRow["type"])
                            : getTypePrinter(e.selectedRow["type"])}
                    </div>
                );
            },
        },
        {
            indexOFColumn: oneShop ? 4 : 6,
            columnName: t("Ticket"),
            customJsx: (e: any) => {
                let text: string = "";
                if (Array.isArray(e.selectedRow["tickets"])) {
                    if (e.selectedRow["tickets"].length !== 0) {
                        text = e.selectedRow["tickets"]
                            .map((x: any) => {
                                return t(x.label);
                            })
                            .join(",");
                    }
                } else if (
                    e.selectedRow["tickets"] !== undefined &&
                    e.selectedRow["tickets"].label !== undefined
                ) {
                    text = t(e.selectedRow["tickets"].label);
                }
                return <CustomColumnDisplay text={text} />;
            },
        },
    ];
    const disableCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.add("not-allowed-icon-check__clz");
        }
    };

    async function handleTestPrinterButtonOnClickEvent(
        ip: string,
        port: string
    ) {
        let apiUrl = `${
            process.env.REACT_APP_API_V2_URL
        }/settings/testPrint?ip=${ip}${
            port.length !== 0 ? `&port=${port}` : ""
        }`;

        try {
            mutate(
                apiUrl,
                await fetchWithToken(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${keycloak.token}`,
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while delete selection!");
                        }
                        toast.success(`${t("Printing successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                    })
            );
        } catch (e: any) {
            toast.warning(
                `${t(
                    "A problem detected. Please check your printer settings."
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }
    React.useEffect(() => {
        if (editedData.Id) {
            if (
                data.data
                    .filter((bt: any) => {
                        return bt.shopId === editedData.shopId;
                    })[0]
                    ?.subRows?.filter((imp: any) => {
                        return imp.Id === editedData.Id;
                    })[0] !== undefined
            ) {
                setEditedData(
                    data.data
                        .filter((bt: any) => {
                            return bt.shopId === editedData.shopId;
                        })[0]
                        ?.subRows.filter((imp: any) => {
                            return imp.Id === editedData.Id;
                        })[0]
                );
            }
        }

        // eslint-disable-next-line
    }, [data]);

    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/printers?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=${typeDisplay}&uuidUser=${uuidUser}`
        );
        setIsAddPrinterClicked(false);
        if (didMountRef.current) {
            setDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oneShop, franchiseID, userID, i18nextLng]);

    React.useEffect(() => {
        if (dataIsUpdated && didMountRef.current) {
            UpdateResourceIp(userID, franchiseID);
        } else {
            didMountRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIsUpdated]);

    React.useEffect(() => {
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
        // eslint-disable-next-line
    }, [data]);
    React.useEffect(() => {
        let localShop: string[] = [];
        let ids: any[] = [];
        var localShopId: number[] = [];

        selectedRows.forEach((el: any) => {
            if (el[`${t("Shop")}`] !== "" && el[`${t("Shop")}`] !== undefined) {
                localShop.push(el[`${t("Shop")}`]);
                localShopId.push(el.shopId);
            } else {
                ids.push({
                    Id: el.Id,
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                });
            }
        });
        if (oneShop === false) {
            setLocalShopIdList(arrayGroupedString(localShopId));
        } else {
            setLocalShopIdList([]);
        }
        setLocalShop(localShop);
        setSelectedIds(ids);
        setIsShopSelected(localShop.length >= 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, oneShop]);

    React.useEffect(() => {
        if (filterActive) {
            setIsAddPrinterClicked(false);
            setEditedData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterActive]);
    React.useEffect(() => {
        if (isAddPrinterClicked === true) {
            setLocalFilterActive(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddPrinterClicked]);

    React.useEffect(() => {
        setIsPrinterConfigOpened(isAddPrinterClicked);
    }, [isAddPrinterClicked]);

    return (
        <React.Fragment>
            <PageTitle title={t("Peripherals")} />
            <div className="page-content__clz page-scroll__clz">
                <div className="imp_txt_printer">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("Peripherals").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: t(
                                    typeDisplay === "notArchived"
                                        ? "Printers"
                                        : "Archived printers"
                                ).toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "The configuration of printers, whether they are IP, USB, serial, or other types, can be done in this section."
                    )}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: "600px",
                        gridTemplateColumns:
                            isAddPrinterClicked && isMobile === false
                                ? "2fr 1fr "
                                : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        key={key}
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng, authorization: `Bearer ${keycloak.token}` }}
                        canExpand={oneShop ? false : true}
                        canSelect
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setData={setData}
                        customSelect
                        actionColumn={(e: any) =>
                            modify === true ? (
                                <>
                                    <ActionColumn
                                        e={e}
                                        setShopId={setShopId}
                                        handleTestPrinterButtonOnClickEvent={
                                            handleTestPrinterButtonOnClickEvent
                                        }
                                        setIsConfirmationEditRunning={
                                            setIsConfirmationEditRunning
                                        }
                                        typeDisplay={typeDisplay}
                                        setIsAddPrinterClicked={
                                            setIsAddPrinterClicked
                                        }
                                    />
                                </>
                            ) : null
                        }
                        canMovedCheckboxLeftOnExpand
                        setSelectedRows={setSelectedRows}
                        customJsxSideFilterButton={
                            <div className="d-flex justify-content-start align-items-center ml-2">
                                {modify === true &&
                                isAddPrinterClicked !== true ? (
                                    <div>
                                        <span
                                            onClick={() => {
                                                disableCheckbox();
                                                setIsAddPrinterClicked(
                                                    !isAddPrinterClicked
                                                );
                                                setShopId(
                                                    oneShop === false
                                                        ? selectedRows[0].shopId
                                                        : shopID
                                                );
                                                setLocalFilterActive(false);
                                            }}
                                            className={`cmn_btn_openForm ${classnames(
                                                {
                                                    "not-allowed-icon__clz ":
                                                        (!isShopSelected &&
                                                            !oneShop) ||
                                                        typeDisplay ===
                                                            "archived",
                                                }
                                            )}`}
                                        >
                                            <CustomMainColorButtonWithIcon
                                                icon="PlusIcon"
                                                iconPosition="left"
                                                className="w-100"
                                                style={{ height: "0px" }}
                                                rounded
                                                variant="primary"
                                            >
                                                {t("Add device")}
                                            </CustomMainColorButtonWithIcon>
                                        </span>
                                    </div>
                                ) : modify === true ? (
                                    <div className="d-flex justify-content-start cmn_btn_openForm">
                                        <CustomMainColorIconButton
                                            icon="PlusIcon"
                                            rounded
                                            className="imp_btn_reset"
                                            disabled={
                                                editedData?.length === 0
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsInInitializationModalOpened(
                                                        true
                                                    );
                                                } else {
                                                    setInputDesignation(false);
                                                    setInputIp(false);
                                                }
                                            }}
                                        >
                                            <PlusIcon
                                                height={20}
                                                width={20}
                                                fill="white"
                                            />
                                        </CustomMainColorIconButton>
                                    </div>
                                ) : null}
                                <DropdownAction
                                    setIsAddPrinterClicked={
                                        setIsAddPrinterClicked
                                    }
                                    typeDisplay={typeDisplay}
                                    setTypeDisplay={setTypeDisplay}
                                    selectedIds={selectedIds}
                                    ListNumberOrigin={ListNumberOrigin}
                                    setDataIsUpdated={setDataIsUpdated}
                                />
                            </div>
                        }
                        canResize
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setDataIsUpdated={setDataIsUpdated as any}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="printer"
                    />
                    {isAddPrinterClicked && isMobile ? (
                        <ModalResponsiveMobileForm
                            isModalOpened={isAddPrinterClicked}
                            setIsModalOpened={setIsAddPrinterClicked}
                            JsxContent={() => {
                                return (
                                    <PrinterConfig
                                        setIsAddPrinterClicked={
                                            setIsAddPrinterClicked!
                                        }
                                        isAddPrinterClicked={
                                            isAddPrinterClicked
                                        }
                                        setDataIsUpdated={setDataIsUpdated}
                                        editedData={editedData}
                                        setEditedData={setEditedData}
                                        isAddNewPrinterButtonClicked={
                                            isAddNewPrinterButtonClicked
                                        }
                                        shopIdPrinter={shopId}
                                        localShopId={
                                            oneShop ? [shopID] : localShopIdList
                                        }
                                        inputDesignation={inputDesignation}
                                        setInputDesignation={
                                            setInputDesignation
                                        }
                                        inputIp={inputIp}
                                        setInputIp={setInputIp}
                                        dataTable={data}
                                        setIsUpdateOperation={
                                            setIsUpdateOperation
                                        }
                                        isConsult={isConsult}
                                        setIsConsult={setIsConsult}
                                    />
                                );
                            }}
                        />
                    ) : isAddPrinterClicked && isMobile === false ? (
                        <PrinterConfig
                            setIsAddPrinterClicked={setIsAddPrinterClicked!}
                            isAddPrinterClicked={isAddPrinterClicked}
                            setDataIsUpdated={setDataIsUpdated}
                            editedData={editedData}
                            setEditedData={setEditedData}
                            isAddNewPrinterButtonClicked={
                                isAddNewPrinterButtonClicked
                            }
                            shopIdPrinter={shopId}
                            localShopId={oneShop ? [shopID] : localShopIdList}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            inputIp={inputIp}
                            setInputIp={setInputIp}
                            dataTable={data}
                            setIsUpdateOperation={setIsUpdateOperation}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : null}
                </div>
                <ToastContainer limit={1} />
                {isModalDeleteAssociatePeripheralOpened === false ? (
                    <ConfirmationDeleteMessage
                        isModalOpened={isModalOpened}
                        setIsModalOpened={setIsModalOpened}
                        setDataIsUpdated={setDataIsUpdated}
                        text={t(
                            typeDisplay === "notArchived"
                                ? "Please confirm archivation of this device"
                                : "Please confirm unarchivation of this device"
                        )}
                        message={t(
                            typeDisplay === "notArchived"
                                ? "The device was successfully archived"
                                : "The device was successfully unarchived"
                        )}
                        apiUrl={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/printer?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&id=${printerId}&isArchived=${
                            typeDisplay === "notArchived" ? "true" : "false"
                        }&operatorId=${operatorID}&uuidUser=${uuidUser}`}
                        data={{ shopId: shopId }}
                        setKey={setKey}
                        setIsLoading={setIsLoading}
                    />
                ) : (
                    <AlertDeletePeripheralMessage
                        text={t(
                            "This device is associated with one or more applications! Unable to perform delete action"
                        )}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                    />
                )}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewPrinterButtonClicked}
                    isClicked={isAddNewPrinterButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddPrinterClicked}
                    archive={typeDisplay === "archived"}
                />
            ) : null}
            {isMultiDeletePeripheralModalOpened === true && (
                <ConfirmationMultiDeleteMessage
                    isModalOpened={isMultiDeletePeripheralModalOpened}
                    setIsModalOpened={setIsMultiDeletePeripheralModalOpened}
                />
            )}
            {isAddPrinterOriginHistoryClicked && (
                <OriginHistory
                    url={`${process.env.REACT_APP_API_V2_URL}/settings/printers/printer/history?userId=${userID}&shopId=${selectedShopId}&franchiseId=${franchiseID}&id=${printerId}`}
                    setIsModalOpened={setIsAddPrinterOriginHistoryClicked}
                    isModalOpened={isAddPrinterOriginHistoryClicked}
                />
            )}
            {isSettingModalOpened ? (
                <PrinterSetting
                    oneShop={oneShop}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ) : null}
        </React.Fragment>
    );
}
