import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import {
    setIsShopUser,
    setNumberClick,
    setOneShopSwitch,
    store,
} from "@components/VerticalLayout/store";

import { decryptWord } from "@helpers/general";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import PageTitle from "@components/Common/PageTitle";
import ForgotPasswordModal from "./ForgotPasswordModal";

import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import PortalLogoSvgIcon from "@components/Common/SvgIcons/PortalLogoSvgIcon";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./Login.css";
import { BloqArchiveAccountLogin } from "./BloqArchiveAccountLogin";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const fetchWithToken = useFetchWithKcToken();

    const [inputEmail, setInputEmail] = React.useState<string>("");
    const [inputPwd, setInputPwd] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [messagePwdError, setMessagePwdError] = React.useState<string>(
        t("This field is required")
    );
    const [isInvalidPassword, setIsInvalidPassword] = React.useState<boolean>(
        false
    );
    const { isShopUser } = useSnapshot(store);
    const [
        isBlockedArchiveAccountLoginOpened,
        setIsBlockedArchiveAccountLoginOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [messageError, setMessageError] = React.useState<string>(
        t("This field is required")
    );
    const [isPasswordDisplayed, setIsPasswordDisplayed] = React.useState<
        boolean
    >(false);

    const [, setIsFocusInInputs] = React.useState<boolean>(false);
    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string) => {
            return await fetchWithToken(
                `${process.env.REACT_APP_ACCESS_API_URL}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    return response;
                })
                .catch((error) => {
                    return "";
                });
        },
        // eslint-disable-next-line
        []
    );

    async function handleValidSubmit({ values }: any) {
        setIsFocusInInputs(false);
        setLoading(true);
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_ACCESS_API_URL}/customerAccount`;
        const email = values.email;
        const passWord = values.password;

        await fetchWithToken(urlVerifyUserAccount, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `email=${email}&password=${passWord}`,
            method: "POST",
        })
            .then((result) => result.json())
            .then(async (response: any) => {
                if (response.error) {
                    setLoading(false);
                    const decryptEmail = decryptWord(response.data?.email);
                    const decryptPwd = decryptWord(response.data?.pwd);
                    if (decryptEmail === "" && decryptPwd === "") {
                        setMessageError(
                            t("Account not found. Verify your email address.")
                        );
                        setInputEmail("");
                    } else {
                        if (email === decryptEmail && passWord !== decryptPwd) {
                            setIsInvalidPassword(true);
                            setInputPwd("");
                        } else {
                            if (
                                email !== decryptEmail &&
                                passWord === decryptPwd
                            ) {
                                setMessageError(
                                    t(
                                        "Account not found. Verify your email address."
                                    )
                                );
                                setInputEmail("");
                            }
                        }
                    }
                } else {
                    if (response.data?.email !== "" && !response.error) {
                        if (response.data?.etataccount === "Activer") {
                            const urlApi = `${process.env.REACT_APP_ACCESS_API_URL}/getAutorisationProfileEntity?uuidEntity=${response.data?.uuidprofile}`;
                            await fetchWithToken(urlApi, {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                                .then((response) => response.json())
                                .then((result) => {
                                    localStorage.setItem(
                                        "authorizationSetting",
                                        JSON.stringify(result)
                                    );
                                });

                            localStorage.setItem(
                                "uuidUser",
                                response.data?.uuiduser
                            );
                            localStorage.setItem(
                                "Uid_Entitie_User",
                                response.data?.uidentitie
                            );
                            localStorage.setItem(
                                "firstName",
                                response.data?.firstname
                            );
                            localStorage.setItem(
                                "lastName",
                                response.data?.name
                            );
                            localStorage.setItem("email", response.data.email);
                            localStorage.setItem(
                                "secondaryColor",
                                response.data.secondarycolor
                            );
                            localStorage.setItem(
                                "mainColor",
                                response.data.maincolor
                            );

                            setLoading(false);

                            const url = `${process.env.REACT_APP_API_V2_URL}/userInfo?userId=${response.data?.uidentitie}`;

                            await fetchWithToken(url, {
                                method: "GET",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            })
                                .then((response) => response.json())
                                .then(async (result) => {
                                    let pays: any = cryptDecryptDataUser(
                                        result.data?.country
                                    );

                                    if (
                                        result.data?.type === "boutique" ||
                                        result.data?.type === "Boutique"
                                    ) {
                                        await cryptDecryptDataUser(
                                            result.data?.commercialname
                                        ).then((name: string) => {
                                            localStorage.setItem(
                                                "selectedAccount",
                                                name
                                            );
                                            setOneShopSwitch(
                                                result.data?.uid_franchise,
                                                result.data?.uid,
                                                pays,
                                                name
                                            );
                                        });

                                        setNumberClick(0);
                                        setIsShopUser(true);
                                        navigate("/setting/customer-account");
                                    } else {
                                        setIsShopUser(false);
                                        //navigate("/");
                                    }
                                })
                                .catch((error) => {
                                    setIsShopUser(false);
                                    // navigate("/");
                                });
                        } else {
                            setLoading(false);
                            setIsBlockedArchiveAccountLoginOpened(true);
                        }
                    } else {
                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                return false;
            });
    }

    function toggleOpeningForgotPasswordModal() {
        setIsModalOpened(!isModalOpened);
    }

    function handleCreateAccountButtonOnClickEvent() {
        navigate("registration");
    }

    React.useEffect(() => {
        if (inputPwd.length === 0 && !isInvalidPassword) {
            setMessagePwdError(t("This field is required"));
        } else {
            setMessagePwdError(t("Incorrect password"));
            setIsInvalidPassword(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputPwd]);

    React.useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity !== null && isShopUser === false) {
            navigate("/compte");
        }
    });

    return (
        <React.Fragment>
            <PageTitle title={t("Connection")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid={true} className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />

                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden login-card w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Connection")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e: any, v: any) => {
                                        handleValidSubmit({ values: v });
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: "2rem",
                                        }}
                                    >
                                        <StyledLabel htmlFor="email">
                                            {t("Email")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="email"
                                            name="email"
                                            type="email"
                                            className="form-control__clz"
                                            onChange={(e: any) => {
                                                setInputEmail(e.target.value);
                                            }}
                                            value={inputEmail}
                                            placeholder={t("Email")}
                                            onFocus={() =>
                                                setIsFocusInInputs(true)
                                            }
                                            validate={{
                                                email: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "Invalid email address"
                                                    ),
                                                },
                                                required: {
                                                    value: true,
                                                    errorMessage: messageError,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <StyledLabel htmlFor="password">
                                            {t("Password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            value={inputPwd}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            onFocus={() =>
                                                setIsFocusInInputs(true)
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: messagePwdError,
                                                },
                                            }}
                                            onChange={(e: any) => {
                                                // setPassWord(e.target.value);
                                                setInputPwd(e.target.value);
                                            }}
                                            placeholder={t("Password")}
                                            className="form-control__clz"
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="d-none custom-control custom-checkbox mb-5">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customControlInline"
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customControlInline"
                                            >
                                                {t("Remember me")}
                                            </label>
                                        </div>
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            className="forgot-text__clz"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={
                                                toggleOpeningForgotPasswordModal
                                            }
                                        >
                                            {t("Forgot your password?")}
                                        </StyledButton>
                                    </div>

                                    <CustomMainColorButton
                                        variant="primary"
                                        rounded={true}
                                        outline={false}
                                        className="text-uppercase d-flex justify-content-center submit-button__clz"
                                        type="submit"
                                    >
                                        {t("Connection")}
                                    </CustomMainColorButton>
                                    <div className="d-none">
                                        <StyledButton
                                            //@ts-ignore
                                            variant="link"
                                            style={{
                                                textDecoration: "underline",
                                                color: "#495057",
                                                background: "transparent",
                                            }}
                                            onClick={
                                                handleCreateAccountButtonOnClickEvent
                                            }
                                        >
                                            {t("Create new account")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            {isBlockedArchiveAccountLoginOpened ? (
                <BloqArchiveAccountLogin
                    setIsModalOpened={setIsBlockedArchiveAccountLoginOpened}
                    isModalOpened={isBlockedArchiveAccountLoginOpened}
                    msgTitleModal={
                        "You are no longer authorized to use this account. Please contact our support department."
                    }
                />
            ) : null}
            <ForgotPasswordModal
                isModalOpened={isModalOpened}
                toggleOpeningForgotPasswordModal={
                    toggleOpeningForgotPasswordModal
                }
            />
        </React.Fragment>
    );
}
