import React from "react";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { toast } from "react-toastify";

import {
    store,
    setIsPlusIconClicked,
    setIsModesModalDisassociate,
} from "../../store/project/index";
import { store as userStore } from "../../components/VerticalLayout/store";
import {
    setInitDesignStoreVariables,
    setInitStoreVariables,
} from "../../store/index";
import ErrorToast from "@components/Common/ErrorTost";
import {
    setIsKioskSetting,
    setProjectRowData,
} from "@pages/GeneralConfig/store";
import {
    store as appStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "./store";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ProjectCreationType = {
    setIsDataUpdated: Function;
    setIsModalArchiveOpened: Function;
    isModalArchiveOpened: boolean;
    setIsModalDissociateOpened: Function;
    isModalDissociateOpened: boolean;
    selectedRows: any;
    isArchivedTemplateClicked: boolean;
    setIsCreatedProject: Function;
    isCreatedProject: boolean;
    setIsInInitializationModalOpened: Function;
    setSelectedRows: Function;
    setIsArchivedTemplateClicked: Function;
};

export function ProjectCreationButtonWrapper({
    setIsDataUpdated,
    setIsModalArchiveOpened,
    isModalArchiveOpened,
    selectedRows,
    isArchivedTemplateClicked,
    setIsModalDissociateOpened,
    isModalDissociateOpened,
    setIsCreatedProject,
    isCreatedProject,
    setIsInInitializationModalOpened,
    setSelectedRows,
    setIsArchivedTemplateClicked,
}: ProjectCreationType): JSX.Element {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    function shopAssociate() {
        let shopsAssociate: any = [];
        Object.values(selectedRows).forEach((el: any) => {
            if (el?.subRows !== undefined) {
                shopsAssociate.push(...el?.subRows);
            }
        });
        return shopsAssociate;
    }
    let shopsAssociate: any = [];

    const { numberArchived, numberActive, modify, isLoading } = useSnapshot(
        store
    );
    const { isEdited, isActionsClicked } = useSnapshot(appStore);
    const { oneShop, shopID, franchiseID } = useSnapshot(userStore);
    if (!oneShop) {
        shopsAssociate = shopAssociate();
    } else {
        shopsAssociate = [];
    }
    const isExistPublishProject = React.useCallback(() => {
        let isExist: boolean = false;
        Object.values(selectedRows).forEach((el: any) => {
            if (el?.Status === "Publié" && el.shopKey === undefined) {
                isExist = true;
            }
        });
        return isExist;
    }, [selectedRows]);
    let isExistProjectPublish = isExistPublishProject();

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isCreatedProject !== true && modify === true ? (
                <span
                    onClick={async () => {
                        const getCheckAddedTemplateURL: Response = await fetchWithToken(
                            `${process.env.REACT_APP_API_V2_URL}/settings/check_add_template?franchiseId=${franchiseID}&shopId=${shopID}`
                        );
                        const checkAddedTemplate = await getCheckAddedTemplateURL.json();
                        if (!checkAddedTemplate) {
                            toast.warning(
                                `${t(
                                    "Please note, you cannot create a project. Please check your general settings."
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        } else {
                            localStorage.setItem("projet", "projectId");
                            setIsKioskSetting(false);
                            setInitStoreVariables();
                            setInitDesignStoreVariables();
                            setIsCreatedProject(!isCreatedProject);
                            setSelectedRows([]);
                            setProjectRowData({});
                        }
                    }}
                    className={
                        isArchivedTemplateClicked
                            ? "w-100 ml-2 not-allowed-icon__clz pointer__clz cmn_btn_openForm"
                            : "w-100 ml-2 pointer__clz cmn_btn_openForm"
                    }
                >
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                    >
                        {t("Create a project")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : modify === true ? (
                <CustomMainColorIconButton
                    icon="PlusIcon"
                    className="lgo_btn_BTbuttonRéinitialiser"
                    rounded
                    disabled={selectedRows.length === 0 ? false : true}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsInInitializationModalOpened(true);
                        } else {
                            setIsPlusIconClicked(true);
                        }
                    }}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </CustomMainColorIconButton>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={27}
                fill="black"
                className={`${classnames("cmn_drp_FRmenu ml-2", {
                    "not-allowed-icon__clz": isLoading,
                })}`}
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className="cmn_btn_FRlistActivated dropdown-item pl-1"
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsArchivedTemplateClicked(false);
                                setIsCreatedProject(false);
                                setSelectedRows([]);
                            });
                        } else {
                            setIsArchivedTemplateClicked(false);
                            setIsCreatedProject(false);
                            setSelectedRows([]);
                        }
                    }}
                >
                    {t("Templates enabled")} ({numberActive})
                </div>
                {!oneShop ? (
                    <div
                        style={{ cursor: "pointer" }}
                        className="cmn_btn_FRlistArchived dropdown-item pl-1"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsArchivedTemplateClicked(true);
                                    setIsCreatedProject(false);
                                    setSelectedRows([]);
                                });
                            } else {
                                setIsArchivedTemplateClicked(true);
                                setIsCreatedProject(false);
                                setSelectedRows([]);
                            }
                        }}
                    >
                        {t("Archived templates")} ({numberArchived})
                    </div>
                ) : null}
                {modify === true && (
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                        className={`${classnames(
                            "dropdown-item pl-1 cmn_btn_FRunarchive cmn_btn_delete",
                            {
                                "not-allowed-icon__clz":
                                    selectedRows.length === 0 ||
                                    (isExistProjectPublish &&
                                        !isArchivedTemplateClicked) ||
                                    isCreatedProject,
                            }
                        )}`}
                        onClick={() => {
                            oneShop
                                ? setIsModalDissociateOpened(
                                      !isModalDissociateOpened
                                  )
                                : shopsAssociate.length !== 0 && !oneShop
                                ? ErrorToast(
                                      `${t(
                                          "Please note that you cannot archive a Template associated with the store(s)."
                                      )!}`
                                  )
                                : setIsModalArchiveOpened(
                                      !isModalArchiveOpened
                                  );
                        }}
                    >
                        {!isArchivedTemplateClicked
                            ? oneShop
                                ? `${t("Delete")}`
                                : `${t("Archive")}`
                            : `${t("Enable")}`}
                    </div>
                )}
                {!oneShop && modify === true && (
                    <div
                        style={{
                            cursor: "pointer",
                        }}
                        className={`${classnames(
                            "dropdown-item pl-1 cmn_btn_FRunarchive cmn_btn_delete",
                            {
                                "not-allowed-icon__clz":
                                    isCreatedProject ||
                                    selectedRows.length === 0 ||
                                    isArchivedTemplateClicked ||
                                    (Array.isArray(selectedRows) &&
                                        selectedRows.every(
                                            (el: any) =>
                                                el.subRows === undefined ||
                                                el.key_project !== undefined
                                        )),
                            }
                        )}`}
                        onClick={() => {
                            setIsModesModalDisassociate(true);
                        }}
                    >
                        {t("Dissociate")}
                    </div>
                )}
            </div>
        </div>
    );
}
