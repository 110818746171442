import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { setGlobalDataIsUpdated } from "../store";
import { store } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsEdited: Function;
    setDataIsUpdated: Function;
    dataFidelity: any;
    associated: any[];
    isAddFiscalYearClicked: boolean;
    setIsAddFiscalYearClicked: Function;
};

export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    setIsEdited,
    setDataIsUpdated,
    dataFidelity,
    associated,
    isAddFiscalYearClicked,
    setIsAddFiscalYearClicked,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();

    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);
     const uuidUser = localStorage.getItem("uuidUser");


    async function editFidelity() {
        toast.dismiss();
        let objectData = {};
        let localData: any[] = [];

        associated.forEach(async (el: any) => {
            Object.assign(objectData, {
                [el.Id]: {
                    designation: dataFidelity.data[el.Id].designation,
                    type: dataFidelity.data[el.Id].type,
                    number_fidelity_points:
                        dataFidelity.data[el.Id].number_fidelity_points,
                    money_correspondence:
                        dataFidelity.data[el.Id].money_correspondence,
                    devise: dataFidelity.data[el.Id].devise,
                    pays: dataFidelity.data[el.Id].pays,
                    authentication_mode:
                        dataFidelity.data[el.Id].authentication_mode,
                    sortedVisibility: dataFidelity.data[el.Id].sortedVisibility,
                    Login: dataFidelity.data[el.Id].Login,
                    password: dataFidelity.data[el.Id].password,
                    note: dataFidelity.data[el.Id].note,
                    isActive: el.isActive,
                    isArchived: el.isArchived,
                    shopId: el.shopId,

                    dashboardLink: dataFidelity.data[el.Id].dashboardLink,
                    user: dataFidelity.data[el.Id].user,
                    apiKey: dataFidelity.data[el.Id].apiKey,
                    auresKonnectGroup:
                        dataFidelity.data[el.Id].auresKonnectGroup,
                    isCentralizationActive:
                        dataFidelity.data[el.Id].isCentralizationActive,
                        isVisibiliteActive:
                        dataFidelity.data[el.Id].isVisibiliteActive,
                        
                    fidelityChoiceIndex:
                        dataFidelity.data[el.Id].fidelityChoiceIndex,
                },
            });

            let dataOfFidelity = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                uuidUser: uuidUser,
                data: objectData,
                isShop: true,
                isAssociate: true,
            };

            localData.push(
                fetchWithToken(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataOfFidelity),
                    }
                ).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }

        if (error === false) {
            toast.success(
                `${t("The fidelity setting has been successfully modified")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        }
        setDataIsUpdated(true);
        setIsAddFiscalYearClicked(!isAddFiscalYearClicked);
        setIsEdited(false);
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsAddFiscalYearClicked(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        editFidelity();
                        setGlobalDataIsUpdated(true);
                        setIsAddFiscalYearClicked(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
