import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";

import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import ErrorToast from "@components/Common/ErrorTost";

import { store as posEditor } from "../../../../components/VerticalLayout/store";
import store, {
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedPosEditor,
    setIsConsultModalOpened,
    setSelectedRowsPosEditor,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { useFetchWithKcToken } from "@hooks/useFetchWithKcToken";
import { keycloak  } from "../.././../../keycloak";

export async function redisPosEditor(uidShop: string, t: any) {
    toast.dismiss();

    const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/RedisPosEditor?shopId=${uidShop}`;
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", authorization: `Bearer ${keycloak.token}` },
    };
    try {
        mutate(
            apiUrlAdd,
            await fetch(apiUrlAdd, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(`${t("sync successfully")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });
                })
        );
    } catch (_) {
        toast.error(`${t("There's an error")!}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
}
export function CustomIcons({
    e,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isPosEditorArchived,
    selectedRows,
    type,
}: any) {
    const { t } = useTranslation();
    const fetchWithToken = useFetchWithKcToken();
    
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        posEditorData,
        shopsPosEditorData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(posEditor);

    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archivePosEditor();
    }
    function archivePosEditor() {
        let idsAssociated: any = [];
        if (posEditorData.data !== undefined) {
            posEditorData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activePosEditor() {
        toast.dismiss();
        let dataPosEditor = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,
            data: {
                [e.selectedRow.Id]: {
                    posEditor: e.selectedRow[t("Pos Editor")],
                    note: e.selectedRow[t("Remark")],
                    codeComment: e.selectedRow["codeComment"],
                    isActive: false,
                    isArchived: false,
                    shopId: e.selectedRow.shopId,
                    user: e.selectedRow.user,
                    versionServer: e.selectedRow.versionServer,
                    keyAuthentication: e.selectedRow.keyAuthentication,
                    apiEmail: e.selectedRow.apiEmail,
                    port: e.selectedRow.port,
                    server: e.selectedRow.server,
                    apiServer: e.selectedRow.apiServer,
                    setupId: e.selectedRow.setupId,
                    apiPassword: e.selectedRow.apiPassword,
                    modeOfSaleEmpId: e.selectedRow.modeOfSaleEmpId,
                    modeOfSaleLivId: e.selectedRow.modeOfSaleLivId,
                    modeOfSaleSPId: e.selectedRow.modeOfSaleSPId,
                    divisionPriceItems: e.selectedRow.divisionPriceItems,
                    divisionPriceOptions: e.selectedRow.divisionPriceOptions,
                    divisionTax: e.selectedRow.divisionTax,
                    layoutLabel: e.selectedRow.layoutLabel,
                    apiWebhook: e.selectedRow.apiWebhook,
                    happyHour: e?.selectedRow?.happyHour,
                    happyHourState: e?.selectedRow?.happyHourState,
                    tcposStoreId: e.selectedRow?.tcposStoreId,
                    tcposWebhookPort: e.selectedRow?.tcposWebhookPort,
                    tcposWebhookIPAddess: e.selectedRow?.tcposWebhookIPAddess,
                    tcposFranchiseSchema: e.selectedRow?.tcposFranchiseSchema,
                    tcposPaymentCash: e.selectedRow?.tcposPaymentCash,
                    tcposPaymentCreditCard:
                        e.selectedRow?.tcposPaymentCreditCard,
                    tcposSetupDisk: e.selectedRow?.tcposSetupDisk,
                    tcposCodeCountry: e.selectedRow?.tcposCodeCountry,
                    tcposLangue: e.selectedRow?.tcposLangue,
                    tcposApiPort: e.selectedRow?.tcposApiPort,
                    tcposPartnerApiUrl: e.selectedRow?.tcposPartnerApiUrl,
                    tcposIp: e.selectedRow?.tcposIp,
                    tcposConnectionCheckTime:
                        e.selectedRow?.tcposConnectionCheckTime,
                    tcposDeletingDataSate: e.selectedRow?.tcposDeletingDataSate,
                    codeRetryTable: e.selectedRow?.codeRetryTable,
                    maxRetry: e.selectedRow?.maxRetry,
                    retryDelai: e.selectedRow?.retryDelai,
                    checkStock: e.selectedRow?.checkStock,
                    orderValidationPreCheck:
                        e.selectedRow?.orderValidationPreCheck,
                    timeoutAPI: e.selectedRow?.timeoutAPI,
                    automaticProductionLevel:
                        e.selectedRow?.automaticProductionLevel,
                    tcposCorrTakeawayDisplayName:
                        e.selectedRow?.tcposCorrTakeawayDisplayName,
                    tcposCorrTakeawayID: e.selectedRow?.tcposCorrTakeawayID,
                    tcposCorrOnSiteDisplayName:
                        e.selectedRow?.tcposCorrOnSiteDisplayName,
                    tcposCorrOnSiteID: e.selectedRow?.tcposCorrOnSiteID,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/posEditor`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataPosEditor),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetchWithToken(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The Pos Editor has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isPosEditorArchived ? "" : t("configure")
                                }
                            >
                                <div>
                                    <SettingsIcon
                                        onClick={() => {
                                            setIsConsultModalOpened(true);
                                            setSelectedRows([e.selectedRow]);
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ marginTop: "8px" }}
                                        className={`${classnames(
                                            "pointer__clz fed_icn_BTconsult",
                                            {
                                                "not-allowed-icon__clz": isPosEditorArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isPosEditorArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div className="">
                                        <TrashIcon
                                            onClick={() => {
                                                setIsArchiveModalOpened(true);
                                                setSelectedRowsPosEditor([]);
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 cmn_icn_BTarchived d-none",
                                                {
                                                    "not-allowed-icon__clz":
                                                        e.selectedRow[
                                                            t("State")
                                                        ] === true,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    shopsPosEditorData.othersData.allPosEditor.data.filter(
                                                        (element: any) =>
                                                            element.shopId ===
                                                            e.selectedRow.shopId
                                                    )[0].subRows.length !== 0
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one Pos Editor"
                                                        )
                                                    );
                                                } else {
                                                    activePosEditor();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop +
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop -
                                                            1
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 mr-2 cmn_icn_BTunarchive d-none",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}

                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz mt-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedPosEditor([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.Id,
                                        },
                                    ]);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
